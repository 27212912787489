export const generateRevenueKey = (member_id: number, isoDate: string) => {
    const month = generateMonthKey(isoDate);
    return `${member_id}_${month}`;
};

export const generateFundTableKey = (circle_id: number, isoDate: string) => {
    return generateRevenueKey(circle_id, isoDate);
};

/** Strip the day and/or time from the ISO date string. */
export const generateMonthKey = (isoDate: string) => {
    const month = isoDate.substring(0, 7);
    return month;
};

export const getNextMonthKey = (monthKey: string) => {
    const date = monthKey ? new Date(monthKey) : new Date();
    date.setMonth(date.getMonth() + 1);
    date.setHours(15); // Handle DST.
    const isoDate = date.toISOString();
    return generateMonthKey(isoDate);
};

export const getPreviousMonthKey = (monthKey?: string) => {
    const date = monthKey ? new Date(monthKey) : new Date();
    date.setMonth(date.getMonth() - 1);
    date.setHours(15); // Handle DST.
    const isoDate = date.toISOString();
    return generateMonthKey(isoDate);
};

export const generateMonthKeyArray = (startMonthKey: string, length: number) => {
    const monthKeyArray = [generateMonthKey(startMonthKey)];
    let nextMonthKey = startMonthKey;
    while (length > 1) {
        nextMonthKey = getNextMonthKey(nextMonthKey);
        monthKeyArray.push(nextMonthKey);
        length--;
    }
    return monthKeyArray;
};

export const generateMonthKeyRange = (startMonthKey: string, endMonthKey: string) => {
    const monthKeyArray = [generateMonthKey(startMonthKey)];
    let nextMonthKey = startMonthKey;
    while (nextMonthKey < endMonthKey) {
        nextMonthKey = getNextMonthKey(nextMonthKey);
        monthKeyArray.push(nextMonthKey);
    }
    return monthKeyArray;
};
