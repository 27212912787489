import format from 'date-fns/format';
import intervalToDuration from 'date-fns/intervalToDuration';
import parseISO from 'date-fns/parseISO';

import { LOCAL_DATE_PATTERN, LOCALE_CODE } from '../config';

export const formatNumber = (value: number, decimals = 2) => {
    const numberFormat = new Intl.NumberFormat(LOCALE_CODE, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
    const amountString = numberFormat.format(value);
    return amountString;
};

export const formatDate = (date_to_format: Date | string, pattern = null) => {
    if (date_to_format instanceof Object) {
        date_to_format = date_to_format.toISOString();
    }
    date_to_format = parseISO(date_to_format);
    const format_pattern = pattern ? pattern : LOCAL_DATE_PATTERN;
    const DateFormatted = format(date_to_format, format_pattern);
    return DateFormatted;
};

export const formatDatesInterval = (startDate?: Date, endDate?: Date) => {
    if (startDate && endDate) {
        const newEndDate = new Date(endDate);
        const { months, years } = intervalToDuration({
            start: startDate,
            end: newEndDate.setDate(newEndDate.getDate() + 2),
        });
        let output = '';

        if (years) {
            if (years === 1) {
                output = `${years} year`;
            } else {
                output = `${years} years`;
            }
        }

        if (months) {
            if (years) output += ' and ';
            if (months === 1) {
                output = output + `${months} month`;
            } else {
                output = output + `${months} months`;
            }
        }

        return output;
    }
    return null;
};

export const formatHours = (hours: number) => {
    const sign = hours < 0 ? '-' : '';
    const absHours = Math.abs(hours);
    const h = Math.floor(absHours);
    const m = Math.abs(Math.round((absHours - h) * 60));

    let output = '';
    output += sign;
    if (h) {
        output += `${h}h`;
    }
    if (m) {
        output += `${m}m`;
    }
    if (output === '') {
        output = '0';
    }
    return output;
};
