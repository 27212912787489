import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import LegacySignOutIcon from '@rsuite/icons/legacy/SignOut';
import TagNumberIcon from '@rsuite/icons/TagNumber';
import ThemeIcon from '@rsuite/icons/TextImage';
import headerLogo from 'assets/Circles-logo-vaaka-nega.png';
import { resetCognitoSession } from 'auth/cognito';
import { envConfig, ValidEnvironments } from 'config';
import { useLocalStorage } from 'hooks/misc/useLocalStorage';
import { useDevMode } from 'hooks/useDevMode';
import { useProfile } from 'hooks/useProfile';
import { useWiki } from 'hooks/useWiki';
import { debounce } from 'lodash';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownProps, FlexboxGrid, Input, List, Loader, Nav, Navbar, Toggle } from 'rsuite';

import { useAdminTasks } from './useAdminTasks';
import { UserWithPicture } from './UserWithPicture';

function AdminTasksIcon({ isLoading, noTasks }: { isLoading?: boolean; noTasks?: boolean }) {
    if (isLoading === true || typeof isLoading === undefined) {
        return <Loader size="md" />;
    }

    const iconCSS: CSSProperties = { fontSize: '2em' };

    if (noTasks === true) {
        return <CheckOutlineIcon style={{ ...iconCSS, color: 'aquamarine' }} />;
    }

    return <InfoOutlineIcon style={{ ...iconCSS, color: 'darkorange' }} />;
}

const menuWidth = '25em';

const listStyle: CSSProperties = {
    position: 'absolute',
    zIndex: 999,
    color: 'black',
    width: menuWidth,
};

const listItemStyle: CSSProperties = {
    paddingLeft: '1em',
};

const devOrStagingEnvironment = envConfig.app.stage === 'dev' || envConfig.app.stage === 'staging';

const NavigationBar = () => {
    const { user, isAdmin } = useProfile();
    const adminTasks = useAdminTasks();
    const { toggleAdmin } = useDevMode();
    const [theme, setTheme] = useLocalStorage('theme', 'default');
    const [searchText, setSearchText] = useState('');
    const [resultsOpen, setResultsOpen] = useState(true);
    const menuRef = useRef<HTMLDivElement>(null);
    const { searchWiki, setWikiSearch } = useWiki({});

    const colorScheme: { [key in ValidEnvironments]: string } = {
        test: 'green',
        dev: 'blue',
        staging: 'purple',
        production: '#3498ff',
    };

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    useEffect(() => {
        const debouncedHandleSearch = debounce((value: string) => {
            setWikiSearch(value);
            setResultsOpen(true);
        }, 1000);

        debouncedHandleSearch(searchText);

        return () => {
            debouncedHandleSearch.cancel();
        };
    }, [searchText]);

    // Close the menu when the user clicks outside of it
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setResultsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const searchWikiResults = searchWiki.data;

    return (
        <Navbar appearance="inverse" style={{ background: colorScheme[envConfig.app.stage] }}>
            <Navbar.Brand>
                <img src={headerLogo} alt="logo" style={{ height: 30, marginRight: 10 }} />
                {envConfig.app.stage === 'dev' && 'LOCALHOST'}
            </Navbar.Brand>

            <Nav>
                {isAdmin && (
                    <Nav.Menu
                        icon={
                            <AdminTasksIcon
                                isLoading={adminTasks?.isLoading}
                                noTasks={adminTasks?.noTasks}
                            />
                        }
                    >
                        {adminTasks?.tasks.map((task, idx) => (
                            <Nav.Item key={`task_${idx} `}>
                                <Link to={task.link}>{task.text}</Link>
                            </Nav.Item>
                        ))}
                    </Nav.Menu>
                )}
            </Nav>
            <Nav>
                <div style={{ width: menuWidth, paddingTop: '1em' }} ref={menuRef}>
                    <div style={{ display: 'flex', justifyContent: 'row', gap: '0.5em' }}>
                        <Input
                            placeholder="Search wiki"
                            value={searchText}
                            onChange={handleSearch}
                        />
                        {searchWiki.isLoading && (
                            <Loader
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            />
                        )}
                    </div>
                    {searchText && searchWikiResults && resultsOpen ? (
                        searchWikiResults.length > 0 ? (
                            <List hover style={listStyle}>
                                {searchWikiResults.map((item, index) => (
                                    <Link key={`wiki_link_${index}`} to={`/wiki/${item.id}`}>
                                        <List.Item style={listItemStyle} key={index} index={index}>
                                            {item.revision.title}
                                        </List.Item>
                                    </Link>
                                ))}
                            </List>
                        ) : (
                            <List hover style={listStyle}>
                                <List.Item style={listItemStyle}>No results found</List.Item>
                            </List>
                        )
                    ) : null}
                </div>
            </Nav>

            <Nav pullRight>
                <Nav.Menu
                    renderToggle={(props: DropdownProps, ref: React.Ref<string>) => {
                        return (
                            <FlexboxGrid
                                {...props}
                                ref={ref}
                                align="middle"
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                            >
                                {user && <UserWithPicture user={user} fontWeight="bold" />}
                            </FlexboxGrid>
                        );
                    }}
                >
                    <Nav.Item icon={<ThemeIcon />}>
                        <span>Theme</span>
                        <Toggle
                            style={{ marginLeft: 20 }}
                            checkedChildren="Dark"
                            unCheckedChildren="Default"
                            checked={theme === 'dark'}
                            onChange={() => {
                                setTheme(theme == 'dark' ? 'default' : 'dark');
                                window.location.reload();
                            }}
                        />
                    </Nav.Item>
                    <Nav.Item icon={<TagNumberIcon />} disabled>
                        Version {envConfig.app.version}
                    </Nav.Item>
                    {devOrStagingEnvironment && (
                        <Nav.Item
                            icon={<LegacySignOutIcon />}
                            onClick={() => {
                                toggleAdmin.mutate();
                            }}
                        >
                            DEV/STG: Toggle admin
                        </Nav.Item>
                    )}
                    <Nav.Item
                        icon={<LegacySignOutIcon />}
                        onClick={() => {
                            resetCognitoSession();
                            location.reload();
                        }}
                    >
                        Sign Out
                    </Nav.Item>
                </Nav.Menu>
            </Nav>
        </Navbar>
    );
};

export default NavigationBar;
