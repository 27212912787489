import { DateRange } from 'rsuite/esm/DateRangePicker';

const timeDurationDay = 24 * 60 * 60 * 1000;

export const getThisWeekRange = (d: Date): DateRange => {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    const first_date = d.setDate(diff);
    const last_date = first_date + 6 * timeDurationDay;

    return [new Date(first_date), new Date(last_date)];
};

export const getPreviousWeekRange = (d: Date): DateRange => {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - 7 - day + (day == 0 ? -6 : 1);
    const firstDayPreviousWeek = d.setDate(diff);
    const lastDayPreviousWeek = firstDayPreviousWeek + 6 * timeDurationDay;
    return [new Date(firstDayPreviousWeek), new Date(lastDayPreviousWeek)];
};

export const getThisMonthRange = (d: Date): DateRange => {
    d = new Date(d);
    const first_date = d.setDate(1);
    d.setMonth(d.getMonth() + 1);
    const last_date = d.setHours(d.getHours() - 24);
    return [new Date(first_date), new Date(last_date)];
};

export const getPreviousMonthRange = (d: Date): DateRange => {
    d = new Date(d); // current date
    d.setDate(1); // going to 1st of the month
    const lastDayPreviousMonth = d.setHours(d.getHours() - 24);
    const firstDayPreviousMonth = d.setDate(1); // going to first day of the previous month.
    return [new Date(firstDayPreviousMonth), new Date(lastDayPreviousMonth)];
};

export const getIsPastDate = (givenDate: string | undefined) => {
    let dateHasPassed = false;
    if (givenDate) {
        const [year, month, date] = givenDate.split('-').map(Number);
        const costEndDate = new Date(year, month, date);
        dateHasPassed = costEndDate < new Date();
    }
    return dateHasPassed;
};
