import { Button, Form, Input, Radio, RadioGroup } from 'rsuite';

const styles = {
    resolution: {
        display: 'flex',
        columnGap: 20,
        margin: 10,
    },
    radioGroupLabel: {
        padding: '7px 2px 7px 12px',
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    input: {
        flex: 'auto',
    },
};

interface Props {
    setText: (text: string) => void;
    setVotePassed: (votePassed: boolean) => void;
    remove: () => void;
}

export const Resolution = ({ remove, setText, setVotePassed }: Props) => {
    return (
        <div style={styles.resolution}>
            <Form.Group style={styles.input}>
                <Input
                    name="resolution"
                    placeholder="Enter resolution"
                    onChange={value => setText(value)}
                />
            </Form.Group>
            <Form.Group>
                <RadioGroup
                    name="radioList"
                    inline
                    onChange={value => setVotePassed(Boolean(value))}
                    defaultValue={1}
                >
                    <span style={styles.radioGroupLabel}>Vote passed: </span>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                </RadioGroup>
            </Form.Group>
            <Form.Group>
                <Button appearance="primary" color="red" onClick={() => remove()}>
                    Remove
                </Button>
            </Form.Group>
        </div>
    );
};
