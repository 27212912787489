import { Col, FlexboxGrid, Grid, Panel, PanelGroup } from 'rsuite';

import RevenueTable from '../tables/RevenueTable';

const RevenueScreen = () => {
    return (
        <Grid fluid>
            <Col xs={24}>
                <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                    <FlexboxGrid justify="space-between" align="middle">
                        <Panel>
                            <h1>Revenue</h1>
                        </Panel>
                    </FlexboxGrid>
                </Panel>
                <PanelGroup accordion defaultActiveKey={1} bordered>
                    <Panel eventKey={1} bordered header={<h3>Revenue by the Month</h3>}>
                        <RevenueTable></RevenueTable>
                    </Panel>
                </PanelGroup>
            </Col>
        </Grid>
    );
};

export default RevenueScreen;
