import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import PlusIcon from '@rsuite/icons/Plus';
import format from 'date-fns/format';
import { useProfile } from 'hooks/useProfile';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Col, IconButton, Input, Row, SelectPicker } from 'rsuite';

import { queryClient } from '../../../App';
import { MYSQL_DATE_STRING } from '../../../config';
import { TimeTrackModel } from '../../../generated-types/timetrack';
import { useLeaveTypes } from '../../../hooks/useLeaveTypes';
import { TimeTrackQuery, useTimeTracks } from '../../../hooks/useTimeTracks';
import { LeaveTimeTrack, LeaveTimeTrackType } from '../../../schema/timetracks';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import { showErrorNotification, showNotification } from '../../ShowNotification';
import { LogHoursApplyParams, scrollToElement } from '.';
import { defaultDailyHours } from './date-hour-utils';
import { ErrorParagraph, formStyles } from './styles';

type Props = {
    selectedDate: Date;
    ttQuery: TimeTrackQuery;
    selectedTimetrack: TimeTrackModel | undefined;
    applyParams: (param: LogHoursApplyParams, value: string) => void;
};

export function LogLeaveFormEdit({
    selectedDate,
    ttQuery,
    selectedTimetrack: tt,
    applyParams,
}: Props) {
    const {
        formState: { errors },
        control,
        handleSubmit,
        setValue,
        register,
        resetField,
    } = useForm<LeaveTimeTrackType>({
        resolver: zodResolver(LeaveTimeTrack),
        defaultValues: {
            leave_type: tt?.leave_type,
        },
    });
    const { leaveTypes } = useLeaveTypes();
    const { user } = useProfile();
    const { addPersonalTimeTrack, deleteTimeTrack, updateTimeTrack } = useTimeTracks({});

    const leaveOptions = leaveTypes.map(leave => {
        return { value: leave.id, label: leave.name };
    });

    const onSubmit: SubmitHandler<LeaveTimeTrackType> = data => {
        const { id, ...rest } = data;
        if (id) {
            updateTimeTrack.mutate(
                {
                    id: Number(id),
                    payload: {
                        ...rest,
                        date: format(selectedDate, MYSQL_DATE_STRING),
                        member_id: Number(rest.member_id),
                    },
                },
                {
                    onSuccess: () => {
                        showNotification({ header: 'Timetrack updated succesfully.' });
                        queryClient.invalidateQueries([ttQuery]);
                        applyParams('timetrack_id', '');
                        scrollToElement('header');
                    },
                    onError: err => {
                        showErrorNotification(err);
                    },
                },
            );
        } else {
            addPersonalTimeTrack.mutate(
                {
                    ...data,
                    date: format(selectedDate, MYSQL_DATE_STRING),
                    member_id: Number(data.member_id),
                },
                {
                    onSuccess: () => {
                        resetField('comment');
                        setValue('leave_type', data.leave_type);
                        showNotification({ header: 'Timetrack submitted succesfully.' });
                        queryClient.invalidateQueries([ttQuery]);
                        scrollToElement('header');
                    },
                    onError: err => {
                        showErrorNotification(err);
                    },
                },
            );
        }
    };

    const handleTimeTrackDelete = () => {
        if (!tt) {
            return;
        }
        deleteTimeTrack.mutate(tt.id, {
            onSuccess: () => {
                showNotification({ header: 'Timetrack deleted succesfully!' });
                queryClient.invalidateQueries([ttQuery]);
                applyParams('timetrack_id', '');
                scrollToElement('header');
            },
        });
    };
    const userId = user?.id;
    const timetrackNotEditable = tt?.is_locked || tt?.is_confirmed;

    return (
        <form className={formStyles} onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <input hidden {...register('id')} defaultValue={tt?.id} />
                {userId && <input hidden {...register('member_id')} value={userId} />}
                <input hidden {...register('hours')} defaultValue={defaultDailyHours} />
            </Row>
            <Row>
                <Col xl={6}>
                    <p>Leave type</p>
                    <Controller
                        name="leave_type"
                        control={control}
                        render={({ field }) => (
                            <SelectPicker
                                readOnly={true}
                                defaultValue={tt?.leave_type}
                                {...field}
                                data={leaveOptions}
                            />
                        )}
                    />
                    <ErrorParagraph>{errors?.leave_type?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <p>Comment</p>
                <Controller
                    name="comment"
                    control={control}
                    defaultValue={tt?.comment ?? ''}
                    render={({ field }) => (
                        <Input
                            as="textarea"
                            type="text"
                            rows={3}
                            readOnly={updateTimeTrack.isLoading || timetrackNotEditable}
                            {...field}
                        />
                    )}
                />
            </Row>
            <Row>
                {tt ? (
                    <Col>
                        {timetrackNotEditable ? (
                            <>
                                <CheckIcon color="green" />
                                &nbsp;
                                <b>Your abscense has been confirmed.</b>
                            </>
                        ) : (
                            <>
                                <Col>
                                    <IconButton
                                        icon={<PlusIcon />}
                                        type="submit"
                                        disabled={updateTimeTrack.isLoading}
                                        loading={updateTimeTrack.isLoading}
                                        appearance="primary"
                                        color="violet"
                                        placement="right"
                                        size="md"
                                    >
                                        Save absence
                                    </IconButton>
                                </Col>
                                <Col>
                                    <ConfirmationDialog
                                        handleOK={handleTimeTrackDelete}
                                        header={'Delete timetrack'}
                                        message={'Are you sure you want to delete this timetrack?'}
                                    >
                                        <IconButton
                                            icon={<CloseIcon />}
                                            appearance="primary"
                                            disabled={deleteTimeTrack.isLoading}
                                            loading={deleteTimeTrack.isLoading}
                                            color="red"
                                            placement="right"
                                            size="md"
                                        >
                                            Delete absence
                                        </IconButton>
                                    </ConfirmationDialog>
                                </Col>
                            </>
                        )}
                    </Col>
                ) : (
                    <Col>
                        <IconButton
                            icon={<PlusIcon />}
                            type="submit"
                            disabled={addPersonalTimeTrack.isLoading}
                            loading={addPersonalTimeTrack.isLoading}
                            appearance="primary"
                            color="green"
                            placement="right"
                            size="md"
                        >
                            Add new leave
                        </IconButton>
                    </Col>
                )}
            </Row>
        </form>
    );
}
