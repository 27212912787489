import * as z from 'zod';

export const titleMaxLength = 100;
export const experienceDescriptionMaxLength = 1200;

export const NewExperienceForm = z.object({
    title: z.string().min(1).max(titleMaxLength),
    description: z.string().min(1).max(experienceDescriptionMaxLength),
    start_date: z.date(),
    end_date: z.date().optional().nullable(),
    selected_skills: z.array(z.string()).optional().nullable(),
});

export const EditExperienceForm = NewExperienceForm.extend({
    id: z.string(),
});

export type NewExperienceFormType = z.infer<typeof NewExperienceForm>;
export type EditExperienceFormType = z.infer<typeof EditExperienceForm>;
