import CloseIcon from '@rsuite/icons/Close';
import ShieldIcon from '@rsuite/icons/Shield';
import { useMembers } from 'hooks/useMembers';
import { RefAttributes, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableProps, Tooltip, Whisper } from 'rsuite';
import { InnerCellProps } from 'rsuite-table/lib/Cell';

import { CircleModel } from '../../generated-types/circle';
import { MemberModel } from '../../generated-types/member';
import { useProfile } from '../../hooks/useProfile';
import EditMemberDialog from '../dialogs/EditMemberDialog';
import { WeightCell } from '../Formats';

const { Column, HeaderCell, Cell } = Table;

type TableData = {
    id: number;
    name: string;
    email: string;
    __circleRevenueShare: number;
    weight_sum: number;
    current_membership_count: number;
    image_url: string;
    is_active: boolean;
    is_admin: boolean;
    firstname: string;
    lastname: string;
    employment_start: string;
    role: string;
};

type Props = {
    data: MemberModel[];
    referenceCircle?: CircleModel;
    selectedUserId?: number;
};

const ImageCell = (
    props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
) => (
    <Cell {...props} style={{ padding: 0 }}>
        <div
            style={{
                width: 40,
                height: 40,
                background: '#f5f5f5',
                borderRadius: 20,
                marginTop: 2,
                marginLeft: 10,
                marginRight: 0,
                marginBottom: 2,
                overflow: 'hidden',
                display: 'inline-block',
            }}
        >
            {props.dataKey && (
                <img
                    alt="avatar"
                    referrerPolicy="no-referrer"
                    src={props.rowData[props.dataKey]}
                    width="42"
                />
            )}
            <div
                style={{
                    position: 'absolute',
                    bottom: '0px',
                    right: '0px',
                }}
            >
                {props.rowData.is_admin && (
                    <Whisper speaker={<Tooltip>Admin</Tooltip>}>
                        <ShieldIcon
                            style={{
                                paddingBottom: 2,
                                paddingTop: 2,
                                color: '#2196f3',
                                borderRadius: '25px',
                                background: '#FFFFFF',
                            }}
                        />
                    </Whisper>
                )}
                &nbsp;
                {!props.rowData.is_active && (
                    <Whisper speaker={<Tooltip>Inactive</Tooltip>}>
                        <CloseIcon
                            style={{
                                paddingBottom: 2,
                                paddingTop: 2,
                                color: 'red',
                                borderRadius: '25px',
                                background: '#FFFFFF',
                            }}
                        />
                    </Whisper>
                )}
            </div>
        </div>
    </Cell>
);

export const ActionCell = (
    props: JSX.IntrinsicAttributes &
        InnerCellProps &
        RefAttributes<HTMLDivElement> & { selecteduserid?: number },
) => {
    return (
        <Cell {...props}>
            <EditMemberDialog
                user={props.rowData}
                openDialog={
                    (props?.selecteduserid && props.selecteduserid === props.rowData.id) || false
                }
            />
        </Cell>
    );
};

const ActionCellProfile = (
    props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
) => {
    return (
        <Cell {...props}>
            <Link to={`/${props.rowData.id}/career`}>Profile</Link>
        </Cell>
    );
};

export default function MemberTable(props: Props) {
    const { isAdmin } = useProfile();
    const { roles } = useMembers();
    const [sortColumn, setSortColumn] = useState<keyof TableData>('name');
    const [sortType, setSortType] = useState<TableProps['sortType']>('desc');

    const setSort = (sortColumn: keyof TableData) => {
        setSortColumn(sortColumn);
        if (sortType === 'asc') {
            setSortType('desc');
        } else {
            setSortType('asc');
        }
    };

    const getTableData = () => {
        const tableData: TableData[] = props.data.map(member => {
            return {
                id: member.id,
                name: `${member.firstname} ${member.lastname}`,
                email: member.email,
                __circleRevenueShare: props.referenceCircle
                    ? Math.round((props.referenceCircle.current_weight / member.weight_sum) * 100) /
                      100
                    : 0,
                weight_sum: member.weight_sum,
                current_membership_count: member.current_membership_count,
                image_url: member.image_url || '',
                is_active: member.is_active,
                is_admin: member.is_admin,
                firstname: member.firstname,
                lastname: member.lastname,
                employment_start: member.employment_start || '',
                role: roles.find(role => role.role === member.role)?.role || '',
                role_name: roles.find(role => role.role === member.role)?.name || '',
            };
        });

        tableData.sort((a, b) => {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
        });
        sortType === 'desc' && tableData.reverse();
        return tableData;
    };

    return (
        <Table
            autoHeight
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={setSort as (sortColumn: string) => void}
            data={getTableData()}
        >
            <Column align="left" width={60}>
                <HeaderCell>&nbsp;</HeaderCell>
                <ImageCell dataKey="image_url" />
            </Column>
            <Column align="left" sortable width={180}>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
            </Column>
            {!props.referenceCircle && (
                <>
                    <Column align="left" sortable width={240}>
                        <HeaderCell>Email</HeaderCell>
                        <Cell dataKey="email" />
                    </Column>
                    <Column align="left" sortable>
                        <HeaderCell>Role</HeaderCell>
                        <Cell dataKey="role_name" />
                    </Column>
                </>
            )}
            {props.referenceCircle ? (
                <Column align="right" sortable width={130}>
                    <HeaderCell>Revenue Share</HeaderCell>
                    <Cell dataKey="__circleRevenueShare" />
                </Column>
            ) : (
                <Column align="right" sortable width={120}>
                    <HeaderCell>Weight Sum</HeaderCell>
                    <WeightCell dataKey="weight_sum" />
                </Column>
            )}
            {!props.referenceCircle && (
                <Column align="right" sortable width={90}>
                    <HeaderCell>Circles</HeaderCell>
                    <Cell dataKey="current_membership_count" />
                </Column>
            )}

            {isAdmin && !props.referenceCircle && (
                <Column width={120}>
                    <HeaderCell align="center">Actions</HeaderCell>
                    <ActionCell align="center" selecteduserid={props?.selectedUserId} />
                </Column>
            )}

            {isAdmin && !props.referenceCircle && (
                <Column width={120}>
                    <HeaderCell align="center">Profile</HeaderCell>
                    <ActionCellProfile align="center" />
                </Column>
            )}
        </Table>
    );
}
