import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { getIsPastDate } from 'utils/dateUtils';

import lambdaApi from '../api/LambdaApi';
import {
    OperativeCostList,
    OperativeCostModel,
    OperativeCostRequestPayload,
} from '../generated-types/operative_cost';

export function useOperativeCost(circleId: number | undefined = undefined) {
    const query = `/me/operativecosts${circleId ? `?circleId=${circleId}` : ''}`;

    const { data, isLoading, refetch } = useQuery<OperativeCostList>([query], () =>
        lambdaApi.get(query).then(res => res.data),
    );

    const createOperativeCost = useMutation<
        OperativeCostModel,
        AxiosError,
        OperativeCostRequestPayload
    >(
        payload => {
            return lambdaApi.post(`/operativecosts`, payload).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    const updateOperativeCost = useMutation<
        OperativeCostModel,
        AxiosError,
        { id: number; payload: OperativeCostRequestPayload }
    >(
        props => {
            const { id, payload } = props;
            return lambdaApi.put(`/operativecosts/${id}`, payload).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    let total = 0;
    if (data) {
        const costs = data.map(cost => {
            return cost?.amount ?? 0;
        });

        total = costs.reduce((total, cost, idx) => {
            let realcost;

            const endDate = data[idx]?.end_date;

            if (getIsPastDate(endDate)) {
                realcost = 0;
            } else {
                realcost = cost;
            }
            return total + realcost;
        }, 0);
    }

    return {
        operativeCost: data ?? ([] as OperativeCostList),
        total,
        isLoading,
        createOperativeCost,
        updateOperativeCost,
        refetch,
    };
}
