import BalanceHistoryChart from 'components/charts/BalanceHistoryChart';
import { Euros } from 'components/Formats';
import { TransactionTable } from 'components/tables/TransactionTable';
import { CircleModel } from 'generated-types/circle';
import { useTransactions } from 'hooks/useTransactions';
import { Col, FlexboxGrid, Panel } from 'rsuite';

export const BalanceTab = ({ circle }: { circle: CircleModel }) => {
    const {
        isLoading,
        page,
        setPage,
        pageSize,
        setPageSize,
        sortColumn,
        setSortColumn,
        sortType,
        setSortType,
        transactions,
        totalTransactions,
    } = useTransactions(circle.id);
    return (
        <>
            <FlexboxGrid justify="start">
                <FlexboxGrid.Item as={Col} colspan={8}>
                    <h3>Current balance {Euros(circle.current_balance)} €</h3>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item as={Col} colspan={8}>
                    <h3>Limit {Euros(circle?.max_balance)} €</h3>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="start">
                <FlexboxGrid.Item as={Col} colspan={24} xl={12}>
                    <Panel>
                        <h4>Ledger</h4>
                        <TransactionTable
                            circleId={circle.id}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            sortColumn={sortColumn}
                            setSortColumn={setSortColumn}
                            sortType={sortType}
                            setSortType={setSortType}
                            transactions={transactions}
                            isLoading={isLoading}
                            totalTransactions={totalTransactions}
                        />
                    </Panel>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item as={Col} colspan={24} xl={12}>
                    <Panel>
                        <h4>Balance history</h4>
                        <BalanceHistoryChart circleId={circle.id} />
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};
