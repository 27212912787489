import { AxiosError } from 'axios';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { CircleModel } from 'generated-types/circle';
import { CreateMeetingRequest } from 'generated-types/meeting';
import { CreateMeetingCallbacks, useMeetings, useResolutions } from 'hooks/useMeetings';
import { useMembers } from 'hooks/useMembers';
import { useState } from 'react';
import { byDate } from 'utils/sortFunctions';

import { CloseFn, ExpandableForm } from '../../../../misc/ExpandableForm';
import { AddMeetingForm, Participant } from './AddMeetingForm';
import { MeetingMinutes } from './MeetingMinutes';
import { createResolutions, ResolutionModel } from './Resolutions/Resolutions';
type Props = { circle: CircleModel };

export function MeetingsTab({ circle }: Props) {
    const { createMeeting, meetings } = useMeetings(circle.id);
    const { resolutions: storedResolutions, refetchResolutions: getStoredResolutions } =
        useResolutions(circle.id);
    const { members } = useMembers();

    // Store resolutions until the meeting is created and meeting_id is known
    const [resolutions, setResolutions] = useState<ResolutionModel[]>([]);

    const createMeetingCallback = (): CreateMeetingCallbacks => ({
        onSuccess: meeting => {
            Promise.all(
                createResolutions(
                    resolutions.map(r => ({ ...r, meeting_id: meeting.id as number })),
                ),
            )
                .then(() => {
                    showNotification({ header: 'Meeting minutes created!' });
                    getStoredResolutions();
                })
                .catch(err => {
                    // TODO: Should we delete the meeting if the creation of resolutions fail?
                    showErrorNotification(err);
                });
        },
        onError: (err: AxiosError) => showErrorNotification(err),
    });

    const addNewMeeting = (close: CloseFn) => (meeting: CreateMeetingRequest) => {
        createMeeting(meeting, createMeetingCallback());
        close();
    };

    const possibleParticipants: Participant[] = members.map(m => ({
        id: m.id,
        firstName: m.firstname,
        lastName: m.lastname,
    }));

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '75%' }}>
                <div style={{ marginBottom: 20 }}>
                    <ExpandableForm
                        formTitle="Add Meeting Minutes"
                        style={{ width: '100%' }}
                        render={(close: CloseFn) => (
                            <AddMeetingForm
                                possibleParticipants={possibleParticipants}
                                onSubmit={addNewMeeting(close)}
                                resolutionsProps={{
                                    onChangeCallback: resolutions => {
                                        setResolutions(resolutions);
                                    },
                                }}
                            />
                        )}
                    />
                </div>

                {meetings.sort(byDate()).map(m => (
                    <MeetingMinutes
                        key={m.id}
                        meeting={m}
                        resolutions={
                            storedResolutions?.filter(
                                resolution => resolution.meeting_id === m.id,
                            ) || []
                        }
                    />
                ))}
            </div>
        </div>
    );
}
