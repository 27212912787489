import { zodResolver } from '@hookform/resolvers/zod';
import format from 'date-fns/format';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker, Input, Row, SelectPicker } from 'rsuite';

import { MYSQL_DATE_STRING } from '../../config';
import { LedgerRequestPayload } from '../../generated-types/ledger';
import { useCircles } from '../../hooks/useCircles';
import { useProfile } from '../../hooks/useProfile';
import { useTransactions } from '../../hooks/useTransactions';
import { TransactionSchema, TransactionSchemaType } from '../../schema/transaction';
import { DialogWrap } from '../misc/DialogWrap';
import { showErrorNotification, showNotification } from '../ShowNotification';

export function NewTransactionDialog() {
    const { user } = useProfile();
    const { circles } = useCircles();
    const { createTransaction } = useTransactions();
    const [open, setOpen] = useState(false);
    const {
        formState: { errors },
        control,
        handleSubmit,
        setValue,
        reset,
    } = useForm<TransactionSchemaType>({
        resolver: zodResolver(TransactionSchema),
    });

    const onSubmit: SubmitHandler<TransactionSchemaType> = data => {
        const { tx_date, ...rest } = data;

        const payload: LedgerRequestPayload = {
            tx_date: format(tx_date, MYSQL_DATE_STRING),
            ...rest,
        };

        createTransaction.mutate(payload, {
            onError: err => {
                showErrorNotification(err);
            },
            onSuccess: () => {
                showNotification({
                    header: 'New transaction created!',
                });
                reset({});
                setOpen(false);
            },
        });
    };

    if (!user) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                title={'New Transaction'}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Row>
                    <p>Description</p>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input maxLength={255} type="text" {...field} />}
                    />
                    <p className="error">{errors?.description?.message}</p>
                </Row>
                <Row>
                    <p>Reference invoice</p>
                    <Controller
                        name="reference_invoice"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input maxLength={255} type="text" {...field} />}
                    />
                    <p className="error">{errors?.description?.message}</p>
                </Row>

                <Row>
                    <p>Amount</p>
                    <Controller
                        name="amount"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => <Input maxLength={10} {...field} />}
                    />
                    <p className="error">{errors?.amount?.message}</p>
                </Row>
                <Row>
                    <p>Circle</p>
                    <SelectPicker
                        searchable={false}
                        onChange={val => {
                            if (!val) {
                                return;
                            }
                            setValue('circle_id', val);
                        }}
                        data={circles.map(c => {
                            return { value: c.id, label: c.name };
                        })}
                    />
                    <p className="error">{errors?.circle_id?.message}</p>
                </Row>
                <Row>
                    <p>Transaction date</p>
                    <Controller
                        name="tx_date"
                        control={control}
                        defaultValue={new Date()}
                        render={({ field }) => <DatePicker oneTap {...field} />}
                    />
                    <p className="error">{errors?.tx_date?.message}</p>
                </Row>
            </DialogWrap>
        </form>
    );
}
