import { WeightType } from '../components/tables/WeightEditTable';
import { MembershipList } from '../generated-types/membership';

type GetCircleFundingMapType = {
    memberships: MembershipList;
    circleWeights: WeightType;
    monthlyRevenue: number;
    revenueShare: number;
};

type MemberWeightSumsType = {
    [key: number]: number;
};

type MemberWeightMapsType = {
    [key: number]: { [key: number]: number };
};

export function getCircleFundingMap({
    memberships,
    circleWeights,
    monthlyRevenue,
    revenueShare,
}: GetCircleFundingMapType) {
    // Get a list of active memberships.
    const activeMemberships = Object.values(memberships).filter(ms => !ms.end_date);

    // Calculate the weight sum of each member.
    const memberWeightSums: MemberWeightSumsType = {};
    activeMemberships.forEach(ms => {
        memberWeightSums[ms.member_id] = memberWeightSums[ms.member_id] || 0;
        memberWeightSums[ms.member_id] += Number(circleWeights[ms.circle_id]);
    });

    const memberWeightMaps: MemberWeightMapsType = {};
    const circleFundingMap: MemberWeightSumsType = {};

    activeMemberships.forEach(ms => {
        if (!memberWeightMaps?.[ms.member_id]) {
            memberWeightMaps[ms.member_id] = {};
        }
        memberWeightMaps[ms.member_id][ms.circle_id] =
            Number(circleWeights[ms.circle_id]) / memberWeightSums[ms.member_id];
        circleFundingMap[ms.circle_id] = 0;
    });

    const memberCount = Object.values(memberWeightMaps).length;
    const memberRevenueShare = (monthlyRevenue * revenueShare) / memberCount;

    Object.values(memberWeightMaps).forEach(mwm => {
        Object.keys(mwm).forEach(circle_id => {
            circleFundingMap[Number(circle_id)] += mwm[Number(circle_id)] * memberRevenueShare;
        });
    });

    return circleFundingMap;
}
