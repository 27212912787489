import { useInternalProjects } from 'hooks/useInternalProjects';
import { useProfile } from 'hooks/useProfile';
import { Button, Col, FlexboxGrid, Grid, Loader, Panel } from 'rsuite';

import EditInternalProjectDialog from '../dialogs/EditInternalProjectDialog';
import InternalProjectTable from '../tables/InternalProjectTable';

const InternalProjectsScreen = () => {
    const { isAdmin } = useProfile();
    const { internalProjects, isSuccess } = useInternalProjects();

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Internal Projects</h1>
                            </Panel>
                            <Panel>
                                {isAdmin ? (
                                    <EditInternalProjectDialog>
                                        <Button appearance="primary">Add Internal Project</Button>
                                    </EditInternalProjectDialog>
                                ) : null}
                            </Panel>
                        </FlexboxGrid>
                    </Panel>
                    <Panel
                        bordered
                        bodyFill
                        header={
                            <h3>
                                Internal Projects (
                                {isSuccess ? internalProjects.length : <Loader />})
                            </h3>
                        }
                    >
                        <InternalProjectTable />
                    </Panel>
                </Col>
            </Grid>
        </div>
    );
};

export default InternalProjectsScreen;
