import { Tag, Tooltip, Whisper } from 'rsuite';

import { TimeTrackModelList } from '../../../generated-types/timetrack';
import { formatHours } from '../../../utils/formatters';
import { defaultDailyHours } from '../log-hours/date-hour-utils';

type Props = {
    tableData: TimeTrackModelList;
};

export const HoursSummary = ({ tableData }: Props) => {
    const workingDaysSet = new Set();
    let totalHours = 0;
    tableData.forEach(r => {
        totalHours += r.hours;
        workingDaysSet.add(r.date.substring(0, 10));
    });
    const workingDayCount = workingDaysSet.size;
    const overTime = totalHours - workingDayCount * defaultDailyHours;

    return (
        <div style={{ display: 'block', marginTop: 20, textAlign: 'right' }}>
            <Whisper
                controlId="summary-total"
                placement="top"
                speaker={
                    <Tooltip>
                        Working days = The count of individual days when hours have been logged.
                    </Tooltip>
                }
                trigger="hover"
            >
                <Tag size="sm">
                    <span>
                        Total: {formatHours(totalHours)} in {workingDayCount} working days
                    </span>
                </Tag>
            </Whisper>
            <Whisper
                controlId="overtime"
                placement="top"
                speaker={<Tooltip>= TotalHours - 7.5 * WorkingDays</Tooltip>}
                trigger="hover"
            >
                <Tag size="sm" color={overTime < 0 ? 'blue' : overTime > 0 ? 'green' : 'violet'}>
                    <span>{`Difference to 7h30m/d: ${formatHours(overTime)}`}</span>
                </Tag>
            </Whisper>
        </div>
    );
};
