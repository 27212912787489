import * as z from 'zod';

export const headlineMaxLength = 100;
export const aboutMaxLength = 1800;

export const EditProfileForm = z.object({
    headline: z.preprocess(
        arg => (arg === null ? undefined : arg),
        z.string().max(headlineMaxLength).optional(),
    ),
    about: z.preprocess(
        arg => (arg === null ? undefined : arg),
        z.string().max(aboutMaxLength).optional(),
    ),
});

export type EditProfileFormType = z.infer<typeof EditProfileForm>;
