import { TimeTrackModelList } from '../../../generated-types/timetrack';

function mode(arr: (number | undefined)[]) {
    return arr
        .sort((a, b) => arr.filter(v => v === a).length - arr.filter(v => v === b).length)
        .pop();
}

export function getInternalProjectSuggestion(timetracks: TimeTrackModelList) {
    const internalProjectIds = timetracks.map(tt => tt.internal_project_id).filter(i => Boolean(i));
    if (internalProjectIds.length === 0) {
        return;
    }
    const mostCommonId = mode(internalProjectIds);
    const occurrences = timetracks.filter(t => t.internal_project_id === mostCommonId);
    if (occurrences.length < 2) {
        return;
    }
    return mostCommonId;
}

export function getSuggestedHours(currentHours: number, threshold: number) {
    return currentHours >= threshold ? 0 : threshold - currentHours;
}
