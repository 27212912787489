import { GracePeriodList } from '../generated-types/grace_period';
import { TimeTrackModel } from '../generated-types/timetrack';

const findValidGracePeriods = (gracePeriods: GracePeriodList, timeTrack: TimeTrackModel) => {
    const now = new Date();
    const nowStr = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
    const today = new Date(nowStr);
    return gracePeriods.filter(gp => {
        const ttDate = new Date(timeTrack.date);
        return (
            gp.assignment_id === timeTrack.assignment_id &&
            new Date(gp.start_date) <= ttDate &&
            new Date(gp.end_date) >= ttDate &&
            new Date(gp.locking_date) >= today
        );
    });
};

export default findValidGracePeriods;
