import { AxiosError } from 'axios';
import format from 'date-fns/format';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { MYSQL_DATE_STRING } from '../config';
import {
    AbsenceTimeTrack,
    TimeTrackModel,
    TimeTrackModelList,
    TimeTrackQueryOptions,
    TimeTrackQueryResult,
    TimetrackRequestPayload,
    TimetrackResponseModel,
} from '../generated-types/timetrack';
import { getThisMonthRange } from '../utils/dateUtils';
import { calculateTotalHours } from '../utils/timetrack';
import { useProfile } from './useProfile';

type TimeTrackQuerySharedProperties = {
    customer_projects?: number[];
    internal_projects?: number[];
    leave_types?: number[];
    members?: number[];
    is_confirmed?: 'confirmed' | 'unconfirmed' | '';
};

export type TimeTrackQuery = TimeTrackQuerySharedProperties & {
    start_date: Date;
    end_date: Date;
    first: number;
    count: number;
    sort_type: 'desc' | 'asc';
    sort_column: 'date';
};

export function useTimeTracks({ disableQuery }: { disableQuery?: boolean }) {
    const { user } = useProfile();
    const [firstDayOfTheMonth, lastDayOfTheMonth] = getThisMonthRange(new Date());
    const [ttQuery, setQuery] = useState<TimeTrackQuery>({
        first: 0,
        count: 50,
        leave_types: [],
        sort_type: 'desc',
        sort_column: 'date',
        start_date: firstDayOfTheMonth,
        end_date: lastDayOfTheMonth,
        members: [],
        customer_projects: [],
    });

    const { isLoading, isError, data, refetch } = useQuery<
        TimeTrackQueryOptions,
        AxiosError,
        TimeTrackQueryResult
    >(
        [ttQuery],
        () =>
            lambdaApi
                .post('/timetracks/query', {
                    ...ttQuery,
                    start_date: format(
                        ttQuery?.start_date || firstDayOfTheMonth,
                        MYSQL_DATE_STRING,
                    ),
                    end_date: format(ttQuery?.end_date || lastDayOfTheMonth, MYSQL_DATE_STRING),
                })
                .then(res => res.data),
        { enabled: Boolean(user) && !disableQuery },
    );

    function setTtQuery(newValues: Partial<TimeTrackQuery>) {
        const newFirst = newValues?.first ? newValues?.first : 0;
        setQuery({ ...ttQuery, first: newFirst, ...newValues });
    }

    const confirmLeaveTimeTracks = useMutation<
        TimeTrackModelList,
        AxiosError,
        [TimeTrackModelList, boolean | undefined]
    >(
        params => {
            const [payload, hasDoctorCertificate] = params;
            const certificateParam = `?doctorCertificate=${
                hasDoctorCertificate ? 'true' : 'false'
            }`;
            return lambdaApi
                .post(
                    `/timetracks/confirm${
                        typeof hasDoctorCertificate === 'undefined' ? '' : certificateParam
                    }`,
                    payload,
                )
                .then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    const addPersonalTimeTrack = useMutation<
        TimetrackResponseModel,
        AxiosError,
        TimetrackRequestPayload
    >(payload => {
        return lambdaApi.post('/timetracks', payload).then(res => res.data);
    });

    const getTimeTracksAsCSV = useMutation<string, AxiosError>(() => {
        return lambdaApi
            .post('/timetracks/query?responseType=csv', {
                ...ttQuery,
                start_date: format(ttQuery?.start_date || firstDayOfTheMonth, MYSQL_DATE_STRING),
                end_date: format(ttQuery?.end_date || lastDayOfTheMonth, MYSQL_DATE_STRING),
            })
            .then(res => res.data);
    });
    const addAbsenceTimeTrack = useMutation<'', AxiosError, AbsenceTimeTrack>(payload => {
        return lambdaApi.post('/timetracks/absence', payload).then(res => res.data);
    });

    const deleteTimeTrack = useMutation<'', AxiosError, string | number>(id => {
        return lambdaApi.delete(`/timetracks/${id}`).then(res => res.data);
    });

    const updateTimeTrack = useMutation<
        TimeTrackModel,
        AxiosError,
        { id: string | number; payload: TimetrackRequestPayload }
    >(params => {
        const { id, payload } = params;
        return lambdaApi.put(`/timetracks/${id}`, payload).then(res => res.data);
    });

    let filteredData: TimeTrackModelList = [];
    let totalHours = 0;

    if (data) {
        filteredData = data.results;
        totalHours = calculateTotalHours(data.results);
    }

    return {
        ttQuery,
        setTtQuery,
        timetracks: filteredData,
        timetrackCount: data?.total ?? 0,
        isLoading,
        isError,
        refetch,
        addAbsenceTimeTrack,
        confirmLeaveTimeTracks,
        getTimeTracksAsCSV,
        addPersonalTimeTrack,
        deleteTimeTrack,
        updateTimeTrack,
        hoursTotal: totalHours,
    };
}
