import * as z from 'zod';

export const TransactionSchema = z.object({
    amount: z
        .preprocess((val: unknown) => {
            const patchedVal = (val as string).replace(',', '.');
            const v = Number(patchedVal);
            return v;
        }, z.number())
        .refine(
            arg => {
                const validation = !isNaN(arg);
                return validation;
            },
            { message: 'Invalid numeric value' },
        ),
    circle_id: z.number(),
    description: z.string(),
    reference_invoice: z.string().optional(),
    tx_date: z.date(),
});

export type TransactionSchemaType = z.infer<typeof TransactionSchema>;
