// @ts-nocheck - too many TS-errors, whole Dialog should be refactored from ground-up
import { queryClient } from 'App';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { MYSQL_DATE_STRING } from 'config';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { AssignmentModelList } from 'generated-types/assignment';
import { CircleModelList } from 'generated-types/circle';
import { CustomerProjectList } from 'generated-types/customer_project';
import { InternalProjectList } from 'generated-types/internal_project';
import { MemberModel } from 'generated-types/member';
import { TimeTrackModel } from 'generated-types/timetrack';
import { useGracePeriods } from 'hooks/admin/useGracePeriods';
import { useTimeTracks } from 'hooks/useTimeTracks';
import { cloneElement, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    DatePicker,
    FlexboxGrid,
    Form,
    Input,
    InputNumber,
    Loader,
    Modal,
    SelectPicker,
} from 'rsuite';

import { useAssignments } from '../../hooks/admin/useAssignments';
import { useInternalProjects } from '../../hooks/useInternalProjects';
import { useMembers } from '../../hooks/useMembers';
import { useMyAssignments } from '../../hooks/useMyAssignments';
import { useProfile } from '../../hooks/useProfile';
import { arrayToMap } from '../../utils/arrayMapping';
import findOpenGracePeriods from '../../utils/findOpenGracePeriods';
const defaultTemplate = { date: new Date() };

function getInitialState(editable: Partial<TimeTrackModel> = {}) {
    if (!editable.id) {
        return { ...defaultTemplate, ...editable };
    }
    const { assignment_id, internal_project_id, date, ...withoutIds } = editable;

    return {
        ...withoutIds,
        date: date ? parse(date, 'yyyy-MM-dd', new Date()) : new Date(),
        project_id: editable.assignment_id ? `a�${assignment_id}` : `i�${internal_project_id}`,
    };
}

type Props = {
    adminMode?: boolean;
    existingTimetrack?: Partial<TimeTrackModel>;
    optionsData: {
        members: MemberModel[];
        circles: CircleModelList;
        internalProjects: InternalProjectList;
        customerProjects: CustomerProjectList;
        assignments?: AssignmentModelList;
    };
    children: JSX.Element;
};

const EditTimeTrackDialog = (props: Props) => {
    const { optionsData, adminMode } = props;
    const [formState, setFormState] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const { gracePeriods } = useGracePeriods();
    const { assignments: allAssignments } = useAssignments();
    const { members: members } = useMembers();
    const { myAssignments } = useMyAssignments();
    const { isAdmin } = useProfile();
    const { internalProjects } = useInternalProjects();
    const { addPersonalTimeTrack, deleteTimeTrack, ttQuery, updateTimeTrack } = useTimeTracks({
        disableQuery: true,
    });
    useEffect(() => {
        setFormState(getInitialState(props.existingTimetrack));
    }, [props.existingTimetrack]);

    const assignmentsOpts = adminMode ? allAssignments : myAssignments;

    const circlesMap = useMemo(() => arrayToMap(optionsData.circles), [optionsData.circles]);
    const customerProjectsMap = useMemo(
        () => arrayToMap(optionsData.customerProjects),
        [optionsData.customerProjects],
    );

    const membersOptions = members.map(m => ({
        label: `${m.lastname} ${m.firstname}`,
        value: m.id,
    }));

    const isAssignmentActive = a => {
        return (
            a.is_active &&
            (!a.start_date || new Date(a.start_date).getTime() < nowTicks) &&
            (!a.end_date || new Date(a.end_date).getTime() > nowTicks)
        );
    };

    const isGracePeriodForAssignmentOpen = a => {
        if (gracePeriods.length) {
            const openPeriods = findOpenGracePeriods(gracePeriods, optionsData.assignments);
            return openPeriods.find(period => period.assignment_id === a.id);
        }

        return false;
    };

    const nowTicks = new Date().getTime();
    const assignments = assignmentsOpts.filter(a => {
        return (
            members.findIndex(m => m.id === a.member_id) !== -1 &&
            (isAssignmentActive(a) || isGracePeriodForAssignmentOpen(a))
        );
    });

    const customerProjectOptions = assignments
        .map(a => {
            const name = customerProjectsMap[a.project_id]?.name;
            if (!name) {
                return;
            }
            return {
                label: customerProjectsMap[a.project_id]?.name ?? '',
                value: `a�${a.id}`,
                type: 'Customer Projects',
            };
        })
        .filter(a => Boolean(a));

    const internalProjectOptions = internalProjects.map(p => ({
        label:
            p.circle_id && circlesMap[p.circle_id]
                ? `${p.name} (${circlesMap[p.circle_id].name})`
                : p.name,
        value: `i�${p.id}`,
        type: 'Internal Projects',
    }));

    const projectOptions = customerProjectOptions.concat(internalProjectOptions).sort((a, b) => {
        if (a.type > b.type) {
            return 1;
        } else if (a.type < b.type) {
            return -1;
        }
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    });

    const locationOptions = [
        { label: 'Remote', value: 'Remote' },
        { label: 'Office', value: 'Office' },
        { label: 'Customer', value: 'Customer' },
    ];

    const handleFieldChange = changeEvent => {
        const newState = {
            ...formState,
            [changeEvent.field]: changeEvent.newValue,
        };
        if (changeEvent.field === 'project_id' && changeEvent.newValue.indexOf('a�') === 0) {
            const assignmentId = parseInt(changeEvent.newValue.substring(2), 10);
            const ass = assignments.find(a => a.id === assignmentId);
            newState['billing_rate'] = ass.billing_rate;
        }
        setFormState(newState);
    };

    const resetState = () => {
        setDialogOpen(false);
        setFormState(getInitialState(props.existingTimetrack));
    };

    const handleDelete = () => {
        deleteTimeTrack.mutate(formState.id, {
            onError: err => {
                showErrorNotification(err);
            },
            onSuccess: () => {
                showNotification({
                    header: 'Timetrack deleted!',
                });
                queryClient.invalidateQueries(ttQuery);
                resetState();
            },
        });
    };

    const handleOk = () => {
        let timetrackPayload;
        let id;
        let idPropName;

        const payload = { ...formState };
        const projectId = payload['project_id'];

        delete payload['project_id'];
        payload['date'] = format(payload['date'], MYSQL_DATE_STRING);
        if (projectId.indexOf('a�') === 0) {
            id = parseInt(projectId.substring(2), 10);
            idPropName = 'assignment_id';
        } else {
            id = parseInt(projectId.substring(2), 10);
            idPropName = 'internal_project_id';
        }

        if (idPropName === 'internal_project_id') {
            delete payload['billing_rate'];
        }

        if (payload.id) {
            timetrackPayload = {
                ...payload,
                [idPropName]: id,
            };
            updateTimeTrack.mutate(
                { id: payload.id, payload: timetrackPayload },
                {
                    onError: err => {
                        showErrorNotification(err);
                    },
                    onSuccess: () => {
                        showNotification({
                            header: 'Timetrack updated!',
                        });
                        queryClient.invalidateQueries(ttQuery);
                        setDialogOpen(false);
                    },
                },
            );
        } else {
            timetrackPayload = {
                ...payload,
                [idPropName]: id,
            };
            addPersonalTimeTrack.mutate(
                { ...timetrackPayload },
                {
                    onError: err => {
                        showErrorNotification(err);
                    },
                    onSuccess: () => {
                        showNotification({
                            header: 'Timetrack created!',
                        });
                        queryClient.invalidateQueries(ttQuery);
                        setDialogOpen(false);
                    },
                },
            );
        }
    };

    const children = props.children
        ? cloneElement(props.children, {
              onClick: () => {
                  setDialogOpen(true);
              },
          })
        : '';

    const requiredFields = ['member_id', 'project_id', 'comment', 'date'];

    const formIsValid = () => {
        if (!requiredFields) {
            return true;
        }
        for (const r of requiredFields) {
            if (
                formState[r] === null ||
                formState[r] === undefined ||
                formState[r] === '' ||
                formState[r] === 0
            ) {
                return false;
            }
        }

        return true;
    };

    const isLoading =
        addPersonalTimeTrack.isLoading || updateTimeTrack.isLoading || deleteTimeTrack.isLoading;

    return (
        <>
            {children}
            <Modal open={dialogOpen} onClose={resetState}>
                <Modal.Header>
                    <Modal.Title>{formState.id ? 'Edit Timetrack' : 'New timetrack'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group key="member_id">
                            <Form.ControlLabel>Member</Form.ControlLabel>
                            <SelectPicker
                                block
                                data={membersOptions}
                                // autoFocus={field.autofocus}
                                disabled={!isAdmin}
                                // label={field.label}
                                searchable={true}
                                value={formState['member_id']}
                                onChange={(newValue, event) => {
                                    handleFieldChange({ newValue, event, field: 'member_id' });
                                }}
                            />
                        </Form.Group>
                        <Form.Group key="project">
                            <Form.ControlLabel>Project</Form.ControlLabel>
                            <SelectPicker
                                block
                                data={projectOptions}
                                searchable={true}
                                value={formState['project_id']}
                                groupBy={'type'}
                                onChange={(newValue, event) => {
                                    handleFieldChange({ newValue, event, field: 'project_id' });
                                }}
                            />
                        </Form.Group>
                        <Form.Group key="date">
                            <Form.ControlLabel>Date</Form.ControlLabel>
                            <DatePicker
                                block
                                isoWeek
                                value={formState['date'] ? new Date(formState['date']) : new Date()}
                                onChangeCalendarDate={(newValue, event) => {
                                    handleFieldChange({ newValue, event, field: 'date' });
                                }}
                                onClean={(newValue, event) => {
                                    handleFieldChange({ newValue: null, event, field: 'date' });
                                }}
                                oneTap
                            />
                        </Form.Group>
                        <Form.Group key="hours">
                            <Form.ControlLabel>Hours</Form.ControlLabel>
                            <InputNumber
                                id="field-hours"
                                max={24}
                                min={0}
                                step={0.5}
                                value={formState['hours']}
                                onChange={(newValue, event) => {
                                    handleFieldChange({ newValue, event, field: 'hours' });
                                }}
                            />
                        </Form.Group>
                        <Form.Group key="location">
                            <Form.ControlLabel>Location</Form.ControlLabel>
                            <SelectPicker
                                block
                                data={locationOptions}
                                disabled={false}
                                searchable={true}
                                value={formState['location']}
                                onChange={(newValue, event) => {
                                    handleFieldChange({ newValue, event, field: 'location' });
                                }}
                            />
                        </Form.Group>
                        <Form.Group key="comment">
                            <Form.ControlLabel>Comment</Form.ControlLabel>
                            <Input
                                as="textarea"
                                type="text"
                                value={formState['comment']}
                                onChange={(newValue, event) => {
                                    handleFieldChange({ newValue, event, field: 'comment' });
                                }}
                                rows={3}
                            />
                        </Form.Group>
                    </Form>
                    <p style={{ fontSize: '10px' }}>
                        <Link to="/my/hours">Log Hours</Link> and&nbsp;
                        <Link to="/my/hours?new_record_type=Absence">absences</Link> with the new
                        interface!
                    </p>
                </Modal.Body>
                <Modal.Footer style={{ paddingTop: 20 }}>
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item colspan={6} style={{ textAlign: 'start' }}>
                            {formState.id && (
                                <Button
                                    style={{ width: '70px', height: '36px' }}
                                    disabled={isLoading}
                                    color="red"
                                    appearance="primary"
                                    onClick={handleDelete}
                                >
                                    {isLoading ? <Loader /> : 'Delete'}
                                </Button>
                            )}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colSpan={6}>
                            <Button
                                style={{ width: '75px', height: '36px' }}
                                onClick={resetState}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{ width: '60px', height: '36px' }}
                                appearance="primary"
                                disabled={!formIsValid() || isLoading}
                                onClick={handleOk}
                            >
                                {isLoading ? <Loader /> : 'Save'}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditTimeTrackDialog;
