import { Col, FlexboxGrid, Grid, Loader, Panel } from 'rsuite';

import { useInternalProjectTypes } from '../../hooks/useInternalProjectTypes';
import { useProfile } from '../../hooks/useProfile';
import { EditInternalProjectTypeDialog } from '../dialogs/EditInternalProjectTypeDialog';
import InternalProjectTypesTable from '../tables/InternalProjectTypesTable';

const InternalProjectTypesScreen = () => {
    const { isAdmin } = useProfile();
    const { internalProjectTypes, isLoading } = useInternalProjectTypes();
    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Internal Project Types</h1>
                            </Panel>
                            <Panel>{isAdmin ? <EditInternalProjectTypeDialog /> : null}</Panel>
                        </FlexboxGrid>
                    </Panel>
                    <Panel
                        bordered
                        bodyFill
                        header={
                            <h3>
                                Internal Project Types (
                                {isLoading ? <Loader /> : internalProjectTypes.length})
                            </h3>
                        }
                    >
                        {<InternalProjectTypesTable />}
                    </Panel>
                </Col>
            </Grid>
        </div>
    );
};

export default InternalProjectTypesScreen;
