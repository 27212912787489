import * as z from 'zod';

import { strToNumber } from '.';

const location = z.union([z.literal('Office'), z.literal('Remote'), z.literal('Customer')]);

const timetrackHours = z.preprocess(
    val => Number(val),
    z
        .number()
        .positive()
        .max(20, { message: "Can't be more than 20 hours in a day" })
        .multipleOf(0.5, { message: 'Must be in multiples of 0.5 hours' }),
);

export const WorkTimeTrack = z
    .object({
        id: z.string().optional(),
        comment: z.string().min(1),
        hours: timetrackHours,
        internal_project_id: strToNumber.optional(),
        assignment_id: strToNumber.optional(),
        location: location,
        member_id: strToNumber,
        billing_rate: z.coerce.number().optional(),
    })
    .refine(
        data =>
            !!data.internal_project_id || (!!data.assignment_id && data.billing_rate !== undefined),
        {
            message: 'You need to select a project',
            path: ['internal_or_assignment'],
        },
    );

export const LeaveTimeTrack = z.object({
    id: z.string(),
    comment: z.string().optional(),
    hours: timetrackHours,
    member_id: z.string(),
    leave_type: z.number().min(1).max(50),
});

export const LeaveTimeTrackRange = z.object({
    comment: z.string().optional(),
    member_id: z.string(),
    leave_type: z.number().min(1).max(50),
    date_range: z.tuple([z.date(), z.date()]),
});

export type WorkTimeTrackType = z.infer<typeof WorkTimeTrack>;
export type LeaveTimeTrackType = z.infer<typeof LeaveTimeTrack>;
export type LeaveTimeTrackRangeType = z.infer<typeof LeaveTimeTrackRange>;
