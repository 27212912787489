import { useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { MyCustomerProjectList } from '../generated-types/customer_project';

type MyCustomerProjects = {
    isLoading: boolean;
    isSuccess: boolean;
    myCustomerProjects: MyCustomerProjectList;
};

export function useMyCustomerProjects(): MyCustomerProjects {
    const { data, isLoading, isSuccess } = useQuery<MyCustomerProjectList>(
        ['myCustomerProjects'],
        () => lambdaApi.get('/me/projects/customer', {}).then(res => res.data),
    );
    return {
        myCustomerProjects: data ?? [],
        isLoading,
        isSuccess,
    };
}
