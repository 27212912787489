import styled from '@emotion/styled';
import { useParams, Link } from 'react-router-dom';
import { Button } from 'rsuite';

import { WikiSheet } from './WikiSheet';

const LinkPanel = styled.div`
    display: flex;
    gap: 1em;
`;

export function WikiSheetPage() {
    const { articleId } = useParams();
    return (
        <div>
            <h1>Wiki</h1>
            <LinkPanel>
                <Link to="/wiki/1">
                    <Button appearance="link">Back</Button>
                </Link>
            </LinkPanel>
            {articleId && <WikiSheet articleId={articleId} />}
        </div>
    );
}
