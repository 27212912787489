import RemindRoundIcon from '@rsuite/icons/RemindRound';
import { useMemo } from 'react';
import { Tooltip, Whisper } from 'rsuite';

import { GracePeriodList } from '../../generated-types/grace_period';
import { useAssignments } from '../../hooks/admin/useAssignments';
import findOpenGracePeriods from '../../utils/findOpenGracePeriods';

type Props = {
    customerProjectId: number;
    gracePeriods: GracePeriodList;
};

const ProjectGracePeriodAlertIcon = (props: Props) => {
    const { customerProjectId, gracePeriods } = props;
    const { assignments } = useAssignments({ projectId: customerProjectId });

    const hasOpenGps = useMemo(() => {
        const openGps = findOpenGracePeriods(gracePeriods, assignments);
        return openGps.length;
    }, [gracePeriods, assignments]);

    return hasOpenGps ? (
        <Whisper
            placement="top"
            controlId={`open-grace-periods-${customerProjectId}`}
            trigger="hover"
            speaker={<Tooltip>Open grace periods</Tooltip>}
        >
            <RemindRoundIcon style={{ marginLeft: '16px' }} color="red" />
        </Whisper>
    ) : (
        <></>
    );
};

export default ProjectGracePeriodAlertIcon;
