import { useMutation } from 'react-query';

import lambdaApi from '../api/LambdaApi';

/** Convenience features in staging and development environment
 * for testing out features.
 */
export function useDevMode() {
    const toggleAdmin = useMutation(
        () => {
            return lambdaApi.post(`/dev/toggleadmin`).then(res => res.data);
        },
        {
            onSuccess: () => {
                location.reload();
            },
        },
    );

    return {
        toggleAdmin,
    };
}
