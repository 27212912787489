import { LineChart } from 'components/charts/LineChart';
import { SimpleTable } from 'components/tables/common/Simpletable';
import { useMyCareer } from 'hooks/useMyCareer';
import { Loader } from 'rsuite';
import { average, sum } from 'utils/math';

type Props = {
    userId: number;
    year: number;
};

export function SalaryChart({ userId, year }: Props) {
    const { earnings, isLoading } = useMyCareer({ userId: Number(userId), year: year });
    if (isLoading) {
        return <Loader size="md" />;
    }
    if (!earnings) {
        return <p>No data available</p>;
    }

    const { year_real, est_optimistic, est_pessimistic, est_scenario_1 } = earnings;

    return (
        <div style={{ width: '70vw' }}>
            <LineChart
                title={'Salary'}
                labels={earnings.labels}
                datasets={[
                    { label: 'Real income', data: year_real },
                    { label: 'Salary, optimistic', data: est_optimistic },
                    // { label: 'Salary, pessimistic', data: est_pessimistic },
                    // { label: 'Salary, 95% of full hours', data: est_scenario_1 },
                ]}
                options={{
                    yLimits: { yMin: 0, suggestedMax: 10000 },
                }}
            />
            <div>
                <h3>Yearly</h3>
                <SimpleTable
                    headers={['', 'Year total', 'Monthly Average']}
                    rows={[
                        ['Real income', sum(year_real), average(year_real)],
                        ['Salary, optimistic', sum(est_optimistic), average(est_optimistic)],
                        // ['Salary, pessimistic', sum(est_pessimistic), average(est_pessimistic)],
                        // ['Salary, 95% of full hours', sum(est_scenario_1), average(est_scenario_1)],
                    ]}
                />
            </div>
        </div>
    );
}
