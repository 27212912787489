import differenceInDays from 'date-fns/differenceInDays';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import endOfYear from 'date-fns/endOfYear';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import isThisWeek from 'date-fns/isThisWeek';
import isToday from 'date-fns/isToday';
import isWeekend from 'date-fns/isWeekend';
import { isHoliday } from 'utils/holidays';

type Props = {
    dates: Date[];
    unit: 'year' | 'month' | 'week' | 'day';
};

const DateCells = ({ dates, unit }: Props) => {
    let currentDate: string;
    const formats = {
        year: 'yyyy',
        month: 'MMMM',
        week: 'I',
        day: 'd',
    };

    return (
        <>
            {dates.map(date => {
                const formattedDate = format(date, formats[unit]);
                if (currentDate !== formattedDate) {
                    currentDate = formattedDate;

                    let daysUntilEnd: number;
                    switch (unit) {
                        case 'year':
                            daysUntilEnd = differenceInDays(endOfYear(date), date) + 1;
                            break;
                        case 'month':
                            daysUntilEnd = differenceInDays(endOfMonth(date), date) + 1;
                            break;
                        case 'week':
                            daysUntilEnd =
                                differenceInDays(endOfWeek(date, { weekStartsOn: 1 }), date) + 1;
                            break;
                        default:
                            daysUntilEnd = 0;
                    }

                    return (
                        <td
                            key={formatISO(date)}
                            colSpan={daysUntilEnd}
                            className={
                                unit === 'year'
                                    ? 'top-cell-up'
                                    : unit === 'week' && isThisWeek(date, { weekStartsOn: 1 })
                                    ? 'today-cell'
                                    : unit === 'day' && isToday(date)
                                    ? 'today-cell'
                                    : unit === 'day' && (isHoliday(date) || isWeekend(date))
                                    ? 'holiday-cell'
                                    : ''
                            }
                        >
                            {unit === 'month' && daysUntilEnd < 3
                                ? formattedDate.substring(0, 3)
                                : formattedDate}
                        </td>
                    );
                }
                return null;
            })}
        </>
    );
};

export default DateCells;
