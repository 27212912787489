import PeopleFliterIcon from '@rsuite/icons/PeopleFliter';
import PeoplesIcon from '@rsuite/icons/Peoples';
import DataBox from 'components/DataBox';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { EditCircleDialog } from 'components/dialogs/EditCircleDialog';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import UserBox from 'components/UserBox';
import format from 'date-fns/format';
import nextFriday from 'date-fns/nextFriday';
import { CircleModel } from 'generated-types/circle';
import { LedgerList } from 'generated-types/ledger';
import { MemberModel } from 'generated-types/member';
import { useCircles } from 'hooks/useCircles';
import { useMemberships } from 'hooks/useMemberships';
import { useTransactions } from 'hooks/useTransactions';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    FlexboxGrid,
    IconButton,
    Panel,
    Stack,
    Tag,
    Tooltip,
    Whisper,
} from 'rsuite';
import { getPreviousMonthKey } from 'utils/keyGenerators';

type Props = {
    circle: CircleModel;
    currentMembers: MemberModel[];
    members: MemberModel[];
    showEditCircleButton: boolean;
    isAdmin: boolean;
};

export const HeaderPanel = ({
    circle,
    currentMembers,
    members,
    showEditCircleButton,
    isAdmin,
}: Props) => {
    const { myCircles, archiveCircle: _archiveCircle } = useCircles();
    const { joinMembership, leaveMembership } = useMemberships({ circle_id: circle.id });
    const { transactions } = useTransactions(circle.id);
    const navigate = useNavigate();

    const myMembership = myCircles.some(c => c.id === circle.id);
    const currentMembersEmails = currentMembers.map(member => member.email).join(',');

    const joinCircle = () => {
        joinMembership.mutate(circle.id, {
            onSuccess: () => {
                showNotification({ header: `You're now member of ${circle.name}!` });
            },
            onError: err => {
                showErrorNotification(err);
            },
        });
    };

    const leaveCircle = () => {
        leaveMembership.mutate(circle.id, {
            onSuccess: () => {
                showNotification({ header: `You've left ${circle.name}`, type: 'info' });
                navigate('/circles');
            },
            onError: err => {
                showErrorNotification(err);
            },
        });
    };

    const archiveCircle = () => {
        _archiveCircle.mutate(circle.id, {
            onSuccess: () => showNotification({ header: 'The circle was archived', type: 'info' }),
            onError: err => showErrorNotification(err),
        });
    };

    const getLastMonthsExpenses = (transactions: LedgerList) => {
        return transactions
            .filter(tx => {
                if (!tx?.tx_date || !tx?.amount) {
                    return false;
                }
                return tx.tx_date.substring(0, 7) === getPreviousMonthKey() && tx.amount < 0;
            })
            .reduce((acc, t) => (acc += t?.amount ?? 0), 0);
    };

    const getLastMonthsIncome = (transactions: LedgerList) => {
        return transactions
            .filter(tx => {
                if (!tx?.tx_date || !tx?.amount) {
                    return false;
                }
                return tx.tx_date.substring(0, 7) === getPreviousMonthKey() && tx.amount > 0;
            })
            .reduce((acc, t) => (acc += t?.amount ?? 0), 0)
            .toFixed(2);
    };

    const getCircleTransactions = (circle_id: number, transactions: LedgerList) => {
        const circleTransactions = Object.values(transactions).filter(tx => {
            return tx.circle_id === circle_id;
        });
        return circleTransactions;
    };

    const getCalendarDateString = () => {
        const friday = nextFriday(new Date());
        const nextFridayStartString = format(friday, 'yyyyMMdd').concat('T100000000Z');
        const nextFridayEndString = nextFridayStartString.replace('T10', 'T11');
        return `${nextFridayStartString}/${nextFridayEndString}`;
    };

    const currentTransactions = getCircleTransactions(circle.id, transactions);
    const membersInviteLink =
        'https://calendar.google.com/calendar/u/0/r/eventedit?text=' +
        `${circle.name}%20Circle:&dates=${getCalendarDateString()}&add=${currentMembersEmails}`;
    const everyoneInviteLink =
        'https://calendar.google.com/calendar/u/0/r/eventedit?text=' +
        `${circle.name}%20Circle:&dates=${getCalendarDateString()}&add=helsinki@circles.fi`;

    return (
        <Panel
            bordered
            header={
                <FlexboxGrid justify="start" align="middle">
                    <h1 style={{ display: 'inline' }}>{circle.name}</h1> &nbsp;
                    <Tag>
                        <h4>Circle</h4>
                    </Tag>
                    {!circle.is_active && <Tag color="red">Archived</Tag>}
                </FlexboxGrid>
            }
            style={{ marginBottom: '24px' }}
        >
            <FlexboxGrid justify="start" align="middle">
                <UserBox
                    user={members.find(m => m.id === circle.facilitator_id)}
                    role="Facilitator"
                />
                <UserBox user={members.find(m => m.id === circle.scribe_id)} role="Scribe" />
                <UserBox user={members.find(m => m.id === circle.treasurer_id)} role="Treasurer" />
                <DataBox
                    title="Income Last Month"
                    value={getLastMonthsIncome(currentTransactions)}
                />
                <DataBox
                    title="Expenses Last Month"
                    value={getLastMonthsExpenses(currentTransactions)}
                />
            </FlexboxGrid>
            <FlexboxGrid justify="space-between" align="middle" style={{ paddingTop: 20 }}>
                <Stack spacing={6}>
                    {showEditCircleButton ? <EditCircleDialog circle={circle} /> : null}
                    {isAdmin && circle.is_active ? (
                        <ConfirmationDialog
                            handleOK={archiveCircle}
                            header="Archive the Circle?"
                            message={`Are you sure you want to archive the circle "${circle.name}"? This operation can not be undone.`}
                        >
                            <Button appearance="primary" color="red">
                                Archive
                            </Button>
                        </ConfirmationDialog>
                    ) : null}
                </Stack>

                <ButtonToolbar>
                    <ButtonGroup>
                        <Whisper
                            placement="bottom"
                            speaker={
                                <Tooltip>
                                    Create Google Calendar event for members of the circle
                                </Tooltip>
                            }
                        >
                            <IconButton
                                icon={<PeopleFliterIcon />}
                                href={membersInviteLink}
                                target="_blank"
                            />
                        </Whisper>
                        <Whisper
                            placement="bottom"
                            speaker={<Tooltip>Create Google Calendar event for everyone</Tooltip>}
                        >
                            <IconButton
                                icon={<PeoplesIcon />}
                                href={everyoneInviteLink}
                                target="_blank"
                            />
                        </Whisper>
                    </ButtonGroup>

                    {!myMembership ? (
                        <ConfirmationDialog
                            handleOK={joinCircle}
                            header="Join the Circle?"
                            message={`Are you sure you want to join the circle ${circle.name}?`}
                        >
                            <Button appearance="primary" color="green">
                                Join This Circle
                            </Button>
                        </ConfirmationDialog>
                    ) : (
                        <ConfirmationDialog
                            handleOK={leaveCircle}
                            header="Leave the Circle?"
                            message={`Are you sure you want to leave the circle ${circle.name}?`}
                        >
                            <Button appearance="primary" color="yellow">
                                Leave This Circle
                            </Button>
                        </ConfirmationDialog>
                    )}
                </ButtonToolbar>
            </FlexboxGrid>
        </Panel>
    );
};
