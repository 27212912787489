import { queryClient } from 'App';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { Button, Col, FlexboxGrid, Grid, Loader, Panel } from 'rsuite';

import { useCustomers } from '../../hooks/useCustomers';
import { useProfile } from '../../hooks/useProfile';
import CustomerTable from '../tables/CustomerTable';

const CustomersScreen = () => {
    const { isAdmin } = useProfile();
    const { customers, isLoading, syncFromProcountor } = useCustomers();

    const handleProcountorSync = () => {
        syncFromProcountor()
            .then(() => {
                queryClient.invalidateQueries({ queryKey: ['customers'] });
                showNotification({ header: 'Successfully synced customers!' });
            })
            .catch(error => {
                showErrorNotification(error);
            });
    };

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Customers</h1>
                            </Panel>
                            <Panel>
                                {isAdmin ? (
                                    <Button
                                        appearance="primary"
                                        onClick={handleProcountorSync}
                                        target="_blank"
                                    >
                                        Sync from Procountor
                                    </Button>
                                ) : null}
                            </Panel>
                        </FlexboxGrid>
                    </Panel>
                    <Panel
                        bordered
                        bodyFill
                        header={<h3>Customers ({isLoading ? <Loader /> : customers.length})</h3>}
                    >
                        <CustomerTable />
                    </Panel>
                </Col>
            </Grid>
        </div>
    );
};

export default CustomersScreen;
