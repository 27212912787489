import * as z from 'zod';

export const AssignmentSchema = z.object({
    billing_rate: z.preprocess(val => Number(val), z.number()),
    start_date: z.date(),
    end_date: z.date().optional(),
    description: z.string(),
});

export const NewAssignmentSchema = AssignmentSchema.extend({
    member_id: z.number(),
});

export type AssignmentSchemaType = z.infer<typeof AssignmentSchema>;
export type NewAssignmentSchemaType = z.infer<typeof NewAssignmentSchema>;
