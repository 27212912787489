import { AssignmentModelList } from '../../../../generated-types/assignment';
import { CustomerProjectList } from '../../../../generated-types/customer_project';
import { InternalProjectList } from '../../../../generated-types/internal_project';
import { LeaveTypeModelList } from '../../../../generated-types/leave_type';
import { TimeTrackModel } from '../../../../generated-types/timetrack';

export enum LoggedEntryType {
    Customer = 'Customer',
    Internal = 'Internal',
    Leave = 'Leave',
}

type RowType = {
    id: number;
    entryType: LoggedEntryType;
    name: string;
    hours: number;
    comment: string;
};

export function getLoggedEntryType(
    record: TimeTrackModel,
    leaveTypes: LeaveTypeModelList,
    internalProjects: InternalProjectList,
    myCustomerProjects: CustomerProjectList,
    assignments: AssignmentModelList,
): RowType {
    const baseRecord = {
        id: record.id,
        hours: record.hours,
        comment: record.comment ?? '',
    };

    if (record.internal_project_id) {
        return {
            entryType: LoggedEntryType.Internal,
            name:
                internalProjects.find(i => i.id === record?.internal_project_id)?.name ??
                `Project #${record.internal_project_id}`,
            ...baseRecord,
        };
    }

    if (record.assignment_id) {
        const project = assignments.find(a => a.id === record.assignment_id);
        const customerProjectName =
            myCustomerProjects.find(c => c.id === project?.project_id)?.name ??
            `Assignment #${record.assignment_id}`;

        return {
            entryType: LoggedEntryType.Customer,
            name: customerProjectName,
            ...baseRecord,
        };
    }

    if (record.leave_type) {
        return {
            entryType: LoggedEntryType.Leave,
            name: leaveTypes.find(l => record.leave_type === l.id)?.name ?? 'Unknown type',
            ...baseRecord,
        };
    }

    throw new Error(`Logged entry type cannot be deduced! Info: Log ID: ${record.id}`);
}
