import HomeIcon from '@rsuite/icons/legacy/Home';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Nav } from 'rsuite';

import { BackupScreen } from './Backups';
import { UserHolidays } from './UserHolidays';
export function ControlPanel() {
    const { pathname } = useLocation();

    return (
        <Nav appearance="tabs">
            <Nav.Item
                href={'/#/control-panel/holidays'}
                active={pathname === '/control-panel/holidays'}
                eventKey="/holidays"
                icon={<HomeIcon />}
            >
                User Holidays
            </Nav.Item>
            <Nav.Item
                href={'/#/control-panel/backups'}
                active={pathname === '/control-panel/backups'}
                eventKey="/backups"
                icon={<HomeIcon />}
            >
                Backups
            </Nav.Item>

            <Routes>
                <Route element={<BackupScreen />} path={'/backups'} />
                <Route element={<UserHolidays />} path={'/holidays'} />
            </Routes>
        </Nav>
    );
}
