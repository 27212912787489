import isSameDay from 'date-fns/isSameDay';
import { LeaveTypeModelList } from 'generated-types/leave_type';

import { TimeTrackModelList } from '../generated-types/timetrack';

type HoursType = 'paid' | 'unpaid' | 'sick' | 'customer' | 'internal' | 'other';

export function filterOnlyOneDayTimetracks(
    date: Date,
    timetrack: TimeTrackModelList,
    hoursType?: HoursType,
    leaveTypes?: LeaveTypeModelList,
): TimeTrackModelList {
    const paidLeaveId = leaveTypes?.find(
        leaveType => leaveType.identifier_name === 'PaidLeave',
    )?.id;
    const unpaidLeaveId = leaveTypes?.find(
        leaveType => leaveType.identifier_name === 'UnpaidLeave',
    )?.id;
    const sickLeaveID = leaveTypes?.find(
        leaveType => leaveType.identifier_name === 'SickLeave',
    )?.id;

    return timetrack.filter(record => {
        const sameDay = isSameDay(new Date(record.date), date);
        if (leaveTypes && hoursType) {
            switch (hoursType) {
                case 'customer':
                    return sameDay && record.assignment_id;
                case 'internal':
                    return sameDay && record.internal_project_id;
                case 'paid':
                    return sameDay && record.leave_type === paidLeaveId;
                case 'unpaid':
                    return sameDay && record.leave_type === unpaidLeaveId;
                case 'sick':
                    return sameDay && record.leave_type === sickLeaveID;
                case 'other':
                    return (
                        sameDay &&
                        record.leave_type &&
                        ![paidLeaveId, unpaidLeaveId, sickLeaveID].includes(record.leave_type)
                    );
            }
        }
        return sameDay;
    });
}

export function calculateTotalHours(timetracks: TimeTrackModelList) {
    if (timetracks.length === 0) {
        return 0;
    }

    const hours = timetracks.map(result => {
        return result.hours;
    }) ?? [0];

    return hours.reduce((total, hours) => {
        return total + hours;
    });
}
