import LegacySearchIcon from '@rsuite/icons/legacy/Search';
import { Col, FlexboxGrid, Grid, Input, InputGroup, Loader, Panel } from 'rsuite';

import { useProfile } from '../../hooks/useProfile';
import { useTransactions } from '../../hooks/useTransactions';
import { NewTransactionDialog } from '../dialogs/NewTransactionDialog';
import { TransactionTable } from '../tables/TransactionTable';

const LedgerScreen = () => {
    const {
        isLoading,
        totalTransactions,
        setDescription,
        page,
        pageSize,
        setPageSize,
        setPage,
        transactions,
        sortColumn,
        setSortColumn,
        sortType,
        setSortType,
    } = useTransactions();
    const { isAdmin } = useProfile();

    return (
        <Grid fluid>
            <Col xs={24}>
                <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                    <FlexboxGrid justify="space-between" align="middle">
                        <Panel>
                            {isLoading ? (
                                <h1>
                                    Ledger (<Loader size="md" />)
                                </h1>
                            ) : (
                                <h1>Ledger ({totalTransactions})</h1>
                            )}
                        </Panel>
                        <Panel>
                            <InputGroup style={{ margin: 10 }}>
                                <Input placeholder="Search Description" onChange={setDescription} />
                                <InputGroup.Addon>
                                    <LegacySearchIcon />
                                </InputGroup.Addon>
                            </InputGroup>
                        </Panel>
                        <Panel>{isAdmin ? <NewTransactionDialog /> : null}</Panel>
                    </FlexboxGrid>
                </Panel>
                <Panel bordered bodyFill>
                    <TransactionTable
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalTransactions={totalTransactions}
                        transactions={transactions}
                        isLoading={isLoading}
                        sortColumn={sortColumn}
                        setSortColumn={setSortColumn}
                        sortType={sortType}
                        setSortType={setSortType}
                    />
                </Panel>
            </Col>
        </Grid>
    );
};

export default LedgerScreen;
