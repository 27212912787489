import { useCustomers } from 'hooks/useCustomers';
import { useParams } from 'react-router-dom';
import { Button, Col, FlexboxGrid, Grid, Panel, Tag } from 'rsuite';

import EditCustomerDialog from '../dialogs/EditCustomerDialog';
import { NotFound } from './NotFound';

const CustomerDetailsScreen = () => {
    const { customerId } = useParams();
    const { customers } = useCustomers();
    const customer = customers.find(c => c.id === Number(customerId));

    if (!customer) return <NotFound message="Customer not found!" />;

    return (
        <>
            <Grid fluid>
                <Col xs={24}>
                    <Panel
                        bordered
                        header={
                            <FlexboxGrid justify="start" align="middle">
                                <h1 style={{ display: 'inline' }}>{customer.name}</h1>&nbsp;
                                <Tag>
                                    <h4>Customer</h4>
                                </Tag>
                            </FlexboxGrid>
                        }
                        style={{ marginBottom: '24px' }}
                    >
                        <FlexboxGrid
                            justify="space-between"
                            align="middle"
                            style={{ paddingTop: 20 }}
                        >
                            <EditCustomerDialog editable={customer}>
                                <Button appearance="primary">Edit</Button>
                            </EditCustomerDialog>
                        </FlexboxGrid>
                    </Panel>
                </Col>
            </Grid>
        </>
    );
};

export default CustomerDetailsScreen;
