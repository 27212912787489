import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, FlexboxGrid, Grid, Panel, Toggle } from 'rsuite';

import { useMembers } from '../../hooks/useMembers';
import { useProfile } from '../../hooks/useProfile';
import MemberTable from '../tables/MemberTable';

const MembersScreen = () => {
    const [showInactive, toggleShowInactive] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
    const { isAdmin } = useProfile();
    const { members } = useMembers(showInactive);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const userId = Number(searchParams?.get('id')) || undefined;
        setSelectedUserId(userId);
    }, [searchParams]);

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Members</h1>
                            </Panel>
                            <Panel>
                                {isAdmin ? (
                                    <>
                                        <span>
                                            <small>Display all</small>
                                        </span>
                                        <Toggle
                                            style={{ marginLeft: 6 }}
                                            checked={showInactive}
                                            onChange={() => toggleShowInactive(!showInactive)}
                                        />
                                    </>
                                ) : null}
                            </Panel>
                        </FlexboxGrid>
                    </Panel>
                    <Panel bordered bodyFill header={<h3>Members ({members.length})</h3>}>
                        <MemberTable data={members} selectedUserId={selectedUserId} />
                    </Panel>
                </Col>
            </Grid>
        </div>
    );
};

export default MembersScreen;
