import { FlexboxGrid, Panel } from 'rsuite';

type ProfileBarProps = {
    firstName: string;
    lastName: string;
    imageUrl: string | undefined | null;
};

export function ProfileBar({ firstName, lastName, imageUrl }: ProfileBarProps) {
    return (
        <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
            <FlexboxGrid justify="start" align="middle">
                {imageUrl ? (
                    <img
                        alt="Profile"
                        referrerPolicy="no-referrer"
                        src={imageUrl ?? undefined}
                        style={{ height: '100px' }}
                    />
                ) : null}
                <Panel>
                    <h1>
                        {firstName} {lastName}
                    </h1>
                </Panel>
            </FlexboxGrid>
        </Panel>
    );
}
