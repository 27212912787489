import { Defs, LinearGradient, Rect, Stop, StyleSheet, Svg } from '@react-pdf/renderer';
import { PageSize } from '@react-pdf/types/page';

export const pageSize: PageSize = [960, 540];

export const styles = StyleSheet.create({
    introPage: {
        backgroundColor: '#f7f7f7',
        fontFamily: 'OpenSans',
        padding: 0,
        margin: 0,
        minHeight: pageSize[1],
        maxHeight: pageSize[1],
    },
    page: {
        backgroundColor: '#f7f7f7',
        fontFamily: 'OpenSans',
        margin: 0,
        paddingBottom: 60,
    },
    logoContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: 28,
    },
    logo: {
        width: 180,
    },
    profile: {
        flexDirection: 'row',
        marginLeft: 30,
        marginRight: 30,
    },
    pictureContainer: { marginTop: 50, marginLeft: 95 },
    userPicture: { width: 250, borderRadius: 250 },
    informationContainer: { padding: '0 10 10 10', width: 'auto', maxWidth: 550, marginLeft: 20 },
    name: { fontSize: 50, textTransform: 'uppercase', fontFamily: 'Montserrat' },
    headline: {
        fontSize: 15,
        maxWidth: 520,
        maxHeight: 41,
        textTransform: 'uppercase',
        fontFamily: 'OpenSans',
        fontWeight: 'light',
        paddingLeft: 4,
    },
    aboutContainer: {
        marginTop: 'auto',
        marginBottom: 'auto',
        minHeight: 200,
        maxHeight: 340,
        width: 520,
        justifyContent: 'center',
    },
    about: {
        fontSize: 12,
        textAlign: 'left',
        lineHeight: 1.2,
        padding: 4,
        marginBottom: 10,
        textOverflow: 'ellipsis',
    },
    pageCount: {
        position: 'absolute',
        right: 28,
        bottom: 10,
        fontSize: 10,
    },
    pageTitleContainer: {
        paddingTop: 50,
        paddingLeft: 59,
        paddingBottom: 10,
        paddingRight: 59,
    },
    pageTitleText: {
        fontSize: 36,
        maxHeight: 44,
        textTransform: 'uppercase',
        fontFamily: 'Montserrat',
    },
    skillsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: 55,
        marginRight: 55,
    },
    skillBox: {
        width: 400,
        height: 53,
        margin: '3 12.5 3 12.5',
        padding: 5,
    },
    skillBoxFlex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    skillBoxName: {
        fontSize: 15,
    },
    skillBoxLevel: {
        color: 'lightgray',
        fontSize: 10,
        marginTop: 'auto',
    },
    skillProgressBarBox: {
        width: 390,
        backgroundColor: '#e8e8e8',
        padding: 0,
        marginTop: 10,
    },
    flexContainer: {
        flexDirection: 'row',
        flex: 1,
        flexWrap: 'wrap',
        marginLeft: 55,
        marginRight: 55,
    },
    experienceBox: {
        width: 415,
        maxHeight: 365,
        margin: 5,
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
        borderRadius: 5,
        padding: 10,
    },
    educationBox: {
        width: 270,
        height: 175,
        margin: 5,
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
        borderRadius: 5,
        padding: 10,
    },
    containerWithValidationText: {
        position: 'relative',
        height: '100%',
    },
    badgeContainer: {
        margin: 'auto',
    },
    badge: {
        width: 'auto',
        height: 100,
    },
    validationText: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        fontSize: 8,
        color: 'lightgray',
        overflow: 'hidden',
    },
    title: { fontSize: 15, paddingLeft: 5, fontFamily: 'Montserrat', fontWeight: 'bold' },
    subTitle: { fontSize: 15, paddingLeft: 5 },
    dates: { fontSize: 10, paddingLeft: 5 },
    description: {
        fontSize: 10,
        padding: 10,
        textAlign: 'justify',
        wordWrap: 'break-word',
        maxHeight: 275,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    skills: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        maxHeight: 45,
        marginLeft: 5,
        overflow: 'hidden',
    },
    skillTag: {
        fontSize: 9,
        marginRight: 5,
        marginBottom: 5,
        padding: 3,
        backgroundColor: '#f7f7fad9',
        color: '#57574ed9',
        borderRadius: 3,
    },
    footerContainer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 60,
    },
    smallLogoContainer: {
        left: 18,
        width: 150,
        height: 60,
    },
    smallLogo: {
        width: 150,
    },
    closingPageContainer: {
        margin: 'auto',
    },
    closingPageLogoContainer: {
        margin: 'auto',
    },
    largeLogo: {
        width: 350,
    },
    closingPageTextContainer: {
        marginTop: 10,
        width: 500,
    },
    closingPageText: {
        fontSize: 20,
        fontFamily: 'OpenSans',
        fontWeight: 'light',
        textAlign: 'center',
    },
    closingPageTextLink: {
        textDecoration: 'none',
    },
});

export const TopGradientBar = () => (
    <Svg width="100%" height="22" viewBox="0 0 960 22" style={{ position: 'absolute' }} fixed>
        <Defs>
            <LinearGradient id="top-grad" x1="0" x2="1" y1="0" y2="1">
                <Stop offset="0%" stopColor="rgba(31,59,112,1)" />
                <Stop offset="50%" stopColor="rgba(2,175,217,1)" />
                <Stop offset="100%" stopColor="rgba(217,22,92,1)" />
            </LinearGradient>
        </Defs>
        <Rect width="100%" height="22" x={0} y={0} fill="url('#top-grad')" />
    </Svg>
);

export const SideGradientPanel = () => (
    <Svg width="250" height="100%" style={{ position: 'absolute', bottom: 0, left: 0 }}>
        <Defs>
            <LinearGradient id="side-grad" x1="0" x2="1" y1="0" y2="1">
                <Stop offset="0%" stopColor="rgba(31,59,112,1)" />
                <Stop offset="50%" stopColor="rgba(2,175,217,1)" />
                <Stop offset="100%" stopColor="rgba(217,22,92,1)" />
            </LinearGradient>
        </Defs>
        <Rect width={250} height={560} x={0} y={0} fill="url('#side-grad')" />
    </Svg>
);
