import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    GracePeriodList,
    GracePeriodModel,
    GracePeriodRequestPayloadList,
} from 'generated-types/grace_period';
import { useMutation, useQuery } from 'react-query';

import { useProfile } from '../useProfile';

export function useGracePeriods() {
    const { isAdmin } = useProfile();
    const { data, isLoading, refetch } = useQuery<GracePeriodList>(
        ['gracePeriods'],
        () => lambdaApi.get('/graceperiods').then(res => res.data),
        {
            enabled: isAdmin,
        },
    );

    const createGracePeriods = useMutation<
        GracePeriodModel,
        AxiosError,
        GracePeriodRequestPayloadList
    >(payload => {
        return lambdaApi.post('/graceperiods', payload).then(res => res.data);
    });

    const closeGracePeriods = useMutation<GracePeriodModel, AxiosError, GracePeriodList>(
        // @ts-ignore
        // TODO: Fix typing
        payload => {
            const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
            const yesterdayStr = `${yesterday.getFullYear()}-${
                yesterday.getMonth() + 1
            }-${yesterday.getDate()}`;
            return payload.map(gracePeriod => {
                const closedGracePeriod = {
                    ...gracePeriod,
                    locking_date: yesterdayStr,
                };
                return lambdaApi
                    .put(`/graceperiods/${gracePeriod.id}`, closedGracePeriod)
                    .then(res => res.data);
            });
        },
    );

    return {
        createGracePeriods,
        closeGracePeriods,
        gracePeriods: data || [],
        isLoading,
        refetch,
    };
}
