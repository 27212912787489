// Copied from https://github.com/nagix/chartjs-plugin-colorschemes/blob/master/src/colorschemes/colorschemes.office.js

export const ChartColorSchemes = {
    Adjacency6: ['#a9a57c', '#9cbebd', '#d2cb6c', '#95a39d', '#c89f5d', '#b1a089'],
    Advantage6: ['#663366', '#330f42', '#666699', '#999966', '#f7901e', '#a3a101'],
    Angles6: ['#797b7e', '#f96a1b', '#08a1d9', '#7c984a', '#c2ad8d', '#506e94'],
    Apex6: ['#ceb966', '#9cb084', '#6bb1c9', '#6585cf', '#7e6bc9', '#a379bb'],
    Apothecary6: ['#93a299', '#cf543f', '#b5ae53', '#848058', '#e8b54d', '#786c71'],
    Aspect6: ['#f07f09', '#9f2936', '#1b587c', '#4e8542', '#604878', '#c19859'],
    Atlas6: ['#f81b02', '#fc7715', '#afbf41', '#50c49f', '#3b95c4', '#b560d4'],
    Austin6: ['#94c600', '#71685a', '#ff6700', '#909465', '#956b43', '#fea022'],
    Badge6: ['#f8b323', '#656a59', '#46b2b5', '#8caa7e', '#d36f68', '#826276'],
    Banded6: ['#ffc000', '#a5d028', '#08cc78', '#f24099', '#828288', '#f56617'],
    Basis6: ['#f09415', '#c1b56b', '#4baf73', '#5aa6c0', '#d17df9', '#fa7e5c'],
    Berlin6: ['#a6b727', '#df5327', '#fe9e00', '#418ab3', '#d7d447', '#818183'],
    BlackTie6: ['#6f6f74', '#a7b789', '#beae98', '#92a9b9', '#9c8265', '#8d6974'],
    Blue6: ['#0f6fc6', '#009dd9', '#0bd0d9', '#10cf9b', '#7cca62', '#a5c249'],
    BlueGreen6: ['#3494ba', '#58b6c0', '#75bda7', '#7a8c8e', '#84acb6', '#2683c6'],
    BlueII6: ['#1cade4', '#2683c6', '#27ced7', '#42ba97', '#3e8853', '#62a39f'],
    BlueRed6: ['#4a66ac', '#629dd1', '#297fd5', '#7f8fa9', '#5aa2ae', '#9d90a0'],
    BlueWarm6: ['#4a66ac', '#629dd1', '#297fd5', '#7f8fa9', '#5aa2ae', '#9d90a0'],
    Breeze6: ['#2c7c9f', '#244a58', '#e2751d', '#ffb400', '#7eb606', '#c00000'],
    Capital6: ['#4b5a60', '#9c5238', '#504539', '#c1ad79', '#667559', '#bad6ad'],
    Celestial6: ['#ac3ec1', '#477bd1', '#46b298', '#90ba4c', '#dd9d31', '#e25247'],
    Circuit6: ['#9acd4c', '#faa93a', '#d35940', '#b258d3', '#63a0cc', '#8ac4a7'],
    Civic6: ['#d16349', '#ccb400', '#8cadae', '#8c7b70', '#8fb08c', '#d19049'],
    Clarity6: ['#93a299', '#ad8f67', '#726056', '#4c5a6a', '#808da0', '#79463d'],
    Codex6: ['#990000', '#efab16', '#78ac35', '#35aca2', '#4083cf', '#0d335e'],
};
