import * as z from 'zod';

export const NewOperativeCostSchema = z.object({
    name: z.string(),
    description: z.string().optional(),
    amount: z.preprocess(val => (Number(val) ? Number(val) : val), z.number()),
    start_date: z.date(),
    end_date: z.date().optional(),
});

export const EditOperativeCostSchema = NewOperativeCostSchema.extend({
    id: z.union([z.number(), z.string()]),
});

export type EditOperativeCostType = z.infer<typeof EditOperativeCostSchema>;
export type NewOperativeCostType = z.infer<typeof NewOperativeCostSchema>;
