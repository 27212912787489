import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import format from 'date-fns/format';
import isWeekend from 'date-fns/isWeekend';
import parseISO from 'date-fns/parseISO';
import { useLeaveTypes } from 'hooks/useLeaveTypes';
import { useMembers } from 'hooks/useMembers';
import { useProfile } from 'hooks/useProfile';
import { LeaveStatisticsType } from 'hooks/useTimetrackStatistics';
import { Popover, Whisper } from 'rsuite';
import { isHoliday } from 'utils/holidays';

import { TotalUsedAdmin, TotalUsedRegular } from './TotalUsedCells';

type Props = {
    dates: Date[];
    leaveOverview: LeaveStatisticsType;
};

const LeavesData = ({ dates, leaveOverview }: Props) => {
    const { members } = useMembers(false, true);
    const { leaveTypes } = useLeaveTypes();
    const { isAdmin } = useProfile();
    return (
        <>
            {members.map(member => {
                const leaves = leaveOverview[member.id];
                return (
                    <tr key={member.id}>
                        <>
                            <td>
                                {member.lastname} {member.firstname}
                            </td>
                            {isAdmin ? <TotalUsedAdmin userId={member.id} /> : <TotalUsedRegular />}

                            {dates.map(date => {
                                const formattedDate = format(date, 'yyyy-MM-dd');
                                const currentLeave = leaves?.find(
                                    leave => leave.date === formattedDate,
                                );
                                if (!currentLeave) {
                                    return (
                                        <td
                                            key={formattedDate}
                                            className={
                                                isHoliday(date) || isWeekend(date)
                                                    ? 'leave-cell holiday-cell'
                                                    : 'leave-cell'
                                            }
                                        ></td>
                                    );
                                }

                                const leaveName =
                                    leaveTypes.find(leave => leave.id === currentLeave.leave_type)
                                        ?.name || 'Leave';

                                const isPaidLeave =
                                    currentLeave.leave_type ===
                                    leaveTypes.find(leave => leave.identifier_name === 'PaidLeave')
                                        ?.id;

                                const isUnpaidLeave =
                                    currentLeave.leave_type ===
                                    leaveTypes.find(
                                        leave => leave.identifier_name === 'UnpaidLeave',
                                    )?.id;

                                return (
                                    <td
                                        key={formattedDate}
                                        className={
                                            isPaidLeave
                                                ? 'paid-leave-cell'
                                                : isUnpaidLeave
                                                ? 'unpaid-leave-cell'
                                                : 'other-leave-cell'
                                        }
                                    >
                                        <Whisper
                                            trigger="click"
                                            placement="top"
                                            controlId={formattedDate}
                                            speaker={
                                                <Popover title={leaveName}>
                                                    <p>
                                                        {format(
                                                            parseISO(currentLeave.date),
                                                            'EEEE, d MMMM yyy',
                                                        )}
                                                    </p>
                                                    <p>
                                                        Requested by{' '}
                                                        {`${member.firstname} ${member.lastname}`}
                                                        <br />
                                                        {currentLeave.confirmed ? (
                                                            <span>
                                                                <CheckIcon color="green" />{' '}
                                                                Confirmed
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <CloseIcon color="red" />{' '}
                                                                Unconfirmed
                                                            </span>
                                                        )}
                                                    </p>
                                                </Popover>
                                            }
                                        >
                                            <button className="cell-button"></button>
                                        </Whisper>
                                    </td>
                                );
                            })}
                        </>
                    </tr>
                );
            })}
        </>
    );
};

export default LeavesData;
