import RemindRoundIcon from '@rsuite/icons/RemindRound';
import { FlexboxGrid, Tooltip, Whisper } from 'rsuite';

import { MemberModel } from '../generated-types/member';

type Props = {
    user?: MemberModel;
    role: string;
};

const UserBox = (props: Props) => {
    const { user } = props;
    return (
        <div>
            <FlexboxGrid align="middle" style={{ paddingRight: 30 }}>
                <FlexboxGrid.Item style={{ padding: 10 }}>
                    <div
                        style={{
                            width: 40,
                            height: 40,
                            background: '#f5f5f5',
                            borderRadius: 20,
                            marginTop: 2,
                            marginLeft: 10,
                            marginRight: 0,
                            marginBottom: 2,
                            overflow: 'hidden',
                            display: 'inline-block',
                        }}
                    >
                        {user?.image_url ? (
                            <img
                                alt="User"
                                referrerPolicy="no-referrer"
                                src={user.image_url || ''}
                                width="44"
                            />
                        ) : null}
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item style={{ padding: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>
                        {user?.firstname} {user?.lastname}
                    </span>
                    <br />
                    <span>{props.role || user?.email}</span>
                    {!user && (
                        <Whisper
                            trigger="hover"
                            placement="bottom"
                            speaker={
                                <Tooltip>Circle is missing {props.role.toLowerCase()} role</Tooltip>
                            }
                        >
                            <RemindRoundIcon style={{ color: 'red', marginLeft: 5 }} />
                        </Whisper>
                    )}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};

export default UserBox;
