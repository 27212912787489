import FileDownloadIcon from '@rsuite/icons/FileDownload';
import format from 'date-fns/format';
import { useEffect } from 'react';
import {
    DateRangePicker,
    Divider,
    IconButton,
    Loader,
    Pagination,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Whisper,
} from 'rsuite';

import { DATE_PATTERN_DATE_RANGE_PICKER } from '../../../config';
import { CircleModelList } from '../../../generated-types/circle';
import { CustomerProjectList } from '../../../generated-types/customer_project';
import { InternalProjectList } from '../../../generated-types/internal_project';
import { MemberList } from '../../../generated-types/member';
import { TimeTrackQuery, useTimeTracks } from '../../../hooks/useTimeTracks';
import {
    getPreviousMonthRange,
    getPreviousWeekRange,
    getThisMonthRange,
    getThisWeekRange,
} from '../../../utils/dateUtils';
import MemberCheckPicker from '../../pickers/MemberCheckPicker';
import { TimetracksApplyParams } from '.';
import ProjectCheckPicker from './ProjectCheckPicker';

const styles = {
    radioGroupLabel: {
        padding: '8px 2px 8px 10px',
        display: 'inline-block',
        verticalAlign: 'middle',
    },
};

type Props = {
    availableFilters: {
        memberPicker?: boolean;
        datePicker?: boolean;
        projectPicker?: boolean;
        filterUnconfirmed?: boolean;
    };
    query: TimeTrackQuery;
    setQuery: (arg: Partial<TimeTrackQuery>) => void;
    applyParams?: (param: TimetracksApplyParams, value: number[] | string) => void;
    total: number;
    filterOptions: {
        members: MemberList;
        circles: CircleModelList;
        customerProjects: CustomerProjectList;
        internalProjects: InternalProjectList;
    };
    adminMode: boolean;
};

type IsConfirmedType = '' | 'confirmed' | 'unconfirmed' | undefined;

const TimeTrackTableScopeComponent = ({
    availableFilters,
    filterOptions,
    query,
    total,
    adminMode,
    setQuery,
    applyParams,
}: Props) => {
    const { getTimeTracksAsCSV, setTtQuery } = useTimeTracks({ disableQuery: true });

    const downloadCsv = async () => {
        const resp = await getTimeTracksAsCSV.mutateAsync();
        const element = document.createElement('a');
        const file = new Blob([resp], {
            type: 'text/csv',
        });
        element.href = URL.createObjectURL(file);
        element.download = `${adminMode ? '' : 'my_'}timetracks_${new Date().getTime()}.csv`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const cp = query?.customer_projects ?? [];
    const ip = query?.internal_projects ?? [];
    const lt = query?.leave_types ?? [];

    const showConfirmedFilter = cp.length === 0 && ip.length === 0 && lt.length > 0;

    if (!availableFilters) {
        return null;
    }

    useEffect(() => {
        setTtQuery(query);
    }, [query]);

    const [firstDayOfTheMonth, lastDayOfTheMonth] = getThisMonthRange(new Date());
    const dateRangePickerValue: [Date, Date] = [
        query?.start_date || firstDayOfTheMonth,
        query?.end_date || lastDayOfTheMonth,
    ];

    return (
        <Stack direction="column" style={{ display: 'block', marginBottom: '20px' }}>
            <Stack justifyContent="space-between" spacing={6} style={{ marginBottom: '20px' }}>
                <Stack spacing={12} wrap>
                    {availableFilters.datePicker && (
                        <DateRangePicker
                            placeholder="Select Date Range"
                            format={DATE_PATTERN_DATE_RANGE_PICKER}
                            defaultValue={dateRangePickerValue}
                            value={dateRangePickerValue}
                            onChange={val => {
                                if (val) {
                                    setQuery({ start_date: val[0], end_date: val[1] });
                                    if (applyParams) {
                                        applyParams('start_date', format(val[0], 'yyyy-MM-dd'));
                                        applyParams('end_date', format(val[1], 'yyyy-MM-dd'));
                                    }
                                } else {
                                    setQuery({
                                        start_date: firstDayOfTheMonth,
                                        end_date: lastDayOfTheMonth,
                                    });
                                    if (applyParams) {
                                        applyParams('start_date', '');
                                        applyParams('end_date', '');
                                    }
                                }
                            }}
                            isoWeek
                            ranges={[
                                {
                                    label: 'Previous month',
                                    value: getPreviousMonthRange(new Date()),
                                },
                                {
                                    label: 'Previous week',
                                    value: getPreviousWeekRange(new Date()),
                                },
                                {
                                    label: 'This week',
                                    value: getThisWeekRange(new Date()),
                                },
                                {
                                    label: 'This month',
                                    value: getThisMonthRange(new Date()),
                                },
                            ]}
                        />
                    )}

                    {availableFilters.memberPicker && (
                        <MemberCheckPicker
                            members={filterOptions.members}
                            onOk={(val: { members: number[] }) => {
                                setQuery({ members: val.members });
                                if (applyParams) applyParams('members', val.members);
                            }}
                            selectedMemberIds={query.members}
                        />
                    )}
                    {availableFilters.projectPicker && (
                        <ProjectCheckPicker
                            circles={filterOptions.circles}
                            customerProjects={filterOptions.customerProjects}
                            applyParams={applyParams}
                            onOk={val => {
                                setQuery({
                                    customer_projects: val.selectedCustomerProjectIds,
                                    internal_projects: val.selectedInternalProjectIds,
                                    leave_types: val.selectedLeaveTypeIds,
                                });
                                if (applyParams) {
                                    if (val?.selectedCustomerProjectIds)
                                        applyParams(
                                            'customer_projects',
                                            val.selectedCustomerProjectIds,
                                        );
                                    if (val?.selectedInternalProjectIds)
                                        applyParams(
                                            'internal_projects',
                                            val.selectedInternalProjectIds,
                                        );
                                    if (val?.selectedLeaveTypeIds)
                                        applyParams('leave_types', val.selectedLeaveTypeIds);
                                }
                            }}
                            selectedCustomerProjectIds={query?.customer_projects ?? []}
                            selectedInternalProjectIds={query?.internal_projects ?? []}
                            selectedLeaveTypeIds={query?.leave_types ?? []}
                        />
                    )}
                    {availableFilters.projectPicker && showConfirmedFilter && (
                        <RadioGroup
                            inline
                            appearance="picker"
                            name="confirmed"
                            value={query?.is_confirmed ?? ''}
                            onChange={val => {
                                setQuery({
                                    is_confirmed: val as IsConfirmedType,
                                });
                                if (applyParams) applyParams('is_confirmed', String(val));
                            }}
                        >
                            <span style={styles.radioGroupLabel}>Leave confirmed</span>
                            <Radio value="unconfirmed">No</Radio>
                            <Radio value={''}>Neutral</Radio>
                            <Radio value="confirmed">Yes</Radio>
                        </RadioGroup>
                    )}
                </Stack>
                <Stack alignItems="flex-start" spacing={6} style={{ height: '100%' }}>
                    <Whisper
                        controlId="download-csv"
                        placement="top"
                        speaker={<Tooltip>Download as .csv</Tooltip>}
                        trigger="hover"
                    >
                        <IconButton
                            style={{ width: '36px', height: '36px' }}
                            disabled={getTimeTracksAsCSV.isLoading}
                            onClick={() => {
                                downloadCsv();
                            }}
                            icon={getTimeTracksAsCSV.isLoading ? <Loader /> : <FileDownloadIcon />}
                        />
                    </Whisper>
                </Stack>
            </Stack>
            <Divider />
            <Pagination
                activePage={Math.floor(query.first / query.count) + 1}
                boundaryLinks
                ellipsis
                first
                last
                layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                limit={query.count}
                limitOptions={[10, 20, 50, 100]}
                maxButtons={5}
                next
                onChangeLimit={newSize => setQuery({ count: newSize })}
                onChangePage={newPage => setQuery({ first: (newPage - 1) * query.count })}
                prev
                size="xs"
                total={total}
            />
        </Stack>
    );
};

export default TimeTrackTableScopeComponent;
