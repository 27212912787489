import { useState } from 'react';
import { Table } from 'rsuite';

import { OPEN_ENDED_ASSIGNMENT_END } from '../../config';
import { AssignmentModel, AssignmentModelList } from '../../generated-types/assignment';
import { useCustomerProjects } from '../../hooks/useCustomerProjects';
import { useMembers } from '../../hooks/useMembers';
import { EditAssignmentDialog } from '../dialogs/EditAssignmentDialog';
import { ImageCell } from './common/ImageCell';

type AssignmentTableProps = {
    assignments: AssignmentModelList;
};

const { Column, HeaderCell, Cell } = Table;

const ActionCell = ({ ...props }) => {
    const { rowData } = props as { rowData: AssignmentModel };

    return (
        <Cell align="center" {...props}>
            <EditAssignmentDialog assignment={rowData} />
        </Cell>
    );
};

const AssignmentTable = ({ assignments }: AssignmentTableProps) => {
    const [sortColumn, setSortColumn] = useState('__memberName');
    const [sortType, setSortType] = useState('desc');
    const { members } = useMembers();
    const { customerProjects } = useCustomerProjects();

    const setSort = (sortColumn = 'name', sortType = 'desc') => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const isProjectActive =
        customerProjects.find(pr => pr.id === assignments[0]?.project_id)?.is_active ?? false;

    const tableData = assignments.map(row => {
        const member = members.find(m => row.member_id === m.id);
        return {
            ...row,
            is_admin: member?.is_admin ?? false,
            __image_url: member?.image_url ?? '',
            __memberName: member?.lastname + ' ' + member?.firstname,
            __projectName: customerProjects.find(pr => pr.id === row.project_id)?.name ?? '',
            __end_date: row.end_date === OPEN_ENDED_ASSIGNMENT_END ? 'Open ended' : row.end_date,
        };
    });

    return (
        <Table
            autoHeight
            sortColumn={sortColumn}
            sortType={'desc'}
            onSortColumn={setSort}
            data={tableData}
        >
            <Column align="left" width={60} key="memberAvatar">
                <HeaderCell>{''}</HeaderCell>
                <ImageCell dataKey="__image_url" />
            </Column>

            <Column align="left" sortable width={180} key="memberName">
                <HeaderCell>Member</HeaderCell>
                <Cell dataKey="__memberName" />
            </Column>

            <Column align="left" sortable width={180} key="projectName">
                <HeaderCell>Project</HeaderCell>
                <Cell dataKey="__projectName" />
            </Column>

            <Column align="left" sortable width={180} key="billingRate">
                <HeaderCell>Billing Rate, €/h</HeaderCell>
                <Cell dataKey="billing_rate" />
            </Column>

            <Column align="left" sortable width={180} key="startDate">
                <HeaderCell>Start</HeaderCell>
                <Cell dataKey="start_date" />
            </Column>

            <Column align="left" sortable width={180} key="endDate">
                <HeaderCell>End</HeaderCell>
                <Cell dataKey="__end_date" />
            </Column>

            {isProjectActive ? (
                <Column width={80} key="actions">
                    <HeaderCell align="center">Actions</HeaderCell>
                    <ActionCell />
                </Column>
            ) : null}
        </Table>
    );
};

export default AssignmentTable;
