import compareAsc from 'date-fns/compareAsc';
import format from 'date-fns/format';
import { TimeTrackModel, TimeTrackModelList } from 'generated-types/timetrack';
import { useLeaveTypes } from 'hooks/useLeaveTypes';
import { useEffect } from 'react';
import Badge from 'rsuite/esm/Badge';
import Calendar from 'rsuite/esm/Calendar';
import Progress from 'rsuite/esm/Progress';
import Tag from 'rsuite/esm/Tag';
import Tooltip from 'rsuite/esm/Tooltip';
import Whisper from 'rsuite/esm/Whisper';
import { isHoliday } from 'utils/holidays';
import { filterOnlyOneDayTimetracks } from 'utils/timetrack';

import { LogHoursApplyParams } from '.';
import { countHourBalance } from './date-hour-utils';
import {
    BadgeStyle,
    CalendarArea,
    CircleContainerStyle,
    CircleStyle,
    YellowOverlay,
} from './styles';

type Props = {
    timetracks: TimeTrackModelList;
    selectedDate: Date;
    selectedTimetrack: TimeTrackModel | undefined;
    applyParams: (param: LogHoursApplyParams, value: string) => void;
};

export function LoggedHoursCalendar({
    timetracks,
    selectedDate,
    selectedTimetrack,
    applyParams,
}: Props) {
    const { expectedHours, actualHours, customerHours, internalHours, leaveHours } =
        countHourBalance(timetracks, selectedDate);
    const { leaveTypes } = useLeaveTypes();

    const dateInFuture = 1 === compareAsc(selectedDate, new Date());
    const percentageLeave = Math.floor((leaveHours / expectedHours) * 100);
    const percentageCustomer = Math.floor((customerHours / expectedHours) * 100);
    const percentageInternal = Math.floor((internalHours / expectedHours) * 100);
    const percentageTotal = Math.floor((actualHours / expectedHours) * 100);

    const expectedDifferenceCustomer = customerHours - expectedHours;
    const expectedDifferenceInternal = internalHours - expectedHours;
    const expectedDifferenceTotal = actualHours - expectedHours;
    const expectedCustomerIsPositive = 0 < expectedDifferenceCustomer;
    const expectedInternalIsPositive = 0 < expectedDifferenceInternal;
    const expectedTotalIsPositive = 0 < expectedDifferenceTotal;
    const expectedTotalIsNegative = 0 > expectedDifferenceTotal;

    function replacePercentages() {
        const elementName = 'div.rs-progress.rs-progress-circle span.rs-progress-circle-info span';
        const leaveValue = document.querySelector(`#leave-circle ${elementName}`);
        const customerValue = document.querySelector(`#customer-circle ${elementName}`);
        const internalValue = document.querySelector(`#internal-circle ${elementName}`);
        const totalValue = document.querySelector(`#total-circle ${elementName}`);

        if (leaveValue) leaveValue.innerHTML = `<small>${leaveHours}</small>`;
        if (customerValue) customerValue.innerHTML = `<small>${customerHours}</small>`;
        if (internalValue) internalValue.innerHTML = `<small>${internalHours}</small>`;
        if (totalValue) totalValue.innerHTML = `<small>${actualHours}</small>`;
    }

    function renderCell(date: Date) {
        const dateHasPaid =
            filterOnlyOneDayTimetracks(date, timetracks, 'paid', leaveTypes).length > 0;
        const dateHasUnpaid =
            filterOnlyOneDayTimetracks(date, timetracks, 'unpaid', leaveTypes).length > 0;
        const dateHasSick =
            filterOnlyOneDayTimetracks(date, timetracks, 'sick', leaveTypes).length > 0;
        const dateHasCustomer =
            filterOnlyOneDayTimetracks(date, timetracks, 'customer', leaveTypes).length > 0;
        const dateHasInternal =
            filterOnlyOneDayTimetracks(date, timetracks, 'internal', leaveTypes).length > 0;
        const dateHasOther =
            filterOnlyOneDayTimetracks(date, timetracks, 'other', leaveTypes).length > 0;

        return (
            <>
                {dateHasCustomer && <Badge style={BadgeStyle.customer} />}
                {dateHasInternal && <Badge style={BadgeStyle.internal} />}
                {dateHasPaid && <Badge style={BadgeStyle.paid} />}
                {dateHasUnpaid && <Badge style={BadgeStyle.unpaid} />}
                {dateHasSick || dateHasOther ? <Badge style={BadgeStyle.sick} /> : null}
                <div className={isHoliday(date) ? YellowOverlay : ''}></div>
            </>
        );
    }

    useEffect(() => {
        replacePercentages();
    }, [actualHours]);

    return (
        <CalendarArea disabled={Boolean(selectedTimetrack)}>
            <Calendar
                key={`calendar_${selectedDate.toDateString()}`}
                isoWeek
                bordered
                defaultValue={selectedDate}
                compact={true}
                renderCell={renderCell}
                onChange={date => applyParams('date', format(date, 'yyyy-MM-dd'))}
            />

            {dateInFuture ? null : (
                <div style={{ textAlign: 'center' }}>
                    <div id="leave-circle" className={CircleContainerStyle}>
                        <Progress.Circle
                            className={CircleStyle}
                            percent={percentageLeave}
                            showInfo={true}
                            strokeWidth={8}
                            strokeColor="#9a9a9a"
                        />
                        <p>Leave</p>
                    </div>
                    <div id="customer-circle" className={CircleContainerStyle}>
                        <Progress.Circle
                            className={CircleStyle}
                            percent={percentageCustomer}
                            showInfo={true}
                            strokeWidth={8}
                            strokeColor="#4caf50"
                        />
                        <p>Customer</p>
                        {expectedCustomerIsPositive && (
                            <div>
                                <Tag size="sm" color="green">
                                    +{expectedDifferenceCustomer}h
                                </Tag>
                            </div>
                        )}
                    </div>
                    <div id="internal-circle" className={CircleContainerStyle}>
                        <Progress.Circle
                            className={CircleStyle}
                            percent={percentageInternal}
                            showInfo={true}
                            strokeWidth={8}
                            strokeColor="#ffb300"
                        />
                        <p>Internal</p>
                        {expectedInternalIsPositive && (
                            <div>
                                <Tag size="sm" color="green">
                                    +{expectedDifferenceInternal}h
                                </Tag>
                            </div>
                        )}
                    </div>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={
                            <Tooltip>
                                <p>
                                    <b>Total expected {expectedHours}h</b>
                                </p>
                                <p>
                                    <i>Monthly excess hours are only valid within the same month</i>
                                </p>
                            </Tooltip>
                        }
                    >
                        <div id="total-circle" className={CircleContainerStyle}>
                            <Progress.Circle
                                className={CircleStyle}
                                percent={percentageTotal}
                                showInfo={true}
                                strokeWidth={8}
                            />
                            <p>Total</p>
                            {expectedTotalIsPositive ? (
                                <div>
                                    <Tag size="sm" color="green">
                                        +{expectedDifferenceTotal}h
                                    </Tag>
                                </div>
                            ) : expectedTotalIsNegative ? (
                                <div>
                                    <Tag size="sm" color="red">
                                        {expectedDifferenceTotal}h
                                    </Tag>
                                </div>
                            ) : null}
                        </div>
                    </Whisper>
                </div>
            )}
        </CalendarArea>
    );
}
