import { Col, FlexboxGrid, Grid, Panel } from 'rsuite';

import { useMembers } from '../../hooks/useMembers';
import { MembershipGrid } from '../tables/MembershipGrid';

export const MembershipsScreen = () => {
    const { members } = useMembers();

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Memberships</h1>
                            </Panel>
                        </FlexboxGrid>
                    </Panel>
                    <Panel bordered bodyFill header={<h3>Members ({members.length})</h3>}>
                        <MembershipGrid />
                    </Panel>
                </Col>
            </Grid>
        </div>
    );
};
