import { useState } from 'react';
import { Button, Modal } from 'rsuite';

type Props = {
    children: JSX.Element;
    handleOK: () => void;
    header: string;
    message: string;
};

export default function ConfirmationDialog({ children, handleOK, header, message }: Props) {
    const [isOpen, setOpen] = useState(false);

    const handleSuccess = () => {
        handleOK();
        setOpen(false);
    };

    return (
        <span>
            <span onClick={() => setOpen(true)}>{children}</span>
            <Modal open={isOpen || false} onClose={() => setOpen(false)}>
                <Modal.Header>
                    <Modal.Title>{header || '<No dialogHeader prop!>'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{message || '<No message prop!>'}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={handleSuccess} appearance="primary">
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </span>
    );
}
