import { UserWithPicture } from 'components/navigation/UserWithPicture';
import { Meeting, Participant, Resolution } from 'generated-types/meeting';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Link } from 'react-router-dom';
import { Button, FlexboxGrid, Panel, Stack } from 'rsuite';

function ResolutionResult({ result }: { result: boolean }) {
    return <b style={{ color: result ? 'green' : 'red' }}>{result ? 'Yes' : 'No'}</b>;
}

function ResolutionDisplay({ resolution }: { resolution: Resolution }) {
    return (
        <div>
            <span>{resolution.text}</span>
            <span>: </span>
            <ResolutionResult result={resolution.vote_passed} />
        </div>
    );
}

export function MeetingMinutes({
    meeting,
    resolutions,
}: {
    meeting: Meeting;
    resolutions: Resolution[];
}) {
    return (
        <Panel bordered style={{ marginBottom: 20 }}>
            <h4>
                {meeting.date} {meeting.topic}
            </h4>
            <div style={{ marginBottom: '0.5em' }}>
                <Participants values={meeting.participants}></Participants>
            </div>

            <ReactMarkdown>{meeting.notes ?? ''}</ReactMarkdown>
            {resolutions.length > 0 && (
                <div style={{ paddingTop: '1em' }}>
                    {resolutions.map((r: Resolution) => (
                        <ResolutionDisplay key={r.id} resolution={r} />
                    ))}
                </div>
            )}

            <FlexboxGrid justify="end">
                <Link to={`/wiki/${meeting.wiki_id}`}>
                    <Button appearance="link">Wiki page</Button>
                </Link>
            </FlexboxGrid>
        </Panel>
    );
}

export function Participants(props: { values: Participant[] }) {
    return (
        <Stack wrap>
            {props.values.map(p => (
                <UserWithPicture key={p.id} user={p}></UserWithPicture>
            ))}
        </Stack>
    );
}
