import { AssignmentModelList } from '../generated-types/assignment';
import { GracePeriodList } from '../generated-types/grace_period';

const findOpenGracePeriods = (
    gracePeriods: GracePeriodList,
    assignments: null | AssignmentModelList = null,
) => {
    const now = new Date();
    const nowStr = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
    const today = new Date(nowStr);
    return gracePeriods.filter(gp => {
        const dateMatch = new Date(gp.locking_date) >= today;
        const assignmentMatch = assignments
            ? assignments.some(x => x.id === gp.assignment_id)
            : true;
        return dateMatch && assignmentMatch;
    });
};

export default findOpenGracePeriods;
