import { strToNumber } from 'schema';
import * as z from 'zod';

export const InternalProjectRecordType = z.object({
    id: strToNumber.optional(),
    name: z.string(),
    description: z.string(),
});

export type InternalProjectType = z.infer<typeof InternalProjectRecordType>;
