import { useTransactionChart } from 'hooks/useTransactions';
import { Loader } from 'rsuite';

import { LineChart } from './LineChart';

const BalanceHistoryChart = ({ circleId }: { circleId: number }) => {
    const { data: transactions, isLoading } = useTransactionChart(circleId);

    if (isLoading) {
        return <Loader />;
    } else {
        return (
            <LineChart
                labels={transactions.map(t => t.month)}
                datasets={[
                    {
                        data: transactions.map(t => t.sum),
                        label: 'BalanceHistory',
                    },
                ]}
            />
        );
    }
};

export default BalanceHistoryChart;
