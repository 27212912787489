import LegacyAvatarIcon from '@rsuite/icons/legacy/Avatar';
import { useEffect, useState } from 'react';
import { Button, Checkbox, CheckPicker, InputGroup } from 'rsuite';

import { arraysAreEqual } from '../../utils/arrayMapping';

const MemberCheckPicker = props => {
    const { selectedMemberIds, onOk, ...passThroughProps } = props;

    const [state, setState] = useState({
        selectedMemberIds: selectedMemberIds || [],
    });

    const options = props.members
        .map(m => ({
            label: `${m.lastname} ${m.firstname}`,
            value: m.id,
        }))
        .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

    const checkAllState = {
        checked:
            state.selectedMemberIds?.length === options.length &&
            state.selectedMemberIds.length > 0,
        indeterminate:
            state.selectedMemberIds?.length > 0 && state.selectedMemberIds.length < options.length,
    };

    const onChange = newSelection => {
        setState({ selectedMemberIds: newSelection });
    };

    const onClean = () => {
        setState({ selectedMemberIds: [] });
        onOk({ members: undefined });
        picker.close();
    };

    const handleCheckAll = (value, checked) => {
        const { indeterminate } = checkAllState;
        onChange(checked || indeterminate ? options.map(o => o.value) : []);
    };
    let picker;

    useEffect(() => {
        if (selectedMemberIds && !arraysAreEqual(selectedMemberIds, state.selectedMemberIds)) {
            setState({
                ...state,
                selectedMemberIds,
            });
        }
    }, [selectedMemberIds]);

    return (
        <InputGroup>
            <InputGroup.Addon>
                <LegacyAvatarIcon />
            </InputGroup.Addon>
            <CheckPicker
                placeholder="Select members"
                onChange={onChange}
                onClean={onClean}
                value={state.selectedMemberIds}
                {...passThroughProps}
                data={options}
                ref={ref => {
                    picker = ref;
                }}
                renderExtraFooter={() => (
                    <div
                        style={{
                            padding: '10px 2px',
                            borderTop: '1px solid #e5e5e5',
                        }}
                    >
                        <Checkbox
                            inline
                            indeterminate={checkAllState.indeterminate}
                            checked={checkAllState.checked}
                            onChange={handleCheckAll}
                        >
                            Check all
                        </Checkbox>
                        <Button
                            style={{
                                float: 'right',
                                marginRight: 10,
                                marginTop: 2,
                            }}
                            appearance="primary"
                            size="sm"
                            onClick={() => {
                                onOk({
                                    members: state.selectedMemberIds.length
                                        ? state.selectedMemberIds
                                        : undefined,
                                });
                                picker.close();
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                )}
            ></CheckPicker>
        </InputGroup>
    );
};

export default MemberCheckPicker;
