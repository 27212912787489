import lambdaApi from 'api/LambdaApi';
import { SkillsMemberModelList } from 'generated-types/skills/member';
import { useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export function useSearch({
    skills,
    minLevel,
    certifications,
}: {
    skills: number[];
    minLevel: number;
    certifications: number[];
}) {
    const skillsExist = skills.length > 0;
    const certificationsExist = certifications.length > 0;

    const { data, isLoading, isFetched } = useQuery<SkillsMemberModelList>(
        ['skills_member_search', skills, minLevel, certifications],
        () => {
            let requestString = `${skillsDBPrefix}/members/search?`;
            if (skillsExist) {
                const skillsString = encodeURIComponent(skills.join(','));
                requestString += `skills=${skillsString}`;
            }
            if (certificationsExist) {
                if (skillsExist) requestString += '&';

                const certificationsString = encodeURIComponent(certifications.join(','));
                requestString += `certifications=${certificationsString}`;
            }
            if (minLevel && skillsExist) {
                requestString += `&min_level=${minLevel}`;
            }
            return lambdaApi.get(requestString).then(res => res.data);
        },
        {
            retry: false,
        },
    );

    return {
        members: data,
        isLoading,
        isFetched,
    };
}
