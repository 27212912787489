import format from 'date-fns/format';
import { TimeTrackModel } from 'generated-types/timetrack';
import { Control, Controller } from 'react-hook-form';
import Button from 'rsuite/esm/Button';
import SelectPicker from 'rsuite/esm/SelectPicker';
import { WorkTimeTrackType } from 'schema/timetracks';

import { ProjectQuickSelectBox, ProjectSelectionBox } from '../styles';
import {
    HandleAssignmentSelection,
    HandleInternalProjectSelection,
    InternalProjectOptions,
    ProjectOptions,
    SuggestedInternalProject,
} from './Types';

type Props = {
    tt: TimeTrackModel | undefined;
    control: Control<WorkTimeTrackType>;
    internalProjectOptions: InternalProjectOptions;
    projectOptions: ProjectOptions;
    assignmentSelected: boolean;
    suggestedInternalProject: SuggestedInternalProject;
    handleInternalProjectSelection: HandleInternalProjectSelection;
    suggestedInternalProjectSelected: boolean;
    handleAssignmentSelection: HandleAssignmentSelection;
    selectedDate: Date;
};

export function ProjectSelector({
    tt,
    control,
    internalProjectOptions,
    projectOptions,
    assignmentSelected,
    suggestedInternalProject,
    handleInternalProjectSelection,
    suggestedInternalProjectSelected,
    handleAssignmentSelection,
    selectedDate,
}: Props) {
    return (
        <ProjectSelectionBox id="projectselectionbox">
            <div>
                <p>Internal project</p>
                <Controller
                    name="internal_project_id"
                    control={control}
                    render={({ field }) => (
                        <SelectPicker
                            // @ts-ignore
                            onSelect={handleInternalProjectSelection}
                            defaultValue={tt?.internal_project_id}
                            {...field}
                            data={internalProjectOptions}
                        />
                    )}
                />
            </div>
            <ProjectQuickSelectBox>
                {projectOptions
                    .filter(project => {
                        return (
                            project.start_date <= format(selectedDate, 'yyyy-MM-dd') &&
                            project.end_date >= format(selectedDate, 'yyyy-MM-dd')
                        );
                    })
                    .map(project => {
                        return (
                            <Button
                                key={`project_${project.value}`}
                                onClick={() => handleAssignmentSelection(project)}
                                appearance={assignmentSelected ? 'primary' : 'ghost'}
                            >
                                {project.label} - {project.billing_rate} €/h
                            </Button>
                        );
                    })}

                {suggestedInternalProject && (
                    <Button
                        onClick={() =>
                            handleInternalProjectSelection(null, suggestedInternalProject)
                        }
                        appearance={suggestedInternalProjectSelected ? 'primary' : 'ghost'}
                        color="violet"
                        style={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }}
                    >
                        {suggestedInternalProject.label}
                    </Button>
                )}
            </ProjectQuickSelectBox>
        </ProjectSelectionBox>
    );
}
