import { queryClient } from 'App';
import { InternalProjectModel } from 'generated-types/internal_project';
import { useCircles } from 'hooks/useCircles';
import { useInternalProjects } from 'hooks/useInternalProjects';
import { useInternalProjectTypes } from 'hooks/useInternalProjectTypes';
import { cloneElement, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { showErrorNotification, showNotification } from '../ShowNotification';
import DynamicEditDialog from './DynamicEditDialog';

type Props = {
    children: JSX.Element;
    editable?: InternalProjectModel;
};

const EditInternalProjectDialog = (props: Props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editable, setEditable] = useState<InternalProjectModel | undefined>(props.editable);
    const [loading, setLoading] = useState({});
    const navigate = useNavigate();

    const { circles } = useCircles();
    const { internalProjectTypes } = useInternalProjectTypes();
    const { createInternalProject, updateInternalProject, deleteInternalProject } =
        useInternalProjects();

    const openDialog = () => {
        setIsDialogOpen(true);
        setEditable(props?.editable);
    };

    const resetState = () => {
        setIsDialogOpen(false);
        setLoading({});
        setEditable(undefined);
    };

    const getChildren = () => {
        return props.children
            ? cloneElement(props.children, {
                  onClick: openDialog,
              })
            : '';
    };

    const handleOk = (patchData: InternalProjectModel) => {
        setLoading({ ...loading, save: true });

        const onSuccess = () => {
            resetState();
            queryClient.invalidateQueries(['internalProjects']);
        };

        const onError = () => {
            setLoading({});
        };

        let newProject;

        if (patchData.id) {
            newProject = {
                ...editable,
                ...patchData,
            };
            updateInternalProject.mutate(
                { payload: newProject, id: patchData.id },
                {
                    onError: error => {
                        showErrorNotification(error);
                        onError();
                    },
                    onSuccess: () => {
                        showNotification({
                            header: 'Project has been edited successfully!',
                        });
                        onSuccess();
                    },
                },
            );
        } else {
            newProject = {
                ...patchData,
            };
            createInternalProject.mutate(newProject, {
                onError: error => {
                    showErrorNotification(error);
                    onError();
                },
                onSuccess: () => {
                    showNotification({
                        header: 'New internal project created!',
                    });
                    onSuccess();
                },
            });
        }
    };

    const handleDelete = () => {
        setLoading({ ...loading, delete: true });
        if (editable) {
            deleteInternalProject.mutate(editable.id, {
                onError: error => {
                    showErrorNotification(error);
                },
                onSuccess: () => {
                    showNotification({
                        header: 'Internal project deleted successfully!',
                    });
                    setIsDialogOpen(false);
                    navigate('/internalprojects');
                    setLoading({});
                },
            });
        }
    };
    const circlesOptions = useMemo(() => {
        const options = circles.map(c => ({
            label: c.name || `Circle #${c.id}`,
            value: c.id,
        }));
        options.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

        return options;
    }, [circles]);

    const projectTypesOptions = internalProjectTypes
        .map(c => ({
            label: c.name,
            value: c.id,
        }))
        .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

    return (
        <div>
            {getChildren()}
            <DynamicEditDialog
                dialogHeader={props.editable ? 'Edit Internal Project' : 'Add Internal Project'}
                fields={{
                    name: {
                        icon: 'short_text',
                        label: 'Name',
                        type: 'text',
                    },
                    circle_id: {
                        icon: 'circle-o',
                        label: 'Circle',
                        type: 'select',
                        options: circlesOptions,
                    },
                    type_id: {
                        icon: 'list',
                        label: 'Project Type',
                        type: 'select',
                        options: projectTypesOptions,
                    },
                    is_billable: {
                        icon: 'money',
                        label: 'Billable',
                        type: 'checkbox',
                    },
                    description: {
                        icon: 'list',
                        label: 'Description',
                        type: 'textarea',
                    },
                }}
                editable={editable || { name: '' }}
                isOpen={isDialogOpen}
                closeHandler={resetState}
                saveDataHandler={handleOk}
                deleteHandler={editable && handleDelete}
                loading={loading}
            />
        </div>
    );
};

export default EditInternalProjectDialog;
