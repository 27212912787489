import './style.css';

import add from 'date-fns/add';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import sub from 'date-fns/sub';
import { useTimetrackStatistics } from 'hooks/useTimetrackStatistics';
import Loader from 'rsuite/Loader';

import DateCells from './DateCells';
import LeavesData from './LeavesData';
import LeavesTotals from './LeavesTotals';

type Props = {
    months: number;
};

const LeavesTable = ({ months }: Props) => {
    const { leaveOverview, isLoading } = useTimetrackStatistics();

    const dates = eachDayOfInterval({
        start: sub(new Date(), { months }),
        end: add(new Date(), { months }),
    });

    return isLoading ? (
        <Loader />
    ) : (
        <div className="tableContainer">
            <table>
                <thead>
                    <tr>
                        <th className="top-cell-up"></th>
                        <th className="top-cell-up"></th>
                        <th className="top-cell-up"></th>
                        <DateCells dates={dates} unit="year" />
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <DateCells dates={dates} unit="month" />
                    </tr>
                    <tr>
                        <th></th>
                        <th style={{ verticalAlign: 'bottom' }}>Days</th>
                        <th style={{ verticalAlign: 'bottom' }}>Days</th>
                        <DateCells dates={dates} unit="week" />
                    </tr>
                    <tr>
                        <th className="top-cell-down">Name</th>
                        <th className="top-cell-down">total</th>
                        <th className="top-cell-down">left</th>
                        <DateCells dates={dates} unit="day" />
                    </tr>
                </thead>

                <tbody>
                    <LeavesData dates={dates} leaveOverview={leaveOverview} />
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total Absences</th>
                        <td></td>
                        <td></td>
                        <LeavesTotals dates={dates} leaveOverview={leaveOverview} />
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default LeavesTable;
