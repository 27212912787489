import { useState } from 'react';
import { Button, FlexboxGrid, Input, Panel } from 'rsuite';

export default function AddCertification({
    addCertification,
}: {
    addCertification: (issuer: string, name: string) => void;
}) {
    const [issuer, setIssuer] = useState('');
    const [name, setName] = useState('');

    return (
        <FlexboxGrid.Item>
            <Panel
                shaded
                style={{
                    width: 300,
                    height: 320,
                    margin: 10,
                    opacity: 1,
                    position: 'relative',
                }}
                header={
                    <FlexboxGrid justify="center">
                        <h5>Add New</h5>
                    </FlexboxGrid>
                }
            >
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={3}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={15}>
                        Issuer <Input title="Issuer" value={issuer} onChange={setIssuer} />
                        Name <Input title="Name" value={name} onChange={setName} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={3}></FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid justify="center" style={{ paddingTop: '1em' }}>
                    <Button
                        appearance="primary"
                        color="green"
                        onClick={() => {
                            setName('');
                            setIssuer('');
                            addCertification(issuer, name);
                        }}
                        disabled={name === ''}
                    >
                        Add
                    </Button>
                </FlexboxGrid>
            </Panel>
        </FlexboxGrid.Item>
    );
}
