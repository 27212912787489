import { NotFound } from 'components/screens/NotFound';
import { useSearch } from 'hooks/skills/useSearch';
import { Loader, Panel } from 'rsuite';

import { MemberCard } from './MemberCard';

export const SkillsSearch = ({
    selectedSkills,
    selectedMinLevel,
    selectedCertifications,
}: {
    selectedSkills: number[];
    selectedMinLevel: number;
    selectedCertifications: number[];
}) => {
    const { members, isLoading } = useSearch({
        skills: selectedSkills,
        minLevel: selectedMinLevel,
        certifications: selectedCertifications,
    });

    if (isLoading) {
        return <Loader center content="Loading members..." />;
    }

    const noMembersFound = !members || members.length === 0;

    return (
        <>
            <Panel>
                <h2>Search Results</h2>
            </Panel>

            {noMembersFound ? (
                <NotFound message="No members found with these skills" />
            ) : (
                members?.map(member => (
                    <MemberCard
                        key={member.id}
                        selectedSkills={selectedSkills}
                        selectedCertifications={selectedCertifications}
                        member={member}
                    />
                ))
            )}
        </>
    );
};
