import { useState } from 'react';
import { Button, ButtonToolbar, Loader, Table, TableProps } from 'rsuite';

import { TotalRevenueRequestPayload } from '../../generated-types/total_revenues';
import { useProfile } from '../../hooks/useProfile';
import { useTotalRevenues } from '../../hooks/useTotalRevenues';
import { generateMonthKeyRange, getPreviousMonthKey } from '../../utils/keyGenerators';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import DynamicEditDialog from '../dialogs/DynamicEditDialog';
import { EurosCell } from '../Formats';

const { Column, HeaderCell, Cell } = Table;

type TableData = {
    id: string;
    amount: number;
    salaries: number;
    operative_costs: number;
    monthKey: string;
    committed: boolean;
};

type Editable = {
    month: string;
    amount: number;
    salaries: number;
};

const RevenueTable = () => {
    const [sortColumn, setSortColumn] = useState<keyof TableData>('monthKey');
    const [sortType, setSortType] = useState<TableProps['sortType']>('asc');

    const monthKeys = generateMonthKeyRange(
        '2019-09', // The first month that the new funding model was used.
        getPreviousMonthKey(new Date().toISOString()),
    );

    const [state, setState] = useState({
        monthKeys,
        revenueDialogEditable: { month: '', amount: 0, salaries: 0 },
        revenueDialogVisible: false,
    });

    const { isAdmin } = useProfile();
    const { totalRevenues, isSuccess, setAmountsTotalRevenue, commitTotalRevenue } =
        useTotalRevenues();

    const setSort = (sortColumn: keyof TableData) => {
        setSortColumn(sortColumn);
        if (sortType === 'asc') {
            setSortType('desc');
        } else {
            setSortType('asc');
        }
    };

    const getTableData = () => {
        const tableData: TableData[] = monthKeys.map(monthKey => {
            const revenueRow = totalRevenues.find(revenue => revenue.id === monthKey);
            return {
                id: monthKey,
                amount: revenueRow?.amount || 0,
                salaries: revenueRow?.salaries || 0,
                operative_costs: revenueRow?.operative_costs || 0,
                committed: revenueRow?.committed || false,
                monthKey,
            };
        });
        tableData.sort((a, b) => {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
        });
        sortType === 'desc' && tableData.reverse();
        return tableData;
    };

    const openRevenueDialog = (editable: Editable) => {
        setState({
            revenueDialogVisible: true,
            revenueDialogEditable: editable,
            monthKeys,
        });
    };

    const revenueDialogSave = (patchData: Editable) => {
        const payload: TotalRevenueRequestPayload = {
            id: patchData.month,
            amount: patchData.amount,
            salaries: patchData.salaries,
            committed: false,
        };
        setAmountsTotalRevenue.mutate(payload);
        closeDialogs();
    };

    const commitRevenue = (month: string) => {
        const payload = { id: month };
        commitTotalRevenue.mutate(payload);
    };

    const closeDialogs = () => {
        setState({
            revenueDialogVisible: false,
            revenueDialogEditable: { month: '', amount: 0, salaries: 0 },
            monthKeys,
        });
    };

    return isSuccess ? (
        <div>
            <Table
                autoHeight
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={setSort as (sortColumn: string) => void}
                data={getTableData()}
            >
                <Column align="left" fixed flexGrow={1} sortable>
                    <HeaderCell>Month</HeaderCell>
                    <Cell dataKey="monthKey" />
                </Column>
                <Column align="right" fixed flexGrow={1} sortable>
                    <HeaderCell>Revenue</HeaderCell>
                    <EurosCell dataKey="amount" />
                </Column>
                <Column align="right" fixed flexGrow={1} sortable>
                    <HeaderCell>Salaries</HeaderCell>
                    <EurosCell dataKey="salaries" />
                </Column>
                <Column align="right" fixed flexGrow={1} sortable>
                    <HeaderCell>Operative Costs</HeaderCell>
                    <EurosCell dataKey="operative_costs" />
                </Column>
                {isAdmin && (
                    <Column align="left" fixed flexGrow={1}>
                        <HeaderCell>Actions</HeaderCell>
                        <Cell>
                            {rowData => {
                                return (
                                    <ButtonToolbar>
                                        {!rowData.committed && (
                                            <Button
                                                appearance="primary"
                                                size="xs"
                                                onClick={() => {
                                                    openRevenueDialog({
                                                        month: rowData.monthKey,
                                                        amount: rowData.amount || 0,
                                                        salaries: rowData.salaries || 0,
                                                    });
                                                }}
                                            >
                                                Set Amounts
                                            </Button>
                                        )}
                                        {rowData.amount &&
                                        rowData.salaries > 0 &&
                                        !rowData.committed ? (
                                            <ConfirmationDialog
                                                handleOK={() => {
                                                    commitRevenue(rowData.monthKey);
                                                }}
                                                header="Are you sure?"
                                                message={`NOTE: All the commited revenue will be allocated to circles. Make sure you only commit the share of the revenue you want to go to circles. This is new behavior based on budget reform.`}
                                            >
                                                <Button size="xs" appearance="primary">
                                                    Commit
                                                </Button>
                                            </ConfirmationDialog>
                                        ) : (
                                            ''
                                        )}
                                    </ButtonToolbar>
                                );
                            }}
                        </Cell>
                    </Column>
                )}
            </Table>
            <DynamicEditDialog
                dialogHeader="Set the Month's Circles money"
                fields={{
                    amount: {
                        icon: null,
                        label: 'Revenue',
                        step: 1,
                        type: 'number',
                        autofocus: true,
                    },
                    salaries: {
                        icon: null,
                        label: 'Salaries',
                        step: 1,
                        type: 'number',
                        autofocus: true,
                    },
                    month: {
                        icon: 'today',
                        label: 'Month',
                        type: 'text',
                        disabled: true,
                    },
                }}
                editable={state.revenueDialogEditable}
                isOpen={state.revenueDialogVisible}
                closeHandler={closeDialogs}
                saveDataHandler={revenueDialogSave}
            />
        </div>
    ) : (
        <Loader />
    );
};

export default RevenueTable;
