import { LeaveStatisticSingle } from 'generated-types/timetrack';
import { useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';

export type LeaveStatisticsType = {
    [key: string]: LeaveStatisticSingle[];
};

export function useTimetrackStatistics() {
    const { data, isLoading, isSuccess } = useQuery<LeaveStatisticsType>(['leaveOverview'], () =>
        lambdaApi.get('/timetracks/statistics/leaves/overview', {}).then(res => res.data),
    );

    return {
        leaveOverview: data ?? ({} as LeaveStatisticsType),
        isLoading,
        isSuccess,
    };
}
