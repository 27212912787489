import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, Table, TableProps } from 'rsuite';

import { useInternalProjectTypes } from '../../hooks/useInternalProjectTypes';

const { Column, HeaderCell, Cell } = Table;

const InternalProjectTypesTable = () => {
    const [sortType, setSortType] = useState<TableProps['sortType']>('asc');

    const navigate = useNavigate();
    const { internalProjectTypes, isSuccess } = useInternalProjectTypes({
        sortByName: true,
        sortOrder: sortType ?? 'asc',
    });

    const setSort = () => {
        if (sortType === 'asc') {
            setSortType('desc');
        } else {
            setSortType('asc');
        }
    };

    return isSuccess ? (
        <Table
            autoHeight
            sortColumn={'name'}
            sortType={sortType}
            onSortColumn={setSort}
            data={internalProjectTypes}
            onRowClick={row => {
                navigate(`/internalprojecttypes/${row.id}`);
            }}
        >
            <Column align="left" fixed flexGrow={1} minWidth={160} sortable>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
            </Column>
        </Table>
    ) : (
        <Loader />
    );
};

export default InternalProjectTypesTable;
