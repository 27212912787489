import { useCustomerProjects } from 'hooks/useCustomerProjects';
import { useCustomers } from 'hooks/useCustomers';
import { cloneElement, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { showErrorNotification, showNotification } from '../ShowNotification';
import DynamicEditDialog from './DynamicEditDialog';

const defaultTemplate = {
    name: '',
};

const EditCustomerProjectDialog = props => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editable, setEditable] = useState(props.editable || defaultTemplate);
    const [loading, setLoading] = useState({});

    const { customers } = useCustomers();
    const { createCustomerProject, updateCustomerProject, deleteCustomerProject, refetch } =
        useCustomerProjects();
    const navigate = useNavigate();

    const openDialog = () => {
        setIsDialogOpen(true);
        setEditable(props.editable || defaultTemplate);
    };

    const resetState = () => {
        setIsDialogOpen(false);
        setLoading({});
        setEditable(defaultTemplate);
    };

    const getChildren = () => {
        return props.children
            ? cloneElement(props.children, {
                  onClick: openDialog,
              })
            : '';
    };

    const handleOk = patchData => {
        setLoading({ ...loading, save: true });
        let newProject;

        const onSuccess = () => {
            resetState();
            refetch();
            props.afterOK && props.afterOK();
        };

        const onError = () => {
            setLoading({});
        };

        if (patchData.id) {
            console.log(`Updating customer project: ${JSON.stringify(patchData)}`);
            newProject = {
                ...editable,
                ...patchData,
            };
            updateCustomerProject.mutate(
                { payload: newProject, id: newProject.id },
                {
                    onError: error => {
                        showErrorNotification(error);
                        onError();
                    },
                    onSuccess: () => {
                        showNotification({
                            header: 'Customer project updated succesfully!',
                        });
                        onSuccess();
                    },
                },
            );
        } else {
            console.log(`Creating customer project: ${JSON.stringify(patchData)}`);
            newProject = {
                ...patchData,
            };
            createCustomerProject.mutate(newProject, {
                onError: error => {
                    showErrorNotification(error);
                    onError();
                },
                onSuccess: () => {
                    showNotification({ header: 'Customer project created succesfully!' });
                    onSuccess();
                },
            });
        }
    };
    const toggleProjectActive = () => {
        const updatedProject = {
            ...editable,
            is_active: true,
        };
        updateCustomerProject.mutate(
            { payload: updatedProject, id: editable.id },
            {
                onError: error => {
                    showErrorNotification(error);
                    setLoading({});
                },
                onSuccess: () => {
                    showNotification({
                        header: 'Customer project activated succesfully!',
                    });
                    refetch();
                },
            },
        );
        resetState();
    };

    const deleteProject = () => {
        setLoading({ ...loading, delete: true });
        deleteCustomerProject.mutate(editable.id, {
            onError: error => {
                showErrorNotification(error);
                setLoading({});
            },
            onSuccess: () => {
                showNotification({ header: 'Project deleted succesfully!' });
                resetState();
                refetch();
                navigate('/customerprojects');
            },
        });
    };

    const customersOptions = useMemo(() => {
        if (!customers) {
            return [];
        }

        const options = customers.map(c => ({
            label: c.name,
            value: c.id,
        }));
        options.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

        return options;
    }, [customers]);

    if (!customers) {
        return null;
    }

    const fields = {
        name: {
            icon: 'short_text',
            label: 'Name',
            type: 'text',
        },
        customer_id: {
            icon: 'industry',
            label: 'Customer',
            type: 'select',
            options: customersOptions,
        },
        is_active: {
            icon: 'money',
            label: 'Active',
            type: 'checkbox',
        },
        description: {
            icon: 'list',
            label: 'Description',
            type: 'textarea',
        },
    };

    if (props.editable && props.editable.id) {
        // Show the button only if the project already exists. (Meaning that the editable has an id.)
        if (props.editable && props.editable.current_balance > 0) {
            // If the project has transactions, it can not be deleted. Only disabled. Show disable/enable button.
            // TODO: Check the actual transactions, not balance.
            fields.button_delete_disable = props.editable.is_active
                ? {
                      color: 'red',
                      confirmationHeader: 'Are you sure?',
                      confirmationMessage:
                          "Deactivating a project will end all assignments in the project automatically. The project will keep it's balance for future activation. The project will be hidden from regular users.",
                      icon: 'project',
                      handler: toggleProjectActive,
                      label: 'Deactivate Project',
                      type: 'buttonWithConfirmation',
                  }
                : {
                      color: 'green',
                      confirmationHeader: 'Are you sure?',
                      confirmationMessage:
                          'Reactivating a project will make it visible to regular users and allow assigning them to the project.',
                      icon: 'project',
                      handler: toggleProjectActive,
                      label: 'Reactivate Project',
                      type: 'buttonWithConfirmation',
                  };
        } else {
            // If the project has no transactions, it can be deleted. Show the delete button.
            fields.button_delete_disable = {
                color: 'red',
                confirmationHeader: 'Are you sure?',
                confirmationMessage:
                    'Deleting a project is only possible if it has no hours logged. This action will delete the project, and its assignments from the database.',
                icon: 'project',
                handler: deleteProject,
                label: 'Delete Project',
                type: 'buttonWithConfirmation',
            };
        }
    }

    return (
        <div style={{ display: 'inline', marginLeft: '6px' }}>
            {getChildren()}
            <DynamicEditDialog
                dialogHeader={props.editable ? 'Edit Customer Project' : 'Add Customer Project'}
                fields={fields}
                editable={editable}
                isOpen={isDialogOpen}
                closeHandler={resetState}
                saveDataHandler={handleOk}
                loading={loading}
            />
        </div>
    );
};

export default EditCustomerProjectDialog;
