import { Line, Page, Svg, Text, View } from '@react-pdf/renderer';
import { SkillsMemberSkillModelList } from 'generated-types/skills/skill';

import { CVFooter } from './Footer';
import { pageSize, styles, TopGradientBar } from './styles';

export const MaxSkillRenderCount = 24;

export const SkillsPage = ({
    skills,
    footerText,
}: {
    skills: SkillsMemberSkillModelList;
    footerText: string;
}) => {
    const sortedSkills = skills
        .filter(skill => skill.level && skill.show_on_cv !== false)
        .sort((a, b) => {
            if (!a.level) return 1;
            if (!b.level) return -1;
            return b.level - a.level;
        })
        .slice(0, MaxSkillRenderCount);

    const getSkillLevelText = (level: number) => {
        if (level < 40) return 'Intermediate';
        if (level < 80) return 'Advanced';
        return 'Expert';
    };

    return (
        <Page size={pageSize} style={styles.page}>
            <TopGradientBar />
            <View style={styles.pageTitleContainer} fixed>
                <Text style={styles.pageTitleText}>Skills & Technologies</Text>
            </View>
            <View style={styles.skillsContainer}>
                {sortedSkills.map((skill, index) => {
                    const stroke = index % 2 === 0 ? '#1f3b70' : '#d9165c';
                    return (
                        <View key={skill.id} style={styles.skillBox} wrap={false}>
                            <View style={styles.skillBoxFlex}>
                                <Text style={styles.skillBoxName}>{skill.name}</Text>
                                <Text style={styles.skillBoxLevel}>
                                    {skill?.level ? getSkillLevelText(skill.level) : null}
                                </Text>
                            </View>
                            <View style={styles.skillProgressBarBox}>
                                <Svg height={10} width={skill.level ? `${skill.level}%` : 0}>
                                    <Line
                                        x1="0"
                                        x2="390"
                                        y1="0"
                                        y2="0"
                                        strokeWidth={18}
                                        stroke={stroke}
                                    />
                                </Svg>
                            </View>
                        </View>
                    );
                })}
            </View>
            <CVFooter footerText={footerText} />
        </Page>
    );
};
