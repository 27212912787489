import format from 'date-fns/format';
import { SkillsEducationModel } from 'generated-types/skills/education';
import { useLocalStorage } from 'hooks/misc/useLocalStorage';
import { useState } from 'react';
import { Button, ButtonGroup, FlexboxGrid, Panel } from 'rsuite';

import { EducationEditForm } from './EducationEditForm';

export const Education = ({
    editAllowed,
    education,
}: {
    editAllowed: boolean;
    education: SkillsEducationModel;
}) => {
    const [theme] = useLocalStorage<'light' | 'dark'>('theme', 'light');
    const [editMode, setEditMode] = useState(false);
    const endDate = education.end_date && new Date(education.end_date);

    if (editMode) {
        return <EducationEditForm education={education} setEditMode={setEditMode} />;
    }
    return (
        <Panel
            bordered
            shaded
            key={education.id}
            style={{
                marginBottom: 20,
                background: 'white',
                color: theme === 'dark' ? '#0f131a' : 'inherit',
            }}
        >
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item>
                    <h3>{education.degree}</h3>
                    <p>{endDate && format(endDate, 'MMMM yyyy')}</p>
                </FlexboxGrid.Item>
                {editAllowed && (
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button onClick={() => setEditMode(true)}>Edit</Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                )}
            </FlexboxGrid>
            <p
                style={{
                    fontSize: 15,
                    margin: 20,
                    textAlign: 'justify',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {education.description}
            </p>
        </Panel>
    );
};
