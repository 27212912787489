import { Euros, Weight } from 'components/Formats';
import { CircleModel } from 'generated-types/circle';
import { MemberModel } from 'generated-types/member';
import { useState } from 'react';
import { Nav, Panel } from 'rsuite';

import { AboutTab } from './tabs/AboutTab';
import { BalanceTab } from './tabs/BalanceTab';
import { CostsTab } from './tabs/CostsTab';
import { MeetingsTab } from './tabs/MeetingsTab';
import { WeightTab } from './tabs/WeightTab';

type ActiveTab = 'about' | 'balance' | 'costs' | 'weight' | 'meetings';

type TabsProps = {
    active: ActiveTab;
    onSelect: React.Dispatch<React.SetStateAction<ActiveTab>>;
};

type Props = {
    circle: CircleModel;
    currentMembers: MemberModel[];
    showEditCircleButton: boolean;
    isAdmin: boolean;
};

export const BodyPanel = ({ circle, currentMembers, showEditCircleButton, isAdmin }: Props) => {
    const [activeTab, setActiveTab] = useState<ActiveTab>('about');

    const CircleDetailsTabs = ({ active, onSelect }: TabsProps) => {
        return (
            <Nav activeKey={active} onSelect={onSelect} appearance="subtle">
                <Nav.Item eventKey="about">About</Nav.Item>
                <Nav.Item eventKey="balance">Balance {Euros(circle?.current_balance)}€</Nav.Item>
                <Nav.Item eventKey="costs">Operative Costs</Nav.Item>
                <Nav.Item eventKey="weight">Weight {Weight(circle.current_weight)}</Nav.Item>
                <Nav.Item eventKey="meetings">Meetings</Nav.Item>
            </Nav>
        );
    };

    return (
        <Panel bordered header={<CircleDetailsTabs active={activeTab} onSelect={setActiveTab} />}>
            {activeTab === 'about' && (
                <AboutTab
                    circle={circle}
                    currentMembers={currentMembers}
                    showEditCircleButton={showEditCircleButton}
                />
            )}
            {activeTab === 'balance' && <BalanceTab circle={circle} />}
            {activeTab === 'costs' && <CostsTab circle={circle} isAdmin={isAdmin} />}
            {activeTab === 'weight' && <WeightTab circle={circle} />}
            {activeTab === 'meetings' && <MeetingsTab circle={circle} />}
        </Panel>
    );
};
