import { zodResolver } from '@hookform/resolvers/zod';
import format from 'date-fns/format';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker, Input, Row } from 'rsuite';

import { MYSQL_DATE_STRING } from '../../config';
import {
    OperativeCostModel,
    OperativeCostRequestPayload,
} from '../../generated-types/operative_cost';
import { useOperativeCost } from '../../hooks/useOperativeCost';
import { useProfile } from '../../hooks/useProfile';
import { EditOperativeCostSchema, EditOperativeCostType } from '../../schema/operative_costs';
import { DialogWrap } from '../misc/DialogWrap';
import { showErrorNotification, showNotification } from '../ShowNotification';

type Props = {
    circleId: number;
    operativeCost: OperativeCostModel;
};

export function EditOperativeCostDialog({ operativeCost, circleId }: Props) {
    const { user } = useProfile();
    const [open, setOpen] = useState(false);
    const { refetch, updateOperativeCost } = useOperativeCost(circleId);
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
    } = useForm<EditOperativeCostType>({
        resolver: zodResolver(EditOperativeCostSchema),
    });

    const onSubmit: SubmitHandler<EditOperativeCostType> = data => {
        const { end_date, start_date, id, ...rest } = data;
        format(start_date, MYSQL_DATE_STRING);
        const payload: OperativeCostRequestPayload = {
            start_date: format(start_date, MYSQL_DATE_STRING),
            end_date: end_date ? format(end_date, MYSQL_DATE_STRING) : undefined,
            circle_id: circleId,
            ...rest,
        };
        updateOperativeCost.mutate(
            { payload, id: Number(id) },
            {
                onError: err => {
                    showErrorNotification(err);
                },
                onSuccess: () => {
                    showNotification({
                        header: 'Operative cost edited!',
                    });
                    refetch();
                    setOpen(false);
                },
            },
        );
    };

    if (!user) {
        return null;
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                title={`Edit Operative Cost`}
                onSubmit={handleSubmit(onSubmit)}
                buttonOpenStyle={{
                    size: 'xs',
                    appearance: 'primary',
                    color: 'blue',
                    showPlusIcon: false,
                }}
            >
                <input hidden {...register('id')} value={operativeCost.id} />

                <Row>
                    <p>Name</p>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={operativeCost.name}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.name?.message}</p>
                </Row>
                <Row>
                    <p>Description</p>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={operativeCost?.description ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.description?.message}</p>
                </Row>
                <Row>
                    <p>Amount</p>
                    <Controller
                        name="amount"
                        control={control}
                        defaultValue={operativeCost?.amount ?? 0}
                        render={({ field }) => <Input type="number" {...field} />}
                    />
                    <p className="error">{errors?.amount?.message}</p>
                </Row>
                <Row>
                    <p>Start date</p>
                    <Controller
                        name="start_date"
                        control={control}
                        defaultValue={
                            operativeCost?.start_date
                                ? new Date(operativeCost.start_date)
                                : undefined
                        }
                        render={({ field }) => <DatePicker oneTap {...field} />}
                    />
                    <p className="error">{errors?.end_date?.message}</p>
                </Row>
                <Row>
                    <p>End date</p>
                    <Controller
                        name="end_date"
                        control={control}
                        defaultValue={
                            operativeCost?.end_date ? new Date(operativeCost.end_date) : undefined
                        }
                        render={({ field }) => <DatePicker oneTap {...field} />}
                    />
                    <p className="error">{errors?.end_date?.message}</p>
                </Row>
            </DialogWrap>
        </form>
    );
}
