import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Col, Input, Row, SelectPicker } from 'rsuite';

import { useCircles } from '../../hooks/useCircles';
import { useMembers } from '../../hooks/useMembers';
import { useProfile } from '../../hooks/useProfile';
import { NewCircleSchema, NewCircleSchemaType } from '../../schema/circle';
import { DialogWrap } from '../misc/DialogWrap';
import { showErrorNotification, showNotification } from '../ShowNotification';

export function NewCircleDialog() {
    const { members } = useMembers(false, true);
    const { user } = useProfile();
    const { createCircle } = useCircles();
    const [open, setOpen] = useState(false);
    const {
        formState: { errors },
        control,
        handleSubmit,
        setValue,
        register,
        reset,
    } = useForm<NewCircleSchemaType>({
        resolver: zodResolver(NewCircleSchema),
    });

    const onSubmit: SubmitHandler<NewCircleSchemaType> = payload => {
        createCircle.mutate(payload, {
            onError: err => {
                showErrorNotification(err);
            },
            onSuccess: () => {
                showNotification({
                    header: 'Circle created!',
                });
                reset({});
                setOpen(false);
            },
        });
    };

    if (!user) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                title={'New Circle'}
                onSubmit={handleSubmit(onSubmit)}
            >
                <input hidden {...register('is_active')} type="checkbox" value={'true'} />
                <Row>
                    <p>Name</p>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input maxLength={255} type="text" {...field} />}
                    />
                    <p className="error">{errors?.name?.message}</p>
                </Row>
                <Row>
                    <p>Description</p>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input maxLength={255} type="text" {...field} />}
                    />
                    <p className="error">{errors?.description?.message}</p>
                </Row>
                <Row>
                    <p>Google Drive Link</p>
                    <Controller
                        name="drivelink"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.drivelink?.message}</p>
                </Row>
                <Row>
                    <p>Mattermost</p>
                    <Controller
                        name="mattermost"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.mattermost?.message}</p>
                </Row>
                <Row>
                    <p>Mission</p>
                    <Controller
                        name="mission"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.mission?.message}</p>
                </Row>
                <Row>
                    <Col xl={12}>
                        <p>Max balance</p>
                        <Controller
                            name="max_balance"
                            control={control}
                            defaultValue={0}
                            render={({ field }) => <Input type="number" {...field} />}
                        />
                        <p className="error">{errors?.max_balance?.message}</p>
                    </Col>
                    <Col xl={12}>
                        <p>Weight</p>
                        <Controller
                            name="current_weight"
                            control={control}
                            defaultValue={0}
                            render={({ field }) => <Input type="number" {...field} />}
                        />
                        <p className="error">{errors?.max_balance?.message}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7}>
                        <p>Facilitator</p>
                        <SelectPicker
                            style={{ width: '200px' }}
                            searchable={false}
                            onChange={val => {
                                setValue('facilitator_id', val ? val : undefined);
                            }}
                            data={members.map(m => {
                                return { value: m.id, label: `${m.lastname} ${m.firstname}` };
                            })}
                        />
                        <p className="error">{errors?.facilitator_id?.message}</p>
                    </Col>
                    <Col xs={7}>
                        <p>Scribe</p>
                        <SelectPicker
                            style={{ width: '200px' }}
                            searchable={false}
                            onChange={val => {
                                setValue('scribe_id', val ? val : undefined);
                            }}
                            data={members.map(m => {
                                return { value: m.id, label: `${m.lastname} ${m.firstname}` };
                            })}
                        />
                        <p className="error">{errors?.scribe_id?.message}</p>
                    </Col>
                    <Col xs={7}>
                        <p>Treasurer</p>
                        <SelectPicker
                            style={{ width: '200px' }}
                            searchable={false}
                            onChange={val => {
                                setValue('treasurer_id', val ? val : undefined);
                            }}
                            data={members.map(m => {
                                return { value: m.id, label: `${m.lastname} ${m.firstname}` };
                            })}
                        />
                        <p className="error">{errors?.treasurer_id?.message}</p>
                    </Col>
                </Row>
            </DialogWrap>
        </form>
    );
}
