import { useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { MemberModel } from '../generated-types/member';
import { Member, MemberType } from '../schema/member';

type Props = {
    userId: number | string | undefined;
};

export function useMember({ userId }: Props) {
    const { data, isLoading } = useQuery<MemberModel>([userId], () =>
        lambdaApi.get(`/${userId}/profile`, {}).then(res => res.data),
    );

    let parsedMember: MemberType | undefined;

    if (data) {
        parsedMember = Member.parse(data);
    }

    return {
        user: parsedMember,
        isLoading,
    };
}
