import { Table } from 'rsuite';

import { formatNumber } from '../utils/formatters';
const { Cell } = Table;

type CellType = {
    dataKey: string;
    rowData?: { [key: string]: number };
};

export const Euros = (value = 0) => {
    if (value === 0) {
        return <span>{formatNumber(value)}</span>;
    }
    return <span style={{ color: value > 0 ? 'green' : 'red' }}>{formatNumber(value)}</span>;
};

export const EurosCell = ({ rowData, dataKey, ...props }: CellType) => {
    if (rowData) {
        return <Cell {...props}>{Euros(rowData[dataKey])}</Cell>;
    }
    return <Cell {...props}></Cell>;
};

export const Weight = (value = 0) => {
    return <span style={{ color: value ? '' : 'grey' }}>{formatNumber(value)}</span>;
};

export const WeightCell = ({ rowData, dataKey, ...props }: CellType) => {
    if (rowData) {
        return <Cell {...props}>{Weight(rowData[dataKey])}</Cell>;
    }
    return <Cell {...props}></Cell>;
};

export const NumberCell = ({ rowData, dataKey, ...props }: CellType) => {
    if (rowData) {
        return <Cell {...props}>{formatNumber(rowData[dataKey])}</Cell>;
    }
    return <Cell {...props}></Cell>;
};
