import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import {
    LedgerList,
    LedgerModel,
    LedgerRequestPayload,
    LedgerResponse,
    TransactionSum,
} from '../generated-types/ledger';

function createLedgerQueryString(
    circleId: number | undefined,
    page: number,
    pageSize: number,
    sortColumn: string,
    sortType: 'asc' | 'desc',
    description?: string,
) {
    let q = '/me/ledger';
    if (circleId) {
        q += `/${circleId}`;
    }

    const is_asc = sortType === 'desc'; // apparently we have been showing the data in this table
    // in the opposite order all the time, so I am not going to change it now
    const db_page = page - 1; // off by one
    let db_column;
    switch (sortColumn) {
        case 'date':
            db_column = 'tx_date';
            break;
        case 'amount':
            db_column = 'amount';
            break;
        case 'description':
            db_column = 'description';
            break;
        case 'circleName':
            db_column = 'name';
            break;
        default:
            db_column = 'id';
    }
    q += `?page=${db_page}&page_size=${pageSize}&column=${db_column}&${
        is_asc ? `is_asc=true&` : ''
    }${description ? `description=${description}` : ''}`;

    return q;
}

export function useTransactionChart(circleId: number) {
    const { data, isLoading } = useQuery<TransactionSum[], AxiosError>(
        ['ledger_chart', circleId],
        () => lambdaApi.get(`/me/ledger/${circleId}/balances`, {}).then(res => res.data),
    );
    return { data: data ?? [], isLoading };
}
let timer: NodeJS.Timeout;
function debounce(callback: () => void, timeout: number) {
    if (timer) {
        clearTimeout(timer);
    }
    timer = setTimeout(callback, timeout);
}

export function useTransactions(circleId: number | undefined = undefined) {
    const [page, setPage] = useState(1); // In frontend, pages start from one
    const [pageSize, setPageSize] = useState(30);
    const [sortColumn, setSortColumn] = useState('date');
    const [sortType, setSortType] = useState<'asc' | 'desc'>('asc');
    const [description, setDescription] = useState('');

    const getLedgerQueryString = useCallback(() => {
        return createLedgerQueryString(circleId, page, pageSize, sortColumn, sortType, description);
    }, [page, circleId, pageSize, sortColumn, sortType, description]);

    const { data, refetch, isLoading } = useQuery<LedgerResponse, AxiosError>(
        ['ledger', circleId],
        () => lambdaApi.get(getLedgerQueryString(), {}).then(res => res.data),
    );

    useEffect(() => {
        debounce(refetch, isLoading ? 1000 : 200);
    }, [page, pageSize, sortColumn, sortType, description]);

    const createTransaction = useMutation<LedgerModel, AxiosError, LedgerRequestPayload>(
        payload => {
            return lambdaApi.post(`/ledger`, payload).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    return {
        transactions: data?.ledgers ?? ([] as LedgerList),
        totalTransactions: data?.transactionCount ?? 0,
        createTransaction,
        isLoading,
        setPage,
        setSortColumn,
        setSortType,
        setPageSize,
        page,
        sortColumn,
        pageSize,
        sortType,
        setDescription,
    };
}
