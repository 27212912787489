import axios from 'axios';
import { showNotification } from 'components/ShowNotification';

import { getIdToken, resetCognitoSession } from '../auth/cognito';
import { envConfig } from '../config';

export const PROFILE_ENDPOINT = '/me/profile';

const lambdaApi = axios.create({
    baseURL: envConfig.app.lambdaApiUrl,
    headers: {},
});

export const setupAxios = () => {
    // @ts-ignore
    lambdaApi.interceptors.request.use(async req => {
        try {
            const cognitoIdToken = await getIdToken();
            // @ts-ignore
            req.headers.authorization = 'Bearer ' + cognitoIdToken;
            return req;
        } catch (e) {
            console.error('Error getting auth token. Logging out.');
            resetCognitoSession();
            location.reload();
        }
    });
    lambdaApi.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            const msg = {
                type: 'error',
                duration: 5000,
                closable: true,
                header: '',
                body: '',
            };

            /** Handle profile api call separately */
            if (error?.config?.url === PROFILE_ENDPOINT) {
                msg.header = `Session expired`;
                msg.body = 'Please log in again';
                resetCognitoSession();

                showNotification({ header: msg.header, body: msg.body });
                location.reload();
            } else {
                if (error.response) {
                    msg.header = `API Error ${error.response.status}`;
                    switch (error.response.status) {
                        case 401: {
                            msg.body = 'Unauthorized. Please log in again';
                            resetCognitoSession();
                            location.reload();
                            break;
                        }
                        case 400: {
                            msg.body = error.response.data.Message;
                            break;
                        }
                        case 500: {
                            msg.body = 'Unhandled server error occured';
                            break;
                        }
                        default: {
                            msg.body = 'Unknown error occured';
                            break;
                        }
                    }
                } else {
                    msg.header = 'API Error';
                    msg.body = 'Unknown error occured. Has your access token expired?';
                }
                showNotification({ header: msg.header, body: msg.body, type: 'error' });
            }
            return Promise.reject(error);
        },
    );
};

export default lambdaApi;
