import FunnelIcon from '@rsuite/icons/Funnel';
import { Button, FlexboxGrid, Message } from 'rsuite';

import { useAssignments } from '../../hooks/admin/useAssignments';
import { useGracePeriods } from '../../hooks/admin/useGracePeriods';
import { useCustomerProjects } from '../../hooks/useCustomerProjects';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

const AdminGracePeriodAlerts = props => {
    const { onFiltersChanged } = props;

    const { gracePeriods } = useGracePeriods();
    const { customerProjects } = useCustomerProjects();
    const { assignments } = useAssignments({});

    if (!assignments) {
        return <></>;
    }

    const groupedByProject = gracePeriods
        .filter(x => {
            return new Date(x.locking_date) > new Date(Date.now());
        })
        .reduce((p, c) => {
            const assingnment = assignments.find(x => x.id === c.assignment_id);
            const item = p.find(
                x => x.project_id === assingnment.project_id && x.start_date === c.start_date,
            );
            if (item) {
                item.ids.push(c.id);
                item.members.push(assingnment.member_id);
            } else {
                p.push({
                    start_date: c.start_date,
                    project_id: assingnment.project_id,
                    end_date: c.end_date,
                    ids: [c.id],
                    members: [assingnment.member_id],
                });
            }
            return p;
        }, []);

    const filterByGracePeriod = pgp => {
        const customerProject = customerProjects.find(p => p.id === pgp.project_id);
        onFiltersChanged({
            members: pgp.members,
            start_date: new Date(pgp.start_date),
            end_date: new Date(pgp.end_date),
            customer_projects: [customerProject.id],
        });
    };

    return groupedByProject.length ? (
        <Message
            style={{
                marginBottom: '10px',
                overflow: 'hidden',
                overflowY: 'auto',
                maxHeight: '300px',
            }}
            showIcon
            type="warning"
            header="Open grace periods"
        >
            {groupedByProject.map(pgp => {
                const project = customerProjects.find(p => p.id === pgp.project_id);
                return (
                    <FlexboxGrid key={`flex_${pgp.project_id}`} justify="space-between">
                        <div style={{ position: 'relative', top: '8px', lineHeight: '3' }}>
                            <b>{project?.name ?? ''}</b> from {pgp.start_date} to {pgp.end_date}
                            {'   '}
                            <Button appearance="subtle" onClick={() => filterByGracePeriod(pgp)}>
                                Filter by <FunnelIcon color="#f5a623" size="10em" />
                            </Button>
                        </div>

                        <div>
                            <ConfirmationDialog
                                handleOK={() => {}}
                                header="Confirmation"
                                message={`Are you sure to close this grace period?`}
                            ></ConfirmationDialog>
                        </div>
                    </FlexboxGrid>
                );
            })}
        </Message>
    ) : (
        <></>
    );
};

export default AdminGracePeriodAlerts;
