import format from 'date-fns/format';
import { Upcoming } from 'generated-types/meeting';
import { Loader } from 'rsuite';
import { byDate } from 'utils/sortFunctions';
export default function UpcomingMeetings({
    upcoming,
    isUpcomingLoading,
}: {
    upcoming: Upcoming[];
    isUpcomingLoading: boolean;
}) {
    return upcoming?.length > 0 || isUpcomingLoading ? (
        <div style={{ fontSize: '16px' }}>
            {!isUpcomingLoading &&
                upcoming
                    .sort(byDate(true))
                    .slice(0, 3)
                    .map((up, index) => (
                        <div key={index}>
                            {format(new Date(up.date), 'y-MM-dd HH:mm')}{' '}
                            <a href={up.link}>{up.meetingName}</a>
                        </div>
                    ))}
            {isUpcomingLoading && <Loader />}
        </div>
    ) : null;
}
