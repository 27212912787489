import { NewOperativeCostDialog } from 'components/dialogs/NewOperativeCostDialog';
import { Euros } from 'components/Formats';
import { OperativeCostTable } from 'components/tables/OperativeCostTable';
import { CircleModel } from 'generated-types/circle';
import { useOperativeCost } from 'hooks/useOperativeCost';
import { FlexboxGrid, Panel } from 'rsuite';

type Props = {
    circle: CircleModel;
    isAdmin: boolean;
};

export const CostsTab = ({ circle, isAdmin }: Props) => {
    const { total } = useOperativeCost(circle.id);
    return (
        <>
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item>
                    <h3>Current operative costs {Euros(total)} €</h3>
                </FlexboxGrid.Item>
                {isAdmin && (
                    <FlexboxGrid.Item>
                        <NewOperativeCostDialog circleId={circle.id} />
                    </FlexboxGrid.Item>
                )}
            </FlexboxGrid>
            <Panel>
                <OperativeCostTable circleId={circle.id} adminFeatures={isAdmin} />
            </Panel>
        </>
    );
};
