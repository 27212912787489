import PlusIcon from '@rsuite/icons/Plus';
import { SkillsMemberCertificationModelList } from 'generated-types/skills/certification';
import { useState } from 'react';
import { Button, FlexboxGrid, IconButton, Loader, Panel } from 'rsuite';

import { Certification } from './Certification';
import { CertificationsSelect } from './CertificationsSelect';

const CertificationsModeButton = ({
    certificationsExist,
    selectMode,
    setSelectMode,
}: {
    certificationsExist: boolean;
    selectMode: boolean;
    setSelectMode: (value: boolean) => void;
}) =>
    selectMode ? (
        <Button onClick={() => setSelectMode(false)}>Close</Button>
    ) : certificationsExist ? (
        <Button onClick={() => setSelectMode(true)}>Edit</Button>
    ) : (
        <IconButton
            icon={<PlusIcon />}
            appearance="primary"
            color="green"
            onClick={() => setSelectMode(true)}
        >
            Add Certification
        </IconButton>
    );

export const Certifications = ({
    memberCertifications,
    certificationsLoading,
    editAllowed,
}: {
    memberCertifications: SkillsMemberCertificationModelList;
    certificationsLoading: boolean;
    editAllowed: boolean;
}) => {
    const [selectMode, setSelectMode] = useState(false);
    const certificationsExist = memberCertifications.length > 0;
    return (
        <Panel
            bordered
            header={
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <h3>Certification</h3>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {editAllowed && (
                            <CertificationsModeButton
                                certificationsExist={certificationsExist}
                                selectMode={selectMode}
                                setSelectMode={setSelectMode}
                            />
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            }
        >
            {certificationsLoading ? (
                <Loader content={'Certifications are loading...'} />
            ) : selectMode ? (
                <CertificationsSelect
                    memberCertifications={memberCertifications}
                    setSelectMode={setSelectMode}
                />
            ) : !certificationsExist ? (
                <p>No certifications added yet</p>
            ) : (
                <FlexboxGrid justify="center">
                    {memberCertifications.map(certification => (
                        <Certification key={certification.id} certification={certification} />
                    ))}
                </FlexboxGrid>
            )}
        </Panel>
    );
};
