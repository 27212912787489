import add from 'date-fns/add';
import format from 'date-fns/format';
import max from 'date-fns/max';
import min from 'date-fns/min';
import sub from 'date-fns/sub';
import { MemberList } from 'generated-types/member';
import { TimeTrackModel } from 'generated-types/timetrack';
import { useLeaveTypes } from 'hooks/useLeaveTypes';
import { useMembers } from 'hooks/useMembers';
import { useProfile } from 'hooks/useProfile';
import { useTimeTracks } from 'hooks/useTimeTracks';
import { useEffect } from 'react';

type Task = {
    text: string;
    link: string;
};

type Output = {
    isLoading: boolean;
    tasks: Task[];
    noTasks: boolean;
};

type SortedTimeTracks = {
    [key: number]: TimeTrackModel[];
};

function getNameForUserId(memberId: string | number, members: MemberList): string {
    const foundUser = members.find(m => m.id === Number(memberId));
    return foundUser ? `${foundUser.firstname} ${foundUser.lastname}` : String(memberId);
}

export function useAdminTasks(): Output | null {
    const { user, isAdmin } = useProfile();
    const { members } = useMembers(false, false, !isAdmin);
    const { leaveTypes, isLoading: leavesLoading } = useLeaveTypes();
    const {
        timetracks,
        isLoading: timetracksLoading,
        setTtQuery,
    } = useTimeTracks({ disableQuery: !isAdmin });
    const leaveIds = leaveTypes.map(leave => leave.id);

    useEffect(() => {
        setTtQuery({
            leave_types: leaveIds,
            start_date: sub(new Date(), { months: 6 }),
            end_date: add(new Date(), { months: 6 }),
            is_confirmed: 'unconfirmed',
        });
    }, [leavesLoading]);

    const sortedTimeTracks: SortedTimeTracks = {};
    timetracks.forEach(tt => {
        if (!sortedTimeTracks[tt.member_id]) {
            sortedTimeTracks[tt.member_id] = [tt];
        } else {
            sortedTimeTracks[tt.member_id].push(tt);
        }
    });

    const missingEmploymentStartIds = members
        .map(m => {
            return m?.employment_start ? undefined : m.id;
        })
        .flatMap(f => (f ? [f] : []));

    const employmentStartTasks: Task[] = missingEmploymentStartIds.map(employeeId => {
        return {
            text: `${getNameForUserId(employeeId, members)} is missing employment-start info`,
            link: `/members?id=${employeeId}`,
        };
    });

    const ttTasks: Task[] = Object.entries(sortedTimeTracks).map(([memberId, timetracks]) => {
        const dates = timetracks.map(tt => new Date(tt.date));
        const minDate = min(dates);
        const maxDate = max(dates);
        return {
            text: `${getNameForUserId(memberId, members)} has leave requests during ${format(
                minDate,
                'd MMM yyyy',
            )} - ${format(maxDate, 'd MMM yyyy')}`,
            link: `/timetracks?start_date=${format(minDate, 'yyyy-MM-dd')}&end_date=${format(
                maxDate,
                'yyyy-MM-dd',
            )}&members=${memberId}&leave_types=${leaveIds}&is_confirmed=unconfirmed`,
        };
    });

    if (!user?.is_admin) {
        return null;
    }

    const allTasks = [...employmentStartTasks, ...ttTasks];

    if (allTasks.length === 0) {
        return {
            isLoading: timetracksLoading,
            tasks: [{ text: 'Youre all set! No tasks to be done', link: '' }],
            noTasks: true,
        };
    }

    return {
        isLoading: timetracksLoading,
        tasks: allTasks,
        noTasks: false,
    };
}
