import PlusIcon from '@rsuite/icons/Plus';
import { CSSProperties, useState } from 'react';
import { Button, FlexboxGrid, IconButton, Panel } from 'rsuite';

export type CloseFn = () => void;

export type ExpandableFormProps = {
    render: (done: CloseFn) => JSX.Element;
    formTitle: string;
    buttonTitle?: string;
    style?: CSSProperties;
};

export function ExpandableForm(props: ExpandableFormProps) {
    const [isExpanded, setExpanded] = useState(false);
    const expand = () => setExpanded(true);
    const collapse = () => setExpanded(false);

    return (
        <FlexboxGrid justify="center">
            {isExpanded ? (
                <ExpandablePanel title={props.formTitle} style={props.style} onClose={collapse}>
                    {props.render(collapse)}
                </ExpandablePanel>
            ) : (
                <FlexboxGrid.Item>
                    <IconButton
                        icon={<PlusIcon />}
                        appearance="primary"
                        color="green"
                        onClick={expand}
                    >
                        {props.buttonTitle ?? props.formTitle}
                    </IconButton>
                </FlexboxGrid.Item>
            )}
        </FlexboxGrid>
    );
}

function ExpandablePanel(props: {
    title: string;
    children: JSX.Element;
    style?: CSSProperties;
    onClose: () => void;
}) {
    const panelHeader = (
        <FlexboxGrid justify="space-between">
            <h3>{props.title}</h3>
            <Button onClick={props.onClose}>Close</Button>
        </FlexboxGrid>
    );
    const panelStyle = { marginBottom: 20, marginTop: 20, ...(props.style ?? {}) };

    return (
        <Panel bordered header={panelHeader} style={panelStyle}>
            {props.children}
        </Panel>
    );
}
