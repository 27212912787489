import { Page, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';
import { SkillsExperienceModelList } from 'generated-types/skills/experience';
import { formatDatesInterval } from 'utils/formatters';

import { CVFooter } from './Footer';
import { pageSize, styles, TopGradientBar } from './styles';

export const ExperiencePage = ({
    experiences,
    footerText,
    pageTitle,
}: {
    experiences: SkillsExperienceModelList;
    footerText: string;
    pageTitle: string;
}) => (
    <Page size={pageSize} style={styles.page}>
        <TopGradientBar />
        <View style={styles.pageTitleContainer} fixed>
            <Text style={styles.pageTitleText}>{pageTitle}</Text>
        </View>
        <View style={styles.flexContainer}>
            {experiences.map(experience => {
                const startDate = new Date(experience.start_date);
                const endDate = experience.end_date && new Date(experience.end_date);
                const intervalText = formatDatesInterval(startDate, endDate || new Date());
                return (
                    <View style={styles.experienceBox} key={experience.id} wrap={false}>
                        <Text style={styles.title}>{experience.title}</Text>
                        <Text style={styles.subTitle}>
                            {experience.company ? experience.company.name : null}
                        </Text>
                        <Text style={styles.dates}>
                            {format(startDate, 'MMMM yyyy')} -{' '}
                            {endDate ? format(endDate, 'MMMM yyyy') : 'Present'}{' '}
                            {`(${intervalText})`}
                        </Text>
                        <Text style={styles.description}>{experience.description}</Text>
                        <View style={styles.skills}>
                            {experience?.skills &&
                                experience.skills.map(skill => (
                                    <Text key={skill.id} style={styles.skillTag}>
                                        {skill.name}
                                    </Text>
                                ))}
                        </View>
                    </View>
                );
            })}
        </View>
        <CVFooter footerText={footerText} />
    </Page>
);
