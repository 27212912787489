import PlusIcon from '@rsuite/icons/Plus';
import lambdaApi from 'api/LambdaApi';
import { Resolution as GeneratedResolutionModel } from 'generated-types/meeting';
import { useEffect, useState } from 'react';
import { IconButton } from 'rsuite';

import { Resolution } from './Resolution';

// Override the generated Resolution model as `meeting_id` is only known after the meeting is created
export type ResolutionModel = Omit<GeneratedResolutionModel, 'meeting_id'>;

export type Props = {
    onChangeCallback: (resolutions: ResolutionModel[]) => void;
};

export const Resolutions = ({ onChangeCallback }: Props) => {
    const [resolutionRecords, setResolutionRecords] = useState<Record<string, ResolutionModel>>({});

    useEffect(() => {
        onChangeCallback(Object.values(resolutionRecords));
    }, [resolutionRecords]);

    const defaultResolutionRecord = {
        [Math.random().toString(36).substring(2)]: {
            text: '',
            vote_passed: true,
        },
    };

    const createDefaultResolutionRecord = () => {
        setResolutionRecords(r => ({ ...r, ...defaultResolutionRecord }));
    };

    const setText = (key: string, text: string) => {
        setResolutionRecords(r => ({
            ...r,
            [key]: { ...r[key], text },
        }));
    };

    const setVotePassed = (key: string, votePassed: boolean) => {
        setResolutionRecords(r => ({
            ...r,
            [key]: { ...r[key], vote_passed: votePassed },
        }));
    };

    return (
        <>
            <p style={{ marginBottom: 10 }}>Resolutions</p>

            {Object.keys(resolutionRecords).map(key => (
                <Resolution
                    key={key}
                    setText={text => setText(key, text)}
                    setVotePassed={votePassed => setVotePassed(key, votePassed)}
                    remove={() => {
                        const { [key]: _, ...rest } = resolutionRecords;
                        setResolutionRecords(rest);
                    }}
                />
            ))}

            <IconButton
                appearance="primary"
                icon={<PlusIcon />}
                onClick={() => createDefaultResolutionRecord()}
            >
                Add a resolution
            </IconButton>
        </>
    );
};

export const createResolutions = (resolutions: GeneratedResolutionModel[]) => {
    return resolutions.map(resolution => {
        // skip resolution rows that have been added but not filled out
        if (!resolution.text) {
            return;
        }
        return lambdaApi.post<GeneratedResolutionModel>(`/me/resolution`, resolution);
    });
};
