import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import ArrowUpLineIcon from '@rsuite/icons/ArrowUpLine';
import UnvisibleIcon from '@rsuite/icons/Unvisible';
import { SkillsMemberSkillModelList } from 'generated-types/skills/skill';
import { useLocalStorage } from 'hooks/misc/useLocalStorage';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, FlexboxGrid, IconButton, Loader, Panel, Progress } from 'rsuite';

import { SkillsEdit } from './SkillsEdit';

const SkillsModeButton = ({
    editMode,
    skillsExpanded,
    showExpandControls,
    setEditMode,
}: {
    editMode: boolean;
    skillsExpanded: boolean;
    showExpandControls: boolean;
    setEditMode: (editMode: boolean) => void;
}) => (
    <FlexboxGrid.Item>
        {editMode && (skillsExpanded || !showExpandControls) ? (
            <Button onClick={() => setEditMode(false)}>Close</Button>
        ) : skillsExpanded || !showExpandControls ? (
            <Button onClick={() => setEditMode(true)}>Edit</Button>
        ) : (
            <div style={{ width: 50 }}></div>
        )}
    </FlexboxGrid.Item>
);

export const Skills = ({
    memberSkills,
    editAllowed,
    skillsLoading,
}: {
    memberSkills: SkillsMemberSkillModelList;
    editAllowed: boolean;
    skillsLoading: boolean;
}) => {
    const [theme] = useLocalStorage<'light' | 'dark'>('theme', 'light');
    const [editMode, setEditMode] = useState(false);
    const [skillsExpanded, setSkillsExpanded] = useLocalStorage<boolean>('skillsExpanded', false);
    const skillsExist = memberSkills.length > 0;
    const showExpandControls = memberSkills.length > 6;

    const backgroudGradient =
        theme === 'dark'
            ? 'linear-gradient(to bottom, rgba(16, 19, 25, 0.2), rgba(16, 19, 25, 1))'
            : 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1))';

    return (
        <Panel
            bordered
            style={{
                position: 'relative',
                marginBottom: 20,
                maxHeight: skillsExpanded || editMode ? undefined : 360,
            }}
            header={
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <h3>Skills</h3>
                    </FlexboxGrid.Item>
                    {editAllowed && skillsExist && (
                        <SkillsModeButton
                            editMode={editMode}
                            skillsExpanded={skillsExpanded}
                            showExpandControls={showExpandControls}
                            setEditMode={setEditMode}
                        />
                    )}
                </FlexboxGrid>
            }
        >
            {skillsLoading ? (
                <Loader content={'Skills are loading...'} />
            ) : !skillsExist ? (
                <p>No skills found</p>
            ) : editMode ? (
                <SkillsEdit skills={memberSkills} setEditMode={setEditMode} />
            ) : (
                <FlexboxGrid justify="space-between">
                    {!skillsExpanded && showExpandControls && (
                        <div
                            style={{
                                position: 'absolute',
                                background: backgroudGradient,
                                zIndex: 1,
                                height: 260,
                                width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    bottom: 0,
                                    left: 0,
                                    right: 50,
                                    textAlign: 'center',
                                }}
                            >
                                <IconButton
                                    size="lg"
                                    icon={<ArrowDownLineIcon />}
                                    placement="right"
                                    onClick={() => setSkillsExpanded(true)}
                                >
                                    Show More
                                </IconButton>
                            </div>
                        </div>
                    )}
                    {memberSkills.map((skill, index) => {
                        const strokeColor = index % 2 === 0 ? '#1f3b70' : '#d9165c';
                        return (
                            <FlexboxGrid.Item
                                key={skill.id}
                                colspan={12}
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                <FlexboxGrid>
                                    <FlexboxGrid.Item
                                        style={{
                                            paddingTop: 6,
                                            paddingLeft: 7,
                                            width: 30,
                                            fontSize: 15.5,
                                        }}
                                    >
                                        {skill.show_on_cv === false && skill.level && (
                                            <UnvisibleIcon style={{ color: '#8e8e9' }} />
                                        )}
                                    </FlexboxGrid.Item>
                                    <Link
                                        to={`/resume/search?skills=${skill.id}`}
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <FlexboxGrid.Item>
                                            <h4 style={{ paddingLeft: 5 }}>{skill.name}</h4>
                                        </FlexboxGrid.Item>
                                    </Link>
                                </FlexboxGrid>
                                <Progress.Line
                                    strokeColor={strokeColor}
                                    percent={skill.level ?? 0}
                                    style={{ marginLeft: 23, paddingRight: 30 }}
                                />
                            </FlexboxGrid.Item>
                        );
                    })}
                    {showExpandControls && (
                        <FlexboxGrid.Item colspan={24} style={{ textAlign: 'center' }}>
                            <IconButton
                                size="lg"
                                icon={<ArrowUpLineIcon />}
                                placement="right"
                                onClick={() => setSkillsExpanded(false)}
                            >
                                Show less
                            </IconButton>
                        </FlexboxGrid.Item>
                    )}
                </FlexboxGrid>
            )}
        </Panel>
    );
};
