import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserWithPicture } from 'components/navigation/UserWithPicture';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { WikiModel } from 'generated-types/wiki';
import { useMembers } from 'hooks/useMembers';
import { useWiki } from 'hooks/useWiki';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { Button, Loader, Panel, Row, Input } from 'rsuite';
import { EditWikiSchema, WikiEditSchemaType } from 'schema/wiki';
import { copyToClipboard } from 'utils/copyToClipboard';

const WikiSheetArea = styled.div`
    display: flex;
    flex-direction: column;
`;

const TitleAndProfileArea = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;
`;

const LowerControlBar = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1em 0em 0em 0.5em;
`;

type Props = {
    articleId?: number | string;
};

export function WikiSheet({ articleId }: Props) {
    const { getArticle, createWiki, updateWiki } = useWiki({ articleId });
    const { members } = useMembers();
    const [editOpen, setEditOpen] = useState(false);
    const navigate = useNavigate();

    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
    } = useForm<WikiEditSchemaType>({
        resolver: zodResolver(EditWikiSchema),
    });

    const createMarkdownLinkToClipboard = (wiki: WikiModel) => {
        copyToClipboard(`[${wiki.revision.title}](/#/wiki/${wiki.id})`);
        showNotification({ header: 'Markdown link created!' });
    };

    const onSubmit: SubmitHandler<WikiEditSchemaType> = data => {
        if (data.id) {
            updateWiki.mutate(
                { payload: { title: data.title, text: data.text }, id: data.id },
                {
                    onError: error => {
                        showErrorNotification(error);
                    },
                    onSuccess: () => {
                        showNotification({
                            header: 'Wiki edited',
                        });
                        setEditOpen(false);
                    },
                },
            );
        } else {
            createWiki.mutate(
                {
                    text: data.text,
                    title: data.title,
                },
                {
                    onError: err => {
                        showErrorNotification(err);
                    },
                    onSuccess: data => {
                        showNotification({
                            header: 'Wiki created!',
                        });
                        navigate(`/wiki/${data.id}`);
                    },
                },
            );
        }
    };

    const { data: article, isLoading } = getArticle;

    if (isLoading || createWiki.isLoading || updateWiki.isLoading) {
        return <Loader size="md" />;
    }

    if (!article && articleId) {
        return <h4>No article found. Create new?</h4>;
    }

    const writtenBy = members.find(m => m.id === article?.revision.created_by);
    const isEditable = article?.wiki_type === 'wiki';

    return (
        <div style={{ width: '100%' }}>
            <Panel bordered shaded>
                <WikiSheetArea>
                    {!editOpen && (
                        <TitleAndProfileArea>
                            <h2>{article?.revision.title}</h2>
                            {article && writtenBy && <UserWithPicture user={writtenBy} />}
                        </TitleAndProfileArea>
                    )}
                    <Panel bordered>
                        {editOpen || !article ? (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input hidden {...register('id')} defaultValue={article?.id} />
                                <Row>
                                    <p>Title</p>
                                    <Controller
                                        name="title"
                                        control={control}
                                        defaultValue={article?.revision.title ?? ''}
                                        render={({ field }) => <Input type="text" {...field} />}
                                    />
                                    <p className="error"> {errors?.title?.message}</p>
                                </Row>
                                <Row>
                                    <p>Text</p>
                                    <Controller
                                        name="text"
                                        control={control}
                                        defaultValue={article?.revision.text ?? ''}
                                        render={({ field }) => (
                                            <Input type="text" as="textarea" rows={30} {...field} />
                                        )}
                                    />
                                    <p className="error">{errors?.text?.message}</p>
                                </Row>
                                <Row>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '1em',
                                        }}
                                    >
                                        <Button
                                            onClick={handleSubmit(onSubmit)}
                                            appearance="primary"
                                            color="green"
                                        >
                                            {article ? 'Save edits' : 'Create new article'}
                                        </Button>
                                        {article && (
                                            <Button
                                                onClick={() =>
                                                    createMarkdownLinkToClipboard(article)
                                                }
                                                appearance="ghost"
                                                color="blue"
                                            >
                                                Copy hyperlink to clipboard
                                            </Button>
                                        )}
                                        {article && (
                                            <Button
                                                onClick={() => setEditOpen(!editOpen)}
                                                appearance="primary"
                                                color="orange"
                                            >
                                                Close
                                            </Button>
                                        )}
                                    </div>
                                </Row>
                            </form>
                        ) : (
                            <ReactMarkdown>{article?.revision.text ?? ''}</ReactMarkdown>
                        )}
                    </Panel>
                    <LowerControlBar>
                        {editOpen || !article ? null : (
                            <>
                                {isEditable && (
                                    <Button
                                        onClick={() => setEditOpen(!editOpen)}
                                        color="blue"
                                        appearance="primary"
                                    >
                                        Edit
                                    </Button>
                                )}
                                <Button
                                    style={{ paddingLeft: '1em' }}
                                    onClick={() => createMarkdownLinkToClipboard(article)}
                                    appearance="ghost"
                                    color="blue"
                                >
                                    Copy hyperlink to clipboard
                                </Button>
                            </>
                        )}
                    </LowerControlBar>
                </WikiSheetArea>
            </Panel>
        </div>
    );
}
