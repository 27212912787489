import UpcomingMeetings from 'components/misc/UpcomingMeetings';
import { Link } from 'react-router-dom';
import { Col, FlexboxGrid, Grid, Loader, Panel } from 'rsuite';

import { useProfile } from '../../..//hooks/useProfile';
import { useCircles, useMyUpcoming } from '../../../hooks/useCircles';
import { Weight } from '../../Formats';
import CircleTable from '../../tables/CircleTable';
import { ProfileBar } from './ProfileBar';

export const HomeScreen = () => {
    const { user } = useProfile();
    const { myCircles, isLoading: cIsLoading } = useCircles();
    const { upcoming, isLoading } = useMyUpcoming();

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Link
                        style={{ color: 'black', textDecoration: 'none' }}
                        to={`/${user?.id}/career`}
                    >
                        <ProfileBar
                            firstName={user?.firstname ?? ''}
                            lastName={user?.lastname ?? ''}
                            imageUrl={user?.image_url ?? ''}
                        />
                    </Link>
                    <Panel
                        bordered
                        bodyFill
                        header={<h3>Upcoming Meetings</h3>}
                        style={{ marginBottom: '24px' }}
                    >
                        <div style={{ marginLeft: '16px', marginBottom: '16px' }}>
                            <UpcomingMeetings upcoming={upcoming} isUpcomingLoading={isLoading} />
                        </div>
                    </Panel>
                    <Panel
                        bordered
                        bodyFill
                        header={
                            <FlexboxGrid justify="space-between" align="middle">
                                <h3>My Circles ({cIsLoading ? <Loader /> : myCircles.length})</h3>
                                {user?.weight_sum ? (
                                    <span>Weight Sum: {Weight(user.weight_sum)}</span>
                                ) : null}
                            </FlexboxGrid>
                        }
                        style={{ marginBottom: '24px' }}
                    >
                        <CircleTable
                            isLoading={cIsLoading}
                            data={myCircles}
                            referenceMember={user}
                        />
                    </Panel>
                </Col>
            </Grid>
        </div>
    );
};
