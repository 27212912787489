import { ExpandableForm } from 'components/misc/ExpandableForm';
import { SkillsExperienceModelList } from 'generated-types/skills/experience';
import { Loader, Panel } from 'rsuite';

import { Experience } from './Experience';
import { ExperienceAddForm } from './ExperienceAddForm';

export const Experiences = ({
    editAllowed,
    circlesExperiences,
    otherExperiences,
    experiencesLoading,
}: {
    editAllowed: boolean;
    otherExperiences: SkillsExperienceModelList;
    circlesExperiences: SkillsExperienceModelList;
    experiencesLoading: boolean;
}) => {
    return (
        <Panel bordered style={{ marginBottom: 20 }} header={<h3>Work Experience</h3>}>
            {experiencesLoading ? (
                <Loader content={'Experiences are loading...'} />
            ) : otherExperiences.length === 0 && circlesExperiences.length === 0 ? (
                <p>No experiences added yet</p>
            ) : (
                <>
                    {circlesExperiences.length > 0 && (
                        <Panel
                            bordered
                            style={{
                                marginBottom: 20,
                                paddingTop: 20,
                                background:
                                    'linear-gradient(145deg, rgba(31,59,112,1) 0%, rgba(2,175,217,1) 50%, rgba(217,22,92,1) 100%)',
                            }}
                        >
                            {circlesExperiences.map(experience => {
                                return (
                                    <Experience
                                        editAllowed={editAllowed}
                                        experience={experience}
                                        key={experience.id}
                                    />
                                );
                            })}
                        </Panel>
                    )}
                    {otherExperiences.map(experience => {
                        return (
                            <Experience
                                editAllowed={editAllowed}
                                experience={experience}
                                key={experience.id}
                            />
                        );
                    })}
                </>
            )}
            {editAllowed && (
                <ExpandableForm
                    formTitle="Add Experience"
                    style={{ maxWidth: 800, width: '100%' }}
                    render={done => <ExperienceAddForm onAdded={done} />}
                />
            )}
        </Panel>
    );
};
