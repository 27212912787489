import { useCustomerProjects } from 'hooks/useCustomerProjects';
import { useCustomers } from 'hooks/useCustomers';
import { useState } from 'react';
import { Button, Col, FlexboxGrid, Grid, Loader, Panel, Toggle } from 'rsuite';

import { useProfile } from '../../hooks/useProfile';
import EditCustomerProjectDialog from '../dialogs/EditCustomerProjectDialog';
import CustomerProjectTable from '../tables/CustomerProjectTable';

const CustomerProjectsScreen = () => {
    const { isAdmin } = useProfile();
    const { customers } = useCustomers();
    const { customerProjects, isSuccess } = useCustomerProjects();
    const [showInactive, setShowInactive] = useState(false);
    const filteredProjects = showInactive
        ? customerProjects
        : customerProjects.filter(project => project.is_active);

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Customer Projects</h1>
                            </Panel>

                            <Panel>
                                <span>
                                    <small>Display All</small>
                                </span>
                                <Toggle
                                    style={{ marginLeft: '6px' }}
                                    checked={showInactive}
                                    onChange={() => setShowInactive(!showInactive)}
                                />
                                {isAdmin ? (
                                    <EditCustomerProjectDialog>
                                        <Button appearance="primary">Add Customer Project</Button>
                                    </EditCustomerProjectDialog>
                                ) : null}
                            </Panel>
                        </FlexboxGrid>
                    </Panel>
                    <Panel
                        bordered
                        bodyFill
                        header={
                            <h3>
                                Customer Projects (
                                {isSuccess ? filteredProjects.length : <Loader />})
                            </h3>
                        }
                    >
                        <CustomerProjectTable
                            inAdminView={true}
                            customerProjects={filteredProjects}
                            customers={customers}
                        />
                    </Panel>
                </Col>
            </Grid>
        </div>
    );
};

export default CustomerProjectsScreen;
