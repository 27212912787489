import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { CustomerList, CustomerModel } from '../generated-types/customer';

type Props = {
    sortByName: boolean;
};

export function useCustomers(props?: Props) {
    const { data, isLoading } = useQuery<CustomerList>(['customers'], () =>
        lambdaApi.get('/customers', {}).then(res => res.data),
    );

    const syncFromProcountor = () => {
        return lambdaApi.get('/procountor/sync');
    };

    const updateCustomer = useMutation<
        CustomerModel,
        AxiosError,
        { payload: CustomerModel; id: number }
    >(props => {
        const { id, payload } = props;
        return lambdaApi.put(`/customers/${id}`, payload).then(res => res.data);
    });

    let filterData: CustomerList = [];
    if (data) {
        filterData = data;
        if (props?.sortByName) {
            filterData.sort((a, b) => {
                return a.name < b.name ? -1 : 1;
            });
        }
    }
    return {
        syncFromProcountor,
        updateCustomer,
        customers: filterData,
        isLoading,
    };
}
