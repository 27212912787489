import { queryClient } from 'App';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { CustomerModel } from 'generated-types/customer';
import { useCustomers } from 'hooks/useCustomers';
import { cloneElement, useState } from 'react';

import DynamicEditDialog from './DynamicEditDialog';

type Props = {
    children: JSX.Element;
    editable: CustomerModel;
};

const EditCustomerDialog = (props: Props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { updateCustomer } = useCustomers();

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const resetState = () => {
        setIsDialogOpen(false);
        queryClient.invalidateQueries(['customers']);
    };

    const getChildren = () => {
        return props.children
            ? cloneElement(props.children, {
                  onClick: openDialog,
              })
            : '';
    };

    const handleOk = (patchData: CustomerModel) => {
        let updatedCustomer;

        if (patchData.id) {
            updatedCustomer = {
                ...props.editable,
                ...patchData,
            };
            updateCustomer.mutate(
                { payload: updatedCustomer, id: patchData.id },
                {
                    onError: error => {
                        showErrorNotification(error);
                    },
                    onSuccess: () => {
                        showNotification({
                            header: 'Customer updated successfully!',
                        });
                        resetState();
                    },
                },
            );
        }
    };

    return (
        <div>
            {getChildren()}
            <DynamicEditDialog
                dialogHeader={props.editable ? 'Edit Customer' : 'Add Customer'}
                fields={{
                    name: {
                        icon: 'short_text',
                        label: 'Name',
                        type: 'text',
                    },
                }}
                editable={props.editable}
                isOpen={isDialogOpen}
                closeHandler={resetState}
                saveDataHandler={handleOk}
            />
        </div>
    );
};

export default EditCustomerDialog;
