export const copyToClipboard = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;

    // Prevent scrolling to bottom of page in Microsoft Edge
    textarea.style.position = 'fixed';
    textarea.style.left = '-9999px';

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
};
