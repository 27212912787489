import { useWeights } from 'hooks/useWeights';
import { Loader } from 'rsuite';

import { LineChart } from './LineChart';

const getMonthKey = (date: Date | string) => {
    if (date instanceof Date) {
        return date.toISOString().substr(0, 7);
    }
    return date.substr(0, 7);
};

const WeightHistoryChart = ({ circleId }: { circleId: number }) => {
    const { weights, isLoading } = useWeights(circleId);

    const today = new Date();
    let firstMonth = getMonthKey(today);
    const validWeights: { [key: string]: number } = {};

    // Get the last set value for each month for each circle.
    Object.values(weights)
        .sort((a, b) => (a.start_date < b.start_date ? -1 : 1))
        .forEach(w => {
            const month = getMonthKey(w.start_date);
            firstMonth = month < firstMonth ? month : firstMonth;
            validWeights[month] = w.amount;
        });

    if (isLoading) {
        return <Loader />;
    } else {
        return (
            <LineChart
                labels={Object.keys(validWeights)}
                datasets={[{ label: 'Weights', data: Object.values(validWeights) }]}
            />
        );
    }
};

export default WeightHistoryChart;
