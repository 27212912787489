import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { useState } from 'react';
import { Button, Col, Grid, Loader, Message, Panel, Row } from 'rsuite';

import findOpenGracePeriods from '../../utils/findOpenGracePeriods';

const ProjectGracePeriodAlerts = props => {
    const { gracePeriods, assignments, members, closeGracePeriods, refetch } = props;

    const [loading, setLoading] = useState(false);

    let activeGracePeriods = [];

    if (assignments.length && gracePeriods.length) {
        const openPeriods = findOpenGracePeriods(gracePeriods, assignments);

        activeGracePeriods = openPeriods.map(gp => {
            const ass = assignments.find(a => a.id === gp.assignment_id);
            return {
                ...gp,
                member: members.find(m => m.id === ass.member_id),
            };
        });
    }

    const onClosePeriods = gp => {
        setLoading(true);
        let gpsToClose = [];
        if (gp) {
            gpsToClose = [gp];
        } else {
            gpsToClose = [...activeGracePeriods];
        }
        closeGracePeriods.mutate(gpsToClose, {
            onError: error => {
                showErrorNotification(error);
            },
            onSuccess: () => {
                showNotification({ header: 'Grace Periods closed succesfully!' });
                refetch();
                setLoading(false);
            },
        });
    };

    return activeGracePeriods.length ? (
        <Panel bordered style={{ marginBottom: '24px' }}>
            <Message type="error">
                <div style={{ position: 'relative', top: '8px' }}>
                    <Grid fluid style={{ marginTop: '16px' }}>
                        <Row style={{ marginBottom: '16px' }}>
                            <Col>
                                <b>This project has active grace periods:</b>
                            </Col>
                            <Col>
                                <Button
                                    color="red"
                                    appearance="ghost"
                                    style={{ marginLeft: '8px', width: '112px' }}
                                    onClick={() => onClosePeriods()}
                                >
                                    {!loading ? 'Close all' : <Loader />}
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ fontWeight: 'bold' }}>
                            <Col xs={4}>Member</Col>
                            <Col xs={4}>Period</Col>
                            <Col xs={4}>Locked on</Col>
                            <Col xs={2}>&nbsp;</Col>
                        </Row>
                        {activeGracePeriods.map(gp => {
                            return (
                                <Row key={gp.id}>
                                    <Col xs={4}>
                                        {gp.member?.firstname ?? ''} {gp.member?.lastname ?? ''}
                                    </Col>
                                    <Col xs={4}>
                                        {gp.start_date} to {gp.end_date}
                                    </Col>
                                    <Col xs={4}>{gp.locking_date}</Col>
                                    <Col xs={2}>
                                        <div
                                            style={{ cursor: 'pointer', color: 'red' }}
                                            onClick={() => !loading && onClosePeriods(gp)}
                                        >
                                            X
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Grid>
                </div>
            </Message>
        </Panel>
    ) : (
        <></>
    );
};

export default ProjectGracePeriodAlerts;
