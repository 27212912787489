import LegacyProjectIcon from '@rsuite/icons/legacy/Project';
import { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, CheckPicker, InputGroup } from 'rsuite';

import { CircleModelList } from '../../../generated-types/circle';
import { CustomerProjectList } from '../../../generated-types/customer_project';
import { useInternalProjects } from '../../../hooks/useInternalProjects';
import { useLeaveTypes } from '../../../hooks/useLeaveTypes';
import { arraysAreEqual, arrayToMap } from '../../../utils/arrayMapping';
import { TimetracksApplyParams } from '.';

type Props = {
    circles: CircleModelList;
    customerProjects: CustomerProjectList;
    onOk: (val: {
        selectedCustomerProjectIds: number[];
        selectedInternalProjectIds: number[];
        selectedLeaveTypeIds: number[];
    }) => void;
    applyParams?: (param: TimetracksApplyParams, value: number[] | string) => void;
    selectedCustomerProjectIds: number[];
    selectedInternalProjectIds: number[];
    selectedLeaveTypeIds: number[];
};

const ProjectCheckPicker = ({
    customerProjects,
    circles,
    selectedCustomerProjectIds,
    selectedInternalProjectIds,
    selectedLeaveTypeIds,
    onOk,
    applyParams,
}: Props) => {
    const { internalProjects } = useInternalProjects();
    const { leaveTypes } = useLeaveTypes();
    const circlesMap = useMemo(() => arrayToMap(circles), [circles]);

    // Customer Project Ids
    const [cpIds, setCpIds] = useState<number[]>([]);

    // Internal Project Ids
    const [ipIds, setIpIds] = useState<number[]>([]);

    // Leave Type Ids
    const [ltIds, setLtIds] = useState<number[]>([]);

    useEffect(() => {
        if (!arraysAreEqual(selectedCustomerProjectIds, cpIds)) {
            setCpIds(selectedCustomerProjectIds);
        }
        if (!arraysAreEqual(selectedInternalProjectIds, cpIds)) {
            setIpIds(selectedInternalProjectIds);
        }
        if (!arraysAreEqual(selectedLeaveTypeIds, ltIds)) {
            setLtIds(selectedLeaveTypeIds);
        }
    }, [selectedCustomerProjectIds, selectedInternalProjectIds, selectedLeaveTypeIds]);

    const onChange = ({
        cpIds,
        ipIds,
        ltIds,
    }: {
        cpIds: number[];
        ipIds: number[];
        ltIds: number[];
    }) => {
        setCpIds(cpIds);
        setIpIds(ipIds);
        setLtIds(ltIds);
    };

    const onClean = () => {
        if (applyParams) applyParams('is_confirmed', '');
        setCpIds([]);
        setIpIds([]);
        setLtIds([]);
        onOk({
            selectedCustomerProjectIds: [],
            selectedInternalProjectIds: [],
            selectedLeaveTypeIds: [],
        });
    };

    const customerProjectOptions = customerProjects.map(p => ({
        label: p.name,
        value: `c�${p.id}`,
        type: 'Customer Projects',
    }));
    const internalProjectOptions = internalProjects.map(p => ({
        label:
            p.circle_id && circlesMap[p.circle_id]
                ? `${p.name} (${circlesMap[p.circle_id].name})`
                : p.name,
        value: `i�${p.id}`,
        type: 'Internal Projects',
    }));

    const leaveTypeOptions = leaveTypes.map(l => ({
        label: l.name,
        value: `l�${l.id}`,
        type: 'Leaves',
    }));

    const options = customerProjectOptions
        .concat(internalProjectOptions)
        .concat(leaveTypeOptions)
        .sort((a, b) => {
            if (a.type > b.type) {
                return 1;
            } else if (a.type < b.type) {
                return -1;
            }
            const aLabel = a?.label ?? '';
            const bLabel = b?.label ?? '';
            return aLabel.toLowerCase() > bLabel.toLowerCase() ? 1 : -1;
        });

    const selectedCustomerProjectIdValues = cpIds.map(id => `c�${id}`);
    const selectedInternalProjectIdValues = ipIds.map(id => `i�${id}`);
    const selectedLeaveTypeIdValues = ltIds.map(id => `l�${id}`);
    const values = selectedCustomerProjectIdValues
        .concat(selectedInternalProjectIdValues)
        .concat(selectedLeaveTypeIdValues);

    const totalChecked = cpIds.length + ipIds.length + ltIds.length;

    const checkAllState = {
        checked: totalChecked === options.length,
        indeterminate: totalChecked > 0 && totalChecked < options.length,
    };

    const checkLeavesState = {
        checked: ltIds.length === leaveTypeOptions.length,
        indeterminate: ltIds.length > 0 && ltIds.length < leaveTypeOptions.length,
    };

    const checkInternalProjectsState = {
        checked: ipIds.length === internalProjectOptions.length,
        indeterminate: ipIds.length > 0 && ipIds.length < internalProjectOptions.length,
    };

    const handleCheckAll = () => {
        const { indeterminate, checked } = checkAllState;
        if (checked || indeterminate) {
            onChange({
                cpIds: [],
                ipIds: [],
                ltIds: [],
            });
        } else {
            onChange({
                cpIds: customerProjects.map(p => p.id),
                ipIds: internalProjects.map(p => p.id),
                ltIds: leaveTypes.map(p => p.id),
            });
        }
    };

    const handleCheckInternalProjects = () => {
        const { indeterminate, checked } = checkInternalProjectsState;
        const newInternalProjectIds =
            checked || indeterminate ? [] : internalProjects.map(p => p.id);
        onChange({
            cpIds,
            ipIds: newInternalProjectIds,
            ltIds,
        });
    };

    const handleCheckLeaves = () => {
        const { indeterminate, checked } = checkLeavesState;
        const newLeaveTypeIds = checked || indeterminate ? [] : leaveTypes.map(p => p.id);
        onChange({
            cpIds,
            ipIds,
            ltIds: newLeaveTypeIds,
        });
    };

    const valuesChanged = (newValues: string[]) => {
        const customerProjectIds = [];
        const internalProjectIds = [];
        const leaveTypeIds = [];
        if (newValues) {
            for (let i = 0; i < newValues.length; i++) {
                const id = newValues[i];
                if (id.indexOf('c�') === 0) {
                    customerProjectIds.push(parseInt(id.substring(2), 10));
                }
                if (id.indexOf('i�') === 0) {
                    internalProjectIds.push(parseInt(id.substring(2), 10));
                }
                if (id.indexOf('l�') === 0) {
                    leaveTypeIds.push(parseInt(id.substring(2), 10));
                }
            }
        }

        onChange({
            cpIds: customerProjectIds,
            ipIds: internalProjectIds,
            ltIds: leaveTypeIds,
        });
    };

    return (
        <InputGroup>
            <InputGroup.Addon>
                <LegacyProjectIcon />
            </InputGroup.Addon>

            <CheckPicker
                placeholder="Select projects"
                value={values}
                onChange={valuesChanged}
                onClean={onClean}
                data={options}
                style={{ width: '300px' }}
                groupBy="type"
                renderExtraFooter={() => (
                    <div
                        style={{
                            padding: '0px 2px',
                            borderTop: '1px solid #e5e5e5',
                        }}
                    >
                        <Checkbox
                            inline
                            indeterminate={checkAllState.indeterminate}
                            checked={checkAllState.checked}
                            onChange={handleCheckAll}
                        >
                            Check all
                        </Checkbox>
                        <Checkbox
                            inline
                            indeterminate={checkLeavesState.indeterminate}
                            checked={checkLeavesState.checked}
                            onChange={handleCheckLeaves}
                        >
                            Check all leaves
                        </Checkbox>
                        <Checkbox
                            inline
                            indeterminate={checkInternalProjectsState.indeterminate}
                            checked={checkInternalProjectsState.checked}
                            onChange={handleCheckInternalProjects}
                        >
                            Check all internal projects
                        </Checkbox>
                        <div>
                            <Button
                                style={{
                                    float: 'left',
                                    marginLeft: 10,
                                    marginTop: 2,
                                    marginBottom: 10,
                                }}
                                appearance="primary"
                                size="sm"
                                onClick={() => {
                                    const resp = {
                                        selectedCustomerProjectIds: cpIds,
                                        selectedInternalProjectIds: ipIds,
                                        selectedLeaveTypeIds: ltIds,
                                    };
                                    onOk(resp);
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                )}
            ></CheckPicker>
        </InputGroup>
    );
};

export default ProjectCheckPicker;
