import LeavesTable from 'components/tables/LeavesTable';
import { useState } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Col, FlexboxGrid, Grid, Panel } from 'rsuite';

export function CompanyScreen() {
    const [displayMonths, setDisplayMonths] = useState(1);

    return (
        <Grid fluid>
            <Col xs={24}>
                <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                    <FlexboxGrid justify="space-between" align="middle">
                        <Panel>
                            <h1>Company Statistics</h1>
                        </Panel>
                    </FlexboxGrid>
                </Panel>
                <Panel
                    bordered
                    bodyFill
                    header={
                        <FlexboxGrid justify="space-between" align="middle">
                            <h3>Leaves Calendar</h3>
                            <ButtonToolbar>
                                <small>Display months</small>
                                <ButtonGroup>
                                    <Button
                                        appearance={displayMonths === 1 ? 'primary' : 'default'}
                                        onClick={() => setDisplayMonths(1)}
                                    >
                                        2
                                    </Button>
                                    <Button
                                        appearance={displayMonths === 2 ? 'primary' : 'default'}
                                        onClick={() => setDisplayMonths(2)}
                                    >
                                        4
                                    </Button>
                                    <Button
                                        appearance={displayMonths === 3 ? 'primary' : 'default'}
                                        onClick={() => setDisplayMonths(3)}
                                    >
                                        6
                                    </Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </FlexboxGrid>
                    }
                    style={{ marginBottom: '24px', width: 'inherit' }}
                >
                    <LeavesTable months={displayMonths} />
                </Panel>
            </Col>
        </Grid>
    );
}
