import { AxiosError, AxiosResponse } from 'axios';
import format from 'date-fns/format';
import { useMutation, UseMutationResult, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import {
    CustomerProjectList,
    CustomerProjectModel,
    CustomerProjectRequestPayload,
} from '../generated-types/customer_project';
import { useProfile } from './useProfile';
import { TimeTrackQuery } from './useTimeTracks';

type CustomerProjects = {
    getInvoice: (projectId: number, ttQuery: TimeTrackQuery) => Promise<AxiosResponse>;
    getHoursReport: (projectId: number, ttQuery: TimeTrackQuery) => Promise<AxiosResponse>;
    sendToProcountor: (projectId: number, ttQuery: TimeTrackQuery) => Promise<AxiosResponse>;
    createCustomerProject: UseMutationResult<
        CustomerProjectModel,
        AxiosError,
        CustomerProjectRequestPayload
    >;
    updateCustomerProject: UseMutationResult<
        CustomerProjectModel,
        AxiosError,
        {
            payload: CustomerProjectModel;
            id: number;
        }
    >;
    deleteCustomerProject: UseMutationResult<null, AxiosError, number>;
    isLoading: boolean;
    isSuccess: boolean;
    customerProjects: CustomerProjectList;
    refetch: () => void;
};

export function useCustomerProjects(): CustomerProjects {
    const { isAdmin } = useProfile();

    const { isLoading, isSuccess, data, refetch } = useQuery<CustomerProjectList>(
        ['customerProjects'],
        () => lambdaApi.get('/customer/projects', {}).then(res => res.data),
        {
            enabled: isAdmin,
        },
    );

    const getInvoice = (projectId: number, ttQuery: TimeTrackQuery) => {
        const formattedQuery = {
            ...ttQuery,
            start_date: format(ttQuery.start_date, 'y-M-d'),
            end_date: format(ttQuery.end_date, 'y-M-d'),
        };
        return lambdaApi.post(`/invoice/${projectId}`, formattedQuery);
    };

    const getHoursReport = (projectId: number, ttQuery: TimeTrackQuery) => {
        const formattedQuery = {
            ...ttQuery,
            start_date: format(ttQuery.start_date, 'y-M-d'),
            end_date: format(ttQuery.end_date, 'y-M-d'),
        };
        return lambdaApi.post(`/hourreport/${projectId}`, formattedQuery);
    };

    const sendToProcountor = (projectId: number, ttQuery: TimeTrackQuery) => {
        const formattedQuery = {
            ...ttQuery,
            start_date: format(ttQuery.start_date, 'y-M-d'),
            end_date: format(ttQuery.end_date, 'y-M-d'),
        };
        return lambdaApi.post(`/procountor/${projectId}`, formattedQuery);
    };

    const createCustomerProject = useMutation<
        CustomerProjectModel,
        AxiosError,
        CustomerProjectRequestPayload
    >(payload => {
        return lambdaApi.post('/customer/projects', payload).then(res => res.data);
    });

    const updateCustomerProject = useMutation<
        CustomerProjectModel,
        AxiosError,
        { payload: CustomerProjectModel; id: number }
    >(props => {
        const { id, payload } = props;
        return lambdaApi.put(`/customer/projects/${id}`, payload).then(res => res.data);
    });

    const deleteCustomerProject = useMutation<null, AxiosError, number>(id => {
        return lambdaApi.delete(`/customer/projects/${id}`).then(res => res.data);
    });

    return {
        getInvoice,
        getHoursReport,
        sendToProcountor,
        createCustomerProject,
        updateCustomerProject,
        deleteCustomerProject,
        customerProjects: data ?? [],
        isLoading,
        isSuccess,
        refetch,
    };
}
