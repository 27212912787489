import styled from '@emotion/styled';

type FontWeight = 'bold' | 'normal';

type User = {
    firstname: string;
    lastname: string;
    image_url?: string;
};

type Props = {
    user: User;
    fontWeight?: 'bold' | 'normal';
};

const Area = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.5em;
`;

const UserName = styled.div<{ fontWeight: FontWeight }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.5em;
    font-weight: ${props => props.fontWeight};
`;

export const UserWithPicture = ({ user, fontWeight }: Props) => {
    return (
        <Area>
            <div
                style={{
                    width: 40,
                    height: 40,
                    background: '#f5f5f5',
                    borderRadius: 20,
                    marginTop: 2,
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 2,
                    overflow: 'hidden',
                    display: 'inline-block',
                }}
            >
                <img alt="avatar" src={user.image_url} width="44" referrerPolicy="no-referrer" />
            </div>

            <UserName fontWeight={fontWeight ?? 'normal'}>
                {user.firstname ?? ''} {user.lastname ?? ''}
            </UserName>
        </Area>
    );
};
