import styled from '@emotion/styled';
import format from 'date-fns/format';
import { TimeTrackModelList } from 'generated-types/timetrack';
import { useInternalProjects } from 'hooks/useInternalProjects';
import { useLeaveTypes } from 'hooks/useLeaveTypes';
import { useMyAssignments } from 'hooks/useMyAssignments';
import { useMyCustomerProjects } from 'hooks/useMyCustomerProjects';
import { RefAttributes } from 'react';
import { Badge, Divider, Panel, Table, Tooltip, Whisper } from 'rsuite';
import { InnerCellProps } from 'rsuite-table/lib/Cell';
import { calculateTotalHours } from 'utils/timetrack';

import { LogHoursApplyParams } from '..';
import {
    customerProjectColor,
    internalProjectColor,
    paidLeaveColor,
    sickLeaveColor,
    unpaidLeaveColor,
} from '../styles';
import { getLoggedEntryType } from './getLoggedEntryType';

const { Column, HeaderCell, Cell } = Table;

const DisabledOverlay = styled.div<{ disabled: boolean }>`
    opacity: ${props => (props.disabled ? '0.5' : '1')};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const BadgeStyle = {
    customer: { background: customerProjectColor, marginRight: '5px' },
    internal: { background: internalProjectColor, marginRight: '5px' },
    paid: { background: paidLeaveColor, marginRight: '5px' },
    unpaid: { background: unpaidLeaveColor, marginRight: '5px' },
    other: { background: sickLeaveColor, marginRight: '5px' },
};

type Props = {
    selectedDate: Date;
    isLoading?: boolean;
    timetracks: TimeTrackModelList;
    disabled: boolean;
    applyParams: (param: LogHoursApplyParams, value: string) => void;
};

const ProjectCell = (
    props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
) => {
    return (
        <Cell {...props}>
            <Whisper
                trigger="hover"
                placement="left"
                speaker={<Tooltip>{props.rowData.entryType}</Tooltip>}
            >
                <span>
                    {props.rowData.entryType === 'Customer' ? (
                        <Badge style={BadgeStyle.customer} />
                    ) : props.rowData.entryType === 'Internal' ? (
                        <Badge style={BadgeStyle.internal} />
                    ) : props.rowData.entryType === 'Leave' ? (
                        <>
                            {props.rowData.name === 'Paid Leave' ? (
                                <Badge style={BadgeStyle.paid} />
                            ) : props.rowData.name === 'Unpaid Leave' ? (
                                <Badge style={BadgeStyle.unpaid} />
                            ) : (
                                <Badge style={BadgeStyle.other} />
                            )}
                        </>
                    ) : null}
                </span>
            </Whisper>
            <span>{props.rowData.name}</span>
        </Cell>
    );
};

export function LoggedHours({ selectedDate, timetracks, disabled, applyParams }: Props) {
    const { leaveTypes } = useLeaveTypes();
    const { internalProjects } = useInternalProjects();
    const { myCustomerProjects } = useMyCustomerProjects();
    const { myAssignments } = useMyAssignments();

    const sorted = timetracks.map(record => {
        return getLoggedEntryType(
            record,
            leaveTypes,
            internalProjects,
            myCustomerProjects,
            myAssignments,
        );
    });

    return (
        <DisabledOverlay disabled={disabled} style={{ flexGrow: 1 }}>
            <Panel shaded>
                <h5>{format(selectedDate, `EEEE, do 'of' MMMMMM yyyy`)}</h5>

                <Table
                    height={420}
                    data={sorted}
                    onRowClick={rowData => {
                        applyParams('timetrack_id', String(rowData.id));
                        document.querySelector('#edit')?.scrollIntoView({ behavior: 'smooth' });
                    }}
                >
                    <Column width={200}>
                        <HeaderCell>Project / Leave</HeaderCell>
                        <ProjectCell dataKey="name" />
                    </Column>

                    <Column width={60}>
                        <HeaderCell>Hours</HeaderCell>
                        <Cell dataKey="hours" />
                    </Column>

                    <Column flexGrow={1}>
                        <HeaderCell>Comment</HeaderCell>
                        <Cell dataKey="comment" />
                    </Column>
                </Table>
                <Divider />
                <h6>Total {calculateTotalHours(timetracks)} hours</h6>
            </Panel>
        </DisabledOverlay>
    );
}
