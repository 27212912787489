import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Col, DatePicker, Input, Row, SelectPicker, Toggle } from 'rsuite';

import { MemberModel } from '../../generated-types/member';
import { useMembers } from '../../hooks/useMembers';
import { UpdateMember, UpdateMemberType } from '../../schema/member';
import { DialogWrap } from '../misc/DialogWrap';
import { showErrorNotification, showNotification } from '../ShowNotification';

type Props = {
    user: MemberModel;
    openDialog: boolean;
};

export default function EditMemberDialog({ user, openDialog }: Props) {
    const { updateMember, roles } = useMembers();
    const [open, setOpen] = useState(false);
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
    } = useForm<UpdateMemberType>({
        resolver: zodResolver(UpdateMember),
    });

    const onSubmit: SubmitHandler<UpdateMemberType> = data => {
        updateMember.mutate(
            { payload: data },
            {
                onError: err => {
                    showErrorNotification(err);
                },
                onSuccess: () => {
                    showNotification({
                        header: 'Member updated',
                    });
                    setOpen(false);
                },
            },
        );
    };

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                shortTitle="Edit"
                title="Update member"
                onSubmit={handleSubmit(onSubmit)}
                buttonOpenStyle={{
                    size: 'xs',
                    appearance: 'primary',
                    color: 'blue',
                    showPlusIcon: false,
                }}
            >
                <input hidden {...register('id')} value={user.id} />
                <input hidden {...register('google_id')} value={user.google_id} />
                <Row>
                    <p>First name</p>
                    <Controller
                        name="firstname"
                        control={control}
                        defaultValue={user.firstname}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.firstname?.message}</p>
                </Row>
                <Row>
                    <p>Last name</p>
                    <Controller
                        name="lastname"
                        control={control}
                        defaultValue={user.lastname}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.lastname?.message}</p>
                </Row>
                <Row>
                    <p>Email</p>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue={user.email}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.email?.message}</p>
                </Row>
                <Row>
                    <p>Is active</p>
                    <Controller
                        name="is_active"
                        control={control}
                        defaultValue={user.is_active}
                        render={({ field }) => (
                            <Toggle defaultChecked={user.is_active} {...field} />
                        )}
                    />
                    <p className="error">{errors?.is_active?.message}</p>
                </Row>
                <Row>
                    <p>Is admin</p>
                    <Controller
                        name="is_admin"
                        control={control}
                        defaultValue={user.is_admin}
                        render={({ field }) => <Toggle defaultChecked={user.is_admin} {...field} />}
                    />
                    <p className="error">{errors?.is_admin?.message}</p>
                </Row>

                <Row>
                    <Col xl={10}>
                        <p>Employment start</p>
                        <Controller
                            name="employment_start"
                            control={control}
                            defaultValue={
                                user?.employment_start ? new Date(user.employment_start) : undefined
                            }
                            render={({ field }) => (
                                <DatePicker oneTap style={{ width: 140 }} {...field} />
                            )}
                        />
                        <p className="error">{errors?.employment_start?.message}</p>
                    </Col>
                    <Col xl={10} xlPush={1}>
                        <p>Role</p>
                        <Controller
                            defaultValue={roles?.find(role => role.role === user.role)?.role}
                            name="role"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <SelectPicker
                                        style={{ width: 180 }}
                                        searchable={false}
                                        data={
                                            roles?.map(role => ({
                                                label: role.name,
                                                value: role.role,
                                            })) || []
                                        }
                                        {...field}
                                    />
                                );
                            }}
                        />
                        <p className="error">{errors?.role?.message}</p>
                    </Col>
                </Row>
            </DialogWrap>
        </form>
    );
}
