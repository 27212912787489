import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import {
    TotalRevenueRequestPayload,
    TotalRevenueResponseList,
    TotalRevenueResponseModel,
} from '../generated-types/total_revenues';

export function useTotalRevenues() {
    const query = `/totalrevenue`;
    const { data, isLoading, isSuccess, refetch } = useQuery<TotalRevenueResponseList>(
        [query],
        () => lambdaApi.get(query).then(res => res.data),
    );

    const setAmountsTotalRevenue = useMutation<
        TotalRevenueResponseModel,
        AxiosError,
        TotalRevenueRequestPayload
    >(
        payload => {
            return lambdaApi.post(`/totalrevenue`, payload).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    const commitTotalRevenue = useMutation<
        TotalRevenueResponseModel,
        AxiosError,
        TotalRevenueRequestPayload
    >(
        payload => {
            return lambdaApi.post(`/totalrevenue/commit`, payload).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    return {
        totalRevenues: data || [],
        isLoading,
        isSuccess,
        setAmountsTotalRevenue,
        commitTotalRevenue,
    };
}
