import format from 'date-fns/format';
import { SkillsExperienceModel } from 'generated-types/skills/experience';
import { useLocalStorage } from 'hooks/misc/useLocalStorage';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, FlexboxGrid, Panel, Tag, TagGroup } from 'rsuite';
import { formatDatesInterval } from 'utils/formatters';

import { ExperienceEditForm } from './ExperienceEditForm';

export const Experience = ({
    experience,
    editAllowed,
}: {
    experience: SkillsExperienceModel;
    editAllowed: boolean;
}) => {
    const [theme] = useLocalStorage<'light' | 'dark'>('theme', 'light');
    const [editMode, setEditMode] = useState(false);
    const startDate = new Date(experience.start_date);
    const endDate = experience.end_date && new Date(experience.end_date);
    const intervalText = formatDatesInterval(startDate, endDate || new Date());

    if (editMode) {
        return <ExperienceEditForm experience={experience} setEditMode={setEditMode} />;
    }
    return (
        <Panel
            bordered
            shaded
            key={experience.id}
            style={{
                marginBottom: 20,
                background: 'white',
                color: theme === 'dark' ? '#0f131a' : 'inherit',
            }}
        >
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item>
                    <h3>{experience.title}</h3>
                    <p>{experience.company ? experience.company.name : null}</p>
                    <p>
                        {format(startDate, 'MMMM yyyy')} -{' '}
                        {endDate ? format(endDate, 'MMMM yyyy') : 'Present'} {`(${intervalText})`}
                    </p>
                </FlexboxGrid.Item>
                {editAllowed && (
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button onClick={() => setEditMode(true)}>Edit</Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                )}
            </FlexboxGrid>
            <p
                style={{
                    fontSize: 15,
                    margin: 20,
                    textAlign: 'justify',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {experience.description}
            </p>
            <TagGroup>
                {experience?.skills?.map(skill => (
                    <Tag size="lg" key={skill.id}>
                        <Link
                            to={`/resume/search?skills=${skill.id}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            {skill.name}
                        </Link>
                    </Tag>
                ))}
            </TagGroup>
        </Panel>
    );
};
