import isSameDay from 'date-fns/isSameDay';
import holidays from 'finnish-holidays-js';
// TODO: Remove holidays-library and replace with the use of Holidays-library from backend
// Backend-route already exists for it

export function isHoliday(date: Date): boolean {
    return holidays.year(date.getFullYear()).some(holiday => {
        const { year, month, day } = holiday;
        const holidayDate = new Date(year, month - 1, day);
        return isSameDay(date, holidayDate);
    });
}
