import { useState } from 'react';
import { Button, Col, FlexboxGrid, Grid, Modal, Panel, Uploader } from 'rsuite';

import lambdaApi from '../../../api/LambdaApi';
import { envConfig } from '../../../config';

function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
const takeDump = () => {
    lambdaApi
        .get('/backup')
        .then(response => {
            console.log('Api Response:', response);
            downloadURI(
                'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(response.data)),
                'circles_money_dump_' + new Date().toISOString() + '.json',
            );
        })
        .catch(errorResponse => {
            console.error('API ERROR:', errorResponse);
        });
};

export function BackupScreen() {
    const [showRestoreReport, setShowRestoreReport] = useState(false);
    const [restoreLog, setRestoreLog] = useState('');

    const onSuccesfullUpload = (response: { restoreLog: string[] }) => {
        console.log('File upload succesful', response);
        let restoreLog = '';
        response.restoreLog.forEach(r => {
            restoreLog = restoreLog + r + '\n';
        });
        setShowRestoreReport(true);
        setRestoreLog(restoreLog);
    };

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered header={<h1>Backup & Restore the Database</h1>}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Button
                                color="green"
                                onClick={() => {
                                    takeDump();
                                }}
                            >
                                Dump the database as a JSON
                            </Button>
                            <Uploader
                                accept="json"
                                action={envConfig.app.lambdaApiUrl + '/restore'}
                                autoUpload={true}
                                draggable={true}
                                headers={{
                                    authorization:
                                        lambdaApi.defaults.headers.common['Authorization'],
                                }}
                                onSuccess={onSuccesfullUpload}
                            >
                                <Button>Restore database from a JSON dump</Button>
                            </Uploader>
                        </FlexboxGrid>
                    </Panel>
                </Col>
            </Grid>
            <Modal open={showRestoreReport || false}>
                <Modal.Header>
                    <Modal.Title>Database Restore Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ whiteSpace: 'pre-line' }}>{restoreLog}</p>
                </Modal.Body>
            </Modal>
        </div>
    );
}
