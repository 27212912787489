import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import { useGracePeriods } from 'hooks/admin/useGracePeriods';
import { cloneElement, useState } from 'react';
import { Schema } from 'rsuite';

import { useAssignments } from '../../hooks/admin/useAssignments';
import { showErrorNotification, showNotification } from '../ShowNotification';
import DynamicEditDialog from './DynamicEditDialog';
const defaultTemplate = {};

const EditGracePeriodDialog = props => {
    const [loading, setLoading] = useState({});
    if (!props.project_id && !props.assignment_id)
        throw Error(
            'Error: No target specified. Please set a value for project_id or assignment_id',
        );
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editable, setEditable] = useState(props.editable || defaultTemplate);
    const nextWeek = new Date(new Date().setDate(new Date().getDate() + 7));
    const { createGracePeriods, refetch } = useGracePeriods();
    const { assignments } = useAssignments({ projectId: props.project_id });

    const getChildren = () => {
        return props.children
            ? cloneElement(props.children, {
                  onClick: openDialog,
              })
            : '';
    };

    const resetState = () => {
        setIsDialogOpen(false);
        setLoading({});
        setEditable(defaultTemplate);
    };

    const openDialog = () => {
        setIsDialogOpen(true);
        setEditable(props.editable || defaultTemplate);
    };

    const handleOk = patchData => {
        setLoading({ ...loading, save: true });
        const onSuccess = () => {
            resetState();
            refetch();
            props.afterOK && props.afterOK();
        };

        const onError = () => {
            setLoading({});
        };
        // Create a new instance if the patchData does not contain the id prop.
        let targets = [];
        if (props.project_id) {
            targets = assignments
                .filter(a => a.project_id === props.project_id)
                .map(a => ({ ...patchData, assignment_id: a.id }));
        } else {
            targets = [{ ...patchData, assignment_id: props.assignment_id }];
        }
        console.log(`Creating grace period: ${JSON.stringify(patchData)}`);

        createGracePeriods.mutate(targets, {
            onError: error => {
                showErrorNotification(error);
                onError();
            },
            onSuccess: () => {
                showNotification({ header: 'Grace Periods created succesfully!' });
                onSuccess();
            },
        });
    };
    return (
        <div>
            {getChildren()}
            <DynamicEditDialog
                disabledFields={props.disabledFields}
                dialogHeader="Edit Grace period"
                fields={{
                    start_date: {
                        default: startOfMonth(new Date().setMonth(new Date().getMonth() - 1)),
                        disabled: !!props.editable?.start_date,
                        icon: 'today',
                        label: 'Start Date',
                        type: 'popup-date',
                        max: new Date(),
                        validationModel: Schema.Types.DateType().isRequired(),
                    },
                    end_date: {
                        clearable: true,
                        default: endOfMonth(new Date().setMonth(new Date().getMonth() - 1)),
                        icon: 'today',
                        label: 'End Date',
                        type: 'popup-date',
                        max: new Date(),
                        validationModel: Schema.Types.DateType().isRequired(),
                    },
                    locking_date: {
                        clearable: true,
                        default: nextWeek,
                        icon: 'today',
                        label: 'Locking Date',
                        type: 'popup-date',
                        min:
                            (props.editable?.locking_date &&
                                new Date(props.editable?.locking_date)) ||
                            new Date(),
                        validationModel: Schema.Types.DateType().isRequired(),
                    },
                }}
                editable={editable}
                isOpen={isDialogOpen}
                closeHandler={resetState}
                requiredFields={['start_date', 'end_date']}
                saveDataHandler={handleOk}
                loading={loading}
            />
        </div>
    );
};

export default EditGracePeriodDialog;
