import { Image, Text, View } from '@react-pdf/renderer';
import logo from 'assets/circles_logo_cv.png';

import { styles } from './styles';

export const CVFooter = ({ footerText }: { footerText: string }) => {
    return (
        <View style={styles.footerContainer} fixed>
            <View style={styles.smallLogoContainer}>
                <Image style={styles.smallLogo} src={logo} />
            </View>
            <View style={styles.pageCount}>
                <Text
                    render={({ pageNumber, totalPages }) =>
                        `${footerText} Page ${pageNumber} / ${totalPages}`
                    }
                />
            </View>
        </View>
    );
};
