import { Table } from 'rsuite';

import { OperativeCostModel } from '../../generated-types/operative_cost';
import { useOperativeCost } from '../../hooks/useOperativeCost';
import { EditOperativeCostDialog } from '../dialogs/EditOperativeCostDialog';

const { Column, HeaderCell, Cell } = Table;

const ActionCell = ({ ...props }) => {
    const { rowData } = props as { rowData: OperativeCostModel };

    return (
        <Cell align="center" {...props}>
            <EditOperativeCostDialog circleId={rowData.circle_id} operativeCost={rowData} />
        </Cell>
    );
};

type Props = {
    circleId: number;
    adminFeatures: boolean;
};

export function OperativeCostTable({ circleId, adminFeatures }: Props) {
    const { operativeCost } = useOperativeCost(circleId);

    return (
        <div>
            <Table isTree rowKey="id" virtualized autoHeight data={operativeCost}>
                <Column align="right" fixed sortable width={110}>
                    <HeaderCell>Start date</HeaderCell>
                    <Cell dataKey="start_date" />
                </Column>
                <Column align="right" fixed sortable width={110}>
                    <HeaderCell>End date</HeaderCell>
                    <Cell dataKey="end_date" />
                </Column>
                <Column align="right" fixed sortable width={90}>
                    <HeaderCell>Amount</HeaderCell>
                    <Cell dataKey="amount" />
                </Column>
                <Column align="left" sortable width={200}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name" />
                </Column>
                <Column align="left" sortable width={330}>
                    <HeaderCell>Description</HeaderCell>
                    <Cell dataKey="description" />
                </Column>
                {adminFeatures && (
                    <Column width={160}>
                        <HeaderCell align="center">Actions</HeaderCell>
                        <ActionCell align="center" />
                    </Column>
                )}
            </Table>
        </div>
    );
}
