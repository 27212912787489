import { useState } from 'react';
import { Button, Loader, Message, Stack, Tooltip, Whisper } from 'rsuite';

import { GracePeriodModel } from '../../generated-types/grace_period';
import { useMyAssignments } from '../../hooks/useMyAssignments';
import { useMyCustomerProjects } from '../../hooks/useMyCustomerProjects';
import { useMyGracePeriods } from '../../hooks/useMyGracePeriods';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { showErrorNotification, showNotification } from '../ShowNotification';

type Props = {
    onGracePeriodFilterSelected: (val: GracePeriodModel) => void;
};

const MyGracePeriodAlerts = (props: Props) => {
    const { myGracePeriods, closeMyGracePeriod } = useMyGracePeriods();
    const { myCustomerProjects } = useMyCustomerProjects();
    const { myAssignments } = useMyAssignments();
    const { onGracePeriodFilterSelected } = props;

    const [closingGpId, setClosingGpId] = useState<number | null>(null);

    const onGpDialogOk = (gp: GracePeriodModel) => {
        setClosingGpId(gp.id);
        closeMyGracePeriod.mutate(
            { id: gp.id },
            {
                onSuccess: () => {
                    setClosingGpId(null);
                    showNotification({ header: 'Period closed!' });
                },
                onError: error => {
                    showErrorNotification(error);
                },
            },
        );
    };

    return myGracePeriods.length ? (
        <>
            {myGracePeriods.map(gp => {
                const assignment = myAssignments.find(p => {
                    p.id === gp.assignment_id;
                });
                const project = myCustomerProjects.find(p => p.id === assignment?.project_id);
                return (
                    <Message
                        closable
                        header="Open Grace Period"
                        key={gp.id}
                        showIcon
                        style={{ marginBottom: '24px' }}
                        type="warning"
                    >
                        <p>
                            The period{' '}
                            <span style={{ fontWeight: '700' }}>
                                {gp.start_date} - {gp.end_date}
                            </span>
                            for the project{' '}
                            <span style={{ fontWeight: '700' }}>{project?.name}.</span>
                            has been opened for adding and editing timetracks until{' '}
                            <span style={{ fontWeight: '700' }}>{gp.locking_date}</span>.
                        </p>
                        <Stack
                            justifyContent="space-between"
                            spacing={12}
                            style={{ marginTop: '16px' }}
                        >
                            <Whisper
                                controlId="filter-by-gp"
                                placement="top"
                                speaker={<Tooltip>Filter the table by the grace period.</Tooltip>}
                                trigger="hover"
                            >
                                <Button
                                    color="blue"
                                    appearance="ghost"
                                    onClick={() => {
                                        onGracePeriodFilterSelected(gp);
                                    }}
                                >
                                    Filter by period
                                </Button>
                            </Whisper>

                            <ConfirmationDialog
                                handleOK={() => {
                                    onGpDialogOk(gp);
                                }}
                                header="Confirmation"
                                message={`Are you sure you want to close this grace period?`}
                            >
                                <Whisper
                                    controlId="close-gp"
                                    placement="top"
                                    speaker={
                                        <Tooltip>
                                            {
                                                "When you're done editing the grace period's timetracks, you can close the GP by clicking here."
                                            }
                                        </Tooltip>
                                    }
                                    trigger="hover"
                                >
                                    <Button
                                        color="orange"
                                        appearance="ghost"
                                        style={{ marginLeft: '8px', width: '112px' }}
                                    >
                                        {closingGpId === gp.id ? <Loader /> : 'Close period'}
                                    </Button>
                                </Whisper>
                            </ConfirmationDialog>
                        </Stack>
                    </Message>
                );
            })}
        </>
    ) : (
        <></>
    );
};

export default MyGracePeriodAlerts;
