import { AxiosError } from 'axios';
import { CreateMeetingRequest, Meeting, Resolution } from 'generated-types/meeting';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';

export type CreateMeetingCallbacks = {
    onSuccess: (meeting: Meeting) => void;
    onError: (err: AxiosError) => void;
};

export function useMeetings(circleId: number) {
    const {
        data: meetings,
        refetch: refetchMeetings,
        isLoading,
        isRefetching,
    } = useQuery<Meeting[]>({
        queryKey: ['meetings'],
        queryFn: () => lambdaApi.get(`/circles/${circleId}/meetings`).then(res => res.data),
    });
    const createMutation = useMutation<{ data: Meeting }, AxiosError, CreateMeetingRequest>(
        payload => lambdaApi.post(`/circles/${circleId}/meetings`, payload),
    );

    const createMeeting = (req: CreateMeetingRequest, callbacks: CreateMeetingCallbacks) => {
        createMutation.mutate(req, {
            onSuccess: meeting => {
                callbacks.onSuccess(meeting.data);
                refetchMeetings();
            },
            onError: callbacks.onError,
        });
    };

    useEffect(() => {
        refetchMeetings();
    }, [circleId]);

    return {
        meetings: meetings ?? [],
        createMeeting: createMeeting,
        refetchMeetings,
        isLoading: isLoading || isRefetching,
    };
}

export function useResolutions(circleId: number) {
    const { data: resolutions, refetch: refetchResolutions } = useQuery<Resolution[]>({
        queryKey: ['resolutions'],
        queryFn: () => lambdaApi.get(`/me/resolutions/circle/${circleId}`).then(res => res.data),
    });

    return { resolutions, refetchResolutions };
}
