import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import { LeaveStatisticsType } from 'hooks/useTimetrackStatistics';

type Props = {
    dates: Date[];
    leaveOverview: LeaveStatisticsType;
};

const Totals = ({ dates, leaveOverview }: Props) => {
    const allLeaves = Object.keys(leaveOverview)
        .map(key => leaveOverview[key])
        .flat();
    return (
        <>
            {dates.map(date => {
                const formattedDate = format(date, 'yyyy-MM-dd');
                const number = allLeaves.filter(leave => leave.date === formattedDate).length;
                return <td key={formatISO(date)}>{number}</td>;
            })}
        </>
    );
};

export default Totals;
