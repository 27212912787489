import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Col, FlexboxGrid, Grid, Panel } from 'rsuite';

import { GracePeriodModel } from '../../../generated-types/grace_period';
import { useCircles } from '../../../hooks/useCircles';
import { useInternalProjects } from '../../../hooks/useInternalProjects';
import { useMembers } from '../../../hooks/useMembers';
import { useMyAssignments } from '../../../hooks/useMyAssignments';
import { useMyCustomerProjects } from '../../../hooks/useMyCustomerProjects';
import { useMyGracePeriods } from '../../../hooks/useMyGracePeriods';
import { useProfile } from '../../../hooks/useProfile';
import { useTimeTracks } from '../../../hooks/useTimeTracks';
import EditTimeTrackDialog from '../../dialogs/EditTimeTrackDialog';
import MyGracePeriodAlerts from '../../misc/MyGracePeriodAlerts';
import TimeTrackTable from '../../tables/TimeTrackTable';
import { HoursSummary } from './HoursSummary';
import TimeTrackTableScopeComponent from './TimeTrackTableScopeComponent';

export type TimetracksApplyParams =
    | 'start_date'
    | 'end_date'
    | 'members'
    | 'customer_projects'
    | 'internal_projects'
    | 'leave_types'
    | 'is_confirmed';

const MyTimeTrackScreen = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useProfile();
    const { timetracks, timetrackCount, isLoading, ttQuery, setTtQuery } = useTimeTracks({});
    const { myCustomerProjects } = useMyCustomerProjects();
    const { internalProjects } = useInternalProjects();
    const { myGracePeriods } = useMyGracePeriods();
    const { myAssignments } = useMyAssignments();
    const { members } = useMembers();
    const { circles } = useCircles();
    const onGracePeriodFilterSelected = (gp: GracePeriodModel) => {
        const assignment = myAssignments.find(p => p.id === gp.assignment_id);
        if (!assignment) {
            throw new Error('Assignment was not found!');
        }
    };

    const availableFilters = { projectPicker: true, datePicker: true, filterUnconfirmed: true };

    const handleSearchParamsArray = (name: string) => {
        const params = searchParams
            .get(name)
            ?.split(',')
            .map(value => Number(value))
            .filter(value => value > 0);
        const paramsValid = params?.every(value => !isNaN(value));

        if (paramsValid && params) return params;
        return undefined;
    };

    const applyParams = (param: TimetracksApplyParams, value: number[] | string) => {
        if (value.length > 0) {
            searchParams.set(param, value.toString());
            setSearchParams(searchParams);
        } else {
            searchParams.delete(param);
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        const getIsConfirmed = searchParams?.get('is_confirmed');
        const is_confirmed =
            getIsConfirmed === 'confirmed'
                ? 'confirmed'
                : getIsConfirmed === 'unconfirmed'
                ? 'unconfirmed'
                : undefined;

        const init_start_date = searchParams.get('start_date') || undefined;
        const parsed_start_date = init_start_date && parseISO(init_start_date);
        const start_date =
            isValid(parsed_start_date) && parsed_start_date !== '' ? parsed_start_date : undefined;

        const init_end_date = searchParams.get('end_date') || undefined;
        const parsed_end_date = init_end_date && parseISO(init_end_date);
        const end_date =
            isValid(parsed_end_date) && parsed_end_date !== '' ? parsed_end_date : undefined;

        setTtQuery({
            ...ttQuery,
            customer_projects: handleSearchParamsArray('customer_projects'),
            internal_projects: handleSearchParamsArray('internal_projects'),
            leave_types: handleSearchParamsArray('leave_types'),
            start_date,
            end_date,
            is_confirmed,
            members: user?.id ? [user?.id] : [0],
        });
    }, [searchParams, user]);

    return (
        <Grid fluid>
            <Col xs={24}>
                <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                    <FlexboxGrid justify="space-between" align="middle">
                        <Panel>
                            <h1>My Time Tracking</h1>
                        </Panel>
                        <Panel>
                            <EditTimeTrackDialog
                                existingTimetrack={{
                                    member_id: user?.id,
                                }}
                                optionsData={{
                                    members: members,
                                    circles: circles,
                                    internalProjects: internalProjects,
                                    customerProjects: myCustomerProjects,
                                }}
                            >
                                <Button appearance="primary">Record Time</Button>
                            </EditTimeTrackDialog>
                        </Panel>
                    </FlexboxGrid>
                </Panel>
                <MyGracePeriodAlerts onGracePeriodFilterSelected={onGracePeriodFilterSelected} />
                <Panel bordered>
                    <TimeTrackTableScopeComponent
                        total={timetrackCount}
                        query={ttQuery}
                        setQuery={setTtQuery}
                        availableFilters={availableFilters}
                        filterOptions={{
                            members: members,
                            circles: circles,
                            internalProjects: internalProjects,
                            customerProjects: myCustomerProjects,
                        }}
                        adminMode={false}
                        applyParams={applyParams}
                    />
                    <TimeTrackTable
                        data={timetracks}
                        gracePeriods={myGracePeriods}
                        loading={isLoading}
                        query={ttQuery}
                        showColumns={[
                            'status',
                            'confirmed',
                            'projectName',
                            'date',
                            'hours',
                            'comment',
                            'actions',
                        ]}
                    />
                    <HoursSummary tableData={timetracks} />
                </Panel>
            </Col>
        </Grid>
    );
};

export default MyTimeTrackScreen;
