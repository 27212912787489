import { css } from '@emotion/css';

const tableCSS = css`
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }
`;

type Props = {
    headers: string[];
    rows: (string | number)[][];
};

export function SimpleTable({ headers, rows }: Props) {
    const tableRows = rows.map((row, rIndex) => {
        return (
            <tr key={`entitled_${rIndex}`}>
                {row.map((element, elIdx) => {
                    return <th key={`${rIndex}_${elIdx}`}>{element}</th>;
                })}
            </tr>
        );
    });

    return (
        <div className={tableCSS}>
            <table>
                <thead>
                    <tr>
                        {headers.map((header, idx) => {
                            return <th key={`header_${idx}`}>{header}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>{tableRows}</tbody>
            </table>
        </div>
    );
}
