import lambdaApi from 'api/LambdaApi';
import { WeightList } from 'generated-types/weight';
import { useQuery } from 'react-query';

export function useWeights(circleId: number) {
    const { data, isLoading } = useQuery<WeightList>(['weights', circleId], () =>
        lambdaApi.get<WeightList>(`/circles/${circleId}/weights`).then(res => res.data),
    );

    return {
        weights: data || [],
        isLoading,
    };
}
