import { AxiosError } from 'axios';
import { Upcoming } from 'generated-types/meeting';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { TableProps } from 'rsuite';

import lambdaApi from '../api/LambdaApi';
import { CircleModel, CircleModelList, CircleRequestPayload } from '../generated-types/circle';
import { useMemberships } from './useMemberships';

type Props = {
    sort?: {
        order: TableProps['sortType'];
        column: 'name';
    };
    circleId?: number;
};

export function useCircles(props?: Props) {
    const { myMemberships } = useMemberships();
    const { data, isLoading, isSuccess, refetch } = useQuery<CircleModelList>(['circles'], () =>
        lambdaApi.get('/circles', {}).then(res => res.data),
    );

    let myCircles: CircleModelList = [];

    const createCircle = useMutation<CircleModel, AxiosError, CircleRequestPayload>(
        payload => {
            return lambdaApi.post(`/circles`, payload).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    const updateCircle = useMutation<
        CircleModel,
        AxiosError,
        { payload: CircleRequestPayload; id: number }
    >(
        props => {
            const { id, payload } = props;
            return lambdaApi.put(`/circles/${id}`, payload).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    const archiveCircle = useMutation<null, AxiosError, number>(
        id => lambdaApi.delete(`/circles/${id}`).then(res => res.data),
        {
            onSuccess() {
                refetch();
            },
        },
    );

    let filteredCircles: CircleModelList = [];

    if (data) {
        const myCircleIds = myMemberships.map(mem => mem.circle_id);
        myCircles = data.filter(circle => myCircleIds.includes(circle.id));
        filteredCircles = data;
        if (props?.circleId) {
            filteredCircles = filteredCircles.filter(circle => circle.id === props.circleId);
            myCircles = myCircles.filter(circle => circle.id === props.circleId);
        }
        if (props?.sort) {
            filteredCircles.sort((a, b) => {
                const { sort } = props;
                if (!sort?.column || !a?.name || !b?.name) {
                    return -1;
                }
                return a.name.localeCompare(b.name);
            });
        }
    }

    return {
        circles: filteredCircles,
        myCircles,
        isLoading,
        isSuccess,
        updateCircle,
        createCircle,
        archiveCircle,
    };
}

export function useUpcoming(circle_id: number) {
    const { data, refetch, isLoading, isRefetching } = useQuery<Upcoming[]>(
        ['circles_upcoming'],
        () => lambdaApi.get(`/circles/${circle_id}/upcoming`).then(res => res.data),
    );

    useEffect(() => {
        refetch();
    }, [circle_id]);
    return { upcoming: data || [], refetchUpcoming: refetch, isLoading: isLoading || isRefetching };
}

export function useMyUpcoming() {
    const { data, refetch, isLoading, isRefetching } = useQuery<Upcoming[]>(['my_upcoming'], () =>
        lambdaApi.get(`/me/upcoming`).then(res => res.data),
    );
    const { myMemberships } = useMemberships();

    useEffect(() => {
        refetch();
    }, [myMemberships]); // If memberships change, we want to refetch upcoming

    return { upcoming: data || [], refetchUpcoming: refetch, isLoading: isLoading || isRefetching };
}
