import { Col, FlexboxGrid, Grid, Panel, PanelGroup } from 'rsuite';

import { useInternalProjects } from '../../hooks/useInternalProjects';
import { useMyCustomerProjects } from '../../hooks/useMyCustomerProjects';
import CustomerProjectTable from '../tables/CustomerProjectTable';
import InternalProjectTable from '../tables/InternalProjectTable';

const MyProjectsScreen = () => {
    const { internalProjects } = useInternalProjects();
    const { myCustomerProjects } = useMyCustomerProjects();

    const customers = myCustomerProjects.map(cp => {
        return {
            id: cp.customer_id,
            name: cp.name,
            procountor_id: 0,
        };
    });

    return (
        <>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>My Projects</h1>
                            </Panel>
                            <Panel></Panel>
                        </FlexboxGrid>
                    </Panel>
                    <PanelGroup accordion defaultActiveKey={1} bordered>
                        <Panel
                            eventKey={1}
                            bordered
                            header={<h3>Customer Projects ({myCustomerProjects.length})</h3>}
                        >
                            <CustomerProjectTable
                                customerProjects={myCustomerProjects}
                                customers={customers}
                            />
                        </Panel>
                        <Panel
                            eventKey={1}
                            bordered
                            header={<h3>Internal Projects ({internalProjects.length})</h3>}
                        >
                            <InternalProjectTable />
                        </Panel>
                    </PanelGroup>
                </Col>
            </Grid>
        </>
    );
};

export default MyProjectsScreen;
