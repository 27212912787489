import { EditCircleDialog } from 'components/dialogs/EditCircleDialog';
import UpcomingMeetings from 'components/misc/UpcomingMeetings';
import MemberTable from 'components/tables/MemberTable';
import { CircleModel } from 'generated-types/circle';
import { Meeting, Upcoming } from 'generated-types/meeting';
import { MemberModel } from 'generated-types/member';
import { useUpcoming } from 'hooks/useCircles';
import { useMeetings } from 'hooks/useMeetings';
import { Button, ButtonToolbar, Col, FlexboxGrid, Loader, Panel, PanelGroup } from 'rsuite';
import { byDate } from 'utils/sortFunctions';

type Props = {
    circle: CircleModel;
    currentMembers: MemberModel[];
    showEditCircleButton: boolean;
};

const CallToEditCirle = ({
    circle,
    showEditCircleButton,
}: {
    circle: CircleModel;
    showEditCircleButton: boolean;
}) => {
    return (
        <FlexboxGrid.Item as={Col} colspan={24} md={12}>
            <Panel style={{ textAlign: 'center' }}>
                <h4>This circle does not have any details</h4>
                {showEditCircleButton ? (
                    <EditCircleDialog circle={circle} />
                ) : (
                    'Ask facilitator, scribe, treasurer or admin to edit the circle'
                )}
            </Panel>
        </FlexboxGrid.Item>
    );
};

const CircleDetails = ({
    circle,
    showButtonPannel,
    meetings,
    upcoming,
    isMeetingsLoading,
    isUpcomingLoading,
}: {
    circle: CircleModel;
    showButtonPannel: boolean;
    meetings: Meeting[];
    upcoming: Upcoming[];
    isMeetingsLoading: boolean;
    isUpcomingLoading: boolean;
}) => {
    const matterMostLink = `https://mattermost.circles.fi/default/channels/${circle?.mattermost?.substring(
        1,
    )}`;

    return (
        <FlexboxGrid.Item as={Col} colspan={24} md={12} style={{ maxWidth: '900px' }}>
            <PanelGroup>
                {circle?.mission && (
                    <Panel>
                        <h4>Mission</h4>
                        <p style={{ fontSize: '16px' }}>{circle.mission}</p>
                    </Panel>
                )}
                {circle?.description && (
                    <Panel>
                        <h4>Description</h4>
                        <p style={{ fontSize: '16px' }}>{circle.description}</p>
                    </Panel>
                )}
                {(upcoming?.length > 0 || isUpcomingLoading) && (
                    <Panel>
                        <h4>Upcoming meetings</h4>
                        <UpcomingMeetings
                            upcoming={upcoming}
                            isUpcomingLoading={isUpcomingLoading}
                        />
                    </Panel>
                )}
                {(meetings?.length > 0 || isMeetingsLoading) && (
                    <Panel>
                        <h4>Recent meetings</h4>

                        <div style={{ fontSize: '16px' }}>
                            {!isMeetingsLoading &&
                                meetings
                                    .sort(byDate())
                                    .slice(0, 3)
                                    .map(meeting => (
                                        <div key={meeting.id}>
                                            {meeting.date} - {meeting.topic}
                                        </div>
                                    ))}
                            {isMeetingsLoading && <Loader />}
                        </div>
                    </Panel>
                )}
                {showButtonPannel && (
                    <Panel>
                        <ButtonToolbar style={{ paddingTop: 10 }}>
                            {circle.drivelink && (
                                <Button
                                    color="blue"
                                    appearance="ghost"
                                    href={circle.drivelink}
                                    target="_blank"
                                >
                                    Google Drive
                                </Button>
                            )}

                            {circle.mattermost && (
                                <Button
                                    color="blue"
                                    appearance="ghost"
                                    href={matterMostLink}
                                    target="_blank"
                                >
                                    Mattermost
                                </Button>
                            )}
                        </ButtonToolbar>
                    </Panel>
                )}
            </PanelGroup>
        </FlexboxGrid.Item>
    );
};

export const AboutTab = ({ circle, currentMembers, showEditCircleButton }: Props) => {
    const showButtonPannel = !!(circle?.mattermost || circle?.drivelink);
    const showCallToEditCirle = !circle.description && !circle.mission && !showButtonPannel;
    const { meetings, isLoading: isMeetingsLoading } = useMeetings(circle.id);
    const { upcoming, isLoading: isUpcomingLoading } = useUpcoming(circle.id);

    return (
        <FlexboxGrid justify="start">
            <FlexboxGrid.Item as={Col} colspan={24} style={{ width: '30em' }}>
                <Panel>
                    <h4>Members ({currentMembers.length})</h4>
                    <MemberTable data={currentMembers} referenceCircle={circle} />
                </Panel>
            </FlexboxGrid.Item>
            {showCallToEditCirle ? (
                <CallToEditCirle circle={circle} showEditCircleButton={showEditCircleButton} />
            ) : (
                <CircleDetails
                    circle={circle}
                    showButtonPannel={showButtonPannel}
                    meetings={meetings}
                    upcoming={upcoming}
                    isMeetingsLoading={isMeetingsLoading}
                    isUpcomingLoading={isUpcomingLoading}
                />
            )}
        </FlexboxGrid>
    );
};
