import { useNavigate } from 'react-router-dom';
import { Table } from 'rsuite';

import { useCustomers } from '../../hooks/useCustomers';

const { Column, HeaderCell, Cell } = Table;

const CustomerTable = () => {
    const { customers } = useCustomers({ sortByName: true });
    const navigate = useNavigate();

    return (
        <Table
            autoHeight
            data={customers}
            onRowClick={row => {
                navigate(`/customers/${row.id}`);
            }}
        >
            <Column align="left" fixed flexGrow={1} minWidth={160}>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
            </Column>
        </Table>
    );
};

export default CustomerTable;
