import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import {
    InternalProjectTypeList,
    InternalProjectTypeModel,
    InternalProjectTypeRequestPayload,
} from '../generated-types/internal_project_type';

type Props = {
    sortByName: boolean;
    sortOrder: 'asc' | 'desc';
};

export function useInternalProjectTypes(props?: Props) {
    const { data, isLoading, isSuccess } = useQuery<InternalProjectTypeList>(
        ['internal_project_types'],
        () => lambdaApi.get('/internal/projects/types/', {}).then(res => res.data),
    );

    const createInternalProjectType = useMutation<
        InternalProjectTypeModel,
        AxiosError,
        InternalProjectTypeRequestPayload
    >(payload => {
        return lambdaApi.post('/internal/projects/types', payload).then(res => res.data);
    });

    const updateInternalProjectType = useMutation<
        InternalProjectTypeModel,
        AxiosError,
        { id: number; payload: InternalProjectTypeRequestPayload }
    >(params => {
        const { payload, id } = params;
        return lambdaApi.put(`/internal/projects/types/${id}`, payload).then(res => res.data);
    });

    const deleteInternalProjectType = useMutation<InternalProjectTypeModel, AxiosError, number>(
        id => {
            return lambdaApi.delete(`/internal/projects/types/${id}`).then(res => res.data);
        },
    );

    let filterData: InternalProjectTypeList = [];
    if (data) {
        filterData = data;
        if (props?.sortByName) {
            filterData.sort((a, b) => {
                return a.name < b.name ? -1 : 1;
            });
            if (props?.sortOrder === 'desc') {
                filterData.reverse();
            }
        }
    }
    return {
        createInternalProjectType,
        updateInternalProjectType,
        deleteInternalProjectType,
        internalProjectTypes: filterData,
        isLoading,
        isSuccess,
    };
}
