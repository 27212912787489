// @ts-nocheck - too many type-errors to fix now
import ArowBackIcon from '@rsuite/icons/ArowBack';
import BlockIcon from '@rsuite/icons/Block';
import { useState } from 'react';
import { IconButton, InputNumber, Table } from 'rsuite';

import { useCircles } from '../../hooks/useCircles';
import { useMemberships } from '../../hooks/useMemberships';
import { getCircleFundingMap } from '../../utils/funding';
import { EurosCell, WeightCell } from '../Formats';

const REVENUE_SHARE = 0.15;

const { Column, HeaderCell, Cell } = Table;

const NameCell = ({ rowData, _dataKey, ...props }) => (
    <Cell {...props}>
        {rowData.name}
        {!rowData.is_active && <BlockIcon color="red" />}
    </Cell>
);

const EditableWeightCell = ({ rowData, dataKey, setValue, ...props }) => (
    <Cell {...props}>
        <WeightEditField
            value={rowData[dataKey]}
            id={rowData.id}
            originalValue={rowData.current_weight}
            setValue={setValue}
        ></WeightEditField>
    </Cell>
);

const WeightEditField = props => {
    let cssClass = '';
    if (props.value > props.originalValue) {
        cssClass = 'weight-field-green';
    }
    if (props.value < props.originalValue) {
        cssClass = 'weight-field-red';
    }
    return (
        <InputNumber
            className={cssClass}
            value={props.value}
            min={0}
            onChange={value => {
                props.setValue({ value, id: props.id });
            }}
            postfix={
                <IconButton
                    icon={<ArowBackIcon />}
                    onClick={_value => {
                        props.setValue({ value: 'reset', id: props.id });
                    }}
                    size="xs"
                ></IconButton>
            }
            size="sm"
            step={0.1}
        />
    );
};

type Props = {
    monthlyRevenue: number;
};

export type WeightType = { [key: string | number]: string | number };

export function WeightEditTable({ monthlyRevenue }: Props) {
    const [sortColumn, setSortColumn] = useState('name');
    const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
    const [weightsEditable, setWeightsEditable] = useState<WeightType>({});
    const { circles } = useCircles();
    const { memberships } = useMemberships();

    const setSort = (sortColumn = 'name', sortType: 'asc' | 'desc' = 'desc') => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const setEditableWeightValue = ({ value, id }: { value: string; id: string }) => {
        const newWeightEditable = { ...weightsEditable };
        if (value === 'reset' || parseFloat(value) === circles[Number(id)]?.current_weight) {
            delete newWeightEditable[id];
        } else {
            newWeightEditable[id] = value;
        }
        setWeightsEditable(newWeightEditable);
    };

    const getTableData = () => {
        // Calculate current and new weight data.
        const currentWeights: WeightType = {};
        const newWeights: WeightType = {};
        Object.values(circles).forEach(c => {
            currentWeights[c.id] = c.current_weight;
            newWeights[c.id] =
                typeof weightsEditable[c.id] !== 'undefined'
                    ? parseFloat(weightsEditable[c.id])
                    : c.current_weight;
        });

        const currentFundingMap = getCircleFundingMap({
            circleWeights: currentWeights,
            memberships: memberships,
            monthlyRevenue: monthlyRevenue,
            revenueShare: REVENUE_SHARE,
        });

        const newFundingMap = getCircleFundingMap({
            circleWeights: newWeights,
            memberships: memberships,
            monthlyRevenue: monthlyRevenue,
            revenueShare: REVENUE_SHARE,
        });

        const fundingDiff = {};
        Object.keys(circles).forEach(circle_id => {
            fundingDiff[circle_id] = newFundingMap[circle_id] - currentFundingMap[circle_id];
        });

        const tableData = Object.values(circles).map(row => {
            row.__currentFunding = currentFundingMap[row.id] || 0;
            row.__fundingDiff = fundingDiff[row.id];
            row.__newWeight =
                typeof weightsEditable[row.id] !== 'undefined'
                    ? weightsEditable[row.id]
                    : row.current_weight;
            return row;
        });

        tableData.sort((a, b) => {
            if (isNaN(a[sortColumn])) {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
            return a[sortColumn] - b[sortColumn];
        });
        sortType === 'desc' && tableData.reverse();

        return tableData;
    };

    const tableData = getTableData();
    return (
        <Table
            autoHeight
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={setSort}
            data={tableData}
            virtualized
            loading={!tableData.length}
        >
            <Column align="left" fixed width={160} sortable>
                <HeaderCell>Circle</HeaderCell>
                <NameCell dataKey="name" />
            </Column>
            <Column align="right" fixed sortable>
                <HeaderCell>Weight</HeaderCell>
                <WeightCell dataKey="current_weight" />
            </Column>
            <Column align="left" fixed sortable width={140}>
                <HeaderCell>New Weight</HeaderCell>
                <EditableWeightCell dataKey="__newWeight" setValue={setEditableWeightValue} />
            </Column>
            <Column align="right" sortable width={140}>
                <HeaderCell>Current Funding</HeaderCell>
                <EurosCell dataKey="__currentFunding" />
            </Column>
            <Column align="right" sortable width={140}>
                <HeaderCell>Change</HeaderCell>
                <EurosCell dataKey="__fundingDiff" />
            </Column>
        </Table>
    );
}
