import parseISO from 'date-fns/parseISO';

import { InternalProjectTypeModel } from '../generated-types/internal_project_type';

type DefaultSortType = {
    [key: string]: { [key: string]: InternalProjectTypeModel };
};

const defaultSort = (array: [DefaultSortType], sortColumn: string, sortType: string) => {
    array.sort((a, b) => {
        if (a[sortColumn] instanceof Number) {
            return Number(a[sortColumn]) - Number(b[sortColumn]);
        } else if (a[sortColumn] instanceof String) {
            return String(a[sortColumn]).toLowerCase() < String(b[sortColumn]).toLowerCase()
                ? 1
                : -1;
        }
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
    });

    sortType === 'desc' && array.reverse();
};

interface SomethingWithDate {
    date: string;
}

export function byDate(sortAscending?: boolean) {
    return (m1: SomethingWithDate, m2: SomethingWithDate) => {
        const date1 = parseISO(m1.date);
        const date2 = parseISO(m2.date);
        return (date2.getTime() - date1.getTime()) * (sortAscending ? -1 : 1);
    };
}

export default defaultSort;
