import CreditCardPlusIcon from '@rsuite/icons/CreditCardPlus';
import { RefAttributes, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, Table, TableProps, Tooltip, Whisper } from 'rsuite';
import { InnerCellProps } from 'rsuite-table/lib/Cell';

import { useCircles } from '../../hooks/useCircles';
import { useInternalProjects } from '../../hooks/useInternalProjects';
import { useInternalProjectTypes } from '../../hooks/useInternalProjectTypes';
import { useProfile } from '../../hooks/useProfile';

const { Column, HeaderCell, Cell } = Table;

type TableData = {
    id: number;
    name: string;
    circleName: string;
    projectTypeName: string;
    is_billable: boolean;
};

const NameCell = (
    props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
) => (
    <Cell {...props}>
        {props.rowData.name}
        {props.rowData.is_billable ? (
            <>
                &nbsp;
                <Whisper speaker={<Tooltip>Billable</Tooltip>}>
                    <CreditCardPlusIcon style={{ color: 'green' }} />
                </Whisper>
            </>
        ) : (
            ''
        )}
    </Cell>
);

const InternalProjectTable = () => {
    const [sortColumn, setSortColumn] = useState<keyof TableData>('name');
    const [sortType, setSortType] = useState<TableProps['sortType']>('desc');
    const navigate = useNavigate();

    const { isAdmin } = useProfile();
    const { internalProjects, isSuccess: internalProjectsIsSuccess } = useInternalProjects();
    const { internalProjectTypes, isSuccess: internalProjectTypesIsSuccess } =
        useInternalProjectTypes();
    const { circles, isSuccess: circlesIsSuccess } = useCircles();

    const setSort = (sortColumn: keyof TableData) => {
        setSortColumn(sortColumn);
        if (sortType === 'asc') {
            setSortType('desc');
        } else {
            setSortType('asc');
        }
    };

    const getTableData = () => {
        const tableData: TableData[] = internalProjects.map(project => {
            return {
                id: project.id,
                name: project.name,
                circleName: circles.find(cirlce => cirlce.id === project.circle_id)?.name || '',
                projectTypeName:
                    internalProjectTypes.find(type => type.id === project.type_id)?.name || '',
                is_billable: project.is_billable || false,
            };
        });
        tableData.sort((a, b) => {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
        });
        sortType === 'desc' && tableData.reverse();
        return tableData;
    };

    return internalProjectsIsSuccess && internalProjectTypesIsSuccess && circlesIsSuccess ? (
        <Table
            autoHeight
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={setSort as (sortColumn: string) => void}
            data={getTableData()}
            onRowClick={row => {
                isAdmin && navigate(`/internalprojects/${row.id}`);
            }}
        >
            <Column align="left" fixed flexGrow={2} sortable>
                <HeaderCell>Name</HeaderCell>
                <NameCell dataKey="name" />
            </Column>
            <Column align="left" flexGrow={1} sortable>
                <HeaderCell>Circle</HeaderCell>
                <Cell dataKey="circleName" />
            </Column>
            <Column align="left" flexGrow={1} sortable>
                <HeaderCell>Type</HeaderCell>
                <Cell dataKey="projectTypeName" />
            </Column>
        </Table>
    ) : (
        <Loader />
    );
};

export default InternalProjectTable;
