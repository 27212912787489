import { Buffer } from 'buffer';
import { useGracePeriods } from 'hooks/admin/useGracePeriods';
import { useCustomerProjects } from 'hooks/useCustomerProjects';
import { useCustomers } from 'hooks/useCustomers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, FlexboxGrid, Grid, Loader, Modal, Panel, PanelGroup, Tag } from 'rsuite';
import { getPreviousMonthRange } from 'utils/dateUtils';

import { useAssignments } from '../../hooks/admin/useAssignments';
import { useCircles } from '../../hooks/useCircles';
import { useMembers } from '../../hooks/useMembers';
import { useProfile } from '../../hooks/useProfile';
import { useTimeTracks } from '../../hooks/useTimeTracks';
import DataBox from '../DataBox';
import EditCustomerProjectDialog from '../dialogs/EditCustomerProjectDialog';
import EditGracePeriodDialog from '../dialogs/EditGracePeriodDialog';
import { NewAssignmentDialog } from '../dialogs/NewAssignmentDialog';
import ProjectGracePeriodAlerts from '../misc/ProjectGracePeriodAlerts';
import AssignmentTable from '../tables/AssignmentTable';
import TimeTrackTable from '../tables/TimeTrackTable';
import { NotFound } from './NotFound';
import TimeTrackTableScopeComponent from './timetracks/TimeTrackTableScopeComponent';

const CustomerProjectDetailsScreen = () => {
    const { projectId: projectIdStr } = useParams();
    const projectId: number = parseInt(String(projectIdStr), 10);

    const [prevMonthStart, prevMonthEnd] = getPreviousMonthRange(new Date());
    const { timetracks, ttQuery, setTtQuery, timetrackCount } = useTimeTracks({});

    const { isAdmin } = useProfile();
    const { circles } = useCircles();
    const { members } = useMembers();
    const { customers, isLoading: customersLoading } = useCustomers();
    const {
        customerProjects,
        isLoading: projectsLoading,
        getInvoice,
        getHoursReport,
        sendToProcountor,
    } = useCustomerProjects();
    const { assignments } = useAssignments({ projectId: projectId });
    const { gracePeriods, closeGracePeriods, refetch } = useGracePeriods();

    const project = customerProjects.find(project => project.id === projectId);
    const customer = customers.find(customer => customer.id === project?.customer_id);

    const [modalStatus, setModalStatus] = useState({ isOpen: false, message: '' });
    const handleModalClose = () => setModalStatus({ isOpen: false, message: '' });

    const handleInvoice = () => {
        if (project?.id) {
            getInvoice(project.id, ttQuery).then(res => {
                if (res.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'invoice.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                } else if (res.status === 204) {
                    setModalStatus({ isOpen: true, message: 'No timetracks found' });
                }
            });
        }
    };

    const handleHoursReport = () => {
        if (project?.id) {
            getHoursReport(project.id, ttQuery).then(res => {
                const bytes = Buffer.from(res.data, 'base64');
                const blob = new Blob([bytes], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', 'hourReport.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        }
    };

    const handleProcountor = () => {
        if (project?.id) {
            sendToProcountor(project.id, ttQuery)
                .then(() => {
                    setModalStatus({
                        isOpen: true,
                        message: 'Invoice was delivered to Procountor',
                    });
                })
                .catch((error: { response: { status: number } }) => {
                    if (error.response.status === 404) {
                        setModalStatus({ isOpen: true, message: 'No timetracks found' });
                    } else {
                        setModalStatus({
                            isOpen: true,
                            message: 'Failed to deliver invoice to Procountor',
                        });
                    }
                });
        }
    };

    useEffect(() => {
        setTtQuery({
            customer_projects: [projectId],
            start_date: prevMonthStart,
            end_date: prevMonthEnd,
        });
    }, []);

    if (customersLoading || projectsLoading) return <Loader />;

    if (!project || !customer) return <NotFound message="Project not found!" />;

    return (
        <>
            <Grid fluid>
                <Col xs={24}>
                    <Tag>
                        <h3>Customer Project</h3>
                    </Tag>
                    <Panel
                        bordered
                        header={
                            <span>
                                <h1 style={{ display: 'inline' }}>{project.name}</h1>{' '}
                                {!project.is_active && <Tag color="red">Deactivated</Tag>}
                            </span>
                        }
                        style={{ marginBottom: '24px' }}
                    >
                        <FlexboxGrid justify="start" align="middle">
                            <DataBox title="Customer" value={customer.name} />
                        </FlexboxGrid>
                        <FlexboxGrid
                            justify="space-between"
                            align="middle"
                            style={{ paddingTop: 20 }}
                        >
                            {isAdmin ? (
                                <>
                                    <EditCustomerProjectDialog editable={project}>
                                        <Button appearance="primary">Edit</Button>
                                    </EditCustomerProjectDialog>
                                    <EditGracePeriodDialog project_id={project.id}>
                                        <Button appearance="primary">Unlock Timetracking</Button>
                                    </EditGracePeriodDialog>
                                    <Button
                                        appearance="primary"
                                        onClick={handleInvoice}
                                        target="_blank"
                                    >
                                        Get Invoice
                                    </Button>
                                    <Button appearance="primary" onClick={handleHoursReport}>
                                        Get Hours Report
                                    </Button>
                                    <Button
                                        appearance="primary"
                                        onClick={handleProcountor}
                                        target="_blank"
                                    >
                                        Send to Procountor
                                    </Button>
                                </>
                            ) : null}
                            <Modal open={modalStatus.isOpen} onClose={handleModalClose}>
                                <Modal.Body>{modalStatus.message}</Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={handleModalClose} appearance="primary">
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </FlexboxGrid>
                    </Panel>
                    <ProjectGracePeriodAlerts
                        refetch={refetch}
                        closeGracePeriods={closeGracePeriods}
                        gracePeriods={gracePeriods}
                        assignments={assignments}
                        members={members}
                    />
                    <PanelGroup accordion defaultActiveKey={3} bordered>
                        <Panel eventKey={1} header={<h3>About</h3>}>
                            <h3>Description</h3>
                            <p className="circle-description">{project.description}</p>
                        </Panel>
                        <Panel
                            eventKey={2}
                            header={
                                <FlexboxGrid align="middle" justify="start">
                                    <h3 style={{ display: 'inline' }}>
                                        Members ({assignments && assignments.length})
                                    </h3>

                                    {project.is_active ? (
                                        <NewAssignmentDialog projectId={Number(project.id)} />
                                    ) : null}
                                </FlexboxGrid>
                            }
                        >
                            <AssignmentTable assignments={assignments} />
                        </Panel>
                        <Panel
                            eventKey={3}
                            header={
                                <FlexboxGrid align="middle" justify="start">
                                    <h3 style={{ display: 'inline' }}>
                                        Project Time Tracks ({timetracks.length})
                                    </h3>
                                </FlexboxGrid>
                            }
                        >
                            <TimeTrackTableScopeComponent
                                adminMode={true}
                                filterOptions={{
                                    members: members,
                                    circles: circles,
                                    customerProjects: [project],
                                    internalProjects: [],
                                }}
                                availableFilters={{ memberPicker: true, datePicker: true }}
                                total={timetrackCount}
                                query={ttQuery}
                                setQuery={setTtQuery}
                            ></TimeTrackTableScopeComponent>
                            <TimeTrackTable
                                data={timetracks}
                                gracePeriods={gracePeriods}
                                query={ttQuery}
                                showColumns={[
                                    'memberAvatar',
                                    'memberName',
                                    'date',
                                    'hours',
                                    'comment',
                                    'status',
                                    'confirmed',
                                ]}
                            />
                        </Panel>
                    </PanelGroup>
                </Col>
            </Grid>
        </>
    );
};

export default CustomerProjectDetailsScreen;
