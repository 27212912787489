export const FieldInputLength = ({
    currentLength = 0,
    maxLength,
}: {
    currentLength?: number;
    maxLength: number;
}) => {
    const textColor = currentLength > maxLength ? 'red' : '';
    return (
        <div style={{ textAlign: 'right' }}>
            <small style={{ color: textColor }}>
                {currentLength}/{maxLength}
            </small>
        </div>
    );
};
