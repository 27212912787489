import { Col, FlexboxGrid, Grid, Loader, Panel, PanelGroup } from 'rsuite';

import { useCircles } from '../../hooks/useCircles';
import { useProfile } from '../../hooks/useProfile';
import { NewCircleDialog } from '../dialogs/NewCircleDialog';
import { Weight } from '../Formats';
import CircleTable from '../tables/CircleTable';

const CirclesScreen = () => {
    const { user, isAdmin } = useProfile();
    const { myCircles, circles, isLoading } = useCircles({
        sort: { order: 'asc', column: 'name' },
    });

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Circles</h1>
                            </Panel>
                            <Panel>{isAdmin ? <NewCircleDialog /> : null}</Panel>
                        </FlexboxGrid>
                    </Panel>
                    <Panel
                        bordered
                        bodyFill
                        header={
                            <FlexboxGrid justify="space-between" align="middle">
                                <h3>My Circles ({isLoading ? <Loader /> : myCircles.length})</h3>
                                <span>Weight Sum: {Weight(user?.weight_sum ?? 0)}</span>
                            </FlexboxGrid>
                        }
                        style={{ marginBottom: '24px' }}
                    >
                        <CircleTable
                            isLoading={isLoading}
                            data={myCircles}
                            referenceMember={user}
                        />
                    </Panel>
                    <PanelGroup accordion defaultActiveKey={1} bordered>
                        <Panel
                            eventKey={1}
                            bordered
                            header={
                                <h3>All Circles ({isLoading ? <Loader /> : circles.length})</h3>
                            }
                        >
                            <CircleTable isLoading={isLoading} data={circles} />
                        </Panel>
                    </PanelGroup>
                </Col>
            </Grid>
        </div>
    );
};

export default CirclesScreen;
