import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import EditIcon from '@rsuite/icons/Edit';
import PlusIcon from '@rsuite/icons/Plus';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import compareAsc from 'date-fns/compareAsc';
import { TimeTrackModel } from 'generated-types/timetrack';
import Button from 'rsuite/esm/Button';
import Col from 'rsuite/esm/Col';
import IconButton from 'rsuite/esm/IconButton';

import {
    AddPersonalTimeTrack,
    DeleteTimeTrack,
    HandleTimeTrackDelete,
    UpdateTimeTrack,
} from './Types';

type Props = {
    tt: TimeTrackModel | undefined;
    timetrackNotEditable: boolean | undefined;
    nextDayButtonID: string;
    updateTimeTrack: UpdateTimeTrack;
    selectedDate: Date;
    handleTimeTrackDelete: HandleTimeTrackDelete;
    deleteTimeTrack: DeleteTimeTrack;
    addPersonalTimeTrack: AddPersonalTimeTrack;
};

export function ActionButtons({
    tt,
    timetrackNotEditable,
    nextDayButtonID,
    updateTimeTrack,
    selectedDate,
    handleTimeTrackDelete,
    deleteTimeTrack,
    addPersonalTimeTrack,
}: Props) {
    const today = new Date();
    const showMoveToNextDayButton = 0 !== compareAsc(selectedDate, today.setHours(0, 0, 0, 0));

    return (
        <>
            {tt ? (
                <>
                    {timetrackNotEditable ? (
                        <>
                            <CheckIcon color="green" />
                            &nbsp;
                            <b>Timetrack has been locked.</b>
                        </>
                    ) : (
                        <>
                            <Col>
                                <IconButton
                                    type="submit"
                                    icon={<EditIcon />}
                                    disabled={updateTimeTrack.isLoading}
                                    loading={updateTimeTrack.isLoading}
                                    appearance="primary"
                                    color="violet"
                                    placement="right"
                                    size="md"
                                >
                                    Save timetrack
                                </IconButton>
                            </Col>
                            <Col>
                                <ConfirmationDialog
                                    handleOK={handleTimeTrackDelete}
                                    header={'Delete timetrack'}
                                    message={'Are you sure you want to delete this timetrack?'}
                                >
                                    <IconButton
                                        icon={<CloseIcon />}
                                        appearance="primary"
                                        disabled={deleteTimeTrack.isLoading}
                                        loading={deleteTimeTrack.isLoading}
                                        color="red"
                                        placement="right"
                                        size="md"
                                    >
                                        Delete timetrack
                                    </IconButton>
                                </ConfirmationDialog>
                            </Col>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Col>
                        <IconButton
                            icon={<PlusIcon />}
                            type="submit"
                            disabled={addPersonalTimeTrack.isLoading}
                            loading={addPersonalTimeTrack.isLoading}
                            appearance="primary"
                            color="green"
                            placement="right"
                            size="md"
                        >
                            Add new timetrack
                        </IconButton>
                    </Col>
                    {showMoveToNextDayButton ? (
                        <Col>
                            <Button
                                id={nextDayButtonID}
                                type="submit"
                                disabled={addPersonalTimeTrack.isLoading}
                                loading={addPersonalTimeTrack.isLoading}
                                appearance="ghost"
                                color="green"
                                size="md"
                            >
                                Add and move to next day
                            </Button>
                        </Col>
                    ) : null}
                </>
            )}
        </>
    );
}
