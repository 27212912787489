import { zodResolver } from '@hookform/resolvers/zod';
import format from 'date-fns/format';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker, Input, Row } from 'rsuite';

import { MYSQL_DATE_STRING } from '../../config';
import { OperativeCostRequestPayload } from '../../generated-types/operative_cost';
import { useOperativeCost } from '../../hooks/useOperativeCost';
import { useProfile } from '../../hooks/useProfile';
import { NewOperativeCostSchema, NewOperativeCostType } from '../../schema/operative_costs';
import { DialogWrap } from '../misc/DialogWrap';
import { showErrorNotification, showNotification } from '../ShowNotification';

type Props = {
    circleId: number;
};

export function NewOperativeCostDialog({ circleId }: Props) {
    const { user } = useProfile();
    const { createOperativeCost, refetch } = useOperativeCost();
    const [open, setOpen] = useState(false);
    const {
        formState: { errors },
        control,
        handleSubmit,
    } = useForm<NewOperativeCostType>({
        resolver: zodResolver(NewOperativeCostSchema),
    });

    const onSubmit: SubmitHandler<NewOperativeCostType> = data => {
        const { end_date, start_date, ...rest } = data;
        format(start_date, MYSQL_DATE_STRING);
        const payload: OperativeCostRequestPayload = {
            start_date: format(start_date, MYSQL_DATE_STRING),
            end_date: end_date ? format(end_date, MYSQL_DATE_STRING) : undefined,
            circle_id: circleId,
            ...rest,
        };

        createOperativeCost.mutate(payload, {
            onError: err => {
                showErrorNotification(err);
            },
            onSuccess: () => {
                showNotification({
                    header: 'Operative cost created!',
                });
                refetch();
                setOpen(false);
            },
        });
    };

    if (!user) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                title={`New Operative Cost`}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Row>
                    <p>Name</p>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.name?.message}</p>
                </Row>
                <Row>
                    <p>Description</p>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.description?.message}</p>
                </Row>
                <Row>
                    <p>Amount</p>
                    <Controller
                        name="amount"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => <Input type="number" {...field} />}
                    />
                    <p className="error">{errors?.amount?.message}</p>
                </Row>
                <Row>
                    <p>Start date</p>
                    <Controller
                        name="start_date"
                        control={control}
                        defaultValue={undefined}
                        render={({ field }) => <DatePicker oneTap {...field} />}
                    />
                    <p className="error">{errors?.end_date?.message}</p>
                </Row>
                <Row>
                    <p>End date</p>
                    <Controller
                        name="end_date"
                        control={control}
                        defaultValue={undefined}
                        render={({ field }) => <DatePicker oneTap {...field} />}
                    />
                    <p className="error">{errors?.end_date?.message}</p>
                </Row>
            </DialogWrap>
        </form>
    );
}
