import { zodResolver } from '@hookform/resolvers/zod';
import format from 'date-fns/format';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker, Input, InputNumber, Row } from 'rsuite';

import { MYSQL_DATE_STRING, OPEN_ENDED_ASSIGNMENT_END } from '../../config';
import { AssignmentModel, AssignmentRequestPayload } from '../../generated-types/assignment';
import { useAssignments } from '../../hooks/admin/useAssignments';
import { useMember } from '../../hooks/useMember';
import { AssignmentSchema, AssignmentSchemaType } from '../../schema/assignment';
import { DialogWrap } from '../misc/DialogWrap';
import { showErrorNotification, showNotification } from '../ShowNotification';

type Props = {
    assignment: AssignmentModel;
};

export function EditAssignmentDialog({ assignment }: Props) {
    const { user } = useMember({ userId: assignment.member_id });
    const { updateAssignment, assignments } = useAssignments({});
    const [open, setOpen] = useState(false);
    const {
        formState: { errors },
        control,
        handleSubmit,
    } = useForm<AssignmentSchemaType>({
        resolver: zodResolver(AssignmentSchema),
    });

    const onSubmit: SubmitHandler<AssignmentSchemaType> = data => {
        const { end_date, start_date, description, billing_rate } = data;
        if (
            assignments.some(assign => {
                const end_d = end_date ? format(end_date, 'yyyy-MM-dd') : '2099-12-31';
                const start_d = format(start_date, 'yyyy-MM-dd');
                /*
                 *  This truncates any potential time-parts from the date.
                 * Less-than and greater-than comparisons work with year-month-date strings
                 */

                return (
                    assign.member_id === assignment.member_id &&
                    assign.project_id === assignment.project_id &&
                    ((assign.end_date >= start_d && assign.end_date <= end_d) ||
                        (assign.start_date >= start_d && assign.start_date <= end_d)) &&
                    assign.id !== assignment.id
                );
            })
        ) {
            showNotification({
                type: 'error',
                header: 'Duplicate Assignment',
                body: 'Same person cannot have multiple active assignments in the same project',
            });
            return;
        }
        const payload: AssignmentRequestPayload = {
            description,
            billing_rate,
            start_date: format(start_date, MYSQL_DATE_STRING),
            end_date: end_date ? format(end_date, MYSQL_DATE_STRING) : OPEN_ENDED_ASSIGNMENT_END,
            is_active: assignment.is_active,
            member_id: assignment.member_id,
            project_id: assignment.project_id,
        };
        updateAssignment.mutate(
            { id: assignment.id, payload },
            {
                onError: err => {
                    showErrorNotification(err);
                },
                onSuccess: () => {
                    showNotification({
                        header: 'Assignment updated',
                    });
                    setOpen(false);
                },
            },
        );
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                shortTitle="Edit"
                title="Update assignment"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Row>
                    <h5>{`${user?.firstname} ${user?.lastname}`}</h5>
                </Row>
                <Row>
                    <p>Billing rate</p>
                    <Controller
                        name="billing_rate"
                        control={control}
                        defaultValue={assignment.billing_rate}
                        render={({ field }) => <InputNumber type="number" {...field} />}
                    />
                    <p className="error">{errors?.billing_rate?.message}</p>
                </Row>
                <Row>
                    <p>Description</p>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={assignment.description}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.description?.message}</p>
                </Row>

                <Row>
                    <p>Start date</p>
                    <Controller
                        name="start_date"
                        control={control}
                        defaultValue={
                            assignment?.start_date ? new Date(assignment.start_date) : undefined
                        }
                        render={({ field }) => <DatePicker oneTap {...field} />}
                    />
                    <p className="error">{errors?.start_date?.message}</p>
                </Row>
                <Row>
                    <p>End-date</p>
                    <Controller
                        name="end_date"
                        control={control}
                        defaultValue={
                            assignment?.end_date ? new Date(assignment.end_date) : undefined
                        }
                        render={({ field }) => <DatePicker oneTap {...field} />}
                    />
                    <p className="error">{errors?.end_date?.message}</p>
                </Row>
            </DialogWrap>
        </form>
    );
}
