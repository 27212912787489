import { NotFound } from 'components/screens/NotFound';
import { useCircles } from 'hooks/useCircles';
import { useMembers } from 'hooks/useMembers';
import { useMemberships } from 'hooks/useMemberships';
import { useProfile } from 'hooks/useProfile';
import { useParams } from 'react-router-dom';
import { Col, Grid, Loader } from 'rsuite';

import { BodyPanel } from './BodyPanel';
import { HeaderPanel } from './HeaderPanel';

export const CircleDetailsScreen = () => {
    const { id } = useParams();
    const circle_id = Number(id);
    const { members } = useMembers();
    const { user, isAdmin } = useProfile();
    const { memberships } = useMemberships({
        circle_id,
    });
    const { circles, isLoading } = useCircles();

    const circle = circles.find(c => c.id === circle_id);

    if (isLoading) {
        return <Loader />;
    }

    if (!circle) {
        return <NotFound message="Circle was not found" />;
    }

    const isScribe = circle.scribe_id === user?.id;
    const isFacilitator = circle.facilitator_id === user?.id;
    const isTreasurer = circle.treasurer_id === user?.id;
    const memberIdList = memberships.map(membership => membership.member_id);
    const currentMembers = members.filter(member => memberIdList.includes(member.id));

    const showEditCircleButton = isAdmin || isScribe || isFacilitator || isTreasurer;
    return (
        <>
            <Grid fluid>
                <Col xs={24}>
                    <HeaderPanel
                        circle={circle}
                        currentMembers={currentMembers}
                        members={members}
                        showEditCircleButton={showEditCircleButton}
                        isAdmin={isAdmin}
                    />
                    <BodyPanel
                        circle={circle}
                        currentMembers={currentMembers}
                        showEditCircleButton={showEditCircleButton}
                        isAdmin={isAdmin}
                    />
                </Col>
            </Grid>
        </>
    );
};
