import { zodResolver } from '@hookform/resolvers/zod';
import format from 'date-fns/format';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker, Input, InputNumber, Row, SelectPicker } from 'rsuite';

import { MYSQL_DATE_STRING, OPEN_ENDED_ASSIGNMENT_END } from '../../config';
import { AssignmentRequestPayload } from '../../generated-types/assignment';
import { useAssignments } from '../../hooks/admin/useAssignments';
import { useMembers } from '../../hooks/useMembers';
import { NewAssignmentSchema, NewAssignmentSchemaType } from '../../schema/assignment';
import { DialogWrap } from '../misc/DialogWrap';
import { showErrorNotification, showNotification } from '../ShowNotification';

type Props = {
    projectId: number;
};

export function NewAssignmentDialog({ projectId }: Props) {
    const { members } = useMembers(false, true);
    const [open, setOpen] = useState(false);
    const { createAssignment, assignments } = useAssignments({});
    const {
        formState: { errors },
        control,
        handleSubmit,
        setValue,
    } = useForm<NewAssignmentSchemaType>({
        resolver: zodResolver(NewAssignmentSchema),
    });

    const onSubmit: SubmitHandler<NewAssignmentSchemaType> = data => {
        const { end_date, start_date, description, billing_rate, member_id } = data;
        if (
            assignments.some(assignment => {
                const end_d = end_date ? format(end_date, 'yyyy-MM-dd') : '2099-12-31';
                const start_d = format(start_date, 'yyyy-MM-dd');
                /*
                 *  This truncates any potential time-parts from the date.
                 * Less-than and greater-than comparisons work with year-month-date strings
                 */

                return (
                    assignment.member_id === member_id &&
                    assignment.project_id === projectId &&
                    ((assignment.end_date >= start_d && assignment.end_date <= end_d) ||
                        (assignment.start_date >= start_d && assignment.start_date <= end_d))
                );
            })
        ) {
            showNotification({
                type: 'error',
                header: 'Duplicate Assignment',
                body: 'Same person cannot have multiple active assignments in the same project',
            });
            return;
        }
        const payload: AssignmentRequestPayload = {
            description,
            billing_rate,
            member_id,
            start_date: format(start_date, MYSQL_DATE_STRING),
            end_date: end_date ? format(end_date, MYSQL_DATE_STRING) : OPEN_ENDED_ASSIGNMENT_END,
            is_active: true,
            project_id: projectId,
        };
        createAssignment.mutate(payload, {
            onError: err => {
                showErrorNotification(err);
            },
            onSuccess: () => {
                showNotification({
                    header: 'Assignment created!',
                });
                setOpen(false);
            },
        });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                title="New assignment"
                onSubmit={handleSubmit(onSubmit)}
                buttonOpenStyle={{
                    showPlusIcon: true,
                    size: 'sm',
                    appearance: 'primary',
                    color: 'green',
                }}
            >
                <Row>
                    <p>Member</p>

                    <SelectPicker
                        searchable={false}
                        onChange={val => {
                            if (!val) {
                                return;
                            }
                            setValue('member_id', val);
                        }}
                        data={members.map(m => {
                            return { value: m.id, label: `${m.lastname} ${m.firstname}` };
                        })}
                    />

                    <p className="error">{errors?.member_id?.message}</p>
                </Row>
                <Row>
                    <p>Billing rate</p>
                    <Controller
                        name="billing_rate"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => <InputNumber type="number" {...field} />}
                    />
                    <p className="error">{errors?.billing_rate?.message}</p>
                </Row>
                <Row>
                    <p>Description</p>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.description?.message}</p>
                </Row>

                <Row>
                    <p>Start date</p>
                    <Controller
                        name="start_date"
                        control={control}
                        render={({ field }) => <DatePicker oneTap {...field} />}
                    />
                    <p className="error">{errors?.start_date?.message}</p>
                </Row>
                <Row>
                    <p>End-date</p>
                    <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => <DatePicker oneTap {...field} />}
                    />
                    <p className="error">{errors?.end_date?.message}</p>
                </Row>
            </DialogWrap>
        </form>
    );
}
