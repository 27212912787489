import WikiTable from 'components/tables/WikiTable';
import { useWiki } from 'hooks/useWiki';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'rsuite';

export function WikiSheetList() {
    const { searchWiki, setWikiSearch } = useWiki({});

    useEffect(() => {
        setWikiSearch('');
    }, []);

    return (
        <div>
            <Link to="/wiki/1">
                <Button appearance="link">Back to starting page</Button>
            </Link>
            <WikiTable wikis={searchWiki.data ?? []} />
        </div>
    );
}
