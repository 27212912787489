/** End date a is a required property in the Assignment backend model.
 *  Fake 'open ended' assignments with a hardcoded
 *  date long in the future into 'end_date' property. */
export const OPEN_ENDED_ASSIGNMENT_END = '2099-12-31';
export const LOCALE_CODE = 'fi-FI';
export const LOCAL_DATE_PATTERN = 'E dd MMM Y';
export const DATE_PATTERN_DATE_RANGE_PICKER = 'dd MMM Y';
export const MYSQL_DATE_STRING = 'Y-M-d';
import * as z from 'zod';

export type ValidEnvironments = 'test' | 'dev' | 'staging' | 'production';

export const envConfig = {
    app: {
        stage: getStageEnv(),
        lambdaApiUrl: '/api',
        version: getStringEnv('REACT_APP_VERSION'),
    },
};

function getStringEnv(envName: string): string {
    const env = process.env[envName];
    if (!env) {
        throw new Error(`${envName} was not found as env-variable!`);
    }

    return z.string().min(3).max(10).parse(env);
}

function getStageEnv(): ValidEnvironments {
    const validEnvironments: ValidEnvironments[] = ['test', 'dev', 'staging', 'production'];
    const runtimeEnv = process.env.REACT_APP_STAGE;
    if (!runtimeEnv) {
        throw new Error('REACT_APP_STAGE was not found as env-variable!');
    }

    const envName = validEnvironments.find(envName => envName === runtimeEnv);
    if (!envName) {
        throw new Error(
            `Invalid REACT_APP_STAGE -env variable: ${runtimeEnv}. Only ${validEnvironments.join(
                ', ',
            )} are allowed!`,
        );
    }
    return envName;
}
