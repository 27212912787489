import { WikiModelList } from 'generated-types/wiki';
import { Link } from 'react-router-dom';
import { Table } from 'rsuite';
import { InnerCellProps } from 'rsuite-table/lib/Cell';

import { useMembers } from '../../hooks/useMembers';
import { ImageCell } from './common/ImageCell';

type WikiTableProps = {
    wikis: WikiModelList;
};

const { Column, HeaderCell, Cell } = Table;

const WikiTable = ({ wikis }: WikiTableProps) => {
    const { members } = useMembers();

    const tableData = wikis.map(row => {
        const member = members.find(m => row.revision.created_by === m.id);
        return {
            ...row,
            __image_url: member?.image_url ?? '',
            __memberName: member?.lastname + ' ' + member?.firstname,
            __title: row.revision.title,
        };
    });

    const LinkToWikiCell = (
        props: JSX.IntrinsicAttributes & InnerCellProps & React.RefAttributes<HTMLDivElement>,
    ) => {
        return (
            <Cell {...props}>
                <Link to={`/wiki/${props.rowData.id}`}>{props.rowData.revision.title}</Link>
            </Cell>
        );
    };

    return (
        <Table autoHeight data={tableData}>
            <Column align="left" width={60} key="memberAvatar">
                <HeaderCell>{''}</HeaderCell>
                <ImageCell dataKey="__image_url" />
            </Column>

            <Column align="left" sortable width={180} key="memberName">
                <HeaderCell>Member</HeaderCell>
                <Cell dataKey="__memberName" />
            </Column>

            <Column align="left" sortable width={180} key="title">
                <HeaderCell>Title</HeaderCell>
                <LinkToWikiCell align="center" />
            </Column>
        </Table>
    );
};

export default WikiTable;
