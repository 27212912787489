import TimeIcon from '@rsuite/icons/Time';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { TimeTrackModel } from 'generated-types/timetrack';
import { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import Checkbox from 'rsuite/esm/Checkbox';
import Col from 'rsuite/esm/Col';
import DatePicker from 'rsuite/esm/DatePicker';
import IconButton from 'rsuite/esm/IconButton';
import Input from 'rsuite/esm/Input';
import InputGroup from 'rsuite/esm/InputGroup';
import Tooltip from 'rsuite/esm/Tooltip';
import Whisper from 'rsuite/esm/Whisper';
import { WorkTimeTrackType } from 'schema/timetracks';

import { defaultDailyHours, defaultLunchBreak } from '../date-hour-utils';
import { getSuggestedHours } from '../suggestions';
import { SetValue } from './Types';

type Props = {
    tt: TimeTrackModel | undefined;
    control: Control<WorkTimeTrackType>;
    dayHourTotal: number;
    startTime: Date | undefined;
    setStartTime: React.Dispatch<React.SetStateAction<Date | undefined>>;
    endTime: Date | undefined;
    setEndTime: React.Dispatch<React.SetStateAction<Date | undefined>>;
    setValue: SetValue;
    timetrackNotEditable: boolean | undefined;
};

export function HoursSelector({
    tt,
    control,
    dayHourTotal,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    setValue,
    timetrackNotEditable,
}: Props) {
    const [inputHoursAsNumber, setInputHoursAsNumber] = useState(true);
    const [substractLunch, setSubstractLunch] = useState(false);
    const [timeRangeOutput, setTimeRangeOutput] = useState<number | undefined>();

    const suggestedHourInput = tt?.hours
        ? tt.hours
        : timeRangeOutput
        ? timeRangeOutput
        : getSuggestedHours(dayHourTotal, defaultDailyHours);

    const handleTimePeriod = () => {
        if (startTime && endTime) {
            const difference = differenceInMinutes(endTime, startTime);
            const lunch = substractLunch ? defaultLunchBreak : 0;
            const differenceInHours = Math.round((difference / 60) * 2) / 2 - lunch;
            if (differenceInHours > 0) {
                setValue('hours', differenceInHours);
                setTimeRangeOutput(differenceInHours);
            }
        }
    };

    const handleRangeSelect = (timeType: 'start' | 'end', val: Date) => {
        val.setSeconds(0);
        if (timeType === 'start') {
            setStartTime(val);
        } else if (timeType === 'end') {
            setEndTime(val);
        }
        handleTimePeriod();
    };

    useEffect(() => {
        handleTimePeriod();
    }, [substractLunch]);

    useEffect(() => {
        setValue('hours', suggestedHourInput);
    }, [dayHourTotal]);

    return (
        <>
            {!tt ? (
                <Col style={{ paddingLeft: 0 }}>
                    <Whisper
                        trigger="hover"
                        placement="bottom"
                        speaker={<Tooltip>Toggle time range input</Tooltip>}
                    >
                        <IconButton
                            icon={<TimeIcon />}
                            appearance={inputHoursAsNumber ? 'default' : 'primary'}
                            onClick={() => setInputHoursAsNumber(!inputHoursAsNumber)}
                        />
                    </Whisper>
                </Col>
            ) : null}
            {inputHoursAsNumber || tt ? (
                <Col xl={4}>
                    <Controller
                        name="hours"
                        control={control}
                        defaultValue={suggestedHourInput}
                        render={({ field }) => (
                            <Input
                                step={0.5}
                                type="number"
                                readOnly={timetrackNotEditable}
                                {...field}
                            />
                        )}
                    />
                </Col>
            ) : (
                <>
                    <Col>
                        <InputGroup>
                            <DatePicker
                                value={startTime}
                                ranges={[]}
                                format="HH:mm"
                                appearance="subtle"
                                hideHours={hour => hour < 6 || hour > 23}
                                hideMinutes={minute => minute % 15 !== 0}
                                placement="topStart"
                                onSelect={val => handleRangeSelect('start', val)}
                                onOk={val => handleRangeSelect('start', val)}
                            />
                            <InputGroup.Addon>to</InputGroup.Addon>
                            <DatePicker
                                value={endTime}
                                ranges={[]}
                                format="HH:mm"
                                appearance="subtle"
                                hideHours={hour => hour < 6 || hour > 20}
                                hideMinutes={minute => minute % 15 !== 0}
                                disabledHours={hour => {
                                    if (startTime) return hour < startTime.getHours();
                                    return false;
                                }}
                                placement="topStart"
                                onSelect={val => handleRangeSelect('end', val)}
                                onOk={val => handleRangeSelect('end', val)}
                            />
                            <InputGroup.Addon style={{ width: 90 }}>
                                Total: {suggestedHourInput}h
                            </InputGroup.Addon>
                        </InputGroup>
                    </Col>
                    <Col>
                        <Checkbox
                            defaultChecked={substractLunch}
                            onChange={() => setSubstractLunch(!substractLunch)}
                        >
                            Include lunch break
                        </Checkbox>
                    </Col>
                </>
            )}
        </>
    );
}
