import { LedgerList } from 'generated-types/ledger';
import { Pagination, Table } from 'rsuite';

import { useCircles } from '../../hooks/useCircles';
import { EurosCell } from '../Formats';

const { Column, HeaderCell, Cell } = Table;

type Props = {
    circleId?: number;
    filterString?: string;
    transactions: LedgerList;
    isLoading: boolean;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    sortColumn: string;
    setSortColumn: (column: string) => void;
    sortType: 'asc' | 'desc';
    setSortType: (sortType: 'asc' | 'desc') => void;
    totalTransactions: number;
};

type TableData = {
    id: number | string;
    date: string;
    circleName: string;
    amount: number;
    description: string;
};

export function TransactionTable({
    setSortColumn,
    setSortType,
    transactions,
    sortType,
    isLoading,
    sortColumn,
    circleId,
    page,
    pageSize,
    setPage,
    setPageSize,
    totalTransactions,
}: Props) {
    const { circles } = useCircles();

    const setSort = (sortColumn: keyof TableData) => {
        setSortColumn(sortColumn);
        if (sortType === 'asc') {
            setSortType('desc');
        } else {
            setSortType('asc');
        }
    };

    const getTableData = () => {
        const tableData: TableData[] = transactions.map(transaction => {
            return {
                id: transaction.id,
                date: transaction?.tx_date || '',
                circleName:
                    circles.find(circle => circle.id === transaction.circle_id)?.name ||
                    `Circle no. ${transaction.circle_id}`,
                amount: transaction?.amount || 0,
                description: transaction?.description || '',
            };
        });

        return tableData;
    };

    return !isLoading ? (
        <div>
            <Table
                isTree
                rowKey="id"
                virtualized
                autoHeight
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={setSort as (sortColumn: string) => void}
                data={getTableData()}
            >
                <Column align="left" fixed sortable resizable width={100}>
                    <HeaderCell>Date</HeaderCell>
                    <Cell dataKey="date" />
                </Column>
                {!circleId && (
                    <Column align="left" fixed sortable resizable width={160}>
                        <HeaderCell>Circle</HeaderCell>
                        <Cell dataKey="circleName" />
                    </Column>
                )}
                <Column align="right" fixed sortable resizable width={90}>
                    <HeaderCell>Amount</HeaderCell>
                    <EurosCell dataKey="amount" />
                </Column>
                <Column align="left" sortable width={680}>
                    <HeaderCell>Description</HeaderCell>
                    <Cell dataKey="description" />
                </Column>
            </Table>
            <Pagination
                activePage={page}
                limit={pageSize}
                total={totalTransactions}
                onChangePage={setPage}
                onChangeLimit={setPageSize}
            />
        </div>
    ) : null;
}
