import { useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { LeaveTypeModelList } from '../generated-types/leave_type';

type Props = {
    identifier_name?: string;
    disabled?: boolean;
};

export function useLeaveTypes(props?: Props) {
    const { isLoading, isError, data } = useQuery<LeaveTypeModelList>(
        ['leaves_all'],
        () => lambdaApi.get('/me/leavetypes').then(res => res.data),
        { enabled: !props?.disabled },
    );

    let leaveData: LeaveTypeModelList = data ? data : [];
    if (props?.identifier_name) {
        leaveData = leaveData.filter(l => l.identifier_name === props.identifier_name);
    }

    return {
        leaveTypes: leaveData,
        isLoading,
        isError,
    };
}
