import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import logo from 'assets/circles_logo_cv.png';

import { pageSize, styles, TopGradientBar } from './styles';

export const ClosingPage = () => {
    const companyEmail = 'contact@circles.fi';
    const companyWebsite = 'https://circles.fi';
    const companyPhone = '+358 10 3843 330';
    return (
        <Page size={pageSize} style={styles.page}>
            <TopGradientBar />
            <View style={styles.closingPageContainer}>
                <View style={styles.closingPageLogoContainer}>
                    <Link src={companyWebsite}>
                        <Image src={logo} style={styles.largeLogo} />
                    </Link>
                </View>
                <View style={styles.closingPageTextContainer}>
                    <Text style={styles.closingPageText}>Sales: {companyPhone}</Text>
                    <Text style={styles.closingPageText}>
                        Email:{' '}
                        <Link style={styles.closingPageTextLink} src={`mailto:${companyEmail}`}>
                            {companyEmail}
                        </Link>
                    </Text>
                </View>
            </View>
        </Page>
    );
};
