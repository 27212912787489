import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';
import {
    SkillsMemberCertificationModel,
    SkillsMemberCertificationModelList,
} from 'generated-types/skills/certification';
import { SkillsEducationModelList } from 'generated-types/skills/education';

import CertificateBlank from '../../../../assets/icons/certificate_blank.png';
import { CVFooter } from './Footer';
import { pageSize, styles, TopGradientBar } from './styles';

const BadgeContainer = ({ certification }: { certification: SkillsMemberCertificationModel }) => (
    <View style={styles.badgeContainer}>
        {certification.badge_image_url ? (
            <Image src={certification.badge_image_url} style={styles.badge} />
        ) : (
            <Image src={CertificateBlank} style={styles.badge} />
        )}
    </View>
);

export const EducationPage = ({
    educations,
    footerText,
    certifications,
}: {
    educations: SkillsEducationModelList;
    footerText: string;
    certifications: SkillsMemberCertificationModelList;
}) => (
    <Page size={pageSize} style={styles.page}>
        <TopGradientBar />
        <View style={styles.pageTitleContainer} fixed>
            <Text style={styles.pageTitleText}>Education & Certification</Text>
        </View>
        <View style={styles.flexContainer} wrap>
            {educations.map(education => {
                const endDate = education.end_date && new Date(education.end_date);
                return (
                    <View style={styles.educationBox} key={education.id} wrap={false}>
                        <Text style={styles.title} hyphenationCallback={word => [word]}>
                            {education.degree}
                        </Text>
                        <Text style={styles.dates}>{endDate && format(endDate, 'MMMM yyyy')}</Text>
                        <Text style={styles.description}>{education.description}</Text>
                    </View>
                );
            })}
            {certifications.map(certification => {
                const validationIsUrl =
                    !!certification.validation && certification.validation?.startsWith('http');
                return (
                    <View style={styles.educationBox} key={certification.id} wrap={false}>
                        <Text style={styles.title} hyphenationCallback={word => [word]}>
                            {certification.name}
                        </Text>
                        {validationIsUrl ? (
                            <Link
                                src={certification.validation ?? ''}
                                style={styles.containerWithValidationText}
                            >
                                <BadgeContainer certification={certification} />
                            </Link>
                        ) : (
                            <View style={styles.containerWithValidationText}>
                                <BadgeContainer certification={certification} />
                                <Text style={styles.validationText}>
                                    {certification.validation}
                                </Text>
                            </View>
                        )}
                    </View>
                );
            })}
        </View>
        <CVFooter footerText={footerText} />
    </Page>
);
