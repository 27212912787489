import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Col, Input, Row, SelectPicker } from 'rsuite';

import { CircleModel } from '../../generated-types/circle';
import { useCircles } from '../../hooks/useCircles';
import { useMembers } from '../../hooks/useMembers';
import { useProfile } from '../../hooks/useProfile';
import { EditCircleSchema, EditCircleSchemaType } from '../../schema/circle';
import { DialogWrap } from '../misc/DialogWrap';
import { showErrorNotification, showNotification } from '../ShowNotification';

type Props = {
    circle: CircleModel;
};

export function EditCircleDialog({ circle }: Props) {
    const { user } = useProfile();
    const { members } = useMembers();
    const { updateCircle } = useCircles();
    const [open, setOpen] = useState(false);

    const scribeId = circle?.scribe_id;
    const facilitatorId = circle?.facilitator_id;
    const treasurerId = circle?.treasurer_id;

    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        setValue,
    } = useForm<EditCircleSchemaType>({
        defaultValues: {
            scribe_id: scribeId,
            facilitator_id: facilitatorId,
        },
        resolver: zodResolver(EditCircleSchema),
    });

    const onSubmit: SubmitHandler<EditCircleSchemaType> = data => {
        const { id, ...rest } = data;
        updateCircle.mutate(
            { payload: rest, id: Number(id) },
            {
                onError: err => {
                    showErrorNotification(err);
                },
                onSuccess: () => {
                    showNotification({
                        header: 'Circle edited!',
                    });
                    setOpen(false);
                },
            },
        );
    };

    if (!user) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogWrap
                open={open}
                setOpen={setOpen}
                title={`Edit Circle`}
                onSubmit={handleSubmit(onSubmit)}
            >
                <input hidden {...register('id')} value={circle.id} />
                <input
                    hidden
                    {...register('is_active')}
                    value={circle.is_active ? 'true' : undefined}
                />

                <Row>
                    <p>Circle Name</p>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={circle?.name ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.name?.message}</p>
                </Row>
                <Row>
                    <p>Description</p>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={circle?.description ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.description?.message}</p>
                </Row>
                <Row>
                    <p>Google Drive Link</p>
                    <Controller
                        name="drivelink"
                        control={control}
                        defaultValue={circle?.drivelink ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.drivelink?.message}</p>
                </Row>
                <Row>
                    <p>Mattermost</p>
                    <Controller
                        name="mattermost"
                        control={control}
                        defaultValue={circle?.mattermost ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.mattermost?.message}</p>
                </Row>
                <Row>
                    <p>Mission</p>
                    <Controller
                        name="mission"
                        control={control}
                        defaultValue={circle?.mission ?? ''}
                        render={({ field }) => <Input type="text" {...field} />}
                    />
                    <p className="error">{errors?.mission?.message}</p>
                </Row>
                <Row>
                    <Col xl={12}>
                        <p>Max balance</p>

                        <Controller
                            name="max_balance"
                            control={control}
                            defaultValue={circle?.max_balance ?? 0}
                            render={({ field }) => <Input type="number" {...field} />}
                        />
                        <p className="error">{errors?.max_balance?.message}</p>
                    </Col>
                    <Col xl={12}>
                        <p>Weight</p>
                        <Controller
                            name="current_weight"
                            control={control}
                            defaultValue={circle.current_weight}
                            render={({ field }) => <Input type="number" {...field} />}
                        />
                        <p className="error">{errors?.current_weight?.message}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7}>
                        <p>Facilitator</p>
                        <SelectPicker
                            style={{ width: '200px' }}
                            searchable={false}
                            defaultValue={facilitatorId}
                            onChange={val => {
                                setValue('facilitator_id', val ? val : undefined);
                            }}
                            data={members.map(m => {
                                return { value: m.id, label: `${m.lastname} ${m.firstname}` };
                            })}
                        />
                        <p className="error">{errors?.facilitator_id?.message}</p>
                    </Col>
                    <Col xs={7}>
                        <p>Scribe</p>
                        <SelectPicker
                            style={{ width: '200px' }}
                            searchable={false}
                            defaultValue={scribeId}
                            onChange={val => {
                                setValue('scribe_id', val ? val : undefined);
                            }}
                            data={members.map(m => {
                                return { value: m.id, label: `${m.lastname} ${m.firstname}` };
                            })}
                        />
                        <p className="error">{errors?.scribe_id?.message}</p>
                    </Col>
                    <Col xs={7}>
                        <p>Treasurer</p>
                        <SelectPicker
                            style={{ width: '200px' }}
                            searchable={false}
                            defaultValue={treasurerId}
                            onChange={val => {
                                setValue('treasurer_id', val ? val : undefined);
                            }}
                            data={members.map(m => {
                                return { value: m.id, label: `${m.lastname} ${m.firstname}` };
                            })}
                        />
                        <p className="error">{errors?.treasurer_id?.message}</p>
                    </Col>
                </Row>
            </DialogWrap>
        </form>
    );
}
