import { useTotalRevenues } from 'hooks/useTotalRevenues';
import { useEffect, useState } from 'react';
import { Col, FlexboxGrid, Form, Grid, InputGroup, InputNumber, Panel, PanelGroup } from 'rsuite';
import { average } from 'utils/math';

import {
    TotalRevenueResponseList,
    TotalRevenueResponseModel,
} from '../../generated-types/total_revenues';
import {
    generateMonthKey,
    generateMonthKeyRange,
    getPreviousMonthKey,
} from '../../utils/keyGenerators';
import { WeightEditTable } from '../tables/WeightEditTable';

type GetAverageRevenueType = {
    startMonth: string;
    endMonth: string;
    totalRevenues: TotalRevenueResponseList;
};

const WeightEditScreen = () => {
    const date4mAgo = new Date();
    date4mAgo.setMonth(new Date().getMonth() - 3);
    const date13mAgo = new Date();
    date13mAgo.setMonth(new Date().getMonth() - 12);
    const { totalRevenues } = useTotalRevenues();

    const getAverageRevenue = ({ startMonth, endMonth, totalRevenues }: GetAverageRevenueType) => {
        const months = generateMonthKeyRange(startMonth, endMonth);
        // Get the array of revenues and exclude any 0 revenue months.
        const revenues = months
            .map(
                m =>
                    totalRevenues.find((tr: TotalRevenueResponseModel) => tr.id === m)?.amount || 0,
            )
            .filter(amount => amount > 0);
        return average(revenues);
    };
    const [state, setState] = useState({ simRevenue: 0 });
    const setRevenue = (value: number | string | undefined) => {
        if (value) setState({ ...state, simRevenue: Number(value) });
    };

    useEffect(() => {
        if (totalRevenues) {
            setRevenue(
                totalRevenues.find(
                    (tr: TotalRevenueResponseModel) => tr.id === getPreviousMonthKey(),
                )?.amount,
            );
        }
    }, [totalRevenues]);

    const averageRevenues = {
        simRevenue: totalRevenues.find(tr => tr.id === getPreviousMonthKey())?.amount || 0,
        oneMonthRevenue: totalRevenues.find(tr => tr.id === getPreviousMonthKey())?.amount || 0,
        threeMonthRevenue: getAverageRevenue({
            startMonth: generateMonthKey(date4mAgo.toISOString()),
            endMonth: getPreviousMonthKey(),
            totalRevenues: totalRevenues,
        }),
        oneYearRevenue: getAverageRevenue({
            startMonth: generateMonthKey(date13mAgo.toISOString()),
            endMonth: getPreviousMonthKey(),
            totalRevenues: totalRevenues,
        }),
    };

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Weight Simulator (beta)</h1>
                            </Panel>
                        </FlexboxGrid>
                    </Panel>
                    <PanelGroup accordion defaultActiveKey={1} bordered>
                        <Panel eventKey={1} bordered header={<h3>Weight Table</h3>}>
                            <FlexboxGrid justify="space-between" align="middle">
                                <Panel>
                                    <Form.ControlLabel>
                                        Monthly revenue used in the calculations
                                    </Form.ControlLabel>
                                    <InputGroup>
                                        <InputNumber
                                            value={state.simRevenue}
                                            min={0}
                                            onChange={value => {
                                                setRevenue(value);
                                            }}
                                            size="sm"
                                            step={5000}
                                        />
                                        <InputGroup.Button
                                            onClick={() => {
                                                setRevenue(averageRevenues.oneMonthRevenue);
                                            }}
                                            size="xs"
                                            style={
                                                state.simRevenue === averageRevenues.oneMonthRevenue
                                                    ? {
                                                          fontWeight: 'bold',
                                                      }
                                                    : {}
                                            }
                                        >
                                            1m
                                        </InputGroup.Button>
                                        <InputGroup.Button
                                            onClick={() => {
                                                setRevenue(averageRevenues.threeMonthRevenue);
                                            }}
                                            size="xs"
                                            style={
                                                state.simRevenue ===
                                                averageRevenues.threeMonthRevenue
                                                    ? {
                                                          fontWeight: 'bold',
                                                      }
                                                    : {}
                                            }
                                        >
                                            3m
                                        </InputGroup.Button>
                                        <InputGroup.Button
                                            onClick={() => {
                                                setRevenue(averageRevenues.oneYearRevenue);
                                            }}
                                            size="xs"
                                            style={
                                                state.simRevenue === averageRevenues.oneYearRevenue
                                                    ? {
                                                          fontWeight: 'bold',
                                                      }
                                                    : {}
                                            }
                                        >
                                            1y
                                        </InputGroup.Button>
                                    </InputGroup>
                                    <Form.HelpText>
                                        Click the buttons to use the average revenue for the past
                                        month(s).
                                    </Form.HelpText>
                                </Panel>
                            </FlexboxGrid>

                            <WeightEditTable monthlyRevenue={state.simRevenue} />
                        </Panel>
                    </PanelGroup>
                </Col>
            </Grid>
        </div>
    );
};

export default WeightEditScreen;
