import CloseIcon from '@rsuite/icons/Close';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rsuite';
import FlexboxGrid from 'rsuite/esm/FlexboxGrid';
import Panel from 'rsuite/esm/Panel';

export function NotFound({ message }: { message?: string }) {
    const navigate = useNavigate();
    const notFoundMessage = message ? message : 'Page not found';
    return (
        <Panel bordered style={{ marginTop: 20 }}>
            <FlexboxGrid justify="center" style={{ textAlign: 'center' }}>
                <FlexboxGrid.Item>
                    <CloseIcon style={{ fontSize: '5em' }} />
                    <h1>{notFoundMessage}</h1>
                    <Button onClick={() => navigate(-1)}>Go back</Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    );
}
