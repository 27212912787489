import { queryClient } from 'App';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import {
    CreateWikiPageRequest,
    UpdateWikiPageRequest,
    WikiModel,
    WikiModelList,
} from '../generated-types/wiki';

type Props = {
    articleId?: number | string;
};

export function useWiki({ articleId }: Props) {
    const [searchText, setWikiSearch] = useState<undefined | string>();
    const getArticle = useQuery<WikiModel | undefined>(
        [`/wiki/${articleId}`],
        () => {
            if (!articleId) return undefined;
            return lambdaApi.get(`/wiki/${articleId}`, {}).then(res => res.data);
        },
        { enabled: !!articleId },
    );

    const searchWiki = useQuery<WikiModelList>(
        [searchText ? `/wiki?text=${searchText}` : '/wiki'],
        () =>
            lambdaApi.get(searchText ? `/wiki?text=${searchText}` : '/wiki').then(res => res.data),
        {
            staleTime: 1200 * 1000,
            enabled: searchText != undefined,
        },
    );

    const createWiki = useMutation<WikiModel, AxiosError, CreateWikiPageRequest>(
        payload => lambdaApi.post('/wiki', payload).then(res => res.data),
        {
            onSuccess() {
                queryClient.invalidateQueries(`/wiki/${articleId}`);
                queryClient.invalidateQueries(`/wiki`);
            },
        },
    );

    const updateWiki = useMutation<
        WikiModel,
        AxiosError,
        { payload: UpdateWikiPageRequest; id: number }
    >(
        payload => {
            const { id, ...rest } = payload;
            return lambdaApi.post(`/wiki/${id}`, rest.payload).then(res => res.data);
        },
        {
            onSuccess() {
                queryClient.invalidateQueries(`/wiki/${articleId}`);
            },
        },
    );

    return {
        getArticle,
        createWiki,
        setWikiSearch,
        searchWiki,
        updateWiki,
    };
}
