import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { HolidayCount, HolidayModelList } from '../generated-types/holiday';

type HolidayPayload = {
    userId: number;
    year: number;
};

type Props = {
    userId?: string;
};

export function useHolidays(props?: Props) {
    const userId = props?.userId;

    const { data, isLoading: totalLoading } = useQuery<HolidayModelList>(
        [`holidays-${userId}`],
        () => lambdaApi.get(`/holidays/${userId}`, {}).then(res => res.data),
        { enabled: !!userId },
    );

    const { data: holidayCount, isLoading: countLoading } = useQuery<HolidayCount>(
        [`holiday-count-${userId}`],
        () => lambdaApi.get(`/holidays/count/${userId}`, {}).then(res => res.data),
        { enabled: !!userId },
    );

    const calculateHolidays = useMutation<HolidayModelList, AxiosError, HolidayPayload>(payload => {
        return lambdaApi.post(`/${payload.userId}/holidays/${payload.year}`).then(res => res.data);
    });

    const total = holidayCount?.total ?? 0;
    const used = holidayCount?.used ?? 0;
    const remaining = total - used;
    const isLoading = countLoading && totalLoading;

    return {
        isLoading,
        holidayCount: holidayCount ?? {},
        totalHolidays: total,
        usedHolidays: used,
        remainingHolidays: remaining < 0 ? 0 : remaining,
        holidays: data ?? [],
        calculateHolidays,
    };
}
