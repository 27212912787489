import './SignInGate.css';

import styled from '@emotion/styled';
import { Button, Content, FlexboxGrid } from 'rsuite';

import circlesLogo from '../assets/circleslogo.png';
import { gotCognitoSession, launchSigninFlow } from '../auth/cognito';
import { envConfig } from '../config';

const StyledLogoArea = styled.div`
    height: 200px;
    width: 200px;
    overflow: show;
`;

const SignInGate = ({ children }: { children: JSX.Element }) => {
    const loginState = gotCognitoSession() ? 'in' : 'out';

    if (loginState === 'in') {
        return children;
    }
    return (
        <Content style={{ height: '100%', marginTop: '35vh' }}>
            <FlexboxGrid style={{ height: '100%' }} justify="center">
                <FlexboxGrid.Item style={{ paddingRight: '100px' }}>
                    <StyledLogoArea>
                        <img
                            alt="logo"
                            src={circlesLogo}
                            style={{
                                animation: 'rotation 180s infinite linear',
                                display: 'inline-block',
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    </StyledLogoArea>
                    <div
                        style={{
                            position: 'relative',
                            left: '100px',
                            top: '-128px',
                            textAlign: 'right',
                        }}
                    >
                        <Button size="md" onClick={launchSigninFlow}>
                            Sign In
                        </Button>
                        <h1
                            style={{
                                fontFamily: 'Poppins, sans-serif',
                                marginTop: 0,
                                fontSize: 46,
                            }}
                        >
                            <span style={{ fontWeight: 900 }}>My</span>
                            <span style={{ fontWeight: 500 }}>Circles</span>
                        </h1>
                        <h3>{envConfig.app.stage !== 'production' && envConfig.app.stage}</h3>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Content>
    );
};

export default SignInGate;
