import { useInternalProjectTypes } from 'hooks/useInternalProjectTypes';
import { useParams } from 'react-router-dom';
import { Col, FlexboxGrid, Grid, Loader, Panel, PanelGroup, Tag } from 'rsuite';

import { useProfile } from '../../hooks/useProfile';
import { EditInternalProjectTypeDialog } from '../dialogs/EditInternalProjectTypeDialog';

const InternalProjectTypeDetailsScreen = () => {
    const { isAdmin } = useProfile();
    const { typeId } = useParams();

    const { internalProjectTypes } = useInternalProjectTypes();

    if (!typeId) return <h2>Project type not found</h2>;
    const projectType = internalProjectTypes.find(ipt => ipt.id === +typeId);

    return projectType ? (
        <>
            <Grid fluid>
                <Col xs={24}>
                    <Tag>
                        <h3>Internal Project Type</h3>
                    </Tag>
                    <Panel
                        bordered
                        header={
                            <span>
                                <h1 style={{ display: 'inline' }}>{projectType.name}</h1>{' '}
                            </span>
                        }
                        style={{ marginBottom: '24px' }}
                    >
                        <FlexboxGrid
                            justify="space-between"
                            align="middle"
                            style={{ paddingTop: 20 }}
                        >
                            {isAdmin && (
                                <EditInternalProjectTypeDialog internalProjectType={projectType} />
                            )}
                        </FlexboxGrid>
                    </Panel>
                    <PanelGroup accordion defaultActiveKey={1} bordered>
                        <Panel eventKey={1} header={<h3>About</h3>}>
                            <h3>Description</h3>
                            <p className="circle-description">{projectType.description}</p>
                        </Panel>
                    </PanelGroup>
                </Col>
            </Grid>
        </>
    ) : (
        <Loader />
    );
};

export default InternalProjectTypeDetailsScreen;
