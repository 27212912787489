import { FlexboxGrid } from 'rsuite';

type DataBoxType = {
    title: string;
    value?: string | number;
};

const DataBox = ({ title, value }: DataBoxType) => {
    const valueColor = typeof value === 'number' && value < 0 ? 'red' : 'black';
    const formatter = new Intl.NumberFormat('fi-FI');
    return (
        <div>
            <FlexboxGrid align="middle" style={{ paddingRight: 30 }}>
                <FlexboxGrid.Item style={{ padding: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>{title}</span>
                    <br />
                    {isNaN(Number(value)) ? (
                        <span>{value}</span>
                    ) : (
                        <span style={{ color: valueColor }}>{formatter.format(Number(value))}</span>
                    )}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};

export default DataBox;
