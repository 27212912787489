import * as z from 'zod';

export const WikiSchema = z.object({
    text: z.string(),
    title: z.string(),
});

export const EditWikiSchema = WikiSchema.extend({
    id: z.preprocess(val => (Number(val) ? Number(val) : undefined), z.number().optional()),
});

export type WikiSchemaType = z.infer<typeof WikiSchema>;
export type WikiEditSchemaType = z.infer<typeof EditWikiSchema>;
