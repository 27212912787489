import WeightHistoryChart from 'components/charts/WeightHistoryChart';
import { Weight } from 'components/Formats';
import { CircleModel } from 'generated-types/circle';
import { FlexboxGrid, Panel } from 'rsuite';

export const WeightTab = ({ circle }: { circle: CircleModel }) => {
    return (
        <>
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item>
                    <h3>Current weight {Weight(circle.current_weight)} €</h3>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <Panel style={{ maxWidth: 900 }}>
                <h4>Weight History</h4>
                <WeightHistoryChart circleId={circle.id} />
            </Panel>
        </>
    );
};
