import { AssignmentModelList } from 'generated-types/assignment';
import { CircleModelList } from 'generated-types/circle';
import { CustomerProjectList, ProjectList } from 'generated-types/customer_project';
import { GracePeriodList } from 'generated-types/grace_period';
import { InternalProjectList } from 'generated-types/internal_project';
import { LeaveTypeModel } from 'generated-types/leave_type';
import { MemberList } from 'generated-types/member';
import { TimeTrackModelList } from 'generated-types/timetrack';
import { TimeTrackQuery } from 'hooks/useTimeTracks';
import { RefAttributes, useState } from 'react';
import { Button, Table, TableProps } from 'rsuite';
import { InnerCellProps } from 'rsuite-table/lib/Cell';

import { useAssignments } from '../../hooks/admin/useAssignments';
import { useCircles } from '../../hooks/useCircles';
import { useCustomerProjects } from '../../hooks/useCustomerProjects';
import { useInternalProjects } from '../../hooks/useInternalProjects';
import { useLeaveTypes } from '../../hooks/useLeaveTypes';
import { useMembers } from '../../hooks/useMembers';
import { useMyAssignments } from '../../hooks/useMyAssignments';
import { useMyCustomerProjects } from '../../hooks/useMyCustomerProjects';
import findValidGracePeriods from '../../utils/findValidGracePeriods';
import { formatDate } from '../../utils/formatters';
import EditTimeTrackDialog from '../dialogs/EditTimeTrackDialog';
import { EurosCell, NumberCell } from '../Formats';

const { Column, HeaderCell, Cell } = Table;

type Props = {
    adminMode?: boolean;
    data: TimeTrackModelList;
    gracePeriods: GracePeriodList;
    loading?: boolean;
    query: TimeTrackQuery;
    showColumns?: string[];
};

type SortColumn = 'date' | 'hours' | '__memberName' | 'billing_rate' | 'is_confirmed' | 'is_locked';

type TableData = {
    id: number;
    member_id: number;
    internal_project_id?: number;
    assignment_id?: number;
    location?: 'Office' | 'Customer' | 'Remote';
    billing_rate: number;
    comment?: string;
    date: string;
    hours: number;
    is_confirmed: boolean;
    is_locked: boolean;
    leave_type?: LeaveTypeModel;
    __memberName: string;
    __image_url: string;
    __projectName?: string;
};

type OptionsData = {
    optionsdata: {
        members: MemberList;
        circles: CircleModelList;
        internalProjects: InternalProjectList;
        customerProjects: CustomerProjectList;
        assignments: AssignmentModelList;
    };
};

const TimeTrackTable = ({
    adminMode = false,
    data,
    gracePeriods = [],
    loading = false,
    showColumns,
}: Props) => {
    const [sortColumn, setSortColumn] = useState<SortColumn>('date');
    const [sortType, setSortType] = useState<TableProps['sortType']>('desc');

    const { assignments } = useAssignments();
    const { myAssignments } = useMyAssignments();
    const { circles } = useCircles();
    const { members } = useMembers();
    const { internalProjects } = useInternalProjects();
    const { myCustomerProjects } = useMyCustomerProjects();
    const { customerProjects } = useCustomerProjects();
    const { leaveTypes } = useLeaveTypes();

    const projects = adminMode ? customerProjects : myCustomerProjects;
    const assing = adminMode ? assignments : myAssignments;

    const setSort = (sortColumn: SortColumn) => {
        setSortColumn(sortColumn);
        if (sortType === 'asc') {
            setSortType('desc');
        } else {
            setSortType('asc');
        }
    };

    const getTableData = () => {
        const tableData: TableData[] = data.map(timetrack => {
            const member = members.find(member => member.id === timetrack.member_id);
            const projectId = assing.find(
                assignment => assignment.id === timetrack.assignment_id,
            )?.project_id;
            return {
                id: timetrack.id,
                member_id: member?.id || 0,
                internal_project_id: timetrack?.internal_project_id,
                assignment_id: timetrack?.assignment_id,
                location: timetrack.location,
                __image_url: member?.image_url || '',
                __memberName: `${member?.lastname ?? ''} ${member?.firstname ?? ''}`,
                __projectName:
                    (projects as ProjectList).find(project => project.id === projectId)?.name ||
                    internalProjects.find(project => project.id === timetrack.internal_project_id)
                        ?.name ||
                    `Assignment #${timetrack.assignment_id}`,
                billing_rate: timetrack?.billing_rate || 0,
                comment: timetrack?.comment,
                date: timetrack.date,
                hours: timetrack.hours,
                is_confirmed: timetrack?.is_confirmed || false,
                is_locked: timetrack?.is_locked || false,
                leave_type: leaveTypes.find(leave => leave.id === timetrack?.leave_type),
            };
        });

        tableData.sort((a, b) => {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
        });
        sortType === 'desc' && tableData.reverse();
        return tableData;
    };

    const ImageCell = (
        props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
    ) => (
        <Cell {...props} style={{ padding: 0 }}>
            <div
                style={{
                    width: 40,
                    height: 40,
                    background: '#f5f5f5',
                    borderRadius: 20,
                    marginTop: 2,
                    marginLeft: 10,
                    marginRight: 0,
                    marginBottom: 2,
                    overflow: 'hidden',
                    display: 'inline-block',
                }}
            >
                {props.dataKey && (
                    <img
                        alt="avatar"
                        referrerPolicy="no-referrer"
                        src={props.rowData[props.dataKey]}
                        width="42"
                    />
                )}
            </div>
        </Cell>
    );

    const DateCell = (
        props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
    ) => {
        return (
            <Cell {...props}>
                <span>{formatDate(props.rowData.date)}</span>&nbsp;
            </Cell>
        );
    };

    const ProjectAndCircleCell = (
        props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
    ) => {
        if (props.rowData?.leave_type) {
            return (
                <Cell {...props}>
                    <span>{props.rowData.leave_type.name}</span>
                </Cell>
            );
        }

        return (
            <Cell {...props}>
                <span>{props.rowData.__projectName}</span>
            </Cell>
        );
    };

    const StatusCell = (
        props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
    ) => {
        const gps = findValidGracePeriods(gracePeriods, props.rowData);
        const status = !props.rowData.is_locked ? 'No' : gps.length ? 'No (Grace period)' : 'Yes';
        return <Cell {...props}>{status}</Cell>;
    };

    const ConfirmedCell = (
        props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
    ) => {
        const status = !props.rowData.is_confirmed ? 'No' : 'Yes';
        return <Cell {...props}>{status}</Cell>;
    };

    const ActionCell = (
        props: JSX.IntrinsicAttributes &
            InnerCellProps &
            RefAttributes<HTMLDivElement> &
            OptionsData,
    ) => {
        const { rowData, optionsdata } = props;
        const isLocked =
            (!adminMode &&
                props.rowData.is_locked &&
                !findValidGracePeriods(gracePeriods, rowData).length) ||
            rowData.leave_type;

        return (
            <Cell {...props}>
                {isLocked ? (
                    <span>&nbsp;</span>
                ) : (
                    <EditTimeTrackDialog existingTimetrack={rowData} optionsData={optionsdata}>
                        <Button appearance="primary" size="xs">
                            Edit
                        </Button>
                    </EditTimeTrackDialog>
                )}
            </Cell>
        );
    };

    return (
        <Table
            autoHeight
            sortColumn={sortColumn}
            sortType={sortType}
            data={getTableData()}
            loading={loading}
            onSortColumn={setSort as (sortColumn: string) => void}
            wordWrap
        >
            {(!showColumns || showColumns.includes('memberAvatar')) && (
                <Column align="left" width={60} key="memberAvatar">
                    <HeaderCell> </HeaderCell>
                    <ImageCell dataKey="__image_url" />
                </Column>
            )}
            {(!showColumns || showColumns.includes('memberName')) && (
                <Column align="left" sortable width={120} key="memberName">
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="__memberName" />
                </Column>
            )}
            {(!showColumns || showColumns.includes('projectName')) && (
                <Column align="left" width={220} key="projectName">
                    <HeaderCell>Project / Circle / Absence</HeaderCell>
                    <ProjectAndCircleCell />
                </Column>
            )}
            {(!showColumns || showColumns.includes('date')) && (
                <Column align="right" sortable width={150} key="date">
                    <HeaderCell>Date</HeaderCell>
                    <DateCell dataKey="date" />
                </Column>
            )}
            {(!showColumns || showColumns.includes('hours')) && (
                <Column align="right" sortable width={100} key="hours">
                    <HeaderCell>Hours</HeaderCell>
                    <NumberCell dataKey="hours" />
                </Column>
            )}
            {(!showColumns || showColumns.includes('billingRate')) && (
                <Column align="left" sortable width={120} key="billingRate">
                    <HeaderCell>Billing Rate</HeaderCell>
                    <EurosCell dataKey="billing_rate" />
                </Column>
            )}
            {(!showColumns || showColumns.includes('comment')) && (
                <Column align="left" flexGrow={10} key="comment" minWidth={120}>
                    <HeaderCell>Comment</HeaderCell>
                    <Cell dataKey="comment" />
                </Column>
            )}
            {(!showColumns || showColumns.includes('status')) && (
                <Column align="center" sortable key="status">
                    <HeaderCell>Locked</HeaderCell>
                    <StatusCell dataKey="is_locked" />
                </Column>
            )}
            {(!showColumns || showColumns.includes('confirmed')) && (
                <Column align="center" sortable key="confirmed">
                    <HeaderCell>Confirmed</HeaderCell>
                    <ConfirmedCell dataKey="is_confirmed" />
                </Column>
            )}
            {(!showColumns || showColumns.includes('actions')) && (
                <Column width={80} key="actions">
                    <HeaderCell align="center">Actions</HeaderCell>
                    <ActionCell
                        align="center"
                        optionsdata={{
                            members: members,
                            circles: circles,
                            internalProjects: internalProjects,
                            customerProjects: customerProjects,
                            assignments: assignments,
                        }}
                    />
                </Column>
            )}
        </Table>
    );
};
export default TimeTrackTable;
