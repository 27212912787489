import * as z from 'zod';

export const degreeMaxLength = 100;
export const educationDescriptionMaxLength = 500;

export const NewEducationForm = z.object({
    degree: z.string().min(1).max(degreeMaxLength),
    description: z.string().min(1).max(educationDescriptionMaxLength),
    end_date: z.date().optional().nullable(),
});

export const EditEducationForm = NewEducationForm.extend({
    id: z.string(),
});

export type NewEducationFormType = z.infer<typeof NewEducationForm>;
export type EditEducationFormType = z.infer<typeof EditEducationForm>;
