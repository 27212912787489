import { useInternalProjectTypes } from 'hooks/useInternalProjectTypes';
import { useParams } from 'react-router-dom';
import { Button, Col, FlexboxGrid, Grid, Panel, PanelGroup, Tag } from 'rsuite';

import { useCircles } from '../../hooks/useCircles';
import { useInternalProjects } from '../../hooks/useInternalProjects';
import { useProfile } from '../../hooks/useProfile';
import DataBox from '../DataBox';
import EditInternalProjectDialog from '../dialogs/EditInternalProjectDialog';

const InternalProjectDetailsScreen = () => {
    const { isAdmin } = useProfile();
    const { projectId } = useParams();
    const { internalProjectTypes } = useInternalProjectTypes();

    const { internalProjects } = useInternalProjects({ projectId: Number(projectId) });

    const project = internalProjects?.[0];

    const { circles } = useCircles({ circleId: project?.circle_id });
    const circle = circles?.[0];
    const projectType = internalProjectTypes.find(t => t.id === project?.type_id);

    if (!project) return <h2>Project not found</h2>;

    return (
        <Grid fluid>
            <Col xs={24}>
                <Tag>
                    <h3>Internal Project</h3>
                </Tag>
                <Panel
                    bordered
                    header={
                        <span>
                            <h1 style={{ display: 'inline' }}>{project.name}</h1>{' '}
                        </span>
                    }
                    style={{ marginBottom: '24px' }}
                >
                    <FlexboxGrid justify="space-between" align="middle">
                        <DataBox title="Circle" value={circle?.name} />
                        <DataBox title="Project Type" value={projectType?.name ?? ''} />
                        <DataBox title="Billable" value={project.is_billable ? 'Yes' : 'No'} />
                    </FlexboxGrid>
                    <FlexboxGrid justify="space-between" align="middle" style={{ paddingTop: 20 }}>
                        {isAdmin ? (
                            <EditInternalProjectDialog editable={project}>
                                <Button appearance="primary">Edit</Button>
                            </EditInternalProjectDialog>
                        ) : null}
                    </FlexboxGrid>
                </Panel>
                <PanelGroup accordion defaultActiveKey={1} bordered>
                    <Panel eventKey={1} header={<h3>About</h3>}>
                        <h3>Description</h3>
                        <p className="circle-description">{project.description}</p>
                    </Panel>
                </PanelGroup>
            </Col>
        </Grid>
    );
};

export default InternalProjectDetailsScreen;
