import { CircleModel, CircleModelList } from '../generated-types/circle';
type ArrayToMapType = {
    [key: number]: CircleModel;
};

export const arrayToMap = (
    arr: CircleModelList,
    keySelector = (item: CircleModel) => item.id,
    itemSelector = (item: CircleModel) => item,
) =>
    arr
        ? arr.reduce((prev: ArrayToMapType, item: CircleModel) => {
              prev[keySelector(item)] = itemSelector(item);
              return prev;
          }, {})
        : {};

export const arraysAreEqual = (arrA: string[] | number[], arrB: string[] | number[]) => {
    return (
        JSON.stringify([...arrA].sort((a, b) => Number(a) - Number(b))) ===
        JSON.stringify([...arrB].sort((a, b) => Number(a) - Number(b)))
    );
};
