import { Component } from 'react';
import {
    Button,
    Checkbox,
    DatePicker,
    FlexboxGrid,
    Form,
    Input,
    InputNumber,
    Loader,
    Modal,
    SelectPicker,
    Toggle,
} from 'rsuite';

import ConfirmationDialog from '../dialogs/ConfirmationDialog';

/*
 *   DynamicEditDialog
 */

export default class DynamicEditDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formError: {},
        };
    }

    static getDerivedStateFromProps(props, state) {
        const prevProps = state.prevProps || { editable: {} };

        // Deep compare props to prevProps.
        const gotNewProps =
            Object.keys(props.fields).reduce((changed = false, fieldName) => {
                if (changed) {
                    return true;
                }
                if (prevProps.editable[fieldName] !== props.editable[fieldName]) {
                    return true;
                }
                return false;
            }, false) || !state.editable;

        let newEditable = {};
        if (gotNewProps) {
            Object.keys(props.fields).forEach(fieldName => {
                const field = props.fields[fieldName];
                if (props.editable[fieldName] !== undefined) {
                    newEditable[fieldName] = props.editable[fieldName];
                    // console.log('field', field, props.editable[fieldName]);
                    if (['date', 'popup-date'].includes(field.type)) {
                        newEditable[fieldName] =
                            props.editable[fieldName] == null
                                ? undefined
                                : new Date(props.editable[fieldName]);
                    }
                } else if (field.default) {
                    // Otherwise check if a default value is provided.
                    newEditable[fieldName] = field.default;
                } else {
                    // Failing that set the default value for the field type.
                    switch (field.type) {
                        case 'buttonWithConfirmation':
                            break;
                        case 'hidden':
                            newEditable[fieldName] = null;
                            break;
                        case 'text':
                            newEditable[fieldName] = '';
                            break;
                        case 'number':
                            newEditable[fieldName] = 0;
                            break;
                        case 'textarea':
                            newEditable[fieldName] = '';
                            break;
                        case 'select':
                            newEditable[fieldName] = '';
                            break;
                        case 'checkbox':
                            newEditable[fieldName] = false;
                            break;
                        case 'date':
                            newEditable[fieldName] = new Date();
                            break;
                        case 'popup-date':
                            newEditable[fieldName] = null;
                            break;
                        default:
                            throw new Error('Unrecognized field type: ' + field.type);
                    }
                }
            });
            // Pick up id too to if available. Can be used to determine wether to patch or create when saving data.
            if (props.editable.id) {
                newEditable.id = props.editable.id;
            }
        } else {
            newEditable = state.editable;
        }

        return {
            ...state,
            editable: newEditable,
            prevProps: props,
        };
    }

    getInputElement = fieldName => {
        const field = this.props.fields[fieldName];
        const disabled =
            field.disabled ||
            (this.props.disabledFields && this.props.disabledFields.indexOf(fieldName) > -1);

        switch (field.type) {
            case 'hidden':
                return '';
            case 'buttonWithConfirmation':
                return (
                    <Form.Group key={fieldName}>
                        <ConfirmationDialog
                            handleOK={field.handler}
                            header={field.confirmationHeader}
                            message={field.confirmationMessage}
                        >
                            <Button
                                block
                                color={field.color}
                                disabled={field.disabled}
                                onClick={field.handler}
                            >
                                {field.label}
                            </Button>
                        </ConfirmationDialog>
                    </Form.Group>
                );
            case 'text':
                return (
                    <Form.Group key={fieldName}>
                        <Form.ControlLabel>{field.label}</Form.ControlLabel>
                        <Input
                            // autoFocus={field.autofocus}
                            disabled={disabled}
                            // label={field.label}
                            // type="text"
                            value={this.state.editable[fieldName]}
                            onChange={(newValue, event) => {
                                this.handleFieldChange({ newValue, event, fieldName });
                            }}
                        />
                    </Form.Group>
                );
            case 'number':
                // https://rsuitejs.com/en/components/input-number
                return (
                    <Form.Group key={fieldName}>
                        <Form.ControlLabel>{field.label}</Form.ControlLabel>
                        <InputNumber
                            // cleanable={field.clearable || false}
                            id={`field-${fieldName}`}
                            // autoFocus={field.autofocus}
                            defaultValue={field.default}
                            disabled={disabled}
                            // label={field.label}
                            // type="number"
                            max={field.max}
                            min={field.min}
                            step={field.step}
                            value={this.state.editable[fieldName]}
                            onChange={(newValue, event) => {
                                this.handleFieldChange({ newValue, event, fieldName });
                            }}
                        />
                    </Form.Group>
                );
            case 'textarea':
                return (
                    <Form.Group key={fieldName}>
                        <Form.ControlLabel>{field.label}</Form.ControlLabel>
                        <Input
                            as="textarea"
                            disabled={disabled}
                            type="text"
                            value={this.state.editable[fieldName] || ''}
                            onChange={(newValue, event) => {
                                this.handleFieldChange({ newValue, event, fieldName });
                            }}
                            rows={field.rows || 3}
                        />
                    </Form.Group>
                );

            case 'select':
                return (
                    <Form.Group key={fieldName}>
                        <Form.ControlLabel>{field.label}</Form.ControlLabel>
                        <SelectPicker
                            block
                            cleanable={field.clearable || false}
                            data={field.options}
                            // autoFocus={field.autofocus}
                            disabled={disabled}
                            disabledItemValues={field.disabledItemValues}
                            // label={field.label}
                            onChange={(newValue, event) => {
                                this.handleFieldChange({ newValue, event, fieldName });
                            }}
                            searchable={field.searchable}
                            value={this.state.editable[fieldName]}
                            groupBy={field.groupBy}
                        />
                    </Form.Group>
                );

            case 'checkbox':
                return (
                    // https://rsuitejs.com/en/components/checkbox
                    <Form.Group key={fieldName}>
                        <Checkbox
                            key={fieldName}
                            // autoFocus={field.autofocus}
                            disabled={disabled}
                            checked={this.state.editable[fieldName]}
                            onChange={(newValue, checked, event) => {
                                // R-suite checkbox won't pass a new value as it should. But it passes the checked value.
                                this.handleFieldChange({ newValue: checked, event, fieldName });
                            }}
                        >
                            {field.label}
                        </Checkbox>
                    </Form.Group>
                );

            case 'toggle':
                return (
                    // https://rsuitejs.com/en/components/toggle
                    <Toggle
                        key={fieldName}
                        // autoFocus={field.autofocus}
                        disabled={disabled}
                        checked={this.state.editable[fieldName]}
                        checkedChildren={field.checkedChildren}
                        unCheckedChildren={field.unCheckedChildren}
                        onChange={(newValue, checked, event) => {
                            // R-suite checkbox won't pass a new value as it should. But it passes the checked value.
                            this.handleFieldChange({ newValue: checked, event, fieldName });
                        }}
                    ></Toggle>
                );

            case 'date':
                return (
                    <Form.Group key={fieldName}>
                        <Form.ControlLabel>{field.label}</Form.ControlLabel>
                        <DatePicker
                            inline
                            block
                            cleanable={false}
                            disabled={disabled}
                            isoWeek
                            // autoFocus={field.autofocus}
                            // label={field.label}
                            value={this.state.editable[fieldName]}
                            disabledDate={date => {
                                return date > field.max || date < field.min;
                            }}
                            onChangeCalendarDate={(newValue, event) => {
                                this.handleFieldChange({ newValue, event, fieldName });
                            }}
                            onClean={(newValue, event) => {
                                this.handleFieldChange({ newValue: null, event, fieldName });
                            }}
                        />
                    </Form.Group>
                );
            case 'popup-date':
                return (
                    <Form.Group key={fieldName}>
                        <Form.ControlLabel>{field.label}</Form.ControlLabel>
                        <DatePicker
                            block
                            cleanable={field.clearable || false}
                            disabled={disabled}
                            isoWeek
                            // autoFocus={field.autofocus}
                            // label={field.label}
                            value={this.state.editable[fieldName]}
                            disabledDate={date => {
                                return date > field.max || date < field.min;
                            }}
                            onChangeCalendarDate={(newValue, event) => {
                                this.handleFieldChange({ newValue, event, fieldName });
                            }}
                            onClean={(newValue, event) => {
                                this.handleFieldChange({ newValue: null, event, fieldName });
                            }}
                            placeholder={field.placeholder ? field.placeholder : undefined}
                            oneTap
                        />
                    </Form.Group>
                );

            default:
                throw new Error('Unrecognized field type: ' + field.type);
        }
    };

    handleFieldChange = params => {
        const { newValue, fieldName } = params;
        let editableProp = fieldName;
        const editablePatch = {};

        editablePatch[editableProp] = newValue;
        this.setState({ editable: { ...this.state.editable, ...editablePatch } });
    };

    handleDelete = () => {
        this.props.deleteHandler({ id: this.state.editable.id });
    };

    handleOK = () => {
        // Pick only editable fields and the ID
        const patchData = { id: this.state.editable.id };
        Object.keys(this.props.fields).forEach(field => {
            // A buttonWithConfirmation 'field' does not contain data. So we don't try to add that property to the patchData.
            if (this.props.fields[field].type !== 'buttonWithConfirmation') {
                patchData[field] = this.state.editable[field];
            }
            if (this.props.fields[field].type === 'date') {
                patchData[field] = new Date(this.state.editable[field]).toISOString().substr(0, 10);
            }
            if (this.props.fields[field].type === 'select') {
                if (this.state.editable[field] === '') {
                    patchData[field] = null;
                }
            }
            if (
                this.props.fields[field].type === 'date' ||
                this.props.fields[field].type === 'popup-date'
            ) {
                if (typeof this.state.editable[field]?.toISOString === 'function') {
                    patchData[field] = this.state.editable[field].toISOString().substring(0, 10);
                }
            }
        });
        this.props.saveDataHandler(patchData);
    };

    render() {
        const formIsValid = () => {
            if (!this.props.requiredFields) {
                return true;
            }
            for (const r of this.props.requiredFields) {
                if (
                    this.state.editable[r] === null ||
                    this.state.editable[r] === undefined ||
                    this.state.editable[r] === '' ||
                    this.state.editable[r] === 0
                ) {
                    return false;
                }
            }

            return true;
        };
        return (
            <Modal
                open={this.props.isOpen || false}
                onClose={this.props.closeHandler}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>{this.props.dialogHeader || 'No dialogHeader prop!'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {Object.keys(this.props.fields).map(key => {
                            return this.getInputElement(key);
                        })}
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{ paddingTop: 20 }}>
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item colspan={6} style={{ textAlign: 'start' }}>
                            {this.props.deleteHandler && (
                                <Button
                                    style={{ width: '70px', height: '36px' }}
                                    disabled={
                                        this.props.loading &&
                                        Object.keys(this.props.loading).some(
                                            key => !!this.props.loading[key],
                                        )
                                    }
                                    color="red"
                                    appearance="primary"
                                    onClick={this.handleDelete}
                                >
                                    {this.props.loading?.delete ? <Loader /> : 'Delete'}
                                </Button>
                            )}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colSpan={6}>
                            <Button
                                style={{ width: '75px', height: '36px' }}
                                onClick={this.props.closeHandler}
                                disabled={
                                    this.props.loading &&
                                    Object.keys(this.props.loading).some(
                                        key => !!this.props.loading[key],
                                    )
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{ width: '60px', height: '36px' }}
                                appearance="primary"
                                disabled={
                                    !formIsValid() ||
                                    (this.props.loading &&
                                        Object.keys(this.props.loading).some(
                                            key => !!this.props.loading[key],
                                        ))
                                }
                                onClick={this.handleOK}
                            >
                                {this.props.loading?.save ? <Loader /> : 'Save'}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}
