import { Control, Controller } from 'react-hook-form';
import Button from 'rsuite/esm/Button';
import ButtonGroup from 'rsuite/esm/ButtonGroup';
import { WorkTimeTrackType } from 'schema/timetracks';

import { SetValue } from './Types';

type Props = {
    control: Control<WorkTimeTrackType>;
    setValue: SetValue;
    timetrackNotEditable: boolean | undefined;
};

export function LocationSelector({ control, setValue, timetrackNotEditable }: Props) {
    return (
        <Controller
            name="location"
            control={control}
            render={({ field }) => (
                <ButtonGroup>
                    <Button
                        onClick={() => {
                            setValue('location', 'Office');
                        }}
                        appearance={field.value === 'Office' ? 'primary' : 'default'}
                        disabled={timetrackNotEditable}
                    >
                        Office
                    </Button>
                    <Button
                        onClick={() => {
                            setValue('location', 'Remote');
                        }}
                        appearance={field.value === 'Remote' ? 'primary' : 'default'}
                        disabled={timetrackNotEditable}
                    >
                        Remote
                    </Button>
                    <Button
                        onClick={() => {
                            setValue('location', 'Customer');
                        }}
                        appearance={field.value === 'Customer' ? 'primary' : 'default'}
                        disabled={timetrackNotEditable}
                    >
                        Customer
                    </Button>
                </ButtonGroup>
            )}
        />
    );
}
