export function HolidayCalculationDefinition() {
    return (
        <div>
            <p>
                Agreed rules and formalization, regarding vacation calculations, based on discussion
                with Markus Säteri. Date: 26.10.2022
            </p>

            <ul>
                <li>Työssäolopäivä = Workday</li>
                <li>Lomaan oikeuttava poissaolopäivä = Vacation-entitled-leave-days / VELD</li>
                <li>Täysi lomanmääräytymiskuukausi = Vacation month</li>
                <li>
                    Lomanmääräytymisvuosi = Vacation-determitation-year / VDY / 1.4.xxxx -
                    31.3.xxxx+1
                </li>
            </ul>

            <p>Workday is counted, when there are any billable hours during a day which entitle.</p>

            <p>
                VELD is counted, when the day contains any leave-hours which are determined to
                accrue holidays, such as paid leave, paternity leave, etc.
            </p>

            <p>If you have 14 workdays or VELDs in a month, it entitles you for a FVM.</p>

            <p>1 vacation month entitles you for 2 or 2.5 days of vacation..</p>

            <p>
                If you have been employed for more than a year before the end of VDY, you will
                receive 2.5 vacation days per month. Otherwise, you will earn 2 days per VDY.
            </p>

            <p>
                If the holiday days dont add up to a round number, the number is rounded to larger
                next integer
            </p>

            <p>
                Work done on a public-holiday or on a weekend, does not count as workday regarding
                holiday calculations.
            </p>
        </div>
    );
}
