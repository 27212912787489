import TagLockIcon from '@rsuite/icons/TagLock';
import { RefAttributes, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableProps } from 'rsuite';
import { InnerCellProps } from 'rsuite-table/lib/Cell';

import { CustomerList } from '../../generated-types/customer';
import { CustomerProjectList, CustomerProjectModel } from '../../generated-types/customer_project';
import { useGracePeriods } from '../../hooks/admin/useGracePeriods';
import { useMyGracePeriods } from '../../hooks/useMyGracePeriods';
import ProjectGracePeriodAlertIcon from '../misc/ProjectGracePeriodAlertIcon';

const { Column, HeaderCell, Cell } = Table;

type Props = {
    inAdminView?: boolean;
    customerProjects: CustomerProjectList;
    customers: CustomerList;
};

type TableData = {
    id: number;
    name: string;
    customerName: string;
    is_active: boolean;
};

const CustomerProjectTable = (props: Props) => {
    const { customerProjects, customers, inAdminView } = props;
    const [sortColumn, setSortColumn] = useState<keyof TableData>('name');
    const [sortType, setSortType] = useState<TableProps['sortType']>('desc');
    const navigate = useNavigate();
    const { myGracePeriods } = useMyGracePeriods();
    const { gracePeriods } = useGracePeriods();

    const NameCell = (
        props: JSX.IntrinsicAttributes & InnerCellProps & RefAttributes<HTMLDivElement>,
    ) => (
        <Cell {...props}>
            {!props.rowData.is_active && (
                <>
                    <TagLockIcon style={{ color: 'red' }} /> &nbsp;
                </>
            )}
            {props.rowData.name}
            {
                <ProjectGracePeriodAlertIcon
                    gracePeriods={inAdminView ? gracePeriods : myGracePeriods}
                    customerProjectId={props.rowData.id}
                />
            }
        </Cell>
    );

    function setSort(sortColumn: keyof TableData) {
        setSortColumn(sortColumn);
        if (sortType === 'asc') {
            setSortType('desc');
        } else {
            setSortType('asc');
        }
    }

    const getTableData = () => {
        const tableData: TableData[] = customerProjects.map((project: CustomerProjectModel) => {
            return {
                id: project.id,
                name: project.name,
                customerName:
                    customers.find(customer => customer.id === project.customer_id)?.name || '',
                is_active: project?.is_active || true,
                assignees: project.assignees,
            };
        });

        tableData.sort((a, b) => {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
        });
        sortType === 'desc' && tableData.reverse();
        return tableData;
    };

    return (
        <Table
            autoHeight
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={setSort as (sortColumn: string) => void}
            data={getTableData()}
            onRowClick={row => {
                inAdminView && navigate(`/customerprojects/${row.id}`);
            }}
        >
            <Column align="left" fixed flexGrow={1} minWidth={160} sortable>
                <HeaderCell>Name</HeaderCell>
                <NameCell dataKey="name" />
            </Column>
            <Column align="left" flexGrow={1} sortable>
                <HeaderCell>Customer</HeaderCell>
                <Cell dataKey="customerName" />
            </Column>
            <Column align="left" flexGrow={1} sortable>
                <HeaderCell>Assigned to</HeaderCell>
                <Cell dataKey="assignees" />
            </Column>
        </Table>
    );
};

export default CustomerProjectTable;
