import { CloseFn, ExpandableForm } from 'components/misc/ExpandableForm';
import { SkillsEducationModelList } from 'generated-types/skills/education';
import { Loader, Panel } from 'rsuite';

import { Education } from './Education';
import { EducationAddForm } from './EducationAddForm';

export const Educations = ({
    editAllowed,
    educations,
    educationsLoading,
}: {
    editAllowed: boolean;
    educations: SkillsEducationModelList;
    educationsLoading: boolean;
}) => {
    return (
        <Panel bordered style={{ marginBottom: 20 }} header={<h3>Education</h3>}>
            {educationsLoading ? (
                <Loader content={'Educations are loading...'} />
            ) : educations.length === 0 ? (
                <p>No educations added yet</p>
            ) : (
                <>
                    {educations.map(education => (
                        <Education
                            editAllowed={editAllowed}
                            education={education}
                            key={education.id}
                        />
                    ))}
                </>
            )}
            {editAllowed && (
                <ExpandableForm
                    formTitle={'Add Education'}
                    style={{ maxWidth: 800, width: '100%' }}
                    render={(done: CloseFn) => <EducationAddForm onAdded={done} />}
                />
            )}
        </Panel>
    );
};
