import { SkillsMemberCertificationModelList } from 'generated-types/skills/certification';
import { SkillsEducationModelList } from 'generated-types/skills/education';
import { SkillsExperienceModelList, SkillsSkillModelList } from 'generated-types/skills/experience';
import { SkillsMemberModel } from 'generated-types/skills/member';

import { Certifications } from './Certifications';
import { Educations } from './Educations';
import { Experiences } from './Experiences';
import { Profile } from './Profile';
import { Skills } from './Skills';

export const CVEditor = ({
    editAllowed,
    skillsMember,
    memberSkills,
    circlesExperiences,
    otherExperiences,
    educations,
    experiencesLoading,
    educationsLoading,
    skillsLoading,
    memberCertifications,
    certificationsLoading,
}: {
    editAllowed: boolean;
    skillsMember: SkillsMemberModel;
    memberSkills: SkillsSkillModelList;
    circlesExperiences: SkillsExperienceModelList;
    otherExperiences: SkillsExperienceModelList;
    educations: SkillsEducationModelList;
    experiencesLoading: boolean;
    educationsLoading: boolean;
    skillsLoading: boolean;
    memberCertifications: SkillsMemberCertificationModelList;
    certificationsLoading: boolean;
}) => (
    <>
        <Profile member={skillsMember} editAllowed={editAllowed} />
        <Skills
            memberSkills={memberSkills}
            editAllowed={editAllowed}
            skillsLoading={skillsLoading}
        />
        <Experiences
            editAllowed={editAllowed}
            circlesExperiences={circlesExperiences}
            otherExperiences={otherExperiences}
            experiencesLoading={experiencesLoading}
        />
        <Educations
            editAllowed={editAllowed}
            educations={educations}
            educationsLoading={educationsLoading}
        />
        <Certifications
            memberCertifications={memberCertifications}
            certificationsLoading={certificationsLoading}
            editAllowed={editAllowed}
        />
    </>
);
