import add from 'date-fns/add';
import compareAsc from 'date-fns/compareAsc';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isWeekend from 'date-fns/isWeekend';

import { TimeTrackModelList } from '../../../generated-types/timetrack';
import { isHoliday } from '../../../utils/holidays';
import { LoggedEntryType } from './logged-hours-view/getLoggedEntryType';

export const defaultDailyHours = 7.5;
export const defaultLunchBreak = 0.5;

function getExpectedHourCount(startDate: Date, endDate: Date) {
    const daysInBetween = eachDayOfInterval({
        start: startDate,
        end: endDate,
    });
    return daysInBetween
        .map(day => {
            if (isHoliday(day) || isWeekend(day)) {
                return 0;
            } else {
                return Number(defaultDailyHours);
            }
        })
        .reduce((total = 0, day) => {
            return total + day;
        }, 0);
}

type CalculateHoursUpUntilEndDate = {
    customerHours: number;
    internalHours: number;
    leaveHours: number;
};

function calculateHoursUpUntilEndDate(
    timetracks: TimeTrackModelList,
    endDate: Date,
): CalculateHoursUpUntilEndDate {
    const hours = timetracks.map(timetrack => {
        const isInRange = 1 === compareAsc(add(endDate, { days: 1 }), new Date(timetrack.date));
        const current = isInRange ? timetrack.hours : 0;
        const leaveType = timetrack.assignment_id
            ? LoggedEntryType.Customer
            : timetrack.leave_type
            ? LoggedEntryType.Leave
            : LoggedEntryType.Internal;
        return { current, leaveType };
    });

    const customerHours = hours
        .filter(timetrack => timetrack.leaveType === LoggedEntryType.Customer)
        .reduce((total, day) => {
            return total + day.current;
        }, 0);

    const internalHours = hours
        .filter(timetrack => timetrack.leaveType === LoggedEntryType.Internal)
        .reduce((total, day) => {
            return total + day.current;
        }, 0);

    const leaveHours = hours
        .filter(timetrack => timetrack.leaveType === LoggedEntryType.Leave)
        .reduce((total, day) => {
            return total + day.current;
        }, 0);

    return { customerHours, internalHours, leaveHours };
}

/** Accepts days in a month */
export function countHourBalance(timetracks: TimeTrackModelList, endDate: Date) {
    const firstDayOfTheMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

    const { customerHours, internalHours, leaveHours } = calculateHoursUpUntilEndDate(
        timetracks,
        endDate,
    );
    return {
        expectedHours: getExpectedHourCount(firstDayOfTheMonth, endDate),
        actualHours: customerHours + internalHours + leaveHours,
        internalHours,
        customerHours,
        leaveHours,
    };
}

/** Calculate max amount of holidays that can be placed in UI
 * RSuite DateRangePicker can't filter out Sundays, so UI must
 * be given extra days, based on how many Sundays
 */
export function getMaxAllowedHolidaysForUI(remainingHolidays: number) {
    const workweekLengthWithSaturday = 6;
    if (remainingHolidays < workweekLengthWithSaturday) {
        return remainingHolidays;
    }
    return (
        remainingHolidays +
        (remainingHolidays - (remainingHolidays % workweekLengthWithSaturday)) /
            workweekLengthWithSaturday
    );
}
