import { Checkbox, Table } from 'rsuite';

import { useCircles } from '../../hooks/useCircles';
import { useMembers } from '../../hooks/useMembers';
import { useMemberships } from '../../hooks/useMemberships';
import { useProfile } from '../../hooks/useProfile';
import { showErrorNotification, showNotification } from '../ShowNotification';
import { ImageCell } from './common/ImageCell';

const { Column, HeaderCell, Cell } = Table;

type TableData = { [key: string]: string | boolean | number };

type CheckboxCellProps = {
    rowData: TableData;
    dataKey: string;
    circleId: number;
    handleClick: (arg0: number, arg1: number, arg2: boolean) => void;
};

const CheckboxCell = ({ rowData, dataKey, handleClick, circleId, ...props }: CheckboxCellProps) => (
    <Cell {...props} style={{ padding: 0 }}>
        <div
            style={{
                width: 40,
                height: 40,
                marginTop: 2,
                marginLeft: 10,
                marginRight: 0,
                marginBottom: 2,
                overflow: 'hidden',
                display: 'inline-block',
            }}
        >
            <Checkbox
                checked={Boolean(rowData[dataKey])}
                onClick={() => {
                    handleClick(Number(rowData.member_id), circleId, !rowData[dataKey]);
                }}
            ></Checkbox>
        </div>
    </Cell>
);

export function MembershipGrid() {
    const { circles } = useCircles({ sort: { column: 'name', order: 'asc' } });
    const { members } = useMembers(false, true);
    const { addUserToMembership, removeUserFromMembership, circleMemberships } = useMemberships({
        getCircleMemberships: true,
    });
    const { isAdmin } = useProfile();

    const tableData = members.map(m => {
        const base: { [key: string]: string | boolean | number } = {
            __name: m.lastname + ' ' + m.firstname,
            __image_url: m.image_url ?? '',
            is_active: true,
            member_id: m.id,
        };

        circles.forEach(c => {
            const memberList = circleMemberships.data?.[c.id] ?? [];
            base[`${c.id}`] = memberList.includes(m.id);
        });
        return base;
    });

    const toggleMembership = (member_id: number, circle_id: number, checked: boolean) => {
        if (!isAdmin) {
            showNotification({
                header: 'Only admins can edit other users memberships.',
                type: 'warning',
            });

            return;
        }

        if (checked && !members.find(m => m.id === member_id)?.is_active) {
            showNotification({
                header: 'Cannot add inactive member to a circle.',
                type: 'warning',
            });
            return;
        }

        if (checked) {
            addUserToMembership.mutate(
                { userId: member_id, circleId: circle_id },
                {
                    onSuccess: () => {
                        circleMemberships.refetch();
                        showNotification({
                            header: 'User membership added!',
                        });
                    },
                    onError: err => {
                        showErrorNotification(err);
                    },
                },
            );
        } else {
            removeUserFromMembership.mutate(
                { userId: member_id, circleId: circle_id },
                {
                    onSuccess: () => {
                        circleMemberships.refetch().then(() => {
                            showNotification({
                                header: 'User membership removed!',
                            });
                        });
                    },
                    onError: err => {
                        showErrorNotification(err);
                    },
                },
            );
        }
    };

    return (
        <Table
            autoHeight
            sortType={'desc'}
            data={tableData}
            loading={circleMemberships.isRefetching}
        >
            <Column align="left" fixed width={isAdmin ? 75 : 60}>
                <HeaderCell>{''}</HeaderCell>
                <ImageCell dataKey="image_url" showicons={isAdmin ? 'true' : undefined} />
            </Column>
            <Column align="left" fixed sortable width={180}>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="__name" />
            </Column>
            {circles.map(c => {
                return (
                    <Column align="left" width={50} key={c.id}>
                        <HeaderCell>
                            <div
                                style={{
                                    transform: 'rotate(-40deg)',
                                }}
                            >
                                {c.name}
                            </div>
                        </HeaderCell>
                        <CheckboxCell
                            dataKey={`${c.id}`}
                            rowData={tableData[c.id]}
                            circleId={c.id}
                            handleClick={toggleMembership}
                        ></CheckboxCell>
                    </Column>
                );
            })}
        </Table>
    );
}
