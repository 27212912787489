import styled from '@emotion/styled';
import { useHolidays } from 'hooks/useHolidays';

const StyledTotalCell = styled.td`
    text-align: center;
`;

type Props = {
    userId: number;
};

export const TotalUsedAdmin = ({ userId }: Props) => {
    const { totalHolidays, usedHolidays } = useHolidays({ userId: String(userId) });
    return (
        <>
            <StyledTotalCell>{totalHolidays}</StyledTotalCell>
            <StyledTotalCell>{usedHolidays}</StyledTotalCell>
        </>
    );
};

export const TotalUsedRegular = () => {
    return (
        <>
            <StyledTotalCell>0</StyledTotalCell>
            <StyledTotalCell>0</StyledTotalCell>
        </>
    );
};
