import * as z from 'zod';

export const NewCircleSchema = z.object({
    name: z.string(),
    description: z.string().optional(),
    is_active: z.preprocess(val => (val === 'true' ? true : false), z.boolean()),
    drivelink: z
        .string()
        .startsWith('https://drive.google', {
            message: 'Google Drive-links must start with https://drive.google',
        })
        .optional()
        .or(z.literal('')),

    mattermost: z
        .string()
        .startsWith('#', { message: 'Mattermost-channels names must start with #' })
        .optional()
        .or(z.literal('')),
    max_balance: z.preprocess(val => Number(val), z.number().nonnegative().optional()),
    mission: z.string().optional(),
    scribe_id: z.preprocess(val => (Number(val) ? Number(val) : undefined), z.number().optional()),
    facilitator_id: z.preprocess(
        val => (Number(val) ? Number(val) : undefined),
        z.number().optional(),
    ),
    treasurer_id: z.preprocess(
        val => (Number(val) ? Number(val) : undefined),
        z.number().optional(),
    ),
    current_weight: z.preprocess(val => Number(val), z.number().nonnegative().optional()),
});

export const EditCircleSchema = NewCircleSchema.extend({
    id: z.union([z.number(), z.string()]),
});

export type EditCircleSchemaType = z.infer<typeof EditCircleSchema>;
export type NewCircleSchemaType = z.infer<typeof NewCircleSchema>;
