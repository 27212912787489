import { useState } from 'react';
import { Button, Divider, SelectPicker } from 'rsuite';

import { useHolidays } from '../../../hooks/useHolidays';
import { useMembers } from '../../../hooks/useMembers';
import { showNotification } from '../../ShowNotification';
import MemberTable from '../../tables/MemberTable';

const nextYear = new Date().getFullYear() + 1;

const options = [0, 1, 2, 3].map(year => {
    return { value: nextYear - year, label: (nextYear - year).toString() };
});

export function UserHolidays() {
    const [selectedYear, setYear] = useState<number>(nextYear);
    const [selectedUser, setUser] = useState<number | null>(null);
    const [showEmploymentStarts, setShowEmploymentStarts] = useState(false);
    const [showMissingEmployment, setShowMissingEmployment] = useState(false);
    const { members } = useMembers();
    const { calculateHolidays } = useHolidays();

    const hasEmploymentStart = members.filter(m => m.employment_start);

    const missingEmploymentStart = members.filter(m => !m.employment_start);

    const handleHolidayCalculation = () => {
        if (!selectedUser) {
            return;
        }
        calculateHolidays.mutate(
            { userId: selectedUser, year: selectedYear },
            { onSuccess: () => showNotification({ header: 'Holidays calculated!' }) },
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
            <h3>Control Panel</h3>
            <div>
                <p>Which holiday year to calculate</p>
                <SelectPicker
                    onChange={val => {
                        if (!val) {
                            return;
                        }
                        setYear(val);
                    }}
                    value={selectedYear}
                    data={options}
                    searchable={false}
                />
            </div>
            <div>
                <p>Which user to calculate for</p>
                <SelectPicker
                    onChange={val => {
                        if (!val) {
                            return;
                        }
                        setUser(val);
                    }}
                    value={selectedUser}
                    data={hasEmploymentStart.map(emp => {
                        return { value: emp.id, label: `${emp.lastname} ${emp.firstname}` };
                    })}
                    searchable={false}
                />
            </div>
            <div>
                <Button
                    onClick={handleHolidayCalculation}
                    appearance="primary"
                    color="red"
                    size="lg"
                >
                    Calculate holidays
                </Button>
            </div>
            <Divider />
            <h4>Users who have employment data set ({hasEmploymentStart.length})</h4>
            <Button
                onClick={() => {
                    setShowEmploymentStarts(!showEmploymentStarts);
                }}
            >
                {showEmploymentStarts ? 'Hide' : 'Show'}
            </Button>
            {showEmploymentStarts ? <MemberTable data={hasEmploymentStart} /> : null}
            <Divider />
            <h4>Users missing employment-start data ({missingEmploymentStart.length})</h4>
            <Button
                onClick={() => {
                    setShowMissingEmployment(!showMissingEmployment);
                }}
            >
                {showMissingEmployment ? 'Hide' : 'Show'}
            </Button>
            {showMissingEmployment ? <MemberTable data={missingEmploymentStart} /> : null}
        </div>
    );
}
