import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { TableProps } from 'rsuite';

import lambdaApi from '../api/LambdaApi';
import {
    InternalProjectList,
    InternalProjectModel,
    InternalProjectRequestPayload,
} from '../generated-types/internal_project';

type Props = {
    projectId?: number;
    sort?: {
        order: TableProps['sortType'];
        field: 'name';
    };
};

export function useInternalProjects(props?: Props) {
    const { data, isLoading, isSuccess } = useQuery<InternalProjectList>(['internalProjects'], () =>
        lambdaApi.get('/internal/projects', {}).then(res => res.data),
    );

    const createInternalProject = useMutation<
        InternalProjectModel,
        AxiosError,
        InternalProjectRequestPayload
    >(payload => {
        return lambdaApi.post('/internal/projects', payload).then(res => res.data);
    });

    const updateInternalProject = useMutation<
        InternalProjectModel,
        AxiosError,
        { payload: InternalProjectRequestPayload; id: number }
    >(props => {
        const { id, payload } = props;
        return lambdaApi.put(`/internal/projects/${id}`, payload).then(res => res.data);
    });

    const deleteInternalProject = useMutation<null, AxiosError, number>(id => {
        return lambdaApi.delete(`/internal/projects/${id}`).then(res => res.data);
    });

    let filteredData: InternalProjectList = [];
    if (data) {
        filteredData = data;
        if (props?.projectId) {
            filteredData = data.filter(project => project.id === props.projectId);
        }
        if (props?.sort) {
            const { order, field } = props.sort;
            filteredData.sort((a, b) => {
                return a[field] < b[field] ? -1 : 1;
            });
            if (order === 'desc') {
                filteredData.reverse();
            }
        }
    }
    return {
        createInternalProject,
        updateInternalProject,
        deleteInternalProject,
        internalProjects: filteredData,
        isLoading,
        isSuccess,
    };
}
