import { EarningsModel } from 'generated-types/mycareer';
import { useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';

type Props = {
    year: number;
    userId: number;
};

type Output = {
    earnings?: EarningsModel;
    isLoading: boolean;
};

export function useMyCareer({ year, userId }: Props): Output {
    const { data, isLoading } = useQuery<EarningsModel>(['earnings', userId, year], () =>
        lambdaApi.get(`/earnings/${userId}/${year}`, {}).then(res => res.data),
    );

    if (data) {
        return {
            earnings: data,
            isLoading: false,
        };
    }

    return {
        isLoading: isLoading,
    };
}
