import CharacterLock from '@rsuite/icons/CharacterLock';
import ShieldIcon from '@rsuite/icons/Shield';
import { Table } from 'rsuite';

type TableData = {
    __image_url: string;
    __is_admin: boolean;
    is_active: boolean;
};

const { Cell } = Table;

export function ImageCell({ ...props }) {
    const { rowData } = props as { rowData: TableData };
    return (
        <Cell {...props} style={{ padding: 0 }}>
            <div
                style={{
                    width: 40,
                    height: 40,
                    background: '#f5f5f5',
                    borderRadius: 20,
                    marginTop: 2,
                    marginLeft: 10,
                    marginRight: 0,
                    marginBottom: 2,
                    overflow: 'hidden',
                    display: 'inline-block',
                }}
            >
                <img alt="avatar" src={rowData.__image_url} width="44" />
            </div>

            <div
                style={{
                    display: 'inline-block',
                }}
            >
                {rowData.__is_admin && (
                    <ShieldIcon style={{ paddingBottom: 2, color: 'primary' }} />
                )}
                &nbsp;
                {!rowData.is_active && <CharacterLock style={{ color: 'red' }} />}
            </div>
        </Cell>
    );
}
