import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../../api/LambdaApi';
import {
    AssignmentModel,
    AssignmentModelList,
    AssignmentRequestPayload,
} from '../../generated-types/assignment';
import { useProfile } from '../useProfile';

type Props = {
    projectId?: number | undefined;
};

export function useAssignments(props?: Props) {
    const { isAdmin } = useProfile();
    const { data, isSuccess, refetch } = useQuery<AssignmentModelList>(
        'assignments',
        () => lambdaApi.get('/assignments', {}).then(res => res.data),
        { enabled: isAdmin },
    );

    const createAssignment = useMutation<AssignmentModel, AxiosError, AssignmentRequestPayload>(
        payload => {
            return lambdaApi.post('/assignments', payload).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    const updateAssignment = useMutation<
        AssignmentModel,
        AxiosError,
        { id: number; payload: AssignmentRequestPayload }
    >(
        props => {
            const { id, payload } = props;
            return lambdaApi.put(`/assignments/${id}`, payload).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    const deleteAssignment = useMutation<AssignmentModel, AxiosError, { id: number }>(
        props => {
            const { id } = props;
            return lambdaApi.delete(`/assignments/${id}`).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    let filteredData: AssignmentModelList = [];

    if (data) {
        if (props && props?.projectId) {
            filteredData = data.filter(assignment => props.projectId === assignment.project_id);
        } else {
            filteredData = data;
        }
    }

    return {
        assignments: filteredData,
        isSuccess: isSuccess,
        createAssignment,
        deleteAssignment,
        updateAssignment,
    };
}
