import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Button } from 'rsuite';

import { WikiSheet } from './WikiSheet';

const LinkPanel = styled.div`
    display: flex;
    gap: 1em;
    justify-content: flex-end;
`;

const TopPanel = styled.div`
    display: flex;
    justify-content: space-between;
`;

const startingWikiPageId = 1;

export function WikiScreen() {
    return (
        <div>
            <TopPanel>
                <h1>Wiki</h1>
                <LinkPanel>
                    <Link to="/wiki/create">
                        <Button appearance="link">Create new</Button>
                    </Link>
                    <Link to="/wiki/list">
                        <Button appearance="link">Show as list</Button>
                    </Link>
                </LinkPanel>
            </TopPanel>
            <WikiSheet articleId={startingWikiPageId} />
        </div>
    );
}
