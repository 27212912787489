import { useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { AssignmentModel } from '../generated-types/assignment';

export function useMyAssignments() {
    const { data: myAssignments, isSuccess } = useQuery<AssignmentModel[]>('myAssignments', () =>
        lambdaApi.get('/me/assignments', {}).then(res => res.data),
    );

    return {
        myAssignments: myAssignments ?? ([] as AssignmentModel[]),
        isSuccess: isSuccess,
    };
}
