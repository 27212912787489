import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartColorSchemes } from 'styles/chartColors';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function getOptions(title: string | undefined, options: ChartOptions | undefined) {
    const optionsBase: any = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
        },
    };
    if (title) {
        optionsBase.plugins = {
            title: {},
        };
        optionsBase.plugins.title = {
            display: true,
            text: title,
        };
    }

    if (options?.yLimits) {
        optionsBase.scales = {};
        optionsBase.scales.y = {
            min: 0,
            suggestedMax: 10000,
        };
    }

    return optionsBase;
}

type Dataset = {
    label: string;
    data: number[];
};

type DatasetWithColor = Dataset & {
    color: string;
    backgroundColor: string;
};

type ChartOptions = {
    yLimits?: YLimits;
};

type YLimits = {
    yMin?: number;
    suggestedMax?: number;
};

type Props = {
    labels: string[];
    title?: string;
    datasets: Dataset[];
    options?: ChartOptions;
};

export function LineChart({ labels, title, datasets, options }: Props) {
    const datasetsWithColors: DatasetWithColor[] = datasets.map((dataset, idx) => {
        return {
            ...dataset,
            backgroundColor: ChartColorSchemes.Codex6[idx],
            borderColor: ChartColorSchemes.Codex6[idx],
            color: ChartColorSchemes.Codex6[idx],
        };
    });

    return (
        <Line
            options={getOptions(title, options)}
            data={{ labels, datasets: datasetsWithColors }}
        />
    );
}
