import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { GracePeriodList, GracePeriodModel } from '../generated-types/grace_period';

export function useMyGracePeriods() {
    const { data, refetch } = useQuery<GracePeriodList>([`myGracePeriods`], () =>
        lambdaApi.get('/me/graceperiods', {}).then(res => res.data),
    );

    const closeMyGracePeriod = useMutation<GracePeriodModel, AxiosError, { id: number }>(
        payload => {
            return lambdaApi.post(`/me/graceperiods/${payload.id}/close`).then(res => res.data);
        },
        {
            onSuccess() {
                refetch();
            },
        },
    );

    return {
        myGracePeriods: data ?? [],
        closeMyGracePeriod,
    };
}
