import EditTimeTrackDialog from 'components/dialogs/EditTimeTrackDialog';
import AdminGracePeriodAlerts from 'components/misc/AdminGracePeriodAlerts';
import TimeTrackTableScopeComponent from 'components/screens/timetracks/TimeTrackTableScopeComponent';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import TimeTrackTable from 'components/tables/TimeTrackTable';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import { useGracePeriods } from 'hooks/admin/useGracePeriods';
import { useCircles } from 'hooks/useCircles';
import { useCustomerProjects } from 'hooks/useCustomerProjects';
import { useInternalProjects } from 'hooks/useInternalProjects';
import { useLeaveTypes } from 'hooks/useLeaveTypes';
import { useMembers } from 'hooks/useMembers';
import { TimeTrackQuery, useTimeTracks } from 'hooks/useTimeTracks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Checkbox, Col, FlexboxGrid, Grid, Panel, Tooltip, Whisper } from 'rsuite';

import { TimetracksApplyParams } from '..';

export const TimeTrackScreen = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { members } = useMembers();
    const { internalProjects } = useInternalProjects();
    const { customerProjects } = useCustomerProjects();
    const { circles } = useCircles();
    const { gracePeriods } = useGracePeriods();
    const { leaveTypes } = useLeaveTypes({ identifier_name: 'SickLeave' });
    const [hasDoctorCertificate, setHasDoctorCertificate] = useState<boolean | undefined>(
        undefined,
    );
    const [doctorCertificateError, setDoctorCertificateError] = useState('');

    const { confirmLeaveTimeTracks, timetracks, ttQuery, setTtQuery, timetrackCount } =
        useTimeTracks({});

    const sickLeaveId = leaveTypes?.[0]?.id;
    const allowAcceptingLeaves = ttQuery.is_confirmed === 'unconfirmed';
    const showCertificateOption = timetracks.every(t => t.leave_type === sickLeaveId);

    const shouldShowAcceptLeaveHoursAcceptButton = (query: TimeTrackQuery, total: number) => {
        const { customer_projects = [], internal_projects = [], leave_types = [] } = query;
        return (
            customer_projects.length === 0 &&
            internal_projects.length === 0 &&
            leave_types.length > 0 &&
            total > 0
        );
    };

    const showLeaveAcceptance = shouldShowAcceptLeaveHoursAcceptButton(ttQuery, timetracks.length);

    const acceptLeaveHours = () => {
        if (showCertificateOption && !(typeof hasDoctorCertificate === 'boolean')) {
            setDoctorCertificateError('Was there a certificate delivered or not?');
            return;
        }

        confirmLeaveTimeTracks.mutate([timetracks, hasDoctorCertificate], {
            onError: err => {
                showErrorNotification(err);
            },
            onSuccess: () => {
                showNotification({
                    header: 'Leave hours confirmed!',
                });
            },
        });
    };

    const handleDoctorCertificateToggle = () => {
        setHasDoctorCertificate(!hasDoctorCertificate);
        setDoctorCertificateError('');
    };

    const availableFilters = {
        memberPicker: true,
        projectPicker: true,
        datePicker: true,
        filterUnconfirmed: true,
    };

    const handleSearchParamsArray = (name: string) => {
        const params = searchParams
            .get(name)
            ?.split(',')
            .map(value => Number(value))
            .filter(value => value > 0);
        const paramsValid = params?.every(value => !isNaN(value));

        if (paramsValid && params) return params;
        return undefined;
    };

    const applyParams = (param: TimetracksApplyParams, value: number[] | string) => {
        if (value.length > 0) {
            searchParams.set(param, value.toString());
            setSearchParams(searchParams);
        } else {
            searchParams.delete(param);
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        const getIsConfirmed = searchParams?.get('is_confirmed');
        const is_confirmed =
            getIsConfirmed === 'confirmed'
                ? 'confirmed'
                : getIsConfirmed === 'unconfirmed'
                ? 'unconfirmed'
                : undefined;

        const init_start_date = searchParams.get('start_date') || undefined;
        const parsed_start_date = init_start_date && parseISO(init_start_date);
        const start_date =
            isValid(parsed_start_date) && parsed_start_date !== '' ? parsed_start_date : undefined;

        const init_end_date = searchParams.get('end_date') || undefined;
        const parsed_end_date = init_end_date && parseISO(init_end_date);
        const end_date =
            isValid(parsed_end_date) && parsed_end_date !== '' ? parsed_end_date : undefined;

        setTtQuery({
            ...ttQuery,
            customer_projects: handleSearchParamsArray('customer_projects'),
            members: handleSearchParamsArray('members'),
            internal_projects: handleSearchParamsArray('internal_projects'),
            leave_types: handleSearchParamsArray('leave_types'),
            start_date,
            end_date,
            is_confirmed,
        });
    }, [searchParams]);

    useEffect(() => {
        setDoctorCertificateError('');
        setHasDoctorCertificate(undefined);
    }, [ttQuery]);

    return (
        <div>
            <Grid fluid>
                <Col xs={24}>
                    <Panel bordered bodyFill style={{ marginBottom: '24px' }}>
                        <FlexboxGrid justify="space-between" align="middle">
                            <Panel>
                                <h1>Time Tracking</h1>
                            </Panel>
                            <Panel>
                                <EditTimeTrackDialog
                                    adminMode={true}
                                    optionsData={{
                                        members: members,
                                        circles: circles,
                                        internalProjects,
                                        customerProjects,
                                    }}
                                >
                                    <Button appearance="primary">Record Time</Button>
                                </EditTimeTrackDialog>
                            </Panel>
                        </FlexboxGrid>
                    </Panel>
                    <AdminGracePeriodAlerts />

                    <Panel bordered>
                        <TimeTrackTableScopeComponent
                            filterOptions={{
                                members: members,
                                circles: circles,
                                customerProjects,
                                internalProjects,
                            }}
                            availableFilters={availableFilters}
                            total={timetrackCount}
                            query={ttQuery}
                            setQuery={setTtQuery}
                            adminMode={true}
                            applyParams={applyParams}
                        ></TimeTrackTableScopeComponent>
                        {showLeaveAcceptance ? (
                            allowAcceptingLeaves ? (
                                <>
                                    <Button
                                        appearance="primary"
                                        color="green"
                                        onClick={acceptLeaveHours}
                                    >
                                        Confirm leave hours
                                    </Button>
                                    {showLeaveAcceptance && showCertificateOption && (
                                        <span>
                                            <Checkbox
                                                checked={hasDoctorCertificate}
                                                onClick={handleDoctorCertificateToggle}
                                            >
                                                Doctor certificate was delivered
                                            </Checkbox>
                                            <Checkbox
                                                checked={hasDoctorCertificate === false}
                                                onClick={handleDoctorCertificateToggle}
                                            >
                                                No doctor certificate
                                            </Checkbox>
                                            {doctorCertificateError ? (
                                                <p style={{ color: 'red' }}>
                                                    {doctorCertificateError}
                                                </p>
                                            ) : null}
                                        </span>
                                    )}
                                </>
                            ) : (
                                <Whisper
                                    speaker={
                                        <Tooltip>Leave-confirmed needs to be set to No</Tooltip>
                                    }
                                >
                                    <span>
                                        <Button
                                            disabled={true}
                                            style={{ pointerEvents: 'none' }}
                                            appearance="primary"
                                            color="green"
                                        >
                                            Confirm leave hours
                                        </Button>
                                    </span>
                                </Whisper>
                            )
                        ) : null}

                        <TimeTrackTable
                            adminMode={true}
                            data={timetracks}
                            gracePeriods={gracePeriods}
                            query={ttQuery}
                        />
                    </Panel>
                </Col>
            </Grid>
        </div>
    );
};
