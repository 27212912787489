import styled from '@emotion/styled';
import { SimpleTable } from 'components/tables/common/Simpletable';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button, Loader, Nav, Navbar } from 'rsuite';

import { useHolidays } from '../../../hooks/useHolidays';
import { useMember } from '../../../hooks/useMember';
import { ProfileBar } from '../home/ProfileBar';
import { SalaryChart } from './charts/SalaryChart';
import { HolidayCalculationDefinition } from './HolidayCalculationDefinition';

const StyledButtons = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export function MyCareerScreen() {
    const { userId } = useParams<{ userId: string }>();
    const { user, isLoading } = useMember({ userId });
    const [searchParams, setSearchParams] = useSearchParams();
    const [showHolidayDefinition, setShowHolidayDefinition] = useState(false);
    const { holidays } = useHolidays({ userId });

    const thisYear = new Date().getFullYear();
    const year = searchParams.get('year');
    const yearsNavbar = [-2, -1, 0, 1].map(yearAdd => {
        return thisYear + yearAdd;
    });

    if (!user && !isLoading) {
        return <p>No such user exists</p>;
    }

    if (!holidays) {
        return null;
    }

    const tableData = holidays.map(value => {
        const { year, holidays_accrued, month_by_month } = value;
        return [year, holidays_accrued, month_by_month];
    });

    useEffect(() => {
        if (!year) {
            setSearchParams(`?year=${thisYear}`);
        }
    }, [year]);

    return (
        <div>
            <h3>My Career (alpha)</h3>
            {user ? (
                <ProfileBar
                    firstName={user.firstname}
                    lastName={user.lastname}
                    imageUrl={user.image_url}
                />
            ) : null}
            <p>
                <>
                    Employment started:{' '}
                    {user?.employment_start
                        ? user.employment_start.toDateString()
                        : 'Info not available'}
                </>
            </p>

            <StyledButtons>
                <Button onClick={() => setShowHolidayDefinition(!showHolidayDefinition)}>
                    Show definition for holiday calculations
                </Button>
            </StyledButtons>
            {showHolidayDefinition ? <HolidayCalculationDefinition /> : null}
            <h2>Holidays earned</h2>
            {isLoading ? (
                <Loader />
            ) : (
                <SimpleTable
                    headers={['Year', 'Holidays-earned', 'Month-by-month']}
                    rows={tableData}
                />
            )}

            <div style={{ marginTop: '2em' }}>
                <h2>Salary {year}</h2>
                <p>Please note, can be vastly inaccurate</p>
                <Navbar appearance="inverse">
                    <Nav>
                        {yearsNavbar.map(year => {
                            return (
                                <Nav.Item
                                    onClick={() => setSearchParams(`?year=${year}`)}
                                    eventKey={String(year)}
                                    key={`nav_year_${year}`}
                                >
                                    {year}
                                </Nav.Item>
                            );
                        })}
                    </Nav>
                </Navbar>

                {user?.id && year ? <SalaryChart userId={user.id} year={Number(year)} /> : null}
            </div>
        </div>
    );
}
