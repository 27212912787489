import { zodResolver } from '@hookform/resolvers/zod';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import PlusIcon from '@rsuite/icons/Plus';
import { queryClient } from 'App';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { MYSQL_DATE_STRING } from 'config';
import format from 'date-fns/format';
import { useHolidays } from 'hooks/useHolidays';
import { useProfile } from 'hooks/useProfile';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Col,
    DateRangePicker,
    IconButton,
    Input,
    Row,
    SelectPicker,
    Tooltip,
    Whisper,
} from 'rsuite';

import { useLeaveTypes } from '../../../hooks/useLeaveTypes';
import { TimeTrackQuery, useTimeTracks } from '../../../hooks/useTimeTracks';
import { LeaveTimeTrackRange, LeaveTimeTrackRangeType } from '../../../schema/timetracks';
import { scrollToElement } from '.';
import { getMaxAllowedHolidaysForUI } from './date-hour-utils';
import { ErrorParagraph, formStyles } from './styles';

type Props = {
    selectedDate: Date;
    ttQuery: TimeTrackQuery;
};

const { allowedMaxDays } = DateRangePicker;

export function LogLeaveFormNew({ selectedDate, ttQuery }: Props) {
    const {
        formState: { errors },
        control,
        handleSubmit,
        setValue,
        register,
    } = useForm<LeaveTimeTrackRangeType>({
        resolver: zodResolver(LeaveTimeTrackRange),
    });
    const { leaveTypes } = useLeaveTypes();
    const { user } = useProfile();
    const { remainingHolidays } = useHolidays({ userId: String(user?.id) });
    const { addPersonalTimeTrack, addAbsenceTimeTrack } = useTimeTracks({});
    const [leaveType, setLeaveType] = useState<null | number>();
    const [dateRange, setDateRange] = useState<undefined | [Date, Date]>();
    const [paidLeaveSelected, setPaidLeaveSelected] = useState(false);
    const leaveOptions = leaveTypes.map(leave => {
        return { value: leave.id, label: leave.name };
    });

    const paidLeaveId = leaveTypes.find(l => {
        return l.identifier_name === 'PaidLeave';
    })?.id;

    const onSubmit: SubmitHandler<LeaveTimeTrackRangeType> = data => {
        const { date_range, member_id, comment, ...rest } = data;
        addAbsenceTimeTrack.mutate(
            {
                comment: comment || '',
                date_range: [
                    format(date_range[0], MYSQL_DATE_STRING),
                    format(date_range[1], MYSQL_DATE_STRING),
                ],
                member_id: Number(member_id),
                ...rest,
            },
            {
                onSuccess: () => {
                    setValue('comment', '');
                    handleChangeLeaveType(null);
                    showNotification({ header: 'Absence recorded succesfully.' });
                    queryClient.invalidateQueries([ttQuery]);
                    queryClient.invalidateQueries([`holiday-count-${userId}`]);
                    scrollToElement('header');
                },
                onError: err => {
                    showErrorNotification(err);
                },
            },
        );
    };

    const handleChangeLeaveType = (leave: number | null) => {
        if (!leave) {
            setValue('leave_type', 0);
            setLeaveType(null);
        } else {
            setValue('leave_type', leave);
            setLeaveType(leave);
        }

        setPaidLeaveSelected(leave === paidLeaveId && paidLeaveId !== undefined);
    };

    const handleChangeDateRange = (dateRange: [Date, Date] | null) => {
        if (!dateRange) {
            setDateRange(undefined);
        } else {
            setDateRange(dateRange);
            setValue('date_range', dateRange);
        }
    };

    const userId = user?.id;

    useEffect(() => {
        setValue('date_range', [selectedDate, selectedDate]);
    }, [selectedDate]);

    return (
        <form className={formStyles} onSubmit={handleSubmit(onSubmit)}>
            {userId && <input hidden {...register('member_id')} defaultValue={userId} />}
            <Row>
                <Col xl={6}>
                    <p>Leave type</p>
                    <Controller
                        name="leave_type"
                        control={control}
                        render={({ field }) => (
                            <SelectPicker
                                searchable={false}
                                {...field}
                                onChange={handleChangeLeaveType}
                                value={leaveType}
                                data={leaveOptions}
                            />
                        )}
                    />
                    <ErrorParagraph>{errors?.leave_type?.message}</ErrorParagraph>
                </Col>
            </Row>
            {paidLeaveSelected && <p>You have {remainingHolidays} paid-holidays left.</p>}
            <Row>
                <p>Leave range</p>
                <Col>
                    <Controller
                        name="date_range"
                        control={control}
                        render={({ field }) => (
                            <DateRangePicker
                                defaultValue={[selectedDate, selectedDate]}
                                disabled={
                                    !leaveType || (paidLeaveSelected && remainingHolidays === 0)
                                }
                                placement="top"
                                isoWeek
                                showWeekNumbers
                                value={dateRange}
                                onChange={handleChangeDateRange}
                                // @ts-ignore
                                disabledDate={
                                    paidLeaveSelected
                                        ? // @ts-ignore
                                          allowedMaxDays(
                                              getMaxAllowedHolidaysForUI(remainingHolidays),
                                          )
                                        : null
                                }
                            />
                        )}
                    />
                </Col>
                {leaveType && (
                    <Col>
                        <Whisper
                            placement="bottom"
                            speaker={
                                <Tooltip>
                                    Select your entire absence in one go. Finnish law regarding
                                    absences and holidays will be automatically taken into account
                                    (ex. Saturday-rule, weekends, public-holidays etc.)
                                </Tooltip>
                            }
                        >
                            <InfoOutlineIcon />
                        </Whisper>
                    </Col>
                )}
            </Row>
            <ErrorParagraph>{errors?.date_range?.message}</ErrorParagraph>
            <Row>
                <p>Comment</p>
                <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => (
                        <Input
                            as="textarea"
                            placeholder={'Leave comment can be empty'}
                            type="text"
                            rows={3}
                            {...field}
                        />
                    )}
                />
                <ErrorParagraph>{errors?.comment?.message}</ErrorParagraph>
            </Row>
            <Row>
                <Col>
                    <IconButton
                        icon={<PlusIcon />}
                        type="submit"
                        disabled={
                            !leaveType ||
                            (paidLeaveSelected && remainingHolidays === 0) ||
                            addPersonalTimeTrack.isLoading
                        }
                        loading={addPersonalTimeTrack.isLoading}
                        appearance="primary"
                        color="green"
                        placement="right"
                        size="md"
                    >
                        Add new leave
                    </IconButton>
                </Col>
            </Row>
        </form>
    );
}
