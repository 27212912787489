import format from 'date-fns/format';
import parse from 'date-fns/parse';
import * as z from 'zod';

import { MYSQL_DATE_STRING } from '../config';

export const mysqlDateToString = z.date().transform(datev => {
    return format(datev, MYSQL_DATE_STRING);
});

export const mysqlDate = z.preprocess(val => {
    if (!val) {
        return;
    }

    return parse(val as string, 'yyyy-MM-dd', new Date());
}, z.date().optional());

export const strToNumber = z.preprocess(
    val => (Number(val) ? Number(val) : undefined),
    z.number().nonnegative(),
);
